import * as moment from 'moment';
import { cloneDeep } from 'lodash-es';

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

import { isEmpty, isValid, NotifyService, PopupService, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, BfeForm, CommonFeaturesComponent, CommonSearchComponent, DataService } from '@bfeoldenburg/bfe-data-forms';

import { 
  ISettings, IKursgruppe, ILizenzVorlage, ISchnittstelleWiedervorlage, TABLENAMES, IERPBenutzer, IKunde, IntedigiService, IKundentypBereich, IKundentypGruppe, ILizenzartBereichRolle, Rolle 
} from '@bfeoldenburg/intedigi-shared';

import { WiedervorlageService } from '../../../../_services/wiedervorlage.service';

@Component({
  selector: 'wiedervorlage-veranstaltung-form',
  templateUrl: './wiedervorlage-veranstaltung-form.component.html',
  styleUrls: ['./wiedervorlage-veranstaltung-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WiedervorlageVeranstaltungFormComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnDestroy {
  public form: BfeForm;

  public settings: ISettings = <ISettings>{};
  public kursgruppe: IKursgruppe = null;
  public kunde: IKunde;
  public kurs: any = null;
  public lizenzVorlage: ILizenzVorlage = null;
  public loading: boolean = false;

  public gruppenIDs: number[] = [];
  public rollenIDs: string[] = [];
  public bereichIDs: string[] = [];

  private hBenutzer: HTMLElement;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private elementRef: ElementRef,
    private router: Router,
    private popupService: PopupService,
    private wiedervorlageService: WiedervorlageService,
    private notifyService: NotifyService,
    private intedigiService: IntedigiService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.dataService.readData(TABLENAMES.KUNDE, false, false, true);
    this.dataService.readData(TABLENAMES.SETTINGS, false, false, true);
    this.dataService.readData(TABLENAMES.KUNDENTYPBEREICH, false, false, true);
    this.dataService.readData(TABLENAMES.KUNDENTYPGRUPPE, false, false, true);
    this.dataService.readData(TABLENAMES.LIZENZARTBEREICHROLLE, false, false, true);
    this.dataService.readData(TABLENAMES.ERP_BENUTZER, false, false, true);
    this.dataService.readData(TABLENAMES.BENUTZERAUSTAUSCH, false, false, true);
    this.dataService.readData(TABLENAMES.BENUTZER, false, false, true);
    this.dataService.readData(TABLENAMES.KURS, false, false, true);
    this.dataService.readData(TABLENAMES.LIZENZ_VORLAGE, false, false, true);
    this.dataService.readData(TABLENAMES.GRUPPE, false, false, true);
    this.dataService.readData(TABLENAMES.KUNDE_BENUTZER, false, false, true);
    this.dataService.readData(TABLENAMES.BUCHUNG_BENUTZER, false, false, true);
    this.dataService.readData(TABLENAMES.KURSGRUPPEBENUTZER, false, false, true);

    this.form = this.dataService.getForm(TABLENAMES._WIEDERVORLAGE_VERANSTALTUNG);

    this.subscriptions.push(this.wiedervorlageService.onselect.subscribe(() => this.fillForm()));
  }

  ngOnInit(): void {
    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => this.getFKeyData()));
  }

  ngAfterViewInit(): void {
    timer().subscribe(() => this.fillForm());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
  }

  isLoading(): boolean {
    return this.loading;
  }

  isWritable(): boolean {
    return this.dataService.isWritable(TABLENAMES.KURSGRUPPE);
    //return (!isEmpty(this.kundenform) ? this.kundenform.isWritable() : false);
  }

  isNew(): boolean {
    return false;
    //return (!isEmpty(this.kundenform) ? this.kundenform.isNew() : false);
  }

  isValueValid(value: any): boolean {
    return isValid(value);
  }
  
  prepareKunde(): void {
    if (!this.dataService.neverLoaded(TABLENAMES.SETTINGS)) {
      this.kunde = this.dataService.getDataset(TABLENAMES.KUNDE, [ this.settings.IDKunde, this.settings.IDBildungszentrum ]);

      this.bereichIDs = (<IKundentypBereich[]>this.dataService.getData(
        TABLENAMES.KUNDENTYPBEREICH, (item: IKundentypBereich) => item.IDKundentyp === this.kunde.IDKundentyp
      ))
      .reduce((result: string[], current: IKundentypBereich) => {
        return result.concat(current.IDBereich);
      }, <string[]>[]);

      this.gruppenIDs = (<IKundentypGruppe[]>this.dataService.getData(
        TABLENAMES.KUNDENTYPGRUPPE, (item: IKundentypGruppe) => item.IDKundentyp === this.kunde.IDKundentyp
      ))
      .reduce((result: number[], current: IKundentypGruppe) => {
        return result.concat(current.IDGruppe);
      }, <number[]>[]);
    }
  }

  resetForm(): void {
    this.form.reset();
  }

  fillForm(): void {
    if (this.wiedervorlageService.isSelected()) {
      let selected: ISchnittstelleWiedervorlage = this.wiedervorlageService.getSelected();
      let data = JSON.parse(selected.Data);

      this.kursgruppe = data.Kursgruppe;
      this.kurs = data.Kurs;

      let formdata: any = {};
      this.rollenIDs = [];

      if ('LizenzVorlage' in data && !isEmpty(data.LizenzVorlage)) {
        this.lizenzVorlage = data.LizenzVorlage;
        formdata.IDLizenzVorlage = this.lizenzVorlage.ID;
        formdata.OBJLizenzVorlage = this.lizenzVorlage;

        this.rollenIDs = (<ILizenzartBereichRolle[]>this.dataService.getData(
          TABLENAMES.LIZENZARTBEREICHROLLE, 
          (item: ILizenzartBereichRolle) => item.IDLizenzart === this.lizenzVorlage.IDLizenzart && item.IDBereich === this.lizenzVorlage.IDBereich
        ))
        .reduce((result: string[], current: ILizenzartBereichRolle) => {
          return result.concat(current.IDRolle);
        }, <string[]>[]);
      }
      
      formdata.OBJReferenten = [];
      if ('Referenten' in data && !isEmpty(data.Referenten)) {
        formdata.OBJReferenten = this.checkBenutzer(data.Referenten);
      }

      formdata.OBJTeilnehmer = [];
      if ('Teilnehmer' in data && !isEmpty(data.Teilnehmer)) {
        formdata.OBJTeilnehmer = this.checkBenutzer(data.Teilnehmer);
      }

      if (this.form.hasData()) {
        this.form.updateValues(formdata, true);
      } else {
        this.form.setValues(formdata);
      }

      this.detectChanges();
    }
  }
  
  checkBenutzer(data: any[]): any[] {
    return data.reduce((result: any[], current: any) => {
      if (!('Benutzer' in current)) {
        let erpbenutzer: IERPBenutzer[] = <IERPBenutzer[]>this.dataService.getData(
          TABLENAMES.ERP_BENUTZER, (item: IERPBenutzer) => item.ERPID === current.ERPBenutzer.ERPID
        );
        if (!!erpbenutzer.length) {
          current.ERPBenutzer = erpbenutzer[0];
          let benutzer = this.dataService.getDataset(TABLENAMES.BENUTZERAUSTAUSCH, [ erpbenutzer[0].IDBenutzer, erpbenutzer[0].IDBildungszentrum ]);
          if (!isEmpty(benutzer)) {
            current.Benutzer = benutzer;
          }
        }
      }
      current.Fehler = !('Benutzer' in current);
      if (isEmpty(current.Backup)) {
        current.Backup = cloneDeep(current);
      }
      return result.concat(current);
    }, <any[]>[]);
  }

  getFKeyName(table: string, key: any): string {
    return this.dataService.getDatasetName(table, key);
  }

  getDatum(date: string): string {
    return moment.utc(date).local().format('DD.MM.YYYY');
  }

  getColorError(): string {
    return this.appSettings.colorError;
  }
  
  reset(): void {
    if (this.hasChanged()) {
      this.popupService.confirm(
        'Wenn Sie die Übersicht aufrufen gehen alle Änderungen verloren. Möchten Sie fortfahren?', 
        'Übersicht aufrufen',
        () => {
          this.resetForm();
          this.wiedervorlageService.setSelected(null);
        }
      );
    } else {
      this.resetForm();
      this.wiedervorlageService.setSelected(null);
    }
  }

  hasPrevious(): boolean {
    return this.wiedervorlageService.hasPrevious();
  }

  hasNext(): boolean {
    return this.wiedervorlageService.hasNext();
  }

  previous(): void {
    if (this.hasChanged()) {
      this.popupService.confirm(
        'Wenn Sie den Datensatz wechseln gehen alle Änderungen verloren. Möchten Sie fortfahren?', 
        'Vorherigen Datensatz aufrufen',
        () => {
          this.resetForm();
          this.wiedervorlageService.previous();
        }
      );
    } else {
      this.resetForm();
      this.wiedervorlageService.previous();
    }
  }

  next(): void {
    if (this.hasChanged()) {
      this.popupService.confirm(
        'Wenn Sie den Datensatz wechseln gehen alle Änderungen verloren. Möchten Sie fortfahren?', 
        'Nächsten Datensatz aufrufen',
        () => {
          this.resetForm();
          this.wiedervorlageService.next();
        }
      );
    } else {
      this.resetForm();
      this.wiedervorlageService.next();
    }
  }

  save(): void {
    this.loading = true; 
    this.detectChanges();

    let data = JSON.parse(this.wiedervorlageService.getSelected().Data);

    data.LizenzVorlage = this.form.getFormValue('OBJLizenzVorlage');
    data.Kursgruppe.IDLizenzVorlage = this.form.getFormValue('IDLizenzVorlage');

    data.Teilnehmer = this.form.getFormValue('OBJTeilnehmer');
    data.Referenten = this.form.getFormValue('OBJReferenten');

    data.Wiedervorlage = {
      Kontext: this.wiedervorlageService.getSelected().Kontext,
      _uuid: this.wiedervorlageService.getSelected()._uuid
    };
    
    this.wiedervorlageService.save(
      data, 
      () => { 
        this.loading = false; 
        this.detectChanges();
      }, 
      (error: string) => { 
        this.notifyService.error(error);
        this.loading = false; 
        this.detectChanges();
      }
    );
  }

  isReady(): boolean {
    return !isEmpty(this.form);
  }

  benutzerValid(objname: string): boolean {
    if (!isEmpty(this.form)) {
      let data = this.form.getFormValue(objname);
      if (!isEmpty(data)) {
        return data.reduce((result: boolean, current: any) => {
          if (current.Fehler) {
            result = false; 
          }
          return result;
        }, <boolean>true);
      }
    }
    return false;
  }

  benutzerChanged(objname: string): boolean {
    if (!isEmpty(this.form)) {
      let data = this.form.getFormValue(objname);
      if (!isEmpty(data)) {
        return data.reduce((result: boolean, current: any) => {
          if ('Selected' in current && isValid(current.Selected.ID)) {
            result = true; 
          }
          return result;
        }, <boolean>false);
      }
    }
    return false;
  }

  isValid(): boolean {
    return (
      !isEmpty(this.settings) && isValid(this.settings.IDKunde) &&
      (!isEmpty(this.form) ? this.form.valid : true) && 
      this.benutzerValid('OBJReferenten') && 
      this.benutzerValid('OBJTeilnehmer')
    );
  }

  hasChanged(): boolean {
    return (
      (!isEmpty(this.form) && this.form.hasChanged()) || 
      this.benutzerChanged('OBJReferenten') || 
      this.benutzerChanged('OBJTeilnehmer')
    );
  }

  getInfo(): string {
    if (!this.isValid()) {
      if (isEmpty(this.settings) || !isValid(this.settings.IDKunde)) {
        return 'Bitte legen Sie den Kunden in Ihren Einstellungen fest';
      } else if (isEmpty(this.form) || !this.form.valid) {
        return 'Wählen Sie eine Lizenz-Vorlage aus';
      } else if (!this.benutzerValid('OBJReferenten')) {
        return 'Ergänzen Sie bitte die fehlenden Referenten';
      } else if (!this.benutzerValid('OBJTeilnehmer')) {
        return 'Ergänzen Sie bitte die fehlenden Teilnehmer';
      } else {
        return 'Unbekannter Fehler';
      }
    } else {
      return 'Sie können die Daten nun übernehmen';
    }
  }
  
  getFKeyData(): void {
    this.addDataSubscription(TABLENAMES.KURS, () => this.detectChanges());
    this.addDataSubscription(TABLENAMES.LIZENZ_VORLAGE, () => this.detectChanges());
    this.addDataSubscription(TABLENAMES.BENUTZERAUSTAUSCH, () => this.detectChanges());

    this.addDataSubscription(TABLENAMES.SETTINGS, (data: ISettings[]) => {
      this.settings = null;
      if (!!data.length) {
        this.settings = data[0];
      }
      this.prepareKunde();
      this.detectChanges();
    });

    let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
    if (!!settings.length) {
      this.settings = settings[0];
    }
    this.prepareKunde();
    this.detectChanges();
  }

  selectLizenzVorlage(): void {
    this.popupService.open(
      CommonSearchComponent, 
      {
        columns: this.dataService.getSearchColumns(TABLENAMES.LIZENZ_VORLAGE),
        tablename: TABLENAMES.LIZENZ_VORLAGE,
        tabletitle: 'Lizenz-Vorlage',
        minWidth: 1600,
        cbFilter: (dataset: ILizenzVorlage) => {
          return !!dataset.ERPKurs && this.bereichIDs.indexOf(dataset.IDBereich) > -1;
        }
      },
      {
        onselect: (dataset: any) => { 
          this.lizenzVorlage = this.dataService.getDataset(TABLENAMES.LIZENZ_VORLAGE, dataset.ID);
          this.form.setFormValue('IDLizenzVorlage', this.lizenzVorlage.ID);
          this.form.setFormValue('OBJLizenzVorlage', this.lizenzVorlage);

          this.detectChanges();
        },
        oncancel: () => {  }
      }
    );
  }

  getBenutzerWidth(): number {
    if (!this.hBenutzer) {
      this.hBenutzer = this.elementRef.nativeElement.querySelector('#data-benutzer');
    }
    if (!!this.hBenutzer) {
      return this.hBenutzer.offsetWidth - 20;
    }
    return 0;
  }

  getRollenIDs(referent?: boolean) {
    if (referent) {
      return [ Rolle.AUSBILDER, Rolle.DOZENT ];
    } else {
      return this.rollenIDs;
    }
  }

  getReferenten(): any[] {
    let data = this.form.getFormValue('OBJReferenten');
    if (!isEmpty(data)) {
      return data;
    } else {
      return [];
    }
  }

  getTeilnehmer(): any[] {
    let data = this.form.getFormValue('OBJTeilnehmer');
    if (!isEmpty(data)) {
      return data;
    } else {
      return [];
    }
  }

  onReferentenChanged(data: any[]): void {
    this.form.setFormValue('OBJReferenten', this.checkBenutzer(data));
    this.detectChanges();
  }

  onTeilnehmerChanged(data: any[]): void {
    this.form.setFormValue('OBJTeilnehmer', this.checkBenutzer(data));
    this.detectChanges();
  }
}
