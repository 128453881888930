<div class="extended-form" (window:resize)="onResize()" [style.minHeight]="getContentHeight()">
  <div class="data-buttons">
    <div class="left">
      <button pButton type="button" icon="icon-navigate_left" class="p-button-outlined" (click)="previous()" [disabled]="!hasPrevious() || isLoading()"></button>
      <button pButton type="button" label="Übersicht" class="p-button-outlined" (click)="reset()" [disabled]="isLoading()"></button>
      <button pButton type="button" icon="icon-navigate_right" class="p-button-outlined" (click)="next()" [disabled]="!hasNext() || isLoading()"></button>
    </div>
    <div class="info"><div class="info-text" [class.error]="!isValid()" *ngIf="isReady()">{{ getInfo() }}</div></div>
    <div class="right">
      <button pButton type="button" label="Übernehmen" [disabled]="!isValid() || isLoading()" (click)="save()" *ngIf="isWritable()"></button>
    </div>
  </div>
  <div class="form-columns">
    <div class="data-column">
      <div class="data-kursgruppe">
        <div class="form-row">
          <div class="sub-title">Lerngruppe</div>
        </div>
        <div class="form-row">
          <label class="form-label">Name</label>
          <label>{{ kursgruppe?.Name }}</label>
        </div>
        <div class="form-row">
          <label class="form-label">ERP-Nummer</label>
          <label>{{ kursgruppe?.ERPNummer }}</label>
        </div>
        <div class="form-row" *ngIf="isValueValid(kursgruppe?.Code)">
          <label class="form-label">Code</label>
          <label>{{ kursgruppe?.Code }}</label>
        </div>
        <div class="form-row" *ngIf="isValueValid(kursgruppe?.Laufzeit_von)">
          <label class="form-label">Laufzeit von</label>
          <label>{{ getDatum(kursgruppe?.Laufzeit_von) }}</label>
        </div>
        <div class="form-row" *ngIf="isValueValid(kursgruppe?.Laufzeit_bis)">
          <label class="form-label">Laufzeit bis</label>
          <label>{{ getDatum(kursgruppe?.Laufzeit_bis) }}</label>
        </div>
        <div class="form-row" *ngIf="isValueValid(kursgruppe?.IDKurs)">
          <label class="form-label">Kurs</label>
          <label>{{ getFKeyName('Kurs', kursgruppe?.IDKurs) }}</label>
        </div>
      </div>
      <div class="data-kurs" *ngIf="!isValueValid(kursgruppe?.IDLizenzVorlage)">
        <div class="form-row">
          <div class="sub-title">ERP-Kurs</div>
        </div>
        <div class="form-row">
          <label class="form-label">ID</label>
          <label>{{ kurs?.id }}</label>
        </div>
        <div class="form-row">
          <label class="form-label">Name</label>
          <label>{{ kurs?.name }}</label>
        </div>
      </div>
    </div>
    <div class="data-column">
      <div class="data-vorlage">
        <div class="form-row">
          <div class="sub-title">Lizenz-Vorlage</div>
          <div style="flex: 1; min-height: 100%;"></div>
          <button pButton type="button" icon="icon-magnifying_glass" label="Suche" [disabled]="isLoading()" (click)="selectLizenzVorlage()" *ngIf="!isValueValid(kursgruppe?.IDLizenzVorlage) && isWritable()"></button>
        </div>
        <div class="form-row" *ngIf="!isValueValid(lizenzVorlage?.ID)">
          <label [style.color]="getColorError()">Es wurde keine gültige Lizenz-Vorlage übergeben</label>
        </div>
        <div *ngIf="isValueValid(lizenzVorlage?.ID)">
          <div class="form-row">
            <label class="form-label">Name</label>
            <label>{{ lizenzVorlage?.Name }}</label>
          </div>
          <div class="form-row" *ngIf="isValueValid(lizenzVorlage?.IDLizenzart)">
            <label class="form-label">Lizenzart</label>
            <label>{{ getFKeyName('Lizenzart', lizenzVorlage?.IDLizenzart) }}</label>
          </div>
          <div class="form-row" *ngIf="isValueValid(lizenzVorlage?.IDBereich)">
            <label class="form-label">Lernbereich</label>
            <label>{{ getFKeyName('Bereich', lizenzVorlage?.IDBereich) }}</label>
          </div>
          <div class="form-row" *ngIf="isValueValid(lizenzVorlage?.IDPaket)">
            <label class="form-label">Paket</label>
            <label>{{ getFKeyName('Paket', lizenzVorlage?.IDPaket) }}</label>
          </div>
          <div class="form-row" *ngIf="isValueValid(lizenzVorlage?.IDLernangebot)">
            <label class="form-label">Lernangebot</label>
            <label>{{ getFKeyName('Lernangebot', lizenzVorlage?.IDLernangebot) }}</label>
          </div>
          <div class="form-row" *ngIf="isValueValid(lizenzVorlage?.IDFachrichtung)">
            <label class="form-label">Fachrichtung</label>
            <label>{{ getFKeyName('Fachrichtung', lizenzVorlage?.IDFachrichtung) }}</label>
          </div>
          <div class="form-row">
            <label class="form-label">Laufzeit</label>
            <label>{{ lizenzVorlage?.Laufzeit }} Monate</label>
          </div>
        </div>
      </div>
    </div>
    <div class="data-referenten" id="data-benutzer">
      <div class="form-row">
        <div class="sub-title">Referenten</div>
      </div>
      <wiedervorlage-sub-benutzer
        [form] ="form"
        [benutzer]="getReferenten()"
        [settings] = "settings"
        [width]="getBenutzerWidth()"
        [gruppenIDs] ="gruppenIDs"
        [rollenIDs] ="getRollenIDs(true)"
        [bereichIDs] ="bereichIDs"
        [loading]="isLoading()"
        [writable]="isWritable()"
        (onchange)="onReferentenChanged($event)"
      ></wiedervorlage-sub-benutzer>
    </div>
    <div class="data-teilnehmer">
      <div class="form-row">
        <div class="sub-title">Teilnehmer</div>
      </div>
      <wiedervorlage-sub-benutzer
        [form] ="form"
        [benutzer]="getTeilnehmer()"
        [settings] = "settings"
        [width]="getBenutzerWidth()"
        [gruppenIDs] ="gruppenIDs"
        [rollenIDs] ="getRollenIDs()"
        [bereichIDs] ="bereichIDs"
        [loading]="isLoading()"
        [writable]="isWritable()"
        (onchange)="onTeilnehmerChanged($event)"
      ></wiedervorlage-sub-benutzer>
    </div>
  </div>
</div>