import { 
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges 
} from '@angular/core';

import { 
  isObject, isValid, isEmpty, PopupService, AuthenticationService, AppSettings 
} from '@bfeoldenburg/bfe-shared';
import { ToolbarService, CommonPopupFormComponent, DataService, IStandardAuswahl } from '@bfeoldenburg/bfe-data-forms';

import { 
  IBildungszentrum, ISettings, IERPArtikelnummer, IKundentyp, IKundentyp2, ILizenzVorlage, IntedigiService, IntedigiUserService, TABLENAMES 
} from '@bfeoldenburg/intedigi-shared';


@Component({
  selector: 'erp-artikelnummer-popup',
  templateUrl: './erp-artikelnummer-popup.component.html',
  styleUrls: ['./erp-artikelnummer-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErpArtikelnummerPopupComponent extends CommonPopupFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public data: IERPArtikelnummer;

  public bildungszentren: IBildungszentrum[] = [];
  private vorlagen: ILizenzVorlage[] = [];
  public vorlagenAutocomplete: ILizenzVorlage[] = [];
  public kundentypen: IKundentyp[] = [];
  public kundentypen2: IKundentyp2[] = [];
  public nummernkreise: IStandardAuswahl[] = [
    { ID: 1, Name: 'Artikel' },
    { ID: 2, Name: 'Veranstaltungen' }
  ];
  public uebsitems: IStandardAuswahl[] = [
    { ID: 1, Name: 'Nicht relevant' },
    { ID: 2, Name: 'mit ÜBS' },
    { ID: 3, Name: 'ohne ÜBS' }
  ];

  constructor(
    dataService: DataService,
    popupService: PopupService,
    elementRef: ElementRef,
    renderer: Renderer2,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService,
    private authService: AuthenticationService,
    private intedigiUserService: IntedigiUserService
  ) { 
    super(dataService, popupService, elementRef, renderer, cdRef, toolbarService, appSettings);

    this.fkeyTables = [
      TABLENAMES.BILDUNGSZENTRUM,
      TABLENAMES.LIZENZ_VORLAGE,
      TABLENAMES.KUNDENTYP,
      TABLENAMES.KUNDENTYP2,
      TABLENAMES.SETTINGS
    ];

    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => { 
      this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));
      this.onDataChange(); 
    }));
  }

  ngOnInit() {
    super.ngOnInit();

    this.getFTables();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (!isEmpty(this.form)) {
      this.form.resetCallbackWritable();
      this.form.resetCallbackReadonly('erp-artikelnummer-popup');
      this.form.resetCallbackRequired('erp-artikelnummer-popup');
    }
  }

  onResize() {
    this.detectChanges();
  }

  isWritable(): boolean {
    return (
      super.isWritable() && this.isLokal() && 
      this.intedigiService.isInterfaceWritable(this.tablename, (!isEmpty(this.form) ? this.form.getValues() : {}), this.isNew())
    );
  }

  isDeletable(): boolean {
    return (
      super.isDeletable() && this.isLokal() && 
      this.intedigiService.isInterfaceWritable(this.tablename, (!isEmpty(this.form) ? this.form.getValues() : {}), this.isNew())
    );
  }

  cbIsReadonly(field: string): boolean {
    switch (field) {
      case 'UeBS': {
        return !this.isBetriebTeilnehmerAusbildungsLizenz() && !this.isBetriebAusbilderAusbildungsLizenz();
      }
      case 'Nummernkreis': {
        return true;
      }
    }
    return false;
  }

  cbIsRequired(field: string): boolean {
    switch (field) {
      case 'UeBS': {
        return this.isBetriebTeilnehmerAusbildungsLizenz() || this.isBetriebAusbilderAusbildungsLizenz();
      }
    }
    return false;
  }

  onFormInit() {
    this.onDataSet();

    this.form.setCallbackWritable(
      () => this.intedigiService.isInterfaceWritable(this.tablename, (!isEmpty(this.form) ? this.form.getValues() : {}), this.isNew())
    );
    this.form.setCallbackReadonly('erp-artikelnummer-popup', (field: string) => this.cbIsReadonly(field));
    this.form.setCallbackRequired('erp-artikelnummer-popup', (field: string) => this.cbIsRequired(field));
  }

  onDataSet() {
    this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));

    // this.setBildungszentrum(this.form.getFormValue('IDBildungszentrum'));
    this.setLizenzVorlage(this.form.getFormValue('IDLizenzVorlage'));
    this.setKundentyp(this.form.getFormValue('IDKundentyp'));
    this.setKundentyp2(this.form.getFormValue('IDKundentyp2'));
    this.setNummernkreis(this.form.getFormValue('Nummernkreis'));
    this.setUeBSItem(this.form.getFormValue('UeBS'));
    this.onDataChange();
  }

  onDataGet() {
    if (this.isLokal() && this.isNew()) {
      let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
      if (!!settings.length) {
        this.setNummernkreis(settings[0].ERPNummernkreis);
      }
    }
    
    this.setBildungszentrum();
    this.setLizenzVorlage();
    this.setKundentyp();
    this.setKundentyp2();
    this.setNummernkreis();
    this.setUeBSItem();

    if (this.form.isReadonly('UeBS')) {
      this.setUeBSItem(1);
    }
  }

  setBildungszentrum(value?: any) {
    this.setFormObject(TABLENAMES.BILDUNGSZENTRUM, value);
  }

  onBildungszentrumChange() {
    this.setBildungszentrum();
    this.onDataChange();
  }

  setLizenzVorlage(value?: any) {
    this.setFormObject(TABLENAMES.LIZENZ_VORLAGE, value);
  }

  onLizenzVorlageChange() {
    this.setLizenzVorlage();
    this.onDataChange();
  }

  filterVorlagenAutocomplete(event: any) {
    let queryparts = event.query.split(' ');
    this.vorlagenAutocomplete = this.vorlagen.reduce((result: ILizenzVorlage[], current: ILizenzVorlage) => {
      let i = 0, iMax = queryparts.length;
      for(i; i < iMax; i++) {
        if(queryparts[i].length > 0 && current.Search.indexOf(queryparts[i].toLowerCase()) == -1) {
          return result;
        }
      }
      return result.concat(current);
    }, <ILizenzVorlage[]>[]); 
  }

  setKundentyp(value?: any) {
    let obj = this.form.getFormValue('OBJKundentyp');
    if (value === -1 || (isObject(value) && value.ID === -1) || (isObject(obj) && obj.ID === -1)) {
      this.form.setFormValue('OBJKundentyp', { ID: -1, Name: 'Nicht relevant' });
      this.form.setFormValue('IDKundentyp', -1);
    } else {
      this.setFormObject(TABLENAMES.KUNDENTYP, value);
    }
  }

  onKundentypChange() {
    this.setKundentyp();
    this.onDataChange();
  }

  setKundentyp2(value?: any) {
    let obj = this.form.getFormValue('OBJKundentyp2');
    if (value === -1 || (isObject(value) && value.ID === -1) || (isObject(obj) && obj.ID === -1)) {
      this.form.setFormValue('OBJKundentyp2', { ID: -1, Name: 'Nicht relevant' });
      this.form.setFormValue('IDKundentyp2', -1);
    } else {
      this.setFormObject(TABLENAMES.KUNDENTYP2, value);
    }
  }

  onKundentyp2Change() {
    this.setKundentyp2();
    this.onDataChange();
  }

  setNummernkreis(value?: any) {
    console.log('nummernkreise', this.nummernkreise);
    console.log('value', value);
    this.setFormObjectStandardAuswahl('OBJNummernkreis', 'Nummernkreis', this.nummernkreise, value);
    console.log('OBJNummernkreis', this.form.getFormValue('OBJNummernkreis'));
    console.log('Nummernkreis', this.form.getFormValue('Nummernkreis'));
  }

  onNummernkreisChange() {
    this.setNummernkreis();
    this.onDataChange();
  }

  setUeBSItem(value?: any) {
    this.setFormObjectStandardAuswahl('OBJUeBS', 'UeBS', this.uebsitems, value);
  }

  onUeBSItemChange() {
    this.setUeBSItem();
    this.onDataChange();
  }

  onUmkehrenChange() {
    this.onDataChange();
  }

  getBildungszentren(data: IBildungszentrum[]) {
    this.bildungszentren = [];
    if (this.intedigiService.isInterfaceLocal()) {
      this.bildungszentren = [ <IBildungszentrum>this.dataService.getDataset(TABLENAMES.BILDUNGSZENTRUM, this.intedigiService.getBildungszentrum()) ];
      if (!isEmpty(this.form) && !this.dataService.neverLoaded(TABLENAMES.BILDUNGSZENTRUM) && !this.dataService.neverLoaded(TABLENAMES.SETTINGS)) {
        this.setBildungszentrum(this.intedigiService.getBildungszentrum());
      }
    } else {
      this.bildungszentren = data.reduce((result: IBildungszentrum[], current: IBildungszentrum) => {
        if (
          (!current.LokaleInstanz && (this.authService.isAdministrator() || current.ID === this.intedigiUserService.getBildungszentrum())) || 
          (!isEmpty(this.data) && current.ID === this.data.IDBildungszentrum)
        ) {
          return result.concat(current);
        }
        return result;
      }, <IBildungszentrum[]>[]);
      if (!isEmpty(this.form) && !this.dataService.neverLoaded(TABLENAMES.BILDUNGSZENTRUM)) {
        this.setBildungszentrum(this.form.getFormValue('IDBildungszentrum'));

        if (!isValid(this.form.getFormValue('IDBildungszentrum'))) {
          this.setBildungszentrum(this.intedigiUserService.getBildungszentrum());
        }
      }
    }
    this.detectChanges();
  }

  getFTables() {
    this.addDataSubscription(TABLENAMES.SETTINGS, (data: ISettings[]) => {
      if (!!data.length && !isEmpty(this.form)) {
        this.setNummernkreis(data[0].ERPNummernkreis);
      }
    });

    this.addDataSubscription(TABLENAMES.BILDUNGSZENTRUM, (data: IBildungszentrum[]) => {
      this.getBildungszentren(data);
    });

    this.addDataSubscription(TABLENAMES.LIZENZ_VORLAGE, (data: ILizenzVorlage[]) => {
      this.vorlagen = data;
      if (!isEmpty(this.form)) {
        this.setLizenzVorlage(this.form.getFormValue('IDLizenzVorlage'));
      }
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.KUNDENTYP, (data: IKundentyp[]) => {
      this.kundentypen = [ <IKundentyp>{ ID: -1, Name: 'Nicht relevant' } ].concat(data);
      if (!isEmpty(this.form)) {
        this.setKundentyp(this.form.getFormValue('IDKundentyp'));
      }
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.KUNDENTYP2, (data: IKundentyp2[]) => {
      this.kundentypen2 = [ <IKundentyp2>{ ID: -1, Name: 'Nicht relevant' } ].concat(data);
      if (!isEmpty(this.form)) {
        this.setKundentyp2(this.form.getFormValue('IDKundentyp2'));
      }
      this.detectChanges();
    });

    let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
    if (!!settings.length && !isEmpty(this.form)) {
      this.setNummernkreis(settings[0].ERPNummernkreis);
    }
    this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));
    this.vorlagen = <ILizenzVorlage[]>this.dataService.getData(TABLENAMES.LIZENZ_VORLAGE);
    this.kundentypen = [ <IKundentyp>{ ID: -1, Name: 'Nicht relevant' } ].concat(<IKundentyp[]>this.dataService.getData(TABLENAMES.KUNDENTYP));
    this.kundentypen2 = [ <IKundentyp2>{ ID: -1, Name: 'Nicht relevant' } ].concat(<IKundentyp2[]>this.dataService.getData(TABLENAMES.KUNDENTYP2));
    this.detectChanges();
  }

  /** Abfrage ob sich der Formular-Inhalt geändert hat */
  hasChanged(): boolean {
    return (
      this.form.hasChanged() && 
      (
        !this.isNew() || 
        !(Object.keys(this.form.getValues(true)).length === 2 && this.form.hasColumnChanged('IDBildungszentrum') && this.form.hasColumnChanged('Nummernkreis'))
      )
    );
  }

  isLokal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }

  isBetriebTeilnehmerAusbildungsLizenz(): boolean {
    if (!isEmpty(this.form.getFormValue('OBJLizenzVorlage')) && !isEmpty(this.form.getFormValue('OBJKundentyp'))) {
      return (
        this.form.getFormValue('OBJLizenzVorlage').IDLizenzart === 1 && this.form.getFormValue('OBJLizenzVorlage').IDBereich === 'ausbildung' && 
        this.form.getFormValue('OBJKundentyp').IDLDAPTyp === 1
      );
    } else {
      return false;
    }
  }

  isBetriebAusbilderAusbildungsLizenz(): boolean {
    if (!isEmpty(this.form.getFormValue('OBJLizenzVorlage')) && !isEmpty(this.form.getFormValue('OBJKundentyp'))) {
      return (
        this.form.getFormValue('OBJLizenzVorlage').IDLizenzart === 2 && this.form.getFormValue('OBJLizenzVorlage').IDBereich === 'ausbildung' && 
        this.form.getFormValue('OBJKundentyp').IDLDAPTyp === 1
      );
    } else {
      return false;
    }
  }

  getNummerBezeichner(): string {
    return (this.form.getFormValue('Nummernkreis') === 1 ? 'Artikel-ID' : 'Veranstaltungs-ID');
  }

  getNummerVerlaengerungBezeichner(): string {
    return (this.form.getFormValue('Nummernkreis') === 1 ? 'AID Verlängerung' : 'VID Verlängerung');
  }

  isVerlaengerungAktiv(): boolean {
    return (
      !isEmpty(this.form) && isValid(this.form.getFormValue('OBJLizenzVorlage')) && 
      (<ILizenzVorlage>this.form.getFormValue('OBJLizenzVorlage')).LizenzVerlaengerung
    );
  }
  cancel(checkChanges?: boolean): void {
    console.log('hasChanged', this.hasChanged(), this.form.getValues(true));
    super.cancel(checkChanges);
  }
}
