<base-schnittstellen
  [filterStructures]="filterStructures"
  [columnsSchnittstellen]="columnsSchnittstellen"
  [columnsFehler]="columnsFehler"
  [columnsAenderungen]="columnsAenderungen"
  [schnittstellen]="schnittstellen"
  [cbInterfaceLocal]="cbInterfaceLocal"
  [cbInterfaceUser]="cbInterfaceUser"
  [cbGlobalErrors]="cbGlobalErrors"
  [cbResetGlobalErrors]="cbResetGlobalErrors"
></base-schnittstellen>

