import * as moment from "moment";

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";

import { AppSettings, isEmpty, calcTimeByIntervall, calcTimeBySettings, isValid, jsonParse } from '@bfeoldenburg/bfe-shared';
import { CommonFeaturesComponent, DataService, ToolbarService } from '@bfeoldenburg/bfe-data-forms';

import { IntedigiService, ISettings } from "@bfeoldenburg/intedigi-shared";
import { ICronjobNext } from "@bfeoldenburg/bfe-web-apps";


@Component({
  selector: 'cronjobs',
  templateUrl: './cronjobs.component.html',
  styleUrls: ['./cronjobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CronjobsComponent extends CommonFeaturesComponent implements OnInit {
  public cbCronjobAktiv: any = null;
  public cbCalcNextRun: any = null;

  constructor(
    dataService: DataService,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings); 
    
    this.cbCronjobAktiv = (settings: ISettings, id: number) => {
      if (!isEmpty(settings)) {
        switch (id) {
          case 1: return !!settings.SchnittstelleCronjob;
          case 2: return !!settings.LDAPCronjob && (!!settings.LDAPAktiv || !this.isInterfaceLocal());
          case 3: return !!settings.LDAPCronjob && (!!settings.LDAPAktiv || !this.isInterfaceLocal());
          case 4: return !!settings.ERPCronjob && !!settings.ERPAktiv;
          case 5: return !!settings.ERPCronjob && !!settings.ERPAktiv;
          case 6: return settings.ReportWiedervorlageTage !== '[0,0,0,0,0,0,0]';
          case 7: return settings.ReportFehlerTage !== '[0,0,0,0,0,0,0]';
          case 8: return settings.CleanupIntervall > 0;
        }
      }
      return false;
    }

    this.cbCalcNextRun = (settings: ISettings, next: ICronjobNext) => {
      switch (next.id) {
        case 1: {
          next.last = settings.SchnittstelleLastRun;
          next.next = calcTimeByIntervall(settings.SchnittstelleLastRun, settings.SchnittstelleIntervall);
          break;
        }
        case 2: {
          next.last = settings.LDAPLastRun;
          next.next = calcTimeByIntervall(settings.LDAPLastRun, settings.LDAPIntervall);
          break;
        }
        case 3: {
          next.last = settings.LDAPLastRun;
          next.next = calcTimeBySettings(settings.LDAPLastRun, [ settings.LDAPUhrzeitKomplett ]);
          break;
        }
        case 4: {
          next.last = settings.ERPLastRun;
          next.next = calcTimeByIntervall(settings.ERPLastRun, settings.ERPIntervall);
          break;
        }
        case 5: {
          next.last = settings.ERPLastRun;
          next.next = calcTimeBySettings(settings.ERPLastRun, [ settings.ERPUhrzeitKomplett ]);
          break;
        }
        case 6: {
          next.last = settings.LastReportWiedervorlage;
          next.next = calcTimeBySettings(
            settings.LastReportWiedervorlage, 
            jsonParse(settings.ReportWiedervorlageUhrzeiten, []), 
            jsonParse(settings.ReportWiedervorlageTage, [])
          );
          break;
        }
        case 7: {
          next.last = settings.LastReportFehler;
          next.next = calcTimeBySettings(
            settings.LastReportFehler, 
            jsonParse(settings.ReportFehlerUhrzeiten, []), 
            jsonParse(settings.ReportFehlerTage, [])
          );
          break;
        }
        case 8: {
          if (settings.CleanupIntervall > 0 && isValid(settings.CleanupIntervallUhrzeit)) {
            next.last = settings.CleanupLastRun;

            let hours = parseInt(settings.CleanupIntervallUhrzeit.split(':')[0]);
            let minutes = parseInt(settings.CleanupIntervallUhrzeit.split(':')[1]);

            switch (settings.CleanupIntervall) {
              // Monatlich
              case 1: {
                next.next = moment.utc(next.last).local().add(1, 'months').date(1).hours(hours).minutes(minutes).seconds(0).utc();
                break;
              }
              // Vierteljährlich
              case 2: {
                let lastQuartal__: number = Math.floor((moment.utc(next.last).local().month() + 1) / 3);
                let lastYear__: number = moment.utc(next.last).local().year();
                let nextMonth__: number = ((lastQuartal__ === 3 ? 0 : lastQuartal__ + 1) * 3) + 1;
                let nextYear__: number = (lastQuartal__ === 3 ? lastYear__ + 1 : lastYear__);
                next.next = moment.utc(next.last).local().year(nextYear__).month(nextMonth__ - 1).date(1).hours(hours).minutes(minutes).seconds(0).utc();
                break;
              }
              // Halbjährlich
              case 3: {
                let lastHalbjar__: number = Math.floor((moment.utc(next.last).local().month() + 1) / 6);
                let lastYear__: number = moment.utc(next.last).local().year();
                let nextMonth__: number = (lastHalbjar__ === 1 ? 1 : 7);
                let nextYear__: number = (lastHalbjar__ === 1 ? lastYear__ + 1 : lastYear__);
                next.next = moment.utc(next.last).local().year(nextYear__).month(nextMonth__ - 1).date(1).hours(hours).minutes(minutes).seconds(0).utc();
                break;
              }
              // Jährlich
              case 4: {
                next.next = moment.utc(next.last).local().add(1, 'years').month(0).date(1).hours(hours).minutes(minutes).seconds(0).utc();
                break;
              }
            }
          }
          break;
        }
      }
      return next;
    }
  }

  ngOnInit(): void {  
  }

  isInterfaceLocal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }
}