import { 
  Component, OnInit, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, ElementRef, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, AfterViewChecked, ViewChild, Input
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  trigger, state, style, animate, transition
} from '@angular/animations';

import { 
  AuthenticationService, isEmpty, NotifyService, PopupService, AppSettings, windowResize, isValid, getDateFormat
} from '@bfeoldenburg/bfe-shared';
import { 
  ToolbarService, BfeForm, CommonSearchComponent, DataService, DATATYPE, ExtendedFormComponent, ITable
} from '@bfeoldenburg/bfe-data-forms';

import { 
  IBildungszentrum, IKunde, IKundentyp, IKundentyp2, IntedigiService, IPostleitzahl, IOrbisHeader, IOrbisFirma, IBenutzer, IBuchung, IBuchungBenutzer, IKundeBenutzer, IBenutzerAustausch, IntedigiUserService, TABLENAMES, FirmaSearchPopupComponent, KundeAnsprechpartnerComponent, KundeRechnungsadresseComponent 
} from '@bfeoldenburg/intedigi-shared';

import { TABLEALIASSE } from '../../../_models/table-aliasse';
import { InterfacesService } from '@bfeoldenburg/bfe-web-apps';

enum SUBFORMS{
  kundenAnsprechpartner = 0,
  kundenRechnungsadresse = 1
}

interface IOrbisResponse {
  header: IOrbisHeader;
  data: IOrbisFirma[];
}

interface IExtBenutzer extends IBenutzer {
  kundeBenutzer: IKundeBenutzer;
}


@Component({
  selector: 'sub-kundendaten',
  templateUrl: './sub-kundendaten.component.html',
  styleUrls: ['./sub-kundendaten.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
        state('void', style({
            transform: 'translateX(-10%)',
            opacity: 0
        })),
        state('active', style({
            transform: 'translateX(0)',
            opacity: 1
        })),
        transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubKundendatenComponent extends ExtendedFormComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {
  @Input() wiedervorlage: boolean = false;
  /** Es findet gerade eine Datenbank-Operation statt */
  @Input() loading: boolean = false;

  public data: IKunde;

  private benutzer: IBenutzer[] = [];
  private buchungen: IBuchung[] = [];
  private buchungenBenutzer: IBuchungBenutzer[] = [];

  public bildungszentren: IBildungszentrum[] = [];
  public kundentypen: IKundentyp[] = [];
  public kundentypenFiltered: IKundentyp[] = [];
  public kundentypen2: IKundentyp2[] = [];
  public kundentypen2Filtered: IKundentyp2[] = [];
  public postleitzahlen: IPostleitzahl[] = [];
  public plzFiltered: IPostleitzahl[] = [];

  public orbisfirmen: IOrbisFirma[] = [];

  @Output() onforminit = new EventEmitter<BfeForm>(); 
  @Output() onformreinit = new EventEmitter<BfeForm>(); 
  @Output() onselect = new EventEmitter<IKunde>(); 

  @Output() onkundentypchanged = new EventEmitter<IKunde>(); 

  public de: any = getDateFormat();

  @ViewChild(KundeAnsprechpartnerComponent, { static: false }) private ansprechpartnerComp: KundeAnsprechpartnerComponent;
  @ViewChild(KundeRechnungsadresseComponent, { static: false }) private rechnungsadresseComp: KundeRechnungsadresseComponent;
  private dataSetSubForm: { [key: number] : boolean } = { 0: false, 1: false };
  private dataGetSubForm: { [key: number] : boolean } = { 0: false, 1: false };

  constructor(
    elementRef: ElementRef,
    renderer2: Renderer2,
    dataService: DataService,
    popupService: PopupService,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    route: ActivatedRoute,
    router: Router,
    private intedigiService: IntedigiService,
    private intedigiUserService: IntedigiUserService,
    private authService: AuthenticationService,
    private notifyService: NotifyService,
    private interfacesService: InterfacesService
  ) { 
    super(dataService, popupService, appSettings, elementRef, renderer2, cdRef, toolbarService, route, router);

    this.manuallyDataset = true;
    this.searchColumns = this.dataService.getSearchColumns(TABLENAMES.KUNDE);

    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => { 
      this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));
      this.onDataChange(); 
    }));

    this.subForms[SUBFORMS.kundenAnsprechpartner] = false;
    this.subForms[SUBFORMS.kundenRechnungsadresse] = false;
  }

  ngOnInit() {
    super.ngOnInit();

    this.getFkeyTables();
    this.subscriptions.push(this.dataService.onready.subscribe(() => {
      this.getFkeyTables();
    }));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (!!this.ansprechpartnerComp) {
      this.initSubForm(SUBFORMS.kundenAnsprechpartner);
    }
    if (!!this.rechnungsadresseComp) {
      this.initSubForm(SUBFORMS.kundenRechnungsadresse);
    }
  }

  ngAfterViewChecked() {
    if (!!this.ansprechpartnerComp) {
      this.initSubForm(SUBFORMS.kundenAnsprechpartner);
      if (this.dataSetSubForm[SUBFORMS.kundenAnsprechpartner]) {
        this.dataSetSubForm[SUBFORMS.kundenAnsprechpartner] = false;
        this.ansprechpartnerComp.onDataSet();
      }
      if (this.dataGetSubForm[SUBFORMS.kundenAnsprechpartner]) {
        this.dataGetSubForm[SUBFORMS.kundenAnsprechpartner] = false;
        this.ansprechpartnerComp.onDataGet();
      }
    }
    if (!!this.rechnungsadresseComp) {
      this.initSubForm(SUBFORMS.kundenRechnungsadresse);
      if (this.dataSetSubForm[SUBFORMS.kundenRechnungsadresse]) {
        this.dataSetSubForm[SUBFORMS.kundenRechnungsadresse] = false;
        this.rechnungsadresseComp.onDataSet();
      }
      if (this.dataGetSubForm[SUBFORMS.kundenRechnungsadresse]) {
        this.dataGetSubForm[SUBFORMS.kundenRechnungsadresse] = false;
        this.rechnungsadresseComp.onDataGet();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['wiedervorlage']) {
      this.alias = (this.wiedervorlage ? 'WV' : '') + 'Kundendaten';
      this.tablename = TABLENAMES.KUNDE;
      this.tabletitle = 'Kundendaten';
      this.prepareForm();
    }

    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (!isEmpty(this.form)) {
      this.form.resetCallbackReadonly('kundendaten');
      this.form.resetCallbackRequired('kundendaten');
    }
  }

  detectChanges(): void {
    super.detectChanges();
    if (!!this.ansprechpartnerComp) {
      this.ansprechpartnerComp.detectChanges();
    }
    if (!!this.rechnungsadresseComp) {
      this.rechnungsadresseComp.detectChanges();
    }
  }

  onResize() {
    super.onResize();
  }

  cbIsRequired(field: string): boolean {
    switch (field) {
      case 'IDPostleitzahl2': {
        return !this.form.isOptional('IDPostleitzahl') && this.isERPDataset();
      }
      case 'Ort2': {
        return !this.form.isOptional('Ort') && this.isERPDataset();
      }
      case 'IDPostleitzahl2_RE': {
        return !this.form.isOptional('IDPostleitzahl_RE') && this.isERPDataset();
      }
      case 'Ort2_RE': {
        return !this.form.isOptional('Ort_RE') && this.isERPDataset();
      }
    }
    return false;
  }

  cbIsReadonly(field: string): boolean {
    if (this.isERPDataset() && field !== 'Einstellungen') {
      return (
        this.form.isOptional(field) || 
        (isValid(this.form.getFormValue(field)) && this.form.getFormValue(field) === (<any>this.data)[field])
      );
    }

    switch (field) {
      case 'ERPNummer': {
        return this.isErpAktiv();
      }
      case 'IDPostleitzahl': {
        return this.isERPDataset();
      }
      case 'Ort': {
        return this.isERPDataset();
      }
      case 'IDPostleitzahl2': {
        return true;
      }
      case 'Ort2': {
        return true;
      }
      case 'IDPostleitzahl_RE': {
        return this.isERPDataset();
      }
      case 'Ort_RE': {
        return this.isERPDataset();
      }
      case 'IDPostleitzahl2_RE': {
        return true;
      }
      case 'Ort2_RE': {
        return true;
      }
    }
    return false;
  }

  onFormInit() {
    this.onDataSet();

    this.form.setCallbackReadonly('kundendaten', (field: string) => this.cbIsReadonly(field));
    this.form.setCallbackRequired('kundendaten', (field: string) => this.cbIsRequired(field));

    this.onforminit.emit(this.form);
  }

  onDataSet() {
    if (!!this.ansprechpartnerComp) {
      this.ansprechpartnerComp.onDataSet();
    } else {
      this.dataSetSubForm[SUBFORMS.kundenAnsprechpartner] = true;
    }
    if (!!this.rechnungsadresseComp) {
      this.rechnungsadresseComp.onDataSet();
    } else {
      this.dataSetSubForm[SUBFORMS.kundenRechnungsadresse] = true;
    }

    this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));

    //this.form.setFormValue('Ort2', this.form.getFormValue('Ort'));
    //this.form.setFormValue('IDPostleitzahl2', this.form.getFormValue('IDPostleitzahl'));
    
    this.setOrt([this.form.getFormValue('IDPostleitzahl'), this.form.getFormValue('Ort')]);
    this.setKundentyp(this.form.getFormValue('IDKundentyp'));
    this.setKundentyp2(this.form.getFormValue('IDKundentyp2'));

    this.onDataChange();

    this.onselect.emit(this.form.getValues());
  }

  onDataGet() {
    if (!!this.ansprechpartnerComp) {
      this.ansprechpartnerComp.onDataGet();
    } else {
      this.dataGetSubForm[SUBFORMS.kundenAnsprechpartner] = true;
    }
    if (!!this.rechnungsadresseComp) {
      this.rechnungsadresseComp.onDataGet();
    } else {
      this.dataGetSubForm[SUBFORMS.kundenRechnungsadresse] = true;
    }

    this.setBildungszentrum();
    this.setKundentyp();
    this.setKundentyp2();
    this.setOrt();
    // this.setGeschlecht();
  }

  onDataReset(): void {
    this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));
    this.onDataSet()

    this.onselect.emit(this.form.getValues());
  }

  onDataSave() {
    if (!!this.ansprechpartnerComp) this.ansprechpartnerComp.onDataSave();
    if (!!this.rechnungsadresseComp) this.rechnungsadresseComp.onDataSave();
  }

  onAbweichendREChange() {
    this.detectChanges();
  }

  setBildungszentrum(value?: any) {
    //this.setFormObject(TABLENAMES.BILDUNGSZENTRUM, value, null, 'BildungszKd');
    if (!this.dataService.neverLoaded(TABLENAMES.BILDUNGSZENTRUM)) {
      this.setFormObjectStandardAuswahl('OBJBildungszKd', 'IDBildungszKd', this.bildungszentren, value);
    }
  }

  onBildungszentrumChange() {
    this.setBildungszentrum();
    this.onDataChange();
  }

  setKundentyp(value?: any) {
    this.setFormObject(TABLENAMES.KUNDENTYP, value);
    this.onkundentypchanged.emit();
  }

  onKundentypChange() {
    this.setKundentyp();
    this.onDataChange();
  }

  setKundentyp2(value?: any) {
    this.setFormObject(TABLENAMES.KUNDENTYP2, value);
  }

  onKundentyp2Change() {
    this.setKundentyp2();
    this.onDataChange();
  }

  onRechnungPerMailChange() {
    this.onDataChange();
  }

  setOrt(value?: any | any[]) {
    if (!this.isERPDataset()) {
      let obj = this.setFormObject(TABLENAMES.POSTLEITZAHL, value);
      if (!this.dataService.neverLoaded(TABLENAMES.POSTLEITZAHL)) {
        if (!isEmpty(obj)) {
          this.form.setFormValue('Ort', obj.Name);
        } else {
          this.form.setFormValue('Ort', null);
        }
      }

      this.form.setFormValue('Ort2', this.form.getFormValue('Ort'));
      this.form.setFormValue('IDPostleitzahl2', this.form.getFormValue('IDPostleitzahl'));
    }
  }

  filterOrt(event: any) {
    let queryparts = event.query.split(' ');
    this.plzFiltered = this.postleitzahlen.reduce((result: IPostleitzahl[], current: IPostleitzahl) => {
      let i = 0, iMax = queryparts.length;
      for(i; i < iMax; i++) {
        if(queryparts[i].length > 0 && current.Search.indexOf(queryparts[i].toLowerCase()) == -1) {
          return result;
        }
      }
      return result.concat(current);
    }, <IPostleitzahl[]>[]); 
  }

  onOrtChange() {
    this.setOrt();
  }

  isOrtValid(){
    return (
      (isValid(this.form.getFormValue('IDPostleitzahl')) || isValid(this.form.getFormValue('Ort'))) && 
      !this.isReadonly('IDPostleitzahl') && !this.isReadonly('Ort')
    );
  }

  resetOrt(){
    this.form.setFormValue('OBJPostleitzahl', null);
    this.setOrt();
    this.form.setFieldFocus('IDPostleitzahl');
  }

  onPostleitzahl2Change(): void {
    if (this.isERPDataset()) {
      this.form.setFormValue('IDPostleitzahl', this.form.getFormValue('IDPostleitzahl2'));
    }
  }

  onOrt2Change(): void {
    if (this.isERPDataset()) {
      this.form.setFormValue('Ort', this.form.getFormValue('Ort2'));
    }
  }

  getBildungszentren(data: IBildungszentrum[]) {
    this.bildungszentren = [];
    if (this.intedigiService.isInterfaceLocal()) {
      this.bildungszentren = [ <IBildungszentrum>this.dataService.getDataset(TABLENAMES.BILDUNGSZENTRUM, this.intedigiService.getBildungszentrum()) ];
      if (!isEmpty(this.form) && !this.dataService.neverLoaded(TABLENAMES.BILDUNGSZENTRUM) && !this.dataService.neverLoaded(TABLENAMES.SETTINGS)) {
        this.setBildungszentrum(this.intedigiService.getBildungszentrum());
      }
    } else {
      this.bildungszentren = data.reduce((result: IBildungszentrum[], current: IBildungszentrum) => {
        if (
          (!current.LokaleInstanz && (this.authService.isAdministrator() || current.ID === this.intedigiUserService.getBildungszentrum())) || 
          (!isEmpty(this.data) && current.ID === this.data.IDBildungszKd)
        ) {
          return result.concat(current);
        }
        return result;
      }, <IBildungszentrum[]>[]);
      if (!isEmpty(this.form) && !this.dataService.neverLoaded(TABLENAMES.BILDUNGSZENTRUM)) {
        this.setBildungszentrum(this.form.getFormValue('IDBildungszKd'));

        if (!isValid(this.form.getFormValue('IDBildungszKd'))) {
          this.setBildungszentrum(this.intedigiUserService.getBildungszentrum());
        }
      }
    }
    this.detectChanges();
  }

  getFkeyTables() {
    this.addDataSubscription(TABLENAMES.BILDUNGSZENTRUM, (data: IBildungszentrum[]) => {
      this.getBildungszentren(data);
    });
    this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));

    this.addDataSubscription(TABLENAMES.KUNDENTYP, (data: IKundentyp[]) => {
      this.kundentypen = data;
      this.detectChanges();
    });
    
    this.addDataSubscription(TABLENAMES.KUNDENTYP2, (data: IKundentyp2[]) => {
      this.kundentypen2 = data;
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.POSTLEITZAHL, (data: IPostleitzahl[]) => {
      this.postleitzahlen = data;
      this.detectChanges();
    });

    this.kundentypen = <IKundentyp[]>this.dataService.getData(TABLENAMES.KUNDENTYP);
    this.kundentypen2 = <IKundentyp2[]>this.dataService.getData(TABLENAMES.KUNDENTYP2);
    this.postleitzahlen = <IPostleitzahl[]>this.dataService.getData(TABLENAMES.POSTLEITZAHL);
    this.detectChanges();
  }

  hasBenutzer(): boolean {
    return !!this.benutzer.length;
  }

  private resetSubDataArrays__() {
    this.benutzer = [];
    this.buchungen = [];
    this.buchungenBenutzer = [];
  }

  getBenutzer(): void {
    if (
      !isEmpty(this.form) && !this.dataService.neverLoaded(TABLENAMES.BUCHUNG_BENUTZER) && 
      !this.dataService.neverLoaded(TABLENAMES.KUNDE_BENUTZER) && !this.dataService.neverLoaded(TABLENAMES.BENUTZERAUSTAUSCH)
    ) {
      let kundenBenutzer: { [key: string]: { [key: number]: IKundeBenutzer } } = {};

      let benutzerIds: { [key: number]: string } = this.buchungenBenutzer.reduce(
        (result: { [key: number]: string }, current: IBuchungBenutzer) => {
          result[current.IDBenutzer] = current.IDBildungszentrum;
          return result;
        }, <{ [key: number]: string }>{}
      );

      benutzerIds = (<IKundeBenutzer[]>this.dataService.getData(
        TABLENAMES.KUNDE_BENUTZER, 
        (item: IKundeBenutzer) => item.IDKunde === this.data.ID && item.IDBildungszKd === this.data.IDBildungszKd
      )).reduce((result: { [key: number]: string }, current: IKundeBenutzer) => {
        if (!(current.IDBildungszentrum in kundenBenutzer)) {
          kundenBenutzer[current.IDBildungszentrum] = {};
        }
        kundenBenutzer[current.IDBildungszentrum][current.IDBenutzer] = current;
        result[current.IDBenutzer] = current.IDBildungszentrum;
        return result;
      }, benutzerIds);

      this.benutzer = (<IBenutzerAustausch[]>this.dataService.getData(
        TABLENAMES.BENUTZERAUSTAUSCH, 
        (item: IBenutzerAustausch) => (item.ID in benutzerIds) && benutzerIds[item.ID] === item.IDBildungszentrum
      )).reduce((result: IExtBenutzer[], current: IBenutzerAustausch) => {
        let benutzer: IExtBenutzer = <IExtBenutzer>current;
        if (benutzer.IDBildungszentrum in kundenBenutzer && benutzer.ID in kundenBenutzer[benutzer.IDBildungszentrum]) {
          benutzer.kundeBenutzer = kundenBenutzer[benutzer.IDBildungszentrum][benutzer.ID];
        } else {
          benutzer.kundeBenutzer = <IKundeBenutzer>{
            IDBildungszentrum: benutzer.IDBildungszentrum,
            IDBenutzer: benutzer.ID,
            IDBildungszKd: null,
            IDKunde: null
          }
        }
        return result.concat(benutzer);
      }, <IExtBenutzer[]>[]);
    }
  }

  getSubdata() {
    this.resetSubDataArrays__();

    if (!isEmpty(this.data)) {
      let getBuchungen = () => {
        this.buchungen = (<IBuchung[]>this.dataService.getData(
          TABLENAMES.BUCHUNG, (item: IBuchung) => item.IDBildungszKd === this.data.IDBildungszKd && item.IDKunde === this.data.ID
        )).reduce((result: IBuchung[], current: IBuchung) => {
          current.Zusatzinfo = '';
          return result.concat(current);
        }, <IBuchung[]>[]);

        let buchungenIds: { [key: string]: { [key: number]: number[] } } = this.buchungen.reduce(
          (result: { [key: string]: { [key: number]: number[] } }, current: IBuchung) => {
            if (!(current.IDBildungszKd in result)) {
              result[current.IDBildungszKd] = {};
            }
            if (!(current.IDKunde in result[current.IDBildungszKd])) {
              result[current.IDBildungszKd][current.IDKunde] = [];
            }
            result[current.IDBildungszKd][current.IDKunde].push(current.ID);
            return result;
          }, <{ [key: string]: { [key: number]: number[] } }>{}
        );

        this.buchungenBenutzer = <IBuchungBenutzer[]>this.dataService.getData(
          TABLENAMES.BUCHUNG_BENUTZER, 
          (item: IBuchungBenutzer) => (
            item.IDBildungszKd in buchungenIds && item.IDKunde in buchungenIds[item.IDBildungszKd] && 
            buchungenIds[item.IDBildungszKd][item.IDKunde].indexOf(item.IDBuchung) > -1
          )
        );
        
        this.getBenutzer();
      }

      this.addDataSubscription(TABLENAMES.BENUTZERAUSTAUSCH, (data: IBenutzerAustausch[]) => {
        getBuchungen();
        this.setSubDataReady(TABLENAMES.BENUTZERAUSTAUSCH, true);
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.KUNDE_BENUTZER, (data: IKundeBenutzer[]) => {
        getBuchungen();
        this.setSubDataReady(TABLENAMES.KUNDE_BENUTZER, true);
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.BUCHUNG, (data: IBuchung[]) => {
        getBuchungen();
        this.setSubDataReady(TABLENAMES.BUCHUNG, true);
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.BUCHUNG_BENUTZER, (data: IBuchungBenutzer[]) => {
        getBuchungen();
        this.setSubDataReady(TABLENAMES.BUCHUNG_BENUTZER, true);
        this.detectChanges();
      });

      this.setSubDataReady(TABLENAMES.BENUTZERAUSTAUSCH, !this.dataService.neverLoaded(TABLENAMES.BENUTZERAUSTAUSCH));
      this.setSubDataReady(TABLENAMES.BUCHUNG, !this.dataService.neverLoaded(TABLENAMES.BUCHUNG));
      this.setSubDataReady(TABLENAMES.BUCHUNG_BENUTZER, !this.dataService.neverLoaded(TABLENAMES.BUCHUNG_BENUTZER));
      this.setSubDataReady(TABLENAMES.KUNDE_BENUTZER, !this.dataService.neverLoaded(TABLENAMES.KUNDE_BENUTZER));

      this.detectChanges();
    }
  }

  isLokal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }

  hasChanged(): boolean {
    return (
      this.form.hasChanged() && 
      (
        !this.isLokal() || !this.isNew() || 
        !(Object.keys(this.form.getValues(true)).length === 1 && this.form.hasColumnChanged('IDBildungszKd'))
      )
    );
  }

  search(): void {
    this.popupService.open(
      CommonSearchComponent, 
      {
        columns: this.searchColumns,
        tablename: this.tablename,
        tabletitle: this.tabletitle,
        minWidth: this.minWidth,
        cbFilter: (dataset: IKunde) => { 
          return this.intedigiService.isBildungszentrumWritable(dataset.IDBildungszKd);
        }
      },
      {
        onselect: (dataset: any) => { 
          // Ein Datensatz wurde ausgewählt
          let table: ITable = this.dataService.getInfo(this.tablename);
          // Anhand der Primärschlüsselvorgaben der Tabelle aus dem Datensatz die Werte der Primärschlüsselspalten extrahieren
          let keyvalues = [];
          for (let i = 0; i < table.pkeys.length; i++) {
            keyvalues.push(dataset[table.pkeys[i]]);
          }
          this.getDataset(keyvalues);
          if (!this.wiedervorlage && this.isERPDataset()) {
            this.orbisUpdate();
          }
        },
        oncancel: () => {  }
      }
    );
  }

  reset(): void {
    // Formular zurücksetzen
    this.form.reset();
    // onDataReset-Hook (CommonFormComponent) ausführen
    this.onDataReset();
  }

  isERPDataset(): boolean {
    return isValid(this.form.getFormValue('ERPID')) && this.intedigiService.isErpInterfaceActive();
  }

  isErpAktiv(): boolean {
    return this.intedigiService.isErpInterfaceActive();
  }

  hasERPNummer(): boolean {
    return !this.isNew() && isValid(this.form.getFormValue('ERPNummer'));
  }

  hasERPID(): boolean {
    return !this.isNew() && isValid(this.form.getFormValue('ERPID'));
  }

  hasRechnungsadresse(): boolean {
    return isValid(this.form.getFormValue('Abweichend_RE'));
  }

  isRechnungsadresseValid(): boolean {
    if (isEmpty(this.form)) {
      return false;
    }
    let controls = this.form.getInvalidControls();
    for (let i = 0; i < controls.length; i++) {
      if (controls[i].substr(-3) === '_RE') {
        return false;
      }
    } 
    return true;
  }

  isKundendatenValid(): boolean {
    if (isEmpty(this.form)) {
      return false;
    }
    let controls = this.form.getInvalidControls();
    for (let i = 0; i < controls.length; i++) {
      if (controls[i].substr(-3) !== '_RE') {
        return false;
      }
    } 
    return true;
  }

  orbisSelect(): void {
    this.popupService.open(
      CommonSearchComponent, 
      {
        columns: [
          { name: 'id', title: 'ID', minWidth: 0, hidden: true, cbOnread: (dataService: DataService, dataset: any) => {
            return dataset.id; 
          } },
          { name: 'nr', title: 'Nummer', minWidth: 90, searchable: true, cbOnread: (dataService: DataService, dataset: any) => { return dataset.nr; } },
          { name: 'name', title: 'Name', minWidth: 250, searchable: true, cbOnread: (dataService: DataService, dataset: any) => { return dataset.name; } },
          { name: 'name2', title: 'Name 2', minWidth: 250, searchable: true, cbOnread: (dataService: DataService, dataset: any) => { return dataset.name2; } },
          { name: 'name3', title: 'Name 2', minWidth: 250, searchable: true, cbOnread: (dataService: DataService, dataset: any) => { return dataset.name3; } },
          { name: 'strasse', title: 'Straße', minWidth: 150, searchable: true, cbOnread: (dataService: DataService, dataset: any) => { return dataset.strasse; } },
          { name: 'ort', title: 'Ort', minWidth: 150, searchable: true, cbOnread: (dataService: DataService, dataset: any) => { return dataset.plz + ' ' + dataset.ort; } },
          { 
            name: 'ansprechpartner', minWidth: 250, searchable: true, title: 'Ansprechpartner', 
            cbOnread: (dataService: DataService, dataset: any) => { 
              if (isValid(dataset.apName) && isValid(dataset.apVorname)) {
                return dataset.apName + ', ' + dataset.apVorname; 
              } else if (isValid(dataset.apName)) {
                return dataset.apName; 
              } else if (isValid(dataset.apVorname)) {
                return dataset.apVorname; 
              }
            } 
          },
          { name: 'dataset', title: 'Dataset', minWidth: 0, hidden: true, cbOnread: (dataService: DataService, dataset: any) => {
            return dataset; 
          } },
        ],
        rawdata: this.orbisfirmen,
        tabletitle: 'Orbis-Kunden',
        minWidth: 1800
      },
      {
        onselect: (dataset: any) => { 
          dataset = dataset.dataset;
          this.form.reset();
          let kunden: IKunde[] = <IKunde[]>this.dataService.getData(TABLENAMES.KUNDE, (item: IKunde) => item.ERPID === dataset.id);
          if (!!kunden.length) {
            // Den Datensatz vom DataService abrufen
            this.data = kunden[0];
            // Anzeige der Daten im Formular
            this.form.setValues(this.data);
            // onDataSet-Hook (CommonFormComponent) auslösen
            this.onDataSet();
          } else {
            this.form.setFormValue('IDBildungszKd', this.intedigiUserService.getBildungszentrum());
          }

          let map: any = {
            id: 'ERPID',
            nr: 'ERPNummer',
            name: 'Name',
            name2: 'Name2',
            name3: 'Name3',
            strasse: 'Adresse',
            plz: 'IDPostleitzahl',
            ort: 'Ort',
            telefon: 'Telefon',
            telefax: 'Telefax',
            email: 'Email',
            apGeschlecht: 'IDGeschlecht',
            apAnrede: 'APAnrede',
            apName: 'APNachname',
            apVorname: 'APVorname',
            rechnungPerMail: 'RechnungPerMail',
            reKdNr: 'Nummer_RE',
            reName: 'Name_RE',
            reName2: 'Name2_RE',
            reName3: 'Name3_RE',
            reStrasse: 'Adresse_RE',
            rePlz: 'IDPostleitzahl_RE',
            reOrt: 'Ort_RE',
            rePlzPostfach: 'IDPLZPostfach_RE',
            rePostfach: 'Postfach_RE',
            reEmail: 'Email_RE'
          }

          let keys = Object.keys(dataset);
          for (let i = 0; i < keys.length; i++) {
            if (isValid((<any>dataset)[keys[i]]) || this.form.getControl(map[keys[i]]).column.type === DATATYPE.DATATYPE_BOOLEAN) {
              if (keys[i] === 'kundentyp' && isValid((<any>dataset)[keys[i]]['id'])) {
                this.setKundentyp((<any>dataset)[keys[i]]['id'])
              } else if (keys[i] === 'kundentyp2' && isValid((<any>dataset)[keys[i]]['id'])) {
                this.setKundentyp2((<any>dataset)[keys[i]]['id'])
              } else if (keys[i] in map) {
                this.form.setFormValue(map[keys[i]], (<any>dataset)[keys[i]]);
              }
            }
          }

          if (isValid(this.form.getFormValue('Nummer_RE')) || isValid(this.form.getFormValue('Name_RE')) || isValid(this.form.getFormValue('Email_RE'))) {
            this.form.setFormValue('Abweichend_RE', true);
          } else {
            this.form.setFormValue('Abweichend_RE', false);
          }

          this.setOrt([this.form.getFormValue('IDPostleitzahl'), this.form.getFormValue('Ort')]);

          this.ansprechpartnerComp.onDataSet();
          this.rechnungsadresseComp.onDataSet();
          
          this.onDataChange();
          this.data = this.form.getValues(false, true);
          this.onselect.emit(this.form.getValues());
        },
        oncancel: () => {  }
      }
    );
  }

  orbisSearch(): void {
    this.orbisfirmen = [];

    this.popupService.open(
      FirmaSearchPopupComponent, 
      {
        data: null,
        tablename: TABLENAMES._ORBIS_FIRMA,
        tabletitle: 'Orbis-Suche'
      },
      {
        onedit: (data: IOrbisFirma) => { this.orbisSearch__(data); },
        oncancel: () => { }
      }
    );
  }
  
  private orbisSearch__(data: IOrbisFirma): void {
    let keys = Object.keys(data);
    let data__: any = {};
    for (let i = 0; i < keys.length; i++) {
      if (isValid((<any>data)[keys[i]])) {
        data__[keys[i]] = (<any>data)[keys[i]];
      }
    }

    let message = this.notifyService.progress('Die Datensätze werden abgerufen', 'ERP-Schnittstelle', -1);

    this.interfacesService.run(
      'interface.erp.lokal.http.php?kontext=firmen&limit=100', data__, 
      (response: IOrbisResponse) => { 
        this.notifyService.remove(message);

        if (!response.data.length) {
          this.notifyService.error('Es wurde keine passenden Daten gefunden');
          return;
        }

        if (response.header.size > response.header.limit) {
          this.popupService.confirm(
            'Es wurden ' + response.header.size + ' Datensätze gefunden. Möchten Sie die Ergebnisse anzeigen?', 'Ergebnisse anzeigen',
            () => {
              let message = this.notifyService.progress('Die Datensätze werden abgerufen', 'ERP-Schnittstelle', -1);

              this.interfacesService.run(
                'interface.erp.lokal.http.php?kontext=firmen&limit=-1', data__, 
                (response: IOrbisResponse) => { 
                  this.notifyService.remove(message);

                  this.orbisfirmen = response.data;
                  if (!!this.orbisfirmen.length && !this.orbisfirmen[0].id) {
                    this.orbisfirmen.shift();
                  }
                  this.orbisSelect();
                },
                (error: string) => { 
                  this.notifyService.remove(message);
                  console.log(error); 
                },
                true
              );
            }
          );
        } else {
          this.orbisfirmen = response.data;
          if (!!this.orbisfirmen.length && !this.orbisfirmen[0].id) {
            this.orbisfirmen.shift();
          }
          this.orbisSelect();
        }
      },
      (error: string) => { 
        this.notifyService.remove(message);
        console.log(error); 
      },
      true
    );
  }

  orbisUpdate(): void {
    let message = this.notifyService.progress('Der Datensatz wird abgerufen', 'ERP-Schnittstelle', -1);

    this.interfacesService.run(
      'interface.erp.lokal.http.php?kontext=firmen&limit=-1', { id: this.form.getFormValue('ERPID') }, 
      (response: IOrbisResponse) => { 
        this.notifyService.remove(message);

        if (!!response.data.length) {
          let dataset = response.data[0];

          let map: any = {
            id: 'ERPID',
            nr: 'ERPNummer',
            name: 'Name',
            name2: 'Name2',
            name3: 'Name3',
            strasse: 'Adresse',
            plz: 'IDPostleitzahl',
            ort: 'Ort',
            telefon: 'Telefon',
            telefax: 'Telefax',
            email: 'Email',
            apGeschlecht: 'IDGeschlecht',
            apAnrede: 'APAnrede',
            apName: 'APNachname',
            apVorname: 'APVorname',
            rechnungPerMail: 'RechnungPerMail',
            reKdNr: 'Nummer_RE',
            reName: 'Name_RE',
            reName2: 'Name2_RE',
            reName3: 'Name3_RE',
            reStrasse: 'Adresse_RE',
            rePlz: 'IDPostleitzahl_RE',
            reOrt: 'Ort_RE',
            rePlzPostfach: 'IDPLZPostfach_RE',
            rePostfach: 'Postfach_RE',
            reEmail: 'Email_RE'
          }

          let keys = Object.keys(dataset);
          for (let i = 0; i < keys.length; i++) {
            if (isValid((<any>dataset)[keys[i]]) || this.form.getControl(map[keys[i]]).column.type === DATATYPE.DATATYPE_BOOLEAN) {
              if (keys[i] === 'kundentyp' && isValid((<any>dataset)[keys[i]]['id'])) {
                this.setKundentyp((<any>dataset)[keys[i]]['id'])
              } else if (keys[i] === 'kundentyp2' && isValid((<any>dataset)[keys[i]]['id'])) {
                this.setKundentyp2((<any>dataset)[keys[i]]['id'])
              } else if (keys[i] in map) {
                this.form.setFormValue(map[keys[i]], (<any>dataset)[keys[i]]);
              }
            } else if (!isValid((<any>dataset)[keys[i]]) && this.form.isOptional(keys[i])) {
              this.form.setFormValue(map[keys[i]], null);
            }
          }

          if (isValid(this.form.getFormValue('Nummer_RE')) || isValid(this.form.getFormValue('Name_RE')) || isValid(this.form.getFormValue('Email_RE'))) {
            this.form.setFormValue('Abweichend_RE', true);
          } else {
            this.form.setFormValue('Abweichend_RE', false);
          }

          this.setOrt([this.form.getFormValue('IDPostleitzahl'), this.form.getFormValue('Ort')]);

          this.ansprechpartnerComp.onDataSet();
          this.rechnungsadresseComp.onDataSet();
          
          this.onDataChange();
          this.onselect.emit(this.form.getValues());
          this.notifyService.success('Die Daten wurden erfolgreich aktualisiert');
        }
      },
      (error: string) => { 
        this.notifyService.remove(message);
        console.log(error);
        this.notifyService.error(error);
      },
      true
    );
  }

  getTableRE(): string {
    return (!!this.wiedervorlage ? TABLEALIASSE.WVRechnungsadresse : TABLEALIASSE.LVRechnungsadresse);
  }

  getColorError(): string {
    return this.appSettings.colorError;
  }

  getColorSuccess(): string {
    return this.appSettings.colorSuccess;
  }

  tabviewChanged(event: any): void {
    windowResize();
  }
}
