declare const moment: any;
declare const $: any;

import { Component, Renderer2, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

import { PrimeNGConfig } from 'primeng/api';

import { 
  AuthenticationService, isValid, RestService, NotifyService, isEmpty, AppSettings,
  SharedService
} from '@bfeoldenburg/bfe-shared';
import { 
  DataService, ToolbarService, SettingsService, RightSidebarService, MenuService,
  DataFormsService
} from '@bfeoldenburg/bfe-data-forms';
import { IIdentityProvider, IntedigiDataService, IntedigiService, IntedigiUserService, ISettings, KundenService } from '@bfeoldenburg/intedigi-shared';
import { CommonAppComponent, InterfacesService, LDAPService, WebAppsService } from '@bfeoldenburg/bfe-web-apps';

import { LizenzverkaufService } from './_services/lizenzverkauf.service';
import { WiedervorlageService } from './_services/wiedervorlage.service';


/** 
 * Haupt-Komponente zur Darstellung der Administrations-Oberfläche mit der Unterteilung in Menü, Titelleiste und Arbeitsbereich
 */
@Component({
  selector: 'intedigi-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends CommonAppComponent {
  public sso: boolean = true;

  public ldap: boolean = true;

  public settings: ISettings;

  constructor(
    router: Router,
    route: ActivatedRoute,
    renderer: Renderer2,
    elementRef: ElementRef,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    authService: AuthenticationService,
    restService: RestService,
    dataService: DataService,
    notifyService: NotifyService,
    menuService: MenuService,
    titleService: Title,
    appSettings: AppSettings,
    translate: TranslateService,
    config: PrimeNGConfig,
    settingsService: SettingsService,
    rightSidebarService: RightSidebarService,
    http: HttpClient,
    sanitizer: DomSanitizer,
    webAppsService: WebAppsService,
    public sharedService: SharedService,
    public dataFormsService: DataFormsService,
    public intedigiDataService: IntedigiDataService,
    public intedigiService: IntedigiService,
    public intedigiUserService: IntedigiUserService,
    public lizenzService: LizenzverkaufService,
    public wiedervorlageService: WiedervorlageService,
    public ldapService: LDAPService,
    public interfacesService: InterfacesService,
    public kundenService: KundenService
  ) {
    super(
      cdRef, toolbarService, dataService, appSettings, authService, menuService, titleService, settingsService, notifyService, rightSidebarService, restService, elementRef, route, router, renderer, translate, config, http, sanitizer, webAppsService
    ); 
  }

  hasInfo(): boolean {
    return (
      !this.dataService.allLoaded() || 
      this.dataService.getStatus().reading > this.dataService.getStatus().read || 
      this.dataService.getStatus().reading > this.dataService.getStatus().prepared
    );
  }

  getLogoLink(): string {
    if (!this.isGlobal()) {
      if (this.isLocalBfe()) {
        return 'https://www.bfe.de'
      } else if (this.isLocalBze()) {
        return 'https://www.bze-hamburg.de/'
      } else if (this.isLocalBzl()) {
        return 'http://www.bzl-lauterbach.de/'
      } else if (this.isLocalEbz()) {
        return 'https://www.ebz.de/'
      } else if (this.isLocalEtz()) {
        return 'https://www.etz-stuttgart.de/'
      } else if (this.isLocalZeit()) {
        return 'https://zeit-fortbildung.de/'
      }
    }
    return 'https://www.elkonet.de'
  }

  getInfo(): string {
    if (!this.dataService.getStatus().reading || this.dataService.getStatus().reading > this.dataService.getStatus().read) {
      if (!this.dataService.allLoaded()) {
        if (!!this.dataService.getStatus().reading) {
          return 'Die Daten werden initial geladen (' + this.dataService.getStatus().read + ' / ' + this.dataService.getStatus().reading + ')';
        } else {
          return 'Die Daten werden initial geladen';
        }
      } else {
        return 'Es werden Daten geladen';
      }
    } else {
      if (!this.dataService.allLoaded()) {
        return 'Die Daten werden initial aufbereitet';
      } else {
        return 'Es werden Daten aufbereitet';
      }
    }
  }

  onSettings(settings: ISettings): void {
    if (this.isSSoSubpage()) {
      // this.postSsoMessage('title', { title: this.getMainTitle() + ' | ' + this.title });
    }
  }

  getMainTitle(): string {
    let IDBildungszentrum: string = (
      !isEmpty(this.settings) && isValid(this.settings.IDBildungszentrum) ? 
      this.settings.IDBildungszentrum : 
      (!isEmpty(this.intedigiService) ? this.intedigiService.getBildungszentrum() : '')
    );
    switch (IDBildungszentrum) {
      case 'BFE': return 'ELKOnet-Administration';
      case 'etz': return 'etz Administration';
      case 'EBZ': return 'EBZ Administration';
      case 'BZE': return 'BZE Administration';
      case 'BZL': return 'BZL Administration';
      case 'ZEIT': return 'ZEIT Administration';
      default: return 'ELKOnet-Administration';
    } 
  }

  isGlobal(): boolean {
    return !isEmpty(this.settings) && !this.settings.LokaleInstanz;
  }

  isLocalBfe(): boolean {
    return !isEmpty(this.settings) && !!this.settings.LokaleInstanz && this.settings.IDBildungszentrum === 'BFE';
  }

  isLocalEtz(): boolean {
    return !isEmpty(this.settings) && !!this.settings.LokaleInstanz && this.settings.IDBildungszentrum === 'etz';
  }

  isLocalEbz(): boolean {
    return !isEmpty(this.settings) && !!this.settings.LokaleInstanz && this.settings.IDBildungszentrum === 'EBZ';
  }

  isLocalBze(): boolean {
    return !isEmpty(this.settings) && !!this.settings.LokaleInstanz && this.settings.IDBildungszentrum === 'BZE';
  }

  isLocalBzl(): boolean {
    return !isEmpty(this.settings) && !!this.settings.LokaleInstanz && this.settings.IDBildungszentrum === 'BZL';
  }

  isLocalZeit(): boolean {
    return !isEmpty(this.settings) && !!this.settings.LokaleInstanz && this.settings.IDBildungszentrum === 'ZEIT';
  }

  getIdentityProvider(): void {
    if (this.sso) {
      this.webAppsService.noAuthIdentityProvider((data: IIdentityProvider[]) => {
        this.idps = data.filter((item: IIdentityProvider) => !!item.Administration);
        if (!this.idps.length) {
          this.authService.setSsoChecked(true);
        } else {
          this.tryLogin();
        }
        this.detectChanges();
      });
    }
  }
}
