import { AppSettings } from '@bfeoldenburg/bfe-shared';

export const appSettings: AppSettings = {
  contentHeight: 0,
  colorSuccess: '#388E3C',
  colorError: '#c62828',
  colorInfo: '#007ad9',
  colorInfoDark: '#0053d9',
  colorHelp: '#9C27B0',
  colorWarn: '#ffba01',
  colorWarnDark: '#ff9601',
  restrictionName: 'ELKOnet Administration',
  restriction: [ 'admin', 'data', 'readonly' ],
  helpUrl: 'https://intedigi-wiki.bfe-elearning.de/index.php/',
  helpService: 'elkowiki',
  noAuthRoute: '/login',
  develop: {
    '2024': [ 'BFE', 'BZE' ] 
  }
}