import { 
  Component, OnInit, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, ElementRef, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, AfterViewChecked, ViewChild, Input
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PopupService, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, BfeForm, DataService, ExtendedFormComponent } from '@bfeoldenburg/bfe-data-forms';

import { 
  BenutzerFormMainComponent, IBenutzer, IKunde, ILizenzartBereichRolle, IntedigiService, TABLENAMES 
} from '@bfeoldenburg/intedigi-shared';

enum SUBFORMS{
  benutzerFormMain = 0
}

@Component({
  selector: 'sub-kundenadmin',
  templateUrl: './sub-kundenadmin.component.html',
  styleUrls: ['./sub-kundenadmin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubKundenadminComponent extends ExtendedFormComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {
  @Input() kunden: IKunde[] = [];

  @Input() wiedervorlage: boolean;
  /** Es findet gerade eine Datenbank-Operation statt */
  @Input() loading: boolean = false;

  @Output() onforminit = new EventEmitter<BfeForm>();

  public data: IBenutzer;

  public rollenIDs: string[] = [];

  public tablenames = TABLENAMES;

  @ViewChild(BenutzerFormMainComponent, { static: false }) private benutzerFormMain: BenutzerFormMainComponent;
  private dataSetSubForm: boolean = false;
  private dataGetSubForm: boolean = false;

  constructor(
    dataService: DataService,
    popupService: PopupService,
    elementRef: ElementRef,
    renderer: Renderer2,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    route: ActivatedRoute,
    router: Router,
    private intedigiService: IntedigiService
  ) { 
    super(dataService, popupService, appSettings, elementRef, renderer, cdRef, toolbarService, route, router);

    this.manuallyDataset = true;
    this.subForms[SUBFORMS.benutzerFormMain] = false;

    this.fkeyTables = [
      TABLENAMES.LIZENZART,
      TABLENAMES.LIZENZARTBEREICHROLLE
    ];

    this.subTables = [
      TABLENAMES.KUNDEAUSTAUSCH,
      TABLENAMES.BUCHUNG,
      TABLENAMES.BUCHUNG_BENUTZER,
      TABLENAMES.BENUTZERKURS,
      TABLENAMES.KUNDE_BENUTZER
    ];

    this.nofocus = true;

    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => { this.onDataChange(); }));
  }

  ngOnInit() {
    super.ngOnInit();

    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => this.getFKeyData()));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    
    if (!!this.benutzerFormMain) {
      this.initSubForm(SUBFORMS.benutzerFormMain);
    }
  }

  ngAfterViewChecked() {
    if (!!this.benutzerFormMain) {
      this.initSubForm(SUBFORMS.benutzerFormMain);
      if (this.dataSetSubForm) {
        this.dataSetSubForm = false;
        this.benutzerFormMain.onDataSet();
      }
      if (this.dataGetSubForm) {
        this.dataGetSubForm = false;
        this.benutzerFormMain.onDataGet();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['wiedervorlage']) {
      this.alias = (this.wiedervorlage ? 'WV' : '') + 'Kundenadmin';
      this.tablename = TABLENAMES.BENUTZER;
      this.tabletitle = 'Benutzer';
      this.prepareForm();
    }
    
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.form.resetCallbackWritable();
  }

  detectChanges(): void {
    super.detectChanges();
    if (!!this.benutzerFormMain) {
      this.benutzerFormMain.detectChanges();
    }
  }

  onResize() {
    super.onResize();
  }

  hasWritableBZ(): boolean {
    return this.intedigiService.hasWritableBildungszentren();
  }

  getBenutzer(): IBenutzer {
    return this.form.getValues();
  }

  onFormInit() {
    this.onDataSet();

    this.onforminit.emit(this.form);
  }

  onDataSet() {
    this.form.setFormValue('Freigabe', true);
    this.form.setFormValue('EmailBestaetigt', true);

    this.readSubData();

    if (!!this.benutzerFormMain) {
      this.benutzerFormMain.onDataSet();
    } else {
      this.dataSetSubForm = true;
    }

    this.onDataChange();
  }

  onDataGet() {
    this.form.setFormValue('Freigabe', true);
    this.form.setFormValue('EmailBestaetigt', true);

    if (!!this.benutzerFormMain) {
      this.benutzerFormMain.onDataGet();
    } else {
      this.dataGetSubForm = true;
    }
  }

  onDataReset() {
    this.onDataSet();

    this.resetSubDataReady();
  }

  beforeDataSave(cbSave: any) {
    if (!!this.benutzerFormMain) this.benutzerFormMain.beforeDataSave();
    cbSave();
  }

  onDataSave() {
    if (!!this.benutzerFormMain) this.benutzerFormMain.onDataSave();
  }

  getFKeyData(): void {
    let getGruppenIDs = (data: ILizenzartBereichRolle[]) => {
      this.rollenIDs = data.reduce((result: string[], current: ILizenzartBereichRolle) => {
        if (current.IDLizenzart === 5) {
          return result.concat(current.IDRolle);
        }
        return result;
      }, <string[]>[]);
    };

    this.addDataSubscription(TABLENAMES.LIZENZARTBEREICHROLLE, (data: ILizenzartBereichRolle[]) => {
      getGruppenIDs(data);
    });
    getGruppenIDs(<ILizenzartBereichRolle[]>this.dataService.getData(TABLENAMES.LIZENZARTBEREICHROLLE));
  }

  reset(): void {
    // Formular zurücksetzen
    this.form.reset();
    // onDataReset-Hook (CommonFormComponent) ausführen
    this.onDataReset();
  }
  
  isLokal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }

  hasChanged(): boolean {
    return (
      super.hasChanged() &&
      (
        !this.isLokal() || !this.isNew() || 
        !(
          Object.keys(this.form.getValues(true)).length === 3 && this.form.hasColumnChanged('IDBildungszentrum') && 
          this.form.hasColumnChanged('Freigabe') && this.form.hasColumnChanged('EmailBestaetigt') 
        )
      )
    );
  }
}
