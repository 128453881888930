<div class="data-popup popup-outer" (window:resize)="onResize()"> 
  <bfe-popup-title title="Lizenzvergabe" (onclose)="cancel()"></bfe-popup-title>
  <div class="data-form popup-inner">
    <div class="form-row" style="margin-bottom: 10px;">
      <label class="form-label">Möchten Sie dem Kundenadministrator neben der Administrator-Lizenz direkt weitere Lizenzen zuweisen:</label>
    </div>
    <div class="form-row">
      <label class="form-label" ExtendLabel>Benutzer</label>
      <label ExtendLabel>{{ benutzer?.Vorname }} {{ benutzer?.Nachname }}</label>
    </div>
    <div class="form-row">
      <label class="form-label" ExtendLabel>Gruppe</label>
      <label ExtendLabel>{{ getGruppe() }}</label>
    </div>
    <div class="form-row" style="margin-top: 20px; ">
      <multi-select 
        *ngIf="!!buchungen.length"
        [items]="buchungen"
        [(selectedItems)]="buchungenSelected"
        keycol="_id"
        [countFirstColumn]="8"
        (selectedItemsChange)="selectedBuchungenChange($event)"
      >
        <ng-template #title let-item="item">
          <div class="multi-name">
            <div>{{ getLernangebot(item) }}</div>
          </div>
        </ng-template>
      </multi-select>
    </div>
    <div class="form-row form-buttons bottom">
      <button pButton type="button" label="Weiter" (click)="goon()"></button>
      <button pButton type="button" label="Abbrechen" (click)="cancel()"></button>
    </div>
  </div>
</div>
