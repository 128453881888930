import * as moment from 'moment';
import { isEqual } from 'lodash-es';

import { 
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild 
} from '@angular/core';
import { Router } from '@angular/router';

import { isEmpty, AppSettings, PopupService, isValid, IComponent } from '@bfeoldenburg/bfe-shared';
import { 
  ToolbarService, BfeForm, DataService, IWriteSubDataset, WRITESUBSTATE, CommonSearchComponent, TOOLBAR, BaseTool, ITool, CommonFeaturesToolbarComponent 
} from '@bfeoldenburg/bfe-data-forms';

import { 
  IBenutzer, IBuchung, IBuchungBenutzer, IFachrichtung, IGruppe, IKunde, IKundeBenutzer, IKundeFachrichtung, IKundentypGruppe, IKursgruppeBenutzer, IntedigiService, Rolle, TABLENAMES 
} from '@bfeoldenburg/intedigi-shared';

import { LizenzverkaufService } from '../../../../_services/lizenzverkauf.service';
import { SubKundenadminComponent } from '../../sub-kundenadmin/sub-kundenadmin.component';
import { SubKundendatenComponent } from '../../sub-kundendaten/sub-kundendaten.component';
import { SubVertragsdatenComponent } from '../../sub-vertragsdaten/sub-vertragsdaten.component';
import { LizenzverkaufToolbarComponent } from '../lizenzverkauf-toolbar/lizenzverkauf-toolbar.component';

interface IExtBenutzer extends IBenutzer {
  gruppe: IGruppe;
}

enum LIZENZVERKAUFTOOLS {
  Verwerfen = 0,
  Weiter 
}

@Component({
  selector: 'grunddaten',
  templateUrl: './grunddaten.component.html',
  styleUrls: ['./grunddaten.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GrunddatenComponent extends CommonFeaturesToolbarComponent implements OnInit, OnDestroy {
  public kunde: IKunde;
  public kundenForm: BfeForm;
  public kunden: IKunde[] = [];
  public adminForm: BfeForm;
  public vertragsForm: BfeForm;
  public loading: boolean = false;

  public benutzer: IExtBenutzer[] = [];
  public ausbilder: IExtBenutzer[] = [];
  public buchungen: IBuchung[] = [];
  public buchungenBenutzer: IBuchungBenutzer[] = [];
  public kundeFachrichtungen: IKundeFachrichtung[] = []
  public kundentypGruppen: IKundentypGruppe[] = [];

  /** Alle benötigten Fremdschlüssel-Tabellen */
  public fkeyTables: string[] = [];
  /** Alle benötigten Tabellen mit abhängigen Daten */
  public subTables: string[] = [];  

  private lastStatus: { error?: boolean; text?: string; } = {};

  private hFachrichtungen: HTMLElement;

  public tablenames = TABLENAMES;

  @ViewChild(SubKundendatenComponent, { static: false }) private kundendatenComp: SubKundendatenComponent;
  @ViewChild(SubKundenadminComponent, { static: false }) private kundenadminComp: SubKundenadminComponent;
  @ViewChild(SubVertragsdatenComponent, { static: false }) private vertragsdatenComp: SubVertragsdatenComponent;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    private lizenzService: LizenzverkaufService,
    appSettings: AppSettings,
    private elementRef: ElementRef,
    private router: Router,
    private popupService: PopupService,
    private intedigiService: IntedigiService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.lastStatus = { error: !this.isValid(), text: this.getInfo() };

    this.subTables = [
      TABLENAMES.BUCHUNG_BENUTZER,
      TABLENAMES.BENUTZER,
      TABLENAMES.BENUTZERAUSTAUSCH,
      TABLENAMES.BUCHUNG_BENUTZER,
      TABLENAMES.KUNDE_BENUTZER,
      TABLENAMES.KURSGRUPPEBENUTZER,
      TABLENAMES.KUNDEFACHRICHTUNG
    ];

    this.fkeyTables = [
      TABLENAMES.GRUPPE,
      TABLENAMES.KUNDENTYPGRUPPE,
      TABLENAMES.FACHRICHTUNG
    ];

    this.addToolbar(TOOLBAR.MainTop);

    this.toolbarService.setComponent(TOOLBAR.MainTop, <IComponent>{ 
      component: LizenzverkaufToolbarComponent,
      inputs: { status: this.lastStatus },
      outputs: {}
    });
  }

  ngOnInit(): void {
    let tools: BaseTool[] = [
      this.toolbarService.createTool(<ITool>{
        id: LIZENZVERKAUFTOOLS.Verwerfen, name: 'Verwerfen', icon: 'icon-undo',
        cbExecute: () => this.reset(),
        cbDisabled: () => !this.hasChanged() || this.isLoading()
      }),
      this.toolbarService.createTool(<ITool>{
        id: LIZENZVERKAUFTOOLS.Weiter, name: 'Weiter', icon: 'icon-navigate_right', align: 'right', wiggle: true, 
        cbExecute: () => this.next(),
        cbDisabled: () => !this.isValid() || this.isLoading()
      }),
    ];

    this.setTools(
      tools,
      TOOLBAR.MainTop
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
  }

  detectChanges(cbChanging?: any): void {
    this.setStatus();

    super.detectChanges(cbChanging);
  }

  setStatus(): void {
    let status = { error: !this.isValid(), text: this.getInfo() };
    if (!isEqual(this.lastStatus, status)) {
      this.lastStatus = status;
      this.toolbarService.updateComponent(
        TOOLBAR.MainTop, { status: status }
      );
    }
  }

  isLoading(): boolean {
    return this.loading || this.lizenzService.isSaving() || !this.subTablesReady() || !this.fkeyTablesReady();
  }

  isWritable(): boolean {
    return (!isEmpty(this.kundenForm) ? this.kundenForm.isWritable() : false);
  }

  isNew(): boolean {
    return (!isEmpty(this.kundenForm) ? this.kundenForm.isNew() : false);
  }

  needsAdminLizenz(): boolean {
    return this.isNew() || this.lizenzService.needsAdminLizenz();
  }

  /** Abfrage ob bereits alle Fremdschlüssel-Daten initial eingelesen wurden */
  fkeyTablesReady(): boolean {
    for (let i = 0; i < this.fkeyTables.length; i++) {
      if (
        this.dataService.neverLoaded(this.fkeyTables[i]) || 
        !this.dataService.isLoadedByStrategy(this.fkeyTables[i], TABLENAMES.KUNDE, this.kunde)
      ) {
        return false;
      }
    }
    return true;
  }

  /** Abfrage ob bereits alle abhängigen Daten initial eingelesen wurden */
  subTablesReady(): boolean {
    for (let i = 0; i < this.subTables.length; i++) {
      if (
        this.dataService.neverLoaded(this.subTables[i]) || 
        !this.dataService.isLoadedByStrategy(this.subTables[i], TABLENAMES.KUNDE, this.kunde)
      ) {
        return false;
      }
    }
    return true;
  }

  onKundeForm(event: BfeForm): void {
    this.kundenForm = event;
    this.detectChanges();
    this.subscriptions.push(this.kundenForm.getCheckEmitter().subscribe(() => this.detectChanges()));
  }

  onVertragsform(event: BfeForm): void {
    this.vertragsForm = event;

    if (!isEmpty(this.kunde) && !isEmpty(this.vertragsdatenComp)) {
      this.vertragsdatenComp.setUeBSItem(this.kunde.UeBS);
      this.vertragsdatenComp.setRechnungErzeugen(this.kunde.RechnungErzeugen);
    }

    this.detectChanges();
    this.subscriptions.push(this.vertragsForm.getCheckEmitter().subscribe(() => this.detectChanges()));
  }

  onAdminform(event: BfeForm): void {
    this.adminForm = event;
    this.detectChanges();
    this.subscriptions.push(this.adminForm.getCheckEmitter().subscribe(() => this.detectChanges()));
  }

  onKundeSelect(event: IKunde): void {
    this.kunde = event;
    this.kunden = [ event ];
    if (!this.needsAdminLizenz()) {
      this.adminForm = null;
    }
    this.getSubdata();
    if (!isEmpty(this.vertragsdatenComp)) {
      if (!!this.kunde.UeBS) {
        this.vertragsdatenComp.setUeBSItem(this.kunde.UeBS);
      } else {
        this.vertragsdatenComp.resetUeBSItem();
      }
      this.vertragsdatenComp.setRechnungErzeugen(this.kunde.RechnungErzeugen);
      this.vertragsdatenComp.onDataChange();
    }
    if (!isEmpty(this.kundenadminComp)) {
      this.kundenadminComp.reset();
    }
    this.detectChanges();
  }

  reset(): void {
    this.popupService.confirm('Wirklich alle Eingaben zurücksetzen?', 'Verwerfen', () => {
      // Alles zurücksetzen
      this.kundendatenComp.reset();
      this.vertragsdatenComp.reset();
      if (!!this.kundenadminComp) {
        this.kundenadminComp.reset();
      }
      this.lizenzService.resetLizenzen();
      this.detectChanges();
    });
  }

  next(): void {
    this.lizenzService.setLizenzVorlagen(this.kundenForm, this.vertragsForm);
    this.router.navigate([ '/lizenzverkauf/lizenzen' ]);
  }

  fachrichtungenHasChanged(): boolean {
    if (isEmpty(this.kundenForm)) {
      return false;
    }
    let count = this.kundenForm.getSubDatasets().reduce((result: number, current: IWriteSubDataset) => {
      if (current.table === TABLENAMES.KUNDEFACHRICHTUNG) {
        return result + 1;
      }
      return result;
    }, 0);
    return !!count;
  }

  isReady(): boolean {
    return !isEmpty(this.kundenForm) && !isEmpty(this.vertragsForm) && (!this.needsAdminLizenz() || !isEmpty(this.adminForm));
  }

  isValid(): boolean {
    return (
      (!isEmpty(this.kundenForm) ? this.kundenForm.valid : true) && 
      (!isEmpty(this.vertragsForm) ? this.vertragsForm.valid : true) && 
      (this.needsAdminLizenz() && !isEmpty(this.adminForm) ? this.adminForm.valid : true)
    );
  }

  hasChanged(): boolean {
    return (
      (!!this.kundendatenComp && !isEmpty(this.kundenForm) && (this.kundendatenComp.hasChanged() || !this.kundenForm.isNew())) || 
      (!!this.kundenadminComp && !isEmpty(this.adminForm) && this.kundenForm.isNew() && this.kundenadminComp.hasChanged()) || 
      (!!this.vertragsdatenComp && !isEmpty(this.vertragsForm) && this.vertragsdatenComp.hasChanged()) || 
      this.fachrichtungenHasChanged() ||
      this.lizenzService.hasLizenzen()
    );
  }

  getInfo(): string {
    if (!this.isValid()) {
      if (!this.kundendatenComp || !this.kundendatenComp.isKundendatenValid()) {
        return 'Füllen Sie die Kundendaten vollständig und korrekt aus';
      } else if (!this.kundendatenComp || !this.kundendatenComp.isRechnungsadresseValid()) {
        return 'Füllen Sie die Rechnungsadresse vollständig und korrekt aus';
      } else if (isEmpty(this.vertragsForm) || !this.vertragsForm.valid) {
        return 'Füllen Sie die Vertragsdaten vollständig und korrekt aus';
      } else if (this.needsAdminLizenz() && (isEmpty(this.adminForm) || !this.adminForm.valid)) {
        return 'Füllen Sie die Benutzerdaten vollständig und korrekt aus';
      } else {
        return 'Unbekannter Fehler';
      }
    } else {
      return 'Fahren Sie fort';
    }
  }

  subDataReady(table: string): boolean {
    return !this.dataService.neverLoaded(table);
  }

  getFachrichtungenWidth(): number {
    if (!this.hFachrichtungen) {
      this.hFachrichtungen = this.elementRef.nativeElement.querySelector('#data-fachrichtungen');
    }
    if (!!this.hFachrichtungen) {
      return this.hFachrichtungen.offsetWidth;
    }
    return 0;
  }

  getSubdata() {
    this.lizenzService.hasAdminLizenz(false);
    this.benutzer = [];
    this.ausbilder = [];
    this.buchungen = [];
    this.buchungenBenutzer = [];
    this.kundeFachrichtungen = [];
    this.kundentypGruppen = [];

    if (!isEmpty(this.kunde)) {
      let getBuchungen = () => {
        this.buchungen = (<IBuchung[]>this.dataService.getData(
          TABLENAMES.BUCHUNG, (item: IBuchung) => item.IDBildungszKd === this.kunde.IDBildungszKd && item.IDKunde === this.kunde.ID
        )).reduce((result: IBuchung[], current: IBuchung) => {
          current.Zusatzinfo = '';
          return result.concat(current);
        }, <IBuchung[]>[]);

        let buchungenIds: { [key: string]: { [key: number]: number[] } } = this.buchungen.reduce(
          (result: { [key: string]: { [key: number]: number[] } }, current: IBuchung) => {
            if (!(current.IDBildungszKd in result)) {
              result[current.IDBildungszKd] = {};
            }
            if (!(current.IDKunde in result[current.IDBildungszKd])) {
              result[current.IDBildungszKd][current.IDKunde] = [];
            }
            result[current.IDBildungszKd][current.IDKunde].push(current.ID);
            return result;
          }, <{ [key: string]: { [key: number]: number[] } }>{}
        );

        this.buchungenBenutzer = <IBuchungBenutzer[]>this.dataService.getData(
          TABLENAMES.BUCHUNG_BENUTZER, 
          (item: IBuchungBenutzer) => (
            item.IDBildungszKd in buchungenIds && item.IDKunde in buchungenIds[item.IDBildungszKd] && 
            buchungenIds[item.IDBildungszKd][item.IDKunde].indexOf(item.IDBuchung) > -1
          )
        );

        let nowStr: string = moment().utc().format('YYYY-MM-DD');
        let hasAdminLizenz = this.buchungen.filter(
          (item: IBuchung) => {
            return (
              item.IDLizenzart === 5 && 
              (
                (!isValid(item.Laufzeit_von) && !isValid(item.Laufzeit_bis)) ||
                item.Laufzeit_bis >= nowStr || 
                (!isValid(item.Laufzeit_bis) && item.Laufzeit_von <= nowStr)
              )
            );
          }
        )
        .findIndex((item: IBuchung) => {
          return this.buchungenBenutzer.findIndex(
            (itemBB: IBuchungBenutzer) => itemBB.IDBildungszKd === item.IDBildungszKd && itemBB.IDKunde === item.IDKunde && itemBB.IDBuchung === item.ID
          ) > -1;
        }) > -1;

        this.lizenzService.hasAdminLizenz(hasAdminLizenz);
      }

      let getBenutzer = () => {
        this.benutzer = [];
        let benutzerIDs: { [key: string]: number[] } = {};

        let getBenutzer__ = (dataset: any) => {
          let benutzer: IExtBenutzer = null;
          if (this.dataService.hasDataset(TABLENAMES.BENUTZER, [ dataset.IDBenutzer, dataset.IDBildungszentrum ])) {
            benutzer = this.dataService.getDataset(TABLENAMES.BENUTZER, [ dataset.IDBenutzer, dataset.IDBildungszentrum ]);
          } else {
            benutzer = this.dataService.getDataset(TABLENAMES.BENUTZERAUSTAUSCH, [ dataset.IDBenutzer, dataset.IDBildungszentrum ]);
          }
          if (
            !isEmpty(benutzer) && (!(benutzer.IDBildungszentrum in benutzerIDs) || benutzerIDs[benutzer.IDBildungszentrum].indexOf(benutzer.ID)) && 
            this.intedigiService.isBenutzerAktiv(benutzer)
          ) {
            if (!(benutzer.IDBildungszentrum in benutzerIDs)) {
              benutzerIDs[benutzer.IDBildungszentrum] = [];
            }
            benutzerIDs[benutzer.IDBildungszentrum].push(benutzer.ID);
            benutzer.gruppe = this.dataService.getDataset(TABLENAMES.GRUPPE, benutzer.IDGruppe);
            return benutzer;
          }
          return null;
        }

        this.benutzer = (<IKundeBenutzer[]>this.dataService.getData(
          TABLENAMES.KUNDE_BENUTZER, (item: IKundeBenutzer) => this.kunde.ID === item.IDKunde && this.kunde.IDBildungszKd === item.IDBildungszKd
        ))
        .reduce((result: IExtBenutzer[], current: IKundeBenutzer) => {
          let benutzer = getBenutzer__(current);
          if (!isEmpty(benutzer)) {
            return result.concat(benutzer);
          }
          return result;
        }, this.benutzer);

        this.benutzer = (<IBuchungBenutzer[]>this.dataService.getData(
          TABLENAMES.BUCHUNG_BENUTZER, (item: IBuchungBenutzer) => this.kunde.ID === item.IDKunde && this.kunde.IDBildungszKd === item.IDBildungszKd
        ))
        .reduce((result: IExtBenutzer[], current: IBuchungBenutzer) => {
          let benutzer = getBenutzer__(current);
          if (!isEmpty(benutzer)) {
            return result.concat(benutzer);
          }
          return result;
        }, this.benutzer);

        this.benutzer = (<IKursgruppeBenutzer[]>this.dataService.getData(
          TABLENAMES.KURSGRUPPEBENUTZER, (item: IKursgruppeBenutzer) => this.kunde.ID === item.IDKunde && this.kunde.IDBildungszKd === item.IDBildungszKd
        ))
        .reduce((result: IExtBenutzer[], current: IKursgruppeBenutzer) => {
          let benutzer = getBenutzer__(current);
          if (!isEmpty(benutzer)) {
            return result.concat(benutzer);
          }
          return result;
        }, this.benutzer);

        this.ausbilder = this.benutzer.reduce((result: IExtBenutzer[], current: IExtBenutzer) => {
          if (!isEmpty(current.gruppe) && (current.gruppe.IDRolle === Rolle.AUSBILDER || current.gruppe.IDRolle === Rolle.DOZENT)) {
            return result.concat(current);
          }
          return result;
        }, <IExtBenutzer[]>[]);
      }

      this.addDataSubscription(TABLENAMES.BUCHUNG, (data: IBuchung[]) => {
        getBuchungen();
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.BUCHUNG_BENUTZER, (data: IBuchungBenutzer[]) => {
        getBenutzer();
        getBuchungen();
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.KUNDE_BENUTZER, (data: IKundeBenutzer[]) => {
        getBenutzer();
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.KURSGRUPPEBENUTZER, (data: IKursgruppeBenutzer[]) => {
        getBenutzer();
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.GRUPPE, (data: IGruppe[]) => {
        getBenutzer();
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.KUNDEFACHRICHTUNG, (data: IKundeFachrichtung[]) => {
        this.kundeFachrichtungen = data.filter((item: IKundeFachrichtung) => item.IDKunde === this.kunde.ID && item.IDBildungszKd === this.kunde.IDBildungszKd);
        this.detectChanges();
        this.setFachrichtungen();
      });

      this.addDataSubscription(TABLENAMES.KUNDENTYPGRUPPE, (data: IKundentypGruppe[]) => {
        this.kundentypGruppen = data.filter((item: IKundentypGruppe) => item.IDKundentyp === this.kunde.IDKundentyp);
        this.detectChanges();
      });

      getBenutzer();
      getBuchungen();
      this.kundeFachrichtungen = <IKundeFachrichtung[]>this.dataService.getData(
        TABLENAMES.KUNDEFACHRICHTUNG, (item: IKundeFachrichtung) => item.IDKunde === this.kunde.ID && item.IDBildungszKd === this.kunde.IDBildungszKd
      );
      this.kundentypGruppen = <IKundentypGruppe[]>this.dataService.getData(
        TABLENAMES.KUNDENTYPGRUPPE, (item: IKundentypGruppe) => item.IDKundentyp === this.kunde.IDKundentyp
      );
      this.setFachrichtungen();
    }

    this.detectChanges();
  }

  setFachrichtungen(): void {
    
  }

  onFachrichtungenChange(): void {
    if (
      !isEmpty(this.kundenForm) && !this.dataService.neverLoaded(TABLENAMES.FACHRICHTUNG) && 
      !this.dataService.neverLoaded(TABLENAMES.KUNDEFACHRICHTUNG)
    ) {
      let removed: string[] = [];

      let fachrichtungen = this.kundenForm.getSubDatasets().reduce((result: IFachrichtung[], current: IWriteSubDataset) => {
        if (current.table === TABLENAMES.KUNDEFACHRICHTUNG && current.state === WRITESUBSTATE.DELETE) {
          removed.push(current.data.IDFachrichtung);
        } else if (current.table === TABLENAMES.KUNDEFACHRICHTUNG && current.state === WRITESUBSTATE.NEW) {
          return result.concat(this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, current.data.IDFachrichtung));
        }
        return result;
      }, <IFachrichtung[]>[]);

      fachrichtungen = this.kundeFachrichtungen.reduce((result: IFachrichtung[], current: IKundeFachrichtung) => {
        if (removed.indexOf(current.IDFachrichtung) === -1) {
          return result.concat(this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, current.IDFachrichtung));
        }
        return result;
      }, fachrichtungen);

      this.lizenzService.setFachrichtungen(fachrichtungen);
    }
    this.detectChanges();
  }

  onKundentypChange(): void {
    if (!isEmpty(this.kundenForm)) {
      this.kunden = [ this.kundenForm.getValues() ];
    } else {
      this.kunden = [];
    }
    this.detectChanges();
    if (!isEmpty(this.vertragsdatenComp)) {
      this.vertragsdatenComp.onDataChange();
    }
  }

  hasAusbilder(): boolean {
    return !isEmpty(this.kunde) && !!this.ausbilder.length;
  }

  ausbilderSuchen(): void {
    if (!isEmpty(this.kunde) && !!this.ausbilder.length) {
      this.popupService.open(
        CommonSearchComponent, 
        {
          columns: this.dataService.getSearchColumns(TABLENAMES.BENUTZER),
          tabletitle: 'Benutzer',
          minWidth: 1600,
          rawdata: this.ausbilder
        },
        {
          onselect: (dataset: any) => { 
            let benutzer = this.dataService.getDataset(TABLENAMES.BENUTZER, [ dataset.ID, dataset.IDBildungszentrum ]);
            this.kundenadminComp.setDatasetManually(benutzer);
            this.detectChanges();
          },
          oncancel: () => {  }
        }
      );
    }
  }
}
