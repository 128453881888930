<div class="data-popup popup-outer" *ngIf="!!form"> 
  <bfe-popup-title [title]="getTitle()" (onclose)="cancel()"></bfe-popup-title>
  <div class="data-form popup-inner">
    <form [formGroup]="form" autocomplete="off">
      <div class="form-row" [class.hidden]="isLokal()">
        <label class="form-label">Bildungszentrum</label>
        <p-dropdown ExtendControl class="bildungszentrum" [options]="bildungszentren" [required]="!isOptional('IDBildungszentrum')" [readonly]="isReadonly('IDBildungszentrum')" formControlName="OBJBildungszentrum" fieldName="IDBildungszentrum" placeholder="Bildungszentrum auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onBildungszentrumChange()"></p-dropdown>
      </div>
      <div class="form-row">
        <label class="form-label" ExtendLabel [help]="getHelp('IDLizenzVorlage')">Lizenz-Vorlage</label>
        <p-autoComplete ExtendControl class="vorlage" [suggestions]="vorlagenAutocomplete" (completeMethod)="filterVorlagenAutocomplete($event)" [dropdown]="!isReadonly('IDLizenzVorlage')" [required]="!isOptional('IDLizenzVorlage')" [readonly]="isReadonly('IDLizenzVorlage')" formControlName="OBJLizenzVorlage" fieldName="IDLizenzVorlage" placeholder="Vorlage auswählen" field="Name" appendTo="body" [minLength]="2" [autoHighlight]="true" [delay]="200" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onSelect)="onLizenzVorlageChange()" (onBlur)="onLizenzVorlageChange()" autocomplete="nope"></p-autoComplete>
      </div>
      <div class="trennline"></div>
      <div class="form-row">
        <label class="form-label">Kundentyp</label>
        <p-dropdown ExtendControl class="kundentyp" [options]="kundentypen" [required]="!isOptional('IDKundentyp')" [readonly]="isReadonly('IDKundentyp')" [showClear]="isOptional('IDKundentyp') && !(isReadonly('IDKundentyp') || !kundentypen.length)" formControlName="OBJKundentyp" fieldName="IDKundentyp" placeholder="Kundentyp auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onKundentypChange()"></p-dropdown>
      </div>
      <div class="form-row">
        <label class="form-label">Kundentyp 2</label>
        <p-dropdown ExtendControl class="kundentyp" [options]="kundentypen2" [required]="!isOptional('IDKundentyp2')" [readonly]="isReadonly('IDKundentyp2')" [showClear]="isOptional('IDKundentyp2') && !(isReadonly('IDKundentyp2') || !kundentypen2.length)" formControlName="OBJKundentyp2" fieldName="IDKundentyp2" placeholder="Kundentyp 2 auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onKundentyp2Change()"></p-dropdown>
      </div>
      <div class="form-row" [class.hidden]="!isBetriebTeilnehmerAusbildungsLizenz() && !isBetriebAusbilderAusbildungsLizenz()">
        <label class="form-label">ÜBS-Status</label>
        <p-dropdown ExtendControl class="uebs" [options]="uebsitems" [required]="!isOptional('UeBS')" [readonly]="isReadonly('UeBS')" [showClear]="isOptional('UeBS') && !(isReadonly('UeBS') || !uebsitems.length)" formControlName="OBJUeBS" fieldName="UeBS" placeholder="ÜBS-Status auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onUeBSItemChange()"></p-dropdown>
      </div>
      <div class="form-row">
        <label class="form-label" ExtendLabel [help]="getHelp('Umkehren')">Umkehren</label>
        <p-checkbox ExtendControl formControlName="Umkehren" [binary]="true" [readonly]="isReadonly('Umkehren')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onUmkehrenChange()"></p-checkbox>
      </div>
      <div class="trennline"></div>
      <div class="form-row hidden">
        <label class="form-label">Nummernkreis</label>
        <p-dropdown ExtendControl class="nummernkreis" [options]="nummernkreise" [required]="!isOptional('Nummernkreis')" [readonly]="isReadonly('Nummernkreis')" [showClear]="isOptional('Nummernkreis') && !(isReadonly('Nummernkreis') || !nummernkreise.length)" formControlName="OBJNummernkreis" fieldName="Nummernkreis" placeholder="Nummernkreis auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onNummernkreisChange()"></p-dropdown>
      </div>
      <div class="form-row">
        <label class="form-label" ExtendLabel [help]="getHelp('Nummer')" [text]="getNummerBezeichner()">{{ getNummerBezeichner() }}</label>
        <input ExtendControl class="nummer" pInputText type="text" [required]="!isOptional('Nummer')" [readonly]="isReadonly('Nummer')" formControlName="Nummer" [maxlength]="getFieldLength('Nummer')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
      </div>
      <div class="form-row" [class.hidden]="!isVerlaengerungAktiv()" ExtendLabel [help]="getHelp('NummerVerlaengerung')" [text]="getNummerVerlaengerungBezeichner()">
        <label class="form-label">{{ getNummerVerlaengerungBezeichner() }}</label>
        <input ExtendControl class="nummer" pInputText type="text" [required]="!isOptional('NummerVerlaengerung')" [readonly]="isReadonly('NummerVerlaengerung')" formControlName="NummerVerlaengerung" [maxlength]="getFieldLength('NummerVerlaengerung')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
      </div>
      <div class="form-row">
        <label class="form-label" ExtendLabel [help]="getHelp('ArtikelBeschreibung')">Artikelbeschreibung</label>
        <textarea ExtendControl class="beschreibung" [rows]="3" pInputTextarea [required]="!isOptional('ArtikelBeschreibung')" [readonly]="isReadonly('ArtikelBeschreibung')" formControlName="ArtikelBeschreibung" [maxlength]="getFieldLength('ArtikelBeschreibung')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.control.enter)="textareaNextLine($event)" (keydown.enter)="textareaKeyDownEnter($event)" (keyup.enter)="focusNext($event)" ></textarea>
      </div>
      <div>
        <div class="textarea-info">STRG + Enter für einen Zeilenumbruch</div>
      </div>
      <div class="form-row form-buttons bottom">
        <button pButton type="button" label="Speichern" [disabled]="form.isInvalid() || !hasChanged()" (click)="save()" *ngIf="isWritable()"></button>
        <button pButton type="button" label="Abbrechen" (click)="cancel()"></button>
        <div style="flex: 1; min-height: 100%; "></div>
        <button pButton type="button" label="Löschen" class="p-button-danger" [disabled]="isNew()" (click)="delete()" *ngIf="isDeletable()"></button>
      </div>
    </form> 
  </div>
</div>
