<extended-list
  tablename="ERPArtikelnummer" 
  tabletitle="ERP-Artikelnummer"
  dataKey="_uuid"
  [columns]="columns"
  [popupComponent]="popupComponent"
  [fKeyTables]="fKeyTables"
  [stackedWidth]="650"
  [rows]="50" 
  [rowsPerPageOptions]="[25,50,100,200,300]" 
  paginatorPosition="both"
  [expandable]="false"
  [disableExport]="true"
></extended-list>
