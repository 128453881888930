<div class="data-popup popup-outer" *ngIf="!!form" (window:resize)="onResize()"> 
  <bfe-popup-title [title]="getTitle()" (onclose)="cancel()"></bfe-popup-title>
  <div class="data-form popup-inner">
    <form [formGroup]="form" autocomplete="off">
      <div class="form-row">
        <label class="form-label" ExtendLabel [help]="getHelp('Name')">Vorlagenname</label>
        <input ExtendControl class="name" pInputText type="text" [required]="!isOptional('Name')" [readonly]="isReadonly('Name')" formControlName="Name" [maxlength]="getFieldLength('Name')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
      </div>
      <div class="form-row">
        <label class="form-label" for="Privat" ExtendLabel [help]="getHelp('IDBildungszentrum')">Bildungszentrum</label>
        <p-dropdown ExtendControl class="bildungszentrum" [options]="bildungszentren" [required]="!isOptional('IDBildungszentrum')" [readonly]="isReadonly('IDBildungszentrum')" [showClear]="true" formControlName="OBJBildungszentrum" fieldName="IDBildungszentrum" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onBildungszentrumChange()"></p-dropdown>
      </div>
      <div class="form-row" style="margin-top: 40px;">
        <label class="form-label" ExtendLabel [help]="getHelp('Betreff')">E-Mail-Betreff</label>
        <input ExtendControl class="betreff" pInputText type="text" [required]="!isOptional('Betreff')" [readonly]="isReadonly('Betreff')" formControlName="Betreff" [maxlength]="getFieldLength('Betreff')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" (input)="changeSettings()" (paste)="changeSettings()">
      </div>
      <div class="form-row">
        <label class="form-label" ExtendLabel [help]="getHelp('Betreff')">E-Mail-Text</label>
        <p-editor class="text" formControlName="Text" [required]="!isOptional('Text')" [readonly]="isReadonly('Text')" [maxlength]="getFieldLength('Name')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.control.enter)="focusNext($event)" [style]="getStyle()" (input)="changeSettings()" (paste)="changeSettings()"></p-editor>
      </div>
      <div class="form-row form-buttons bottom">
        <button pButton type="button" label="Speichern" [disabled]="form.isInvalid() || !hasChanged()" (click)="save()" *ngIf="isWritable()"></button>
        <button pButton type="button" label="Abbrechen" (click)="cancel()"></button>
        <div style="flex: 1; min-height: 100%; "></div>
        <button pButton type="button" label="Kopieren" [disabled]="isNew()" class=" p-button-outlined" (click)="copyVorlage()"></button>
        <div style="flex: 1; min-height: 100%; "></div>
        <button pButton type="button" label="Löschen" class="p-button-danger" [disabled]="isNew()" (click)="delete()" *ngIf="isDeletable()"></button>
      </div>
    </form>
  </div>
</div>  
