import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { timer } from 'rxjs';

import { isValid, PopupComponent, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, CommonFeaturesComponent, DataService } from '@bfeoldenburg/bfe-data-forms';

import { ILizenzVorlageExt, VKLIZENZBEREICH } from '../../../../../_services/lizenzverkauf.service';

@Component({
  selector: 'lizenz-popup',
  templateUrl: './lizenz-popup.component.html',
  styleUrls: ['./lizenz-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LizenzPopupComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() extLizenzVorlage: ILizenzVorlageExt;

  @Input() lizenzbereich: VKLIZENZBEREICH;

  @Input() count: string;

  @Input() new: boolean;

  @Output() onedit: EventEmitter<number> = new EventEmitter<number>();

  @Output() oncancel: EventEmitter<void> = new EventEmitter<void>();

  /** Referenz auf die Popup-Komponente */
  public popup: PopupComponent;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private elementRef: ElementRef
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.detectChanges();
  }

  setFocus(): void {
    let input: HTMLInputElement = <HTMLInputElement>this.elementRef.nativeElement.querySelector('#count');
    if (!!input) {
      input.focus();
      input.select();
    } else {
      timer(100).subscribe(() => this.setFocus());
    }
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
  }

  hasFachrichtung(): boolean {
    return isValid(this.extLizenzVorlage) && isValid(this.extLizenzVorlage.fachrichtung);
  }

  getFachrichtung(): string {
    return this.extLizenzVorlage.fachrichtung.Name + ' (' + this.extLizenzVorlage.fachrichtung.ID + ')';
  }

  countChanged(): void {
    this.detectChanges();
  }

  save(): void {
    if (!isValid(this.count)) {
      this.count = '0';
    }
    this.onedit.emit(parseInt(this.count));
    this.popup.close();
  }

  cancel(): void {
    this.oncancel.emit();
    this.popup.close();
  }

  delete(): void {
    this.onedit.emit(0);
    this.popup.close();
  }

  isNew(): boolean {
    return this.new;
  }
}
