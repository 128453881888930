<div class="data-popup popup-outer" *ngIf="!!form"> 
  <bfe-popup-title [title]="getTitle()" (onclose)="cancel()"></bfe-popup-title>
  <div class="data-form popup-inner">
    <form [formGroup]="form" autocomplete="off">
      <div class="form-row" [class.hidden]="isLokal()">
        <label class="form-label">Bildungszentrum</label>
        <label>{{ getBildungszentrum() }}</label>
      </div>
      <div class="form-row hidden">
        <label class="form-label">Nummernkreis</label>
        <label>{{ getNummernkreis() }}</label>
      </div>
      <div class="form-row">
        <label class="form-label">{{ getNummerBezeichner() }}</label>
        <label>{{ data?.Nummer }}</label>
      </div>
      <div class="form-row">
        <label class="form-label">Bezeichnung</label>
        <label>{{ data?.Bezeichnung }}</label>
      </div>
      <div class="form-row form-buttons bottom">
        <!--<button pButton type="button" label="Speichern" [disabled]="form.isInvalid() || !hasChanged()" (click)="save()" *ngIf="isWritable()"></button>-->
        <button pButton type="button" label="Abbrechen" (click)="cancel()"></button>
        <div style="flex: 1; min-height: 100%; "></div>
        <button pButton type="button" label="Löschen" class="p-button-danger" [disabled]="isNew()" (click)="delete()" *ngIf="isDeletable()"></button>
      </div>
    </form> 
  </div>
</div>
