import * as moment from 'moment';
import { clone } from 'lodash-es';

import { Injectable } from "@angular/core";
import { first } from 'rxjs/operators';

import { isValid, isEmpty, NotifyService, PopupService, RestService } from '@bfeoldenburg/bfe-shared';
import { DataService, BfeForm, IWriteSubDataset, WRITESUBSTATE } from '@bfeoldenburg/bfe-data-forms';

import { 
  IBenutzer, IBuchung, IERPArtikelnummer, IFachrichtung, IKunde, IKurs, ILernangebot, ILizenzVorlage, IntedigiService, IPaket, IPaketposition, TABLENAMES, IKundentypBereich, IKundentypGruppe, IGruppe, ILizenzartBereichRolle, ILizenzart, IntedigiUserService 
} from '@bfeoldenburg/intedigi-shared';

import { TABLEALIASSE } from '../_models/table-aliasse';
import { AdminLizenzenPopupComponent } from '../_components/workflows/lizenzverkauf/lizenzen/admin-lizenzen-popup/admin-lizenzen-popup.component';
import { HttpClient } from '@angular/common/http';


export enum VKLIZENZBEREICH {
  azubi = 1,
  ausbilder,
  sonstige,
  lernangebote,
  weitere
}

export interface ILizenzVorlageExt extends ILizenzVorlage {
  _id: number;
  _local: boolean;
  IDBildungszentrum?: string;
  IDKundentyp?: number;
  IDKundentyp2?: number;
  Umkehren: boolean;
  fachrichtung: IFachrichtung;
  paket: IPaket;
  lernangebot: ILernangebot;
  kurs: IKurs;
  status: LIZENZSTATUS;
  Artikelnummer?: string;
  ArtikelBeschreibung?: string;
  Verlaengerung?: string;
  Nummernkreis?: number;
}

export interface ILizenzverkauf {
  extLizenzVorlage: ILizenzVorlageExt;
  lernangebot: ILernangebot;
  lizenzbereich: VKLIZENZBEREICH;
  anzahl: number;
  subLizenzen: ILizenzverkauf[];
  _id: number;
}

export enum UEBSSTATUS {
  NichtRelevant = 1,
  MitUeBS,
  OhneUeBS
}

export enum LIZENZSTATUS {
  Alle = 1,
  Grundlizenzen,
  Verlaengerungen
}


@Injectable({ providedIn: 'root' })
export class LizenzverkaufService {
  private fachrichtungen: IFachrichtung[] = [];
  private lizenzVorlagen: ILizenzVorlageExt[] = [];

  private lizenzen: ILizenzverkauf[] = []

  private vertragsbeginn: Date = null;
  private hasAdminLizenz__: boolean = false;
  
  private saving: boolean = false;

  constructor(
    private dataService: DataService,
    private notifyService: NotifyService,
    private intedigiService: IntedigiService,
    private intedigiUserService: IntedigiUserService,
    private popupService: PopupService,
    private http: HttpClient,
    private restService: RestService
  ) {
    this.dataService.addTableAlias(TABLENAMES.KUNDE, TABLEALIASSE.Kundendaten);
    this.dataService.addTableAlias(TABLENAMES.BENUTZER, TABLEALIASSE.Kundenadmin);
  }

  isLocalErp(): boolean {
    return this.intedigiService.isInterfaceLocal() && this.intedigiService.isErpInterfaceActive();
  }

  setFachrichtungen(data: IFachrichtung[]) {
    this.fachrichtungen = data;
  }

  getFachrichtungen(): IFachrichtung[] {
    return this.fachrichtungen;
  }

  setVetragsbeginn(vertragsbeginn: Date): void {
    this.vertragsbeginn = vertragsbeginn;
  }

  getVetragsbeginn(): Date {
    return this.vertragsbeginn;
  }

  hasAdminLizenz(value?: boolean): void {
    this.hasAdminLizenz__ = value;
  }

  needsAdminLizenz(): boolean {
    return !this.hasAdminLizenz__;
  }

  setLizenzVorlagen(kundenform: BfeForm, vertragsform: BfeForm): void {
    let _id = 0;
    let kundenBereichIDs: string[] = (<IKundentypBereich[]>this.dataService.getData(
      TABLENAMES.KUNDENTYPBEREICH, (item: IKundentypBereich) => item.IDKundentyp === kundenform.getFormValue('IDKundentyp')
    ))
    .reduce((result: string[], current: IKundentypBereich) => {
      return result.concat(current.IDBereich);
    }, <string[]>[]);

    let kundenRollenIDs: string[] = (<IKundentypGruppe[]>this.dataService.getData(
      TABLENAMES.KUNDENTYPGRUPPE, (item: IKundentypGruppe) => item.IDKundentyp === kundenform.getFormValue('IDKundentyp')
    ))
    .reduce((result: string[], current: IKundentypGruppe) => {
      let gruppe: IGruppe = this.dataService.getDataset(TABLENAMES.GRUPPE, current.IDGruppe);
      return result.concat(gruppe.IDRolle);
    }, <string[]>[]);

    let demoMode = vertragsform.getFormValue('Demozugang');
    let selfMode = (
      kundenform.getFormValue('IDBildungszKd') === this.intedigiService.getBildungszentrum() && 
      kundenform.getFormValue('ID') === this.intedigiService.getKunde()
    );
    let lizenzStatus = vertragsform.getFormValue('LizenzStatus');

    if (this.isLocalErp() && !demoMode && !selfMode) {
      this.lizenzVorlagen = (<IERPArtikelnummer[]>this.dataService.getData(TABLENAMES.ERP_ARTIKELNUMMER)).reduce(
        (result: ILizenzVorlageExt[], current: IERPArtikelnummer) => {
          if (
            (
              lizenzStatus === LIZENZSTATUS.Alle || lizenzStatus === LIZENZSTATUS.Grundlizenzen || 
              (lizenzStatus === LIZENZSTATUS.Verlaengerungen && isValid(current.NummerVerlaengerung))
            ) &&
            this.intedigiUserService.getBildungszentrum() === current.IDBildungszentrum && 
            (
              (
                !this.intedigiService.isKundeBetrieb(kundenform.getValues()) && 
                (
                  current.UeBS === UEBSSTATUS.NichtRelevant || 
                  (current.UeBS === UEBSSTATUS.OhneUeBS && !current.Umkehren) || 
                  (current.UeBS === UEBSSTATUS.MitUeBS && current.Umkehren)
                )
              ) || 
              (
                this.intedigiService.isKundeBetrieb(kundenform.getValues()) &&
                (
                  (vertragsform.getFormValue('UeBS') === UEBSSTATUS.NichtRelevant && current.UeBS === UEBSSTATUS.NichtRelevant) || 
                  (
                    vertragsform.getFormValue('UeBS') === UEBSSTATUS.OhneUeBS && 
                    (
                      current.UeBS === UEBSSTATUS.NichtRelevant || 
                      (current.UeBS === UEBSSTATUS.OhneUeBS && !current.Umkehren) || 
                      (current.UeBS === UEBSSTATUS.MitUeBS && current.Umkehren)
                    )
                  ) || 
                  (
                    vertragsform.getFormValue('UeBS') === UEBSSTATUS.MitUeBS && 
                    (
                      current.UeBS === UEBSSTATUS.NichtRelevant || 
                      (current.UeBS === UEBSSTATUS.MitUeBS && !current.Umkehren) || 
                      (current.UeBS === UEBSSTATUS.OhneUeBS && current.Umkehren)
                    )
                  )
                )
              )
            )
          ) {
            let extLizenzVorlage = <ILizenzVorlageExt>this.dataService.getDataset(TABLENAMES.LIZENZ_VORLAGE, current.IDLizenzVorlage) || <ILizenzVorlageExt>{};
            let lizenzart = <ILizenzart>this.dataService.getDataset(TABLENAMES.LIZENZART, extLizenzVorlage.IDLizenzart) || <ILizenzart>{};
            let rollenIDs: string[] = [];

            if (lizenzart.IDLizenztyp === 1) {
              rollenIDs = (<ILizenzartBereichRolle[]>this.dataService.getData(
                TABLENAMES.LIZENZARTBEREICHROLLE, (item: ILizenzartBereichRolle) => {
                  return item.IDLizenzart === extLizenzVorlage.IDLizenzart && item.IDBereich === extLizenzVorlage.IDBereich;
                }
              ))
              .reduce((result__: string[], current__: ILizenzartBereichRolle) => {
                if (kundenRollenIDs.indexOf(current__.IDRolle) > -1) {
                  return result__.concat(current__.IDRolle);
                }
                return result__;
              }, <string[]>[]);
            }

            if (lizenzart.IDLizenztyp !== 1 || (kundenBereichIDs.indexOf(extLizenzVorlage.IDBereich) > -1 && rollenIDs.length > 0)) {
              extLizenzVorlage._local = true;
              extLizenzVorlage.IDBildungszentrum = current.IDBildungszentrum;
              extLizenzVorlage.IDKundentyp = (current.IDKundentyp === -1 ? null : current.IDKundentyp);
              extLizenzVorlage.IDKundentyp2 = (current.IDKundentyp2 === -1 ? null : current.IDKundentyp2);
              extLizenzVorlage.Umkehren = current.Umkehren;
              extLizenzVorlage.Artikelnummer = current.Nummer;
              extLizenzVorlage.ArtikelBeschreibung = current.ArtikelBeschreibung;
              extLizenzVorlage.Verlaengerung = current.NummerVerlaengerung;
              extLizenzVorlage.Nummernkreis = current.Nummernkreis;

              if (
                !isValid(extLizenzVorlage.IDFachrichtung) || 
                this.fachrichtungen.findIndex((item: IFachrichtung) => item.ID === extLizenzVorlage.IDFachrichtung) > -1
              ) {
                let items__: ILizenzVorlageExt[] = [];

                if (isValid(extLizenzVorlage.IDPaketgroesse)) {
                  for (let i = 0; i < this.fachrichtungen.length; i++) {
                    let pakete: IPaket[] = <IPaket[]>this.dataService.getData(TABLENAMES.PAKET, (item: IPaket) => {
                      return item.IDPaketgroesse === extLizenzVorlage.IDPaketgroesse && item.IDFachrichtung === this.fachrichtungen[i].ID;
                    });

                    if (!!pakete.length) {
                      if (lizenzStatus === LIZENZSTATUS.Alle || lizenzStatus === LIZENZSTATUS.Grundlizenzen) {
                        _id++;
                        let extLizenzVorlage__ = clone(extLizenzVorlage)
                        extLizenzVorlage__._id = _id;
                        extLizenzVorlage__.paket = pakete[0];
                        extLizenzVorlage__.kurs = this.dataService.getDataset(TABLENAMES.KURS, pakete[0].IDKurs) || null;
                        extLizenzVorlage__.fachrichtung = this.fachrichtungen[i];
                        extLizenzVorlage__.status = LIZENZSTATUS.Grundlizenzen;
                        items__.push(extLizenzVorlage__);
                      }

                      if ((lizenzStatus === LIZENZSTATUS.Alle || lizenzStatus === LIZENZSTATUS.Verlaengerungen) && isValid(current.NummerVerlaengerung)) {
                        _id++;
                        let extLizenzVorlage__ = clone(extLizenzVorlage)
                        extLizenzVorlage__._id = _id;
                        extLizenzVorlage__.paket = pakete[0];
                        extLizenzVorlage__.kurs = this.dataService.getDataset(TABLENAMES.KURS, pakete[0].IDKurs) || null;
                        extLizenzVorlage__.fachrichtung = this.fachrichtungen[i];
                        extLizenzVorlage__.status = LIZENZSTATUS.Verlaengerungen;
                        items__.push(extLizenzVorlage__);
                      }
                    }
                  }

                  return result.concat(items__);

                } else {
                  if (lizenzStatus === LIZENZSTATUS.Alle || lizenzStatus === LIZENZSTATUS.Grundlizenzen) {
                    _id++;
                    let extLizenzVorlage__ = clone(extLizenzVorlage)
                    extLizenzVorlage__._id = _id;
                    extLizenzVorlage__.paket = this.dataService.getDataset(TABLENAMES.PAKET, extLizenzVorlage.IDPaket) || null;
                    extLizenzVorlage__.lernangebot = this.dataService.getDataset(TABLENAMES.LERNANGEBOT, extLizenzVorlage.IDLernangebot) || null;
                    extLizenzVorlage__.kurs = this.dataService.getDataset(TABLENAMES.KURS, extLizenzVorlage.IDKurs) || null;
                    extLizenzVorlage__.fachrichtung = this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, extLizenzVorlage.IDFachrichtung) || null;
                    extLizenzVorlage__.status = LIZENZSTATUS.Grundlizenzen;
                    items__.push(extLizenzVorlage__);
                  }

                  if ((lizenzStatus === LIZENZSTATUS.Alle || lizenzStatus === LIZENZSTATUS.Verlaengerungen) && isValid(current.NummerVerlaengerung)) {
                    _id++;
                    let extLizenzVorlage__ = clone(extLizenzVorlage)
                    extLizenzVorlage__._id = _id;
                    extLizenzVorlage__.paket = this.dataService.getDataset(TABLENAMES.PAKET, extLizenzVorlage.IDPaket) || null;
                    extLizenzVorlage__.lernangebot = this.dataService.getDataset(TABLENAMES.LERNANGEBOT, extLizenzVorlage.IDLernangebot) || null;
                    extLizenzVorlage__.kurs = this.dataService.getDataset(TABLENAMES.KURS, extLizenzVorlage.IDKurs) || null;
                    extLizenzVorlage__.fachrichtung = this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, extLizenzVorlage.IDFachrichtung) || null;
                    extLizenzVorlage__.status = LIZENZSTATUS.Verlaengerungen;
                    items__.push(extLizenzVorlage__);
                  }

                  return result.concat(items__);
                }
              }
            }
          }
          return result;
        }, <ILizenzVorlageExt[]>[]
      );
    } else {
      this.lizenzVorlagen = (<ILizenzVorlage[]>this.dataService.getData(TABLENAMES.LIZENZ_VORLAGE)).reduce(
        (result: ILizenzVorlageExt[], current: ILizenzVorlage) => {
          if (
            !isValid(current.IDPaketgroesse) && 
            (
              !isValid(current.IDFachrichtung) || 
              this.fachrichtungen.findIndex((item: IFachrichtung) => item.ID === current.IDFachrichtung) > -1
            ) &&
            (!demoMode || !!current.Demo)
          ) {
            let extLizenzVorlage = <ILizenzVorlageExt>current;
            let lizenzart = <ILizenzart>this.dataService.getDataset(TABLENAMES.LIZENZART, current.IDLizenzart) || <ILizenzart>{};
            let rollenIDs: string[] = [];

            if (lizenzart.IDLizenztyp === 1) {
              rollenIDs = (<ILizenzartBereichRolle[]>this.dataService.getData(
                TABLENAMES.LIZENZARTBEREICHROLLE, (item: ILizenzartBereichRolle) => {
                  return item.IDLizenzart === extLizenzVorlage.IDLizenzart && item.IDBereich === extLizenzVorlage.IDBereich;
                }
              ))
              .reduce((result: string[], current: ILizenzartBereichRolle) => {
                if (kundenRollenIDs.indexOf(current.IDRolle) > -1) {
                  return result.concat(current.IDRolle);
                }
                return result;
              }, <string[]>[]);
            }

            if (lizenzart.IDLizenztyp !== 1 || (kundenBereichIDs.indexOf(extLizenzVorlage.IDBereich) > -1 && rollenIDs.length > 0)) {
              _id++;
              extLizenzVorlage._id = _id;
              extLizenzVorlage._local = false;
              extLizenzVorlage.paket = this.dataService.getDataset(TABLENAMES.PAKET, extLizenzVorlage.IDPaket) || null;
              extLizenzVorlage.lernangebot = this.dataService.getDataset(TABLENAMES.LERNANGEBOT, extLizenzVorlage.IDLernangebot) || null;
              extLizenzVorlage.kurs = this.dataService.getDataset(TABLENAMES.KURS, extLizenzVorlage.IDKurs) || null;
              extLizenzVorlage.fachrichtung = this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, extLizenzVorlage.IDFachrichtung) || null;
              return result.concat(extLizenzVorlage);
            }
          }
          return result;
        }, <ILizenzVorlageExt[]>[]
      );
    }

    this.lizenzen = this.lizenzen.reduce((result: ILizenzverkauf[], current: ILizenzverkauf) => {
      let extLizenzVorlage: ILizenzVorlageExt = this.lizenzVorlagen.find((item: ILizenzVorlageExt) => {
        return (
          (
            item._uuid === current.extLizenzVorlage._uuid && item.status === current.extLizenzVorlage.status && 
            !isValid(item.IDPaketgroesse)
          ) || 
          (
            item._uuid === current.extLizenzVorlage._uuid && item.status === current.extLizenzVorlage.status && 
            isValid(item.IDPaketgroesse) && item.fachrichtung._uuid === current.extLizenzVorlage.fachrichtung._uuid
          )
        );
      });
      if (!isEmpty(extLizenzVorlage)) {
        current.extLizenzVorlage._id = extLizenzVorlage._id;
        return result.concat(current);
      }
      return result;
    }, <ILizenzverkauf[]>[]);
  }

  getLizenzVorlagen(cbFilter: any): ILizenzVorlageExt[] {
    if (!!cbFilter) {
      return this.lizenzVorlagen.filter(cbFilter);
    } else {
      return this.lizenzVorlagen;
    }
  }

  addLizenz(extLizenzVorlage: ILizenzVorlageExt, lizenzbereich: VKLIZENZBEREICH, anzahl: number) {
    let lizenz: ILizenzverkauf = <ILizenzverkauf>{
      extLizenzVorlage: extLizenzVorlage,
      lizenzbereich: lizenzbereich,
      anzahl: anzahl,
      subLizenzen: [],
      _id: extLizenzVorlage._id
    }
    this.lizenzen.push(lizenz);

    if (isValid(extLizenzVorlage.paket) && isValid(extLizenzVorlage.paket.ID)) {
      let positionen: IPaketposition[] = <IPaketposition[]>this.dataService.getData(
        TABLENAMES.PAKETPOSITION, (item: IPaketposition) => item.IDPaket === extLizenzVorlage.paket.ID
      );
      for (let i = 0; i < positionen.length; i++) {
        let extLizenzVorlagePos = this.lizenzVorlagen.find((item: ILizenzVorlageExt) => {
          return (
            (!isValid(item.IDKundentyp) || item.IDKundentyp === extLizenzVorlage.IDKundentyp) && 
            (!isValid(item.IDKundentyp2) || item.IDKundentyp2 === extLizenzVorlage.IDKundentyp2) && 
            item.IDLernangebot === positionen[i].IDLernangebot && item.IDLizenzart === extLizenzVorlage.IDLizenzart
          );
        });

        if (isValid(extLizenzVorlagePos)) {
          if (this.hasLizenz(extLizenzVorlagePos)) {
            let sublizenz = this.getLizenz(extLizenzVorlagePos);
            sublizenz.subLizenzen.push(lizenz);
          } else {
            let sublizenz: ILizenzverkauf = <ILizenzverkauf>{
              extLizenzVorlage: extLizenzVorlagePos,
              lizenzbereich: VKLIZENZBEREICH.lernangebote,
              anzahl: 0,
              subLizenzen: [ lizenz ],
              _id: extLizenzVorlagePos._id
            }
            this.lizenzen.push(sublizenz);
          }
        }
      }
    }
  }

  getLizenz(extLizenzVorlage: ILizenzVorlageExt): ILizenzverkauf {
    return this.lizenzen.find((item: ILizenzverkauf) => item.extLizenzVorlage._id === extLizenzVorlage._id) || null;
  }

  hasLizenz(extLizenzVorlage: ILizenzVorlageExt): boolean {
    return this.lizenzen.findIndex((item: ILizenzverkauf) => item.extLizenzVorlage._id === extLizenzVorlage._id) > -1;
  }

  private removeLizenz__(lizenzen: ILizenzverkauf[], lizenz: ILizenzverkauf): ILizenzverkauf[] {
    return lizenzen.reduce((result: ILizenzverkauf[], current: ILizenzverkauf) => {
      if (lizenz.extLizenzVorlage._id !== current.extLizenzVorlage._id || !!current.subLizenzen.length) {
        if (lizenz.extLizenzVorlage._id === current.extLizenzVorlage._id) {
          current.anzahl = 0;
        }
        current.subLizenzen = this.removeLizenz__(current.subLizenzen, lizenz);
        if (!!current.anzahl || !!current.subLizenzen.length) {
          return result.concat(current);
        }
      }
      return result;
    }, <ILizenzverkauf[]>[]);
  }

  removeLizenz(lizenz: ILizenzverkauf | ILizenzVorlageExt): void {
    if ('_id' in lizenz) {
      lizenz = this.getLizenz(<ILizenzVorlageExt>lizenz);
    }
    if (isValid(lizenz)) {
      this.lizenzen = this.removeLizenz__(this.lizenzen, lizenz);
    }
  }

  hasLizenzen(lizenzbereich?: VKLIZENZBEREICH): boolean {
    if (!lizenzbereich) {
      return !!this.lizenzen.length;
    } else {
      return !!this.lizenzen.filter((item: ILizenzverkauf) => item.lizenzbereich === lizenzbereich).length;
    }
  }

  hasLizenzart(IDLizenzart: number): boolean {
    return this.lizenzen.findIndex((item: ILizenzverkauf) => item.extLizenzVorlage.IDLizenzart === IDLizenzart) > -1;
  }

  resetLizenzen(): void {
    this.lizenzen = [];
  }

  getLizenzen(lizenzbereich?: VKLIZENZBEREICH): ILizenzverkauf[] {
    if (!lizenzbereich) {
      return this.lizenzen;
    } else {
      return this.lizenzen.filter((item: ILizenzverkauf) => item.lizenzbereich === lizenzbereich);
    }
  }

  countLizenzbereiche(): number {
    let lizenzbereiche: { [key: number]: number } = {};
    this.lizenzen.reduce((result: { [key: number]: number }, current: ILizenzverkauf) => {
      if (!(current.lizenzbereich in result)) {
        result[current.lizenzbereich] = 1;
      } else {
        result[current.lizenzbereich]++;
      }
      return result;
    }, <{ [key: number]: number }>{});

    let keys = Object.keys(lizenzbereiche);
    return keys.length;
  }

  private prepareLizenzen__(vorgang: string, kundenform: BfeForm, vertragsform: BfeForm, admin?: IBenutzer, lizenzen: ILizenzverkauf[] = []) {
    let vertragsbeginn = moment(vertragsform.getFormValue('Vertragsbeginn')).utc(true);
    let demoMode = vertragsform.getFormValue('Demozugang');
    let selfMode = (
      kundenform.getFormValue('IDBildungszKd') === this.intedigiService.getBildungszentrum() && 
      kundenform.getFormValue('ID') === this.intedigiService.getKunde()
    );

    kundenform.addSubDatasets(
      this.lizenzen.reduce((result: IWriteSubDataset[], current: ILizenzverkauf) => {
        if (!!current.anzahl) {
          let vertragsende = moment(vertragsbeginn);
          if (!demoMode) {
            vertragsende.add(current.extLizenzVorlage.Laufzeit, 'months').add(-1, 'days');
          } else {
            vertragsende.add(current.extLizenzVorlage.DemoLaufzeit, 'days');
          }

          let subSubDatasets = null;
          if (
            (current.extLizenzVorlage.IDLizenzart === 5 && isValid(admin)) || 
            lizenzen.findIndex((item: ILizenzverkauf) => item.extLizenzVorlage._id === current.extLizenzVorlage._id) > -1
          ) {
            subSubDatasets = [ <IWriteSubDataset>{
              table: TABLENAMES.BUCHUNG_BENUTZER,
              uuid: null,
              state: WRITESUBSTATE.NEW,
              data: {
                IDBildungszKd: kundenform.getFormValue('IDBildungszKd'),
                IDKurs: (isValid(current.extLizenzVorlage.kurs) ? current.extLizenzVorlage.kurs.ID : 0),
                IDPaket: (isValid(current.extLizenzVorlage.paket) ? current.extLizenzVorlage.paket.ID : ''),
                IDLernangebot: (isValid(current.extLizenzVorlage.lernangebot) ? current.extLizenzVorlage.lernangebot.ID : ''),
                IDFachrichtung: (isValid(current.extLizenzVorlage.fachrichtung) ? current.extLizenzVorlage.fachrichtung.ID : '')
              },
              cbOnExecute: (dataset: IWriteSubDataset, parent: IBuchung) => {
                dataset.data.IDKunde = parent.IDKunde;
                dataset.data.IDBuchung  = parent.ID;
              }
            } ];
          }

          return result.concat(<IWriteSubDataset>{
            table: TABLENAMES.BUCHUNG,
            uuid: null,
            state: WRITESUBSTATE.NEW,
            data: {
              Vorgangsnummer: vorgang,
              IDBildungszKd: kundenform.getFormValue('IDBildungszKd'),
              IDLizenzart: current.extLizenzVorlage.IDLizenzart,
              IDBereich: current.extLizenzVorlage.IDBereich,
              IDKurs: (isValid(current.extLizenzVorlage.kurs) ? current.extLizenzVorlage.kurs.ID : 0),
              IDPaket: (isValid(current.extLizenzVorlage.paket) ? current.extLizenzVorlage.paket.ID : ''),
              IDLernangebot: (isValid(current.extLizenzVorlage.lernangebot) ? current.extLizenzVorlage.lernangebot.ID : ''),
              IDFachrichtung: (isValid(current.extLizenzVorlage.fachrichtung) ? current.extLizenzVorlage.fachrichtung.ID : ''),
              Laufzeit_von: (current.extLizenzVorlage.Unbegrenzt && !demoMode ? null : vertragsbeginn.format('YYYY-MM-DD')),
              Laufzeit_bis: (current.extLizenzVorlage.Unbegrenzt && !demoMode ? null : vertragsende.format('YYYY-MM-DD')),
              Anzahl: current.anzahl,
              Freigabe: (this.isLocalErp() && !demoMode && !selfMode ? 0 : 1),
              Nummernkreis: current.extLizenzVorlage.Nummernkreis,
              Artikelnummer: (
                current.extLizenzVorlage.status === LIZENZSTATUS.Grundlizenzen ? current.extLizenzVorlage.Artikelnummer : current.extLizenzVorlage.Verlaengerung
              ),
              ArtikelBeschreibung: current.extLizenzVorlage.ArtikelBeschreibung,
              Demo: (!!demoMode ? 1 : 0)
            },
            subDatasets: subSubDatasets,
            cbOnExecute: (dataset: IWriteSubDataset, parent: IKunde) => {
              dataset.data.IDKunde = parent.ID
            }
          });
        }
        return result;
      }, <IWriteSubDataset[]>[])
    );
  }

  isSaving(): boolean {
    return this.saving;
  }

  save(kundenform: BfeForm, vertragsform: BfeForm, adminform: BfeForm, cbSuccess?: any, cbError?: any) {
    let cbSave = (lizenzen__: ILizenzverkauf[] = []) => {
      this.saving = true;
      let message = this.notifyService.progress('Der Lizenzverkauf wird gespeichert', 'Lizenzverkauf', -1);

      let admin = null;
      if (!!adminform && adminform.valid) {
        admin = adminform.getValues();
      }

      kundenform.setSubDatasets(
        kundenform.getSubDatasets().reduce((result: IWriteSubDataset[], current: IWriteSubDataset) => {
          if (current.table === TABLENAMES.KUNDEFACHRICHTUNG) {
            return result.concat(current);
          }
          return result;
        }, <IWriteSubDataset[]>[])
      );

      this.prepareLizenzen__('', kundenform, vertragsform, admin, lizenzen__);

      let reduceSubdatasets = (subdatasets: IWriteSubDataset[] = []): any[] => {
        return subdatasets.reduce((result: any[], current: IWriteSubDataset) => {
          let current__: any = {
            table: current.table,
            data: current.data,
            state: current.state,
            uuid: current.uuid
          }
          if (!isEmpty(current.subDatasets)) {
            current__.subdatasets = reduceSubdatasets(current.subDatasets);
          }
          return result.concat(current__);
        }, <any[]>[]);
      }

      let demoMode = !!vertragsform.getFormValue('Demozugang');
      let selfMode = (
        kundenform.getFormValue('IDBildungszKd') === this.intedigiService.getBildungszentrum() && 
        kundenform.getFormValue('ID') === this.intedigiService.getKunde()
      );

      let data: any = {
        kunde: Object.assign(kundenform.getValues(true), { _uuid: kundenform.getFormValue('_uuid') }),
        subdatasets: reduceSubdatasets(kundenform.getSubDatasets()),
        vertrag: {
          Vertragsbeginn: vertragsform.getFormValue('Vertragsbeginn'),
          RechnungErzeugen: !!vertragsform.getFormValue('RechnungErzeugen'),
          LocalMode: this.isLocalErp(),
          DemoMode: demoMode,
          SelfMode: selfMode,
          BildungsVorgang: {
            Bildung: this.intedigiService.getERPVorgangBildung(),
            Replacements: this.intedigiService.getERPAufnrByBildungReplacements(this.intedigiService.getERPVorgangBildung(), 0)
          }
        }
      }

      if (isValid(admin)) {
        data.admin = Object.assign(adminform.getValues(true), { _uuid: adminform.getFormValue('_uuid') });
      }

      this.http.post<any>(
        this.restService.getUrl() + 'lizenzverkauf.php', data
      )
      .pipe(first())
      .subscribe(
        (response: any) => {
          if ('Kunde' in response) {
            this.dataService.updateRowManually(TABLENAMES.KUNDE, response['Kunde']['_uuid'], response['Kunde']);
          }

          if ('Benutzer' in response) {
            this.dataService.updateRowManually(TABLENAMES.BENUTZER, response['Benutzer']['_uuid'], response['Benutzer']);
          }

          if ('Buchung' in response) {
            for (let i = 0; i < response['Buchung'].length; i++) {
              this.dataService.updateRowManually(TABLENAMES.BUCHUNG, response['Buchung'][i]['_uuid'], response['Buchung'][i]);
            }
          }

          if ('KundeFachrichtung' in response) {
            for (let i = 0; i < response['KundeFachrichtung'].length; i++) {
              this.dataService.updateRowManually(
                TABLENAMES.KUNDEFACHRICHTUNG, response['KundeFachrichtung'][i]['_uuid'], response['KundeFachrichtung'][i]
              );
            }
          }

          if ('BuchungBenutzer' in response) {
            for (let i = 0; i < response['BuchungBenutzer'].length; i++) {
              this.dataService.updateRowManually(
                TABLENAMES.BUCHUNG_BENUTZER, response['BuchungBenutzer'][i]['_uuid'], response['BuchungBenutzer'][i]
              );
            }
          }
          
          if ('_deleted' in response) {
            if ('KundeFachrichtung' in response['_deleted']) {
              for (let i = 0; i < response['_deleted']['KundeFachrichtung'].length; i++) {
                this.dataService.removeRowManually(TABLENAMES.KUNDEFACHRICHTUNG, response['_deleted']['KundeFachrichtung'][i]['_uuid'])
              }
            }
          }

          if (this.isLocalErp() && !demoMode && !selfMode) {
            this.notifyService.success('Der Lizenzverkauf wurde gespeichert und die Übertragung zum ERP-System läuft');
          } else {
            this.notifyService.success('Der Lizenzverkauf wurde gespeichert und es wurden gegebenenfalls E-Mails verschickt');
          }

          this.notifyService.remove(message);
          this.saving = false;
          if (!!cbSuccess) {
            cbSuccess();
          }
        },
        (error: string) => {
          this.notifyService.error('Der Lizenzverkauf konnte nicht gespeichert werden');
          console.log('error', error);
          this.notifyService.remove(message);
          this.saving = false;
          if (!!cbError) {
            cbError(error);
          }
        }
      );
    }
    
    if (!!adminform && adminform.valid) {
      let gruppe: IGruppe = this.dataService.getDataset(TABLENAMES.GRUPPE, adminform.getFormValue('IDGruppe'));
      if (!isEmpty(gruppe)) {
        let lizenzartBereichRolle: ILizenzartBereichRolle[] = <ILizenzartBereichRolle[]>this.dataService.getData(
          TABLENAMES.LIZENZARTBEREICHROLLE, (item: ILizenzartBereichRolle) => item.IDRolle === gruppe.IDRolle
        );
        let lizenzen: ILizenzverkauf[] = this.lizenzen.reduce((result: ILizenzverkauf[], current: ILizenzverkauf) => {
          if (current.extLizenzVorlage.IDLizenzart !== 5) {
            if (lizenzartBereichRolle.findIndex((item: ILizenzartBereichRolle) => {
              return (
                item.IDLizenzart === current.extLizenzVorlage.IDLizenzart && 
                (!isValid(item.IDBereich) || item.IDBereich === current.extLizenzVorlage.IDBereich)
              );
            }) > -1) {
              return result.concat(current);
            }
          }
          return result;
        }, <ILizenzverkauf[]>[]);

        if (!!lizenzen.length) {
          this.popupService.open(
            AdminLizenzenPopupComponent,
            {
              benutzer: adminform.getValues(),
              buchungen: lizenzen
            },
            {
              ongoon: (lizenzen__: ILizenzverkauf[]) => {
                cbSave(lizenzen__);
              },
              oncancel: () => {
                if (!!cbError) {
                  cbError('Der Speichervorgang wurde abgebrochen');
                } else {
                  this.notifyService.error('Der Speichervorgang wurde abgebrochen');
                }
              }
            }
          );
        } else {
          cbSave();
        }
      }
    } else {
      cbSave();
    }

    return;
  }
}