import { 
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges 
} from '@angular/core';
import { timer } from 'rxjs';

import { isValid, isEmpty, NotifyService, PopupService, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, BfeForm, CommonFeaturesComponent, DataService, IListColumn } from '@bfeoldenburg/bfe-data-forms';

import { ISettings, IFachrichtung, ILizenzart, IntedigiService, Lizenztyp, TABLENAMES } from '@bfeoldenburg/intedigi-shared';

import { IWiedervorlagePosition } from '../../../../../_services/wiedervorlage.service';
import { SubPositionPopupComponent } from './sub-position-popup/sub-position-popup.component';


@Component({
  selector: 'sub-positionen',
  templateUrl: './sub-positionen.component.html',
  styleUrls: ['./sub-positionen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubPositionenComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() positionen: IWiedervorlagePosition[] = [];

  @Input() fachrichtungen: IFachrichtung[] = [];

  @Input() settings: ISettings;

  @Input() kundenForm: BfeForm;

  @Input() width: number;

  @Input() loading: boolean = false;

  @Input() writable: boolean = false;

  @Output() onchange: EventEmitter<IWiedervorlagePosition[]> = new EventEmitter<IWiedervorlagePosition[]>();

  public selPosition: IWiedervorlagePosition = null;

  public columns: IListColumn[] = [];
  public visibleColumns: IListColumn[] = [];

  constructor(
    dataService: DataService,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService,
    private popupService: PopupService,
    private notifyService: NotifyService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings); 

    this.columns = [
      { 
        name: 'nr', title: 'Nummer', minWidth: 100, 
        cbOnShow: (dataset: IWiedervorlagePosition) => { 
          return dataset.OrbisPosition.nr;
        }
      },
      { 
        name: 'artikelId', title: 'ERP-ID', minWidth: 120, 
        cbOnShow: (dataset: IWiedervorlagePosition) => { 
          return dataset.OrbisPosition.artikelId;
        }
      },
      { 
        name: 'artikelBez', title: 'ERP-Bezeichung', minWidth: 200, 
        cbOnShow: (dataset: IWiedervorlagePosition) => { 
          return dataset.OrbisPosition.artikelBez;
        }
      },
      { 
        name: 'anzahl', title: 'Anzahl', minWidth: 75, 
        cbOnShow: (dataset: IWiedervorlagePosition) => { 
          return dataset.OrbisPosition.anzahl;
        }
      },
      { 
        name: 'verbraucht', title: 'Verbraucht', minWidth: 105, 
        cbOnShow: (dataset: IWiedervorlagePosition) => { 
          return dataset.OrbisPosition.verbraucht;
        }
      },
      { name: 'Lizenz', title: 'Lizenz', minWidth: 300, cbOnShow: (dataset: IWiedervorlagePosition) => { 
        if (isValid(dataset.IDLizenzVorlage)) {
          let lizenzart: ILizenzart = this.dataService.getDataset(TABLENAMES.LIZENZART, dataset.IDLizenzart);
          if (!isEmpty(lizenzart) && lizenzart.IDLizenztyp === Lizenztyp.Lernangebot) {
            return (
              lizenzart.Name + ', ' +
              this.intedigiService.getLernangebot(dataset) + ', ' + 
              this.dataService.getDatasetName(TABLENAMES.BEREICH, dataset.IDBereich) + 
              (
                isValid(dataset.IDFachrichtung) ? 
                ', ' + this.dataService.getDatasetName(TABLENAMES.FACHRICHTUNG, dataset.IDFachrichtung)  : 
                (this.intedigiService.isBereichAusbildung(dataset.IDBereich) ? ', alle Fachrichtungen' : '')
              )
            );
          } else {
            return lizenzart.Name; 
          } 
        } else {
          return 'Unbekannt';
        }
      } }
    ];
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => this.getFKeyData()));

    this.calcTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.calcTable();
    this.detectChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onResize(): void {
    this.calcTable();
    super.onResize();
  }

  isWritable(): boolean {
    return this.writable;
  }

  getColumnAlign(column: any): string {
    return (!!column.align && !!column.align.length ? column.align : 'left');
  }

  calcTable(): void {
    if (this.width > 0) {
      this.visibleColumns = [];
      let width = 40;
      let alwaysVisible = [];
      let i = 0;
      for (i; i < this.columns.length; i++) {
        if (!!this.columns[i].alwaysVisible) {
          alwaysVisible.push(this.columns[i]);
          width += this.columns[i].minWidth;
        }
      }
      i = 0;
      for (i; i < this.columns.length; i++) {
        if (this.columns[i].minWidth + width <= this.width) {
          this.visibleColumns.push(this.columns[i]);
          if (!!this.columns[i].alwaysVisible) {
            alwaysVisible.shift();
          }
        } else {
          break;
        }
        width += this.columns[i].minWidth;
      }
      if (!!alwaysVisible.length) {
        this.visibleColumns = this.visibleColumns.concat(alwaysVisible);
      }
    } else {
      setTimeout(() => this.calcTable(), 10);
    }

    this.detectChanges();
  }

  getFKeyData() {
    this.addDataSubscription(TABLENAMES.BUCHUNG, () => {
      this.detectChanges();
    });
  }

  getBackgroundColor(dataset: IWiedervorlagePosition): string {
    return (
      (dataset.Fehler || dataset.FehlerLizenzen) && 
      (!this.selPosition || this.selPosition.OrbisPosition.id !== dataset.OrbisPosition.id) ? '#ffcabc' : null
    )
  }

  /** Das Popup zum Bearbeiten öffnen */
  onPositionSelect() {
    if (isValid(this.selPosition.IDPaketgroesse) && !isValid(this.selPosition.IDFachrichtung) && !this.fachrichtungen.length) {
      this.notifyService.error('Legen Sie mindestens eine Fachrichtung für diesen Kunden fest');
      timer().subscribe(() => this.selPosition = null);
      return;
    }

    if (
      !isValid(this.selPosition.IDLizenzVorlage) && 
      (!isValid(this.kundenForm.getFormValue('IDKundentyp')) || !isValid(this.kundenForm.getFormValue('IDKundentyp2')))
    ) {
      this.notifyService.error('Legen Sie zuerst die beiden Kundentypen fest');
      timer().subscribe(() => this.selPosition = null);
      return;
    }

    if (
      this.selPosition.Fehler || this.selPosition.IDLizenzVorlage !== this.selPosition.Backup.IDLizenzVorlage || 
      this.selPosition.IDFachrichtung !== this.selPosition.Backup.IDFachrichtung
    ) {
      this.popupService.open(
        SubPositionPopupComponent, 
        {
          data: this.selPosition,
          kundenForm: this.kundenForm,
          fachrichtungen: this.fachrichtungen,
          tablename: TABLENAMES._WIEDERVORLAGE_POSITION,
          tabletitle: 'Position',
          popuptitle: 'Position bearbeiten'
        },
        {
          onedit: (dataset: IWiedervorlagePosition) => { 
            this.selPosition.IDLizenzVorlage = dataset.IDLizenzVorlage; 
            this.selPosition.IDLizenzart = dataset.IDLizenzart; 
            this.selPosition.IDBereich = dataset.IDBereich; 
            this.selPosition.IDKurs = dataset.IDKurs; 
            this.selPosition.IDPaketgroesse = dataset.IDPaketgroesse; 
            this.selPosition.IDPaket = dataset.IDPaket; 
            this.selPosition.IDLernangebot = dataset.IDLernangebot; 
            this.selPosition.IDFachrichtung = dataset.IDFachrichtung; 
            this.selPosition.UeBS = dataset.UeBS; 
            this.selPosition.LizenzVorlage = dataset.LizenzVorlage; 
            this.selPosition.ERPArtikelnummer = dataset.ERPArtikelnummer; 

            this.selPosition = null; 

            this.detectChanges(); 
            this.onchange.emit(this.positionen);
          },
          oncancel: () => { this.selPosition = null; this.detectChanges(); },
          onignore: () => { 
            this.dataService.postDataset(
              TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT, null, {
                IDBildungszentrum: this.settings.IDBildungszentrum,
                Nummernkreis: this.settings.ERPNummernkreis,
                Nummer: this.selPosition.OrbisPosition.artikelId,
                Bezeichnung: this.selPosition.OrbisPosition.artikelBez
              },
              () => {
                this.detectChanges(); 
                this.onchange.emit(this.positionen);
              }
            );
 
            this.selPosition = null; 
            this.detectChanges(); 
          }
        }
      );
    } else {
      if (this.selPosition.FehlerLizenzen) {
        this.notifyService.warning('Die fehlerhafte Anzahl kann hier nicht korrigiert werden');
      }
      timer().subscribe(() => this.selPosition = null);
    }
  }
}
