import { Injectable, EventEmitter } from "@angular/core";

import { isEmpty, NotifyService } from '@bfeoldenburg/bfe-shared';
import { DataService, IData } from '@bfeoldenburg/bfe-data-forms';

import { 
  TABLENAMES, ISchnittstelleWiedervorlage, ILizenzVorlage, IntedigiService, IERPArtikelnummer, IBuchung 
} from '@bfeoldenburg/intedigi-shared';

import { TABLEALIASSE } from '../_models/table-aliasse';
import { InterfacesService } from "@bfeoldenburg/bfe-web-apps";

export interface IWiedervorlagePosition extends IData {
  /** Aktueller Nummernkreis (Veranstaltung / Artikel) */
  Nummernkreis: number;
  /** Fremdschlüssel-ID aus Tabelle LizenzVorlage */
  IDLizenzVorlage: number;
  /** Fremdschlüssel-ID aus Tabelle Lizenzart */
  IDLizenzart: number;
  /** Fremdschlüssel-ID aus Tabelle Bereich */
  IDBereich: string;
  /** Fremdschlüssel-ID aus Tabelle Kurs */
  IDKurs: number;
  /** Fremdschlüssel-ID aus Tabelle Paketgroesse */
  IDPaketgroesse: string;
  /** Fremdschlüssel-ID aus Tabelle Paket */
  IDPaket: string;
  /** Fremdschlüssel-ID aus Tabelle Lernangebot */
  IDLernangebot: string;
  /** Fremdschlüssel-ID aus Tabelle Fachrichtung */
  IDFachrichtung: string;

  Laufzeit: number;

  OrbisPosition: {
    id: number;
    nr: number;
    artikelId: number;
    artikelBez: string;
    anzahl: number;
    verbraucht: number;
  }

  Buchung: IBuchung;

  UeBS?: number;

  Backup?: IWiedervorlagePosition;

  LizenzVorlage?: ILizenzVorlage;

  ERPArtikelnummer?: IERPArtikelnummer;

  Fehler?: boolean;

  FehlerLizenzen?: boolean;

  ZusatzzeileLizenz?: string
}


@Injectable({ providedIn: 'root' })
export class WiedervorlageService {
  private wiedervorlagen: ISchnittstelleWiedervorlage[] = [];
  private selected: ISchnittstelleWiedervorlage;
  private blocked: boolean = false;

  public onselect: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private dataService: DataService,
    private notifyService: NotifyService,
    private interfacesService: InterfacesService
  ) {
    this.dataService.addTableAlias(TABLENAMES.KUNDE, TABLEALIASSE.WVKundendaten);
    this.dataService.addTableAlias(TABLENAMES.BENUTZER, TABLEALIASSE.WVKundenadmin);
    this.dataService.addTableAlias(TABLENAMES._LIZENZVERKAUF, TABLEALIASSE.WVLizenzverkauf);
  }

  getIndex(): number {
    return this.wiedervorlagen.findIndex((item: ISchnittstelleWiedervorlage) => item._uuid === this.selected._uuid);
  }

  setWiedervorlagen(data: ISchnittstelleWiedervorlage[]): void {
    this.wiedervorlagen = data;

    if (this.isSelected() && !this.blocked) {
      if (this.getIndex() === -1) {
        this.selected = null;
        this.onselect.emit();
        this.notifyService.warning('Diese Wiedervorlage ist nicht mehr verfügbar');
      }
    }
  }

  isSelected(): boolean {
    return !isEmpty(this.selected);
  }

  isAuftragSelected(): boolean {
    return !isEmpty(this.selected) && this.selected.Kontext === 'auftraege';
  }

  isVeranstaltungSelected(): boolean {
    return !isEmpty(this.selected) && this.selected.Kontext === 'veranstaltungen';
  }

  setSelected(item: ISchnittstelleWiedervorlage): void {
    this.selected = item;
    this.onselect.emit();
  }

  getSelected(): ISchnittstelleWiedervorlage {
    return this.selected;
  }

  hasPrevious(): boolean {
    let index = this.getIndex();
    return index > 0;
  }

  hasNext(): boolean {
    let index = this.getIndex();
    return index !== -1 && index < this.wiedervorlagen.length - 1;
  }

  first(): void {

  }

  previous(): void {
    let index = this.getIndex();
    if (index > 0) {
      this.selected = this.wiedervorlagen[index - 1];
      this.onselect.emit();
    }
  }

  next(): void {
    let index = this.getIndex();
    if (index !== -1 && index !== this.wiedervorlagen.length - 1) {
      this.selected = this.wiedervorlagen[index + 1];
      this.onselect.emit();
    }
  }

  save(data: any, cbSuccess?: any, cbError?: any): void {
    let message = this.notifyService.progress('Der Datensatz wird verarbeitet', 'Wiedervorlage', -1);
    this.blocked = true;

    this.interfacesService.run(
      'interface.erp.lokal.http.php?kontext=wiedervorlage', data,
      (response: any) => {
        this.notifyService.remove(message);
        this.notifyService.success('Der Datensatz wurde erfolgreich verarbeitet');

        if ('Daten' in response) {
          let keys = Object.keys(response['Daten'])

          for (let i = 0; i < keys.length; i++) {
            for (let j = 0; j < response['Daten'][keys[i]].length; j++) {
              if (!!response['Daten'][keys[i]][j]['_deleted']) {
                this.dataService.removeRowManually(keys[i], response['Daten'][keys[i]][j]['_uuid']);
              } else {
                this.dataService.updateRowManually(keys[i], response['Daten'][keys[i]][j]['_uuid'], response['Daten'][keys[i]][j]);
              }
            }
          }
        }
        
        let index = this.getIndex();
        this.wiedervorlagen.splice(index, 1);
        if (index < this.wiedervorlagen.length) {
          this.selected = this.wiedervorlagen[index]; 
        } else if (this.wiedervorlagen.length > 0) {
          this.selected = this.wiedervorlagen[0]; 
        } else {
          this.selected = null;
        }

        this.onselect.emit();

        if (!!cbSuccess) {
          cbSuccess();
        }

        this.blocked = false;
      },
      (error: string) => {
        this.notifyService.remove(message);
        if (!!cbError) {
          cbError(error);
        } else {
          this.notifyService.error(error);
        }

        this.blocked = false;
      }
    );
  }
}