declare const moment: any;

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { PopupComponent, isEmpty, isValid, AppSettings } from '@bfeoldenburg/bfe-shared';
import { CommonFeaturesComponent, DataService, ToolbarService } from '@bfeoldenburg/bfe-data-forms';

import { IBenutzer, IBereich, IFachrichtung, ILizenzart, IntedigiService, Lizenztyp, TABLENAMES } from '@bfeoldenburg/intedigi-shared';

import { ILizenzverkauf } from '../../../../../_services/lizenzverkauf.service';


@Component({
  selector: 'admin-lizenzen-popup',
  templateUrl: './admin-lizenzen-popup.component.html',
  styleUrls: ['./admin-lizenzen-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminLizenzenPopupComponent extends CommonFeaturesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() benutzer: IBenutzer;
  @Input() buchungen: ILizenzverkauf[] = [];

  @Output() ongoon: EventEmitter<ILizenzverkauf[]> = new EventEmitter<ILizenzverkauf[]>();
  @Output() oncancel: EventEmitter<void> = new EventEmitter<void>();

  public buchungenSelected: ILizenzverkauf[] = [];

  private popup: PopupComponent;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy
  }

  onResize(): void {
    super.onResize();
  }

  isValid(value: any): boolean {
    return isValid(value);
  }

  getGruppe(): string {
    return (!isEmpty(this.benutzer) ? this.dataService.getDatasetName(TABLENAMES.GRUPPE, this.benutzer.IDGruppe) : '');
  }
  
  getLernangebot(item: ILizenzverkauf): string {
    let lizenzart: ILizenzart = this.dataService.getDataset(TABLENAMES.LIZENZART, item.extLizenzVorlage.IDLizenzart);
    if (!isEmpty(lizenzart) && lizenzart.IDLizenztyp === Lizenztyp.Lernangebot) {
      let bereich: IBereich = this.dataService.getDataset(TABLENAMES.BEREICH, item.extLizenzVorlage.IDBereich);
      let fachrichtung: IFachrichtung = this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, item.extLizenzVorlage.IDFachrichtung);
      return (
        (!isEmpty(lizenzart) ? lizenzart.Name + ', ' : '') +
        this.intedigiService.getLernangebot(item.extLizenzVorlage) + ', ' + 
        (!isEmpty(bereich) ? bereich.Name : '') + 
        (
          isValid(item.extLizenzVorlage.fachrichtung) ? 
          ', ' + item.extLizenzVorlage.fachrichtung.Name : 
          (this.intedigiService.isBereichAusbildung(item.extLizenzVorlage.IDBereich) ? ', alle Fachrichtungen' : '')
        )
      );
    } else {
      return (!isEmpty(lizenzart) ? lizenzart.Name : ''); 
    }
  }

  goon(): void {
    this.ongoon.emit(this.buchungenSelected);
    this.popup.close();
  }

  cancel(): void {
    this.oncancel.emit();
    this.popup.close();
  }

  selectedBuchungenChange(event: any): void {
    this.detectChanges();
  }
}
