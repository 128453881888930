import { 
  Component, OnInit, AfterViewInit, OnChanges, OnDestroy, SimpleChanges, ElementRef, Renderer2, ChangeDetectorRef, ChangeDetectionStrategy 
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { isValid, isEmpty, pad, RestService, PopupService, NotifyService, AppSettings, jsonParse } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, IData, DataService, SettingsService, TOOLBAR, ITool, IStandardAuswahl } from '@bfeoldenburg/bfe-data-forms';

import { IBildungszentrum, IKunde, IntedigiService, TABLENAMES } from '@bfeoldenburg/intedigi-shared';
import { BaseEinstellungenComponent, InterfacesService, LDAPService, WebAppsService } from '@bfeoldenburg/bfe-web-apps';

const SETTINGSTOOLS_Emails: number = 99;


@Component({
  selector: 'einstellungen',
  templateUrl: './einstellungen.component.html',
  styleUrls: ['./einstellungen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EinstellungenComponent extends BaseEinstellungenComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  
  public bildungszentren: IBildungszentrum[] = [];

  private kunden: IKunde[] = [];

  public kundenFiltered: IKunde[] = [];
  
  public nummernkreise: IStandardAuswahl[] = [
    { ID: 1, Name: 'Artikel' },
    { ID: 2, Name: 'Veranstaltungen' }
  ];
  
  public erpFreigabeBeiItems: IStandardAuswahl[] = [
    { ID: 1, Name: 'Erstellung' },
    { ID: 2, Name: 'Berechnung' },
    { ID: 3, Name: 'Bezahlung' }
  ];

  public erpArtikelBeschreibungItems: IStandardAuswahl[] = [
    { ID: 1, Name: 'Immer' },
    { ID: 2, Name: 'Bei Rechnungserstellung' }
  ];

  public cleanupItems: IStandardAuswahl[] = [
    { ID: -1, Name: 'Nicht ausführen' },
    { ID: 1, Name: 'Monatlich' },
    { ID: 2, Name: 'Vierteljährlich' },
    { ID: 3, Name: 'Halbjährlich' },
    { ID: 4, Name: 'Jährlich' }
  ];
  
  public LDAPUhrzeitKomplett: any[] = [null, null];
  public ERPUhrzeitKomplett: any[] = [null, null];
  public CleanupIntervallUhrzeit: any[] = [null, null];
  
  public ReportWiedervorlage: boolean[] = [false, false, false, false, false, false, false];
  public ReportWiedervorlageUhrzeiten: any[][] = [[null, null]];
  public ReportFehler: boolean[] = [false, false, false, false, false, false, false];
  public ReportFehlerUhrzeiten: any[][] = [[null, null]];
  
  public stunden: any[] = [];
  public minuten: any[] = [];

  constructor(
    dataService: DataService,
    popupService: PopupService,
    elementRef: ElementRef,
    renderer: Renderer2,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    route: ActivatedRoute,
    router: Router,
    notifyService: NotifyService,
    settingsService: SettingsService,
    webAppsService: WebAppsService,
    private intedigiService: IntedigiService,
    private restService: RestService,
    private http: HttpClient,
    private interfacesService: InterfacesService,
    private ldapService: LDAPService
  ) { 
    super(
      dataService, popupService, elementRef, renderer, cdRef, toolbarService, appSettings, route, router, notifyService, settingsService, webAppsService
    );

    this.fkeyTables = [
      TABLENAMES.BILDUNGSZENTRUM,
      TABLENAMES.KUNDE,
      TABLENAMES.USERS
    ];

    for (let i = 0; i < 24; i++) {
      this.stunden.push({ ID: pad(i, 2), Name: pad(i, 2) });
    }
    for (let i = 0; i < 60; i = i + 15) {
      this.minuten.push({ ID: pad(i, 2), Name: pad(i, 2) });
    }

    this.subscriptions.push(this.toolbarService.onchange.subscribe((name: string) => {
      if (name === TOOLBAR.MainTop) {
        if (!this.toolbarService.hasTool(TOOLBAR.MainTop, SETTINGSTOOLS_Emails)) {
          this.toolbarService.addTools(
            TOOLBAR.MainTop,
            this.toolbarService.createTool(<ITool>{
              id: SETTINGSTOOLS_Emails, name: 'E-Mails', icon: 'icon-mail', title: 'System-E-Mails versenden',
              cbExecute: () => this.sendMails(),
              cbDisabled: () => this.hasChanged()
            })
          );
        }
      }
    }));
  }

  ngOnInit() {
    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => {
      this.getFKeyData();
    }));

    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (!isEmpty(this.form)) {
      this.form.resetCallbackReadonly('einstellungen');
    }
  }

  onResize() {
    super.onResize();
  }

  cbIsReadonly(field: string): boolean {
    switch (field) {
      case 'ERPFachrichtungWiedervorlage': {
        return true;
      }
    }
    return false
  }

  onFormInit() {
    super.onFormInit();

    this.form.setCallbackReadonly('einstellungen', (field: string) => this.cbIsReadonly(field));

    this.onDataSet();
  }

  isNew(): boolean {
    return super.isNew();
  }

  isLoading(): boolean {
    return super.isNew();
  }

  onDataSet() {
    this.setBildungszentrum(this.form.getFormValue('IDBildungszentrum'));
    this.setKunde([ this.form.getFormValue('IDKunde'), this.form.getFormValue('IDBildungszentrum') ]);
    this.setERPNummernkreis(this.form.getFormValue('ERPNummernkreis'));
    this.setERPFreigabeBei(this.form.getFormValue('ERPFreigabeBei'));
    this.setERPArtikelBeschreibung(this.form.getFormValue('ERPArtikelBeschreibung'));
    this.setCleanupIntervall(this.form.getFormValue('CleanupIntervall'));

    try {
      let ReportWiedervorlage = JSON.parse(this.form.getFormValue('ReportWiedervorlageTage'));
      this.ReportWiedervorlage = ReportWiedervorlage.reduce((result: boolean[], current: number) => {
        return result.concat(!!current);
      }, <boolean[]>[]);
      if (!this.ReportWiedervorlage.length) {
        this.ReportWiedervorlage = [false, false, false, false, false, false, false];
      }
    } catch(e) {
      this.ReportWiedervorlage = [false, false, false, false, false, false, false];
    }

    let LDAPUhrzeitKomplett: string = this.form.getFormValue('LDAPUhrzeitKomplett');
    if (isValid(LDAPUhrzeitKomplett) && LDAPUhrzeitKomplett.indexOf(':') > -1) {
      let parts = LDAPUhrzeitKomplett.split(':');
      this.LDAPUhrzeitKomplett = [
        this.stunden.find((item: any) => item.ID === parts[0]),
        this.minuten.find((item: any) => item.ID === parts[1])
      ];
    } else {
      this.LDAPUhrzeitKomplett = [null, null];
    }

    let ERPUhrzeitKomplett: string = this.form.getFormValue('ERPUhrzeitKomplett');
    if (isValid(ERPUhrzeitKomplett) && ERPUhrzeitKomplett.indexOf(':') > -1) {
      let parts = ERPUhrzeitKomplett.split(':');
      this.ERPUhrzeitKomplett = [
        this.stunden.find((item: any) => item.ID === parts[0]),
        this.minuten.find((item: any) => item.ID === parts[1])
      ];
    } else {
      this.ERPUhrzeitKomplett = [null, null];
    }

    let CleanupIntervallUhrzeit: string = this.form.getFormValue('CleanupIntervallUhrzeit');
    if (isValid(CleanupIntervallUhrzeit) && CleanupIntervallUhrzeit.indexOf(':') > -1) {
      let parts = CleanupIntervallUhrzeit.split(':');
      this.CleanupIntervallUhrzeit = [
        this.stunden.find((item: any) => item.ID === parts[0]),
        this.minuten.find((item: any) => item.ID === parts[1])
      ];
    } else {
      this.CleanupIntervallUhrzeit = [null, null];
    }

    try {
      let ReportWiedervorlageUhrzeiten = jsonParse(this.form.getFormValue('ReportWiedervorlageUhrzeiten'), []);
      this.ReportWiedervorlageUhrzeiten = ReportWiedervorlageUhrzeiten.reduce((result: any[][], current: string) => {
        let parts = current.split(':');
        let items = [
          this.stunden.find((item: any) => item.ID === parts[0]),
          this.minuten.find((item: any) => item.ID === parts[1])
        ];
        if (!!items[0] && !!items[1]) {
          result.push(items);
        } 
        return result;
      }, <any[][]>[]);
      if (!this.ReportWiedervorlageUhrzeiten.length) {
        this.ReportWiedervorlageUhrzeiten = [[null, null]];
      }
    } catch(e) {
      this.ReportWiedervorlageUhrzeiten = [[null, null]];
    }

    try {
      let ReportFehler = JSON.parse(this.form.getFormValue('ReportFehlerTage'));
      this.ReportFehler = ReportFehler.reduce((result: boolean[], current: number) => {
        return result.concat(!!current);
      }, <boolean[]>[]);
      if (!this.ReportFehler.length) {
        this.ReportFehler = [false, false, false, false, false, false, false];
      }
    } catch(e) {
      this.ReportFehler = [false, false, false, false, false, false, false];
    }

    try {
      let ReportFehlerUhrzeiten = jsonParse(this.form.getFormValue('ReportFehlerUhrzeiten'), []);
      this.ReportFehlerUhrzeiten = ReportFehlerUhrzeiten.reduce((result: any[][], current: string) => {
        let parts = current.split(':');
        let items = [
          this.stunden.find((item: any) => item.ID === parts[0]),
          this.minuten.find((item: any) => item.ID === parts[1])
        ];
        if (!!items[0] && !!items[1]) {
          result.push(items);
        } 
        return result;
      }, <any[][]>[]);
      if (!this.ReportFehlerUhrzeiten.length) {
        this.ReportFehlerUhrzeiten = [[null, null]];
      }
    } catch(e) {
      this.ReportFehlerUhrzeiten = [[null, null]];
    }

    super.onDataSet();
  }

  onDataGet() {
    super.onDataGet();
    if (!this.isLokal()) {
      this.setEmailVersand(1);
    } else {
      this.setEmailVersand();
    }

    this.setBildungszentrum();
    this.setKunde();
    this.setERPNummernkreis();
    this.setERPFreigabeBei();
    this.setERPArtikelBeschreibung();
    this.setCleanupIntervall();
  }

  setBildungszentrum(value?: any) {
    this.setFormObject(TABLENAMES.BILDUNGSZENTRUM, value);
  }

  onBildungszentrumChange() {
    this.setBildungszentrum();
    this.onDataChange();
  }

  setKunde(value?: any) {
    let obj = this.setFormObject(TABLENAMES.KUNDE, value);
  }

  onKundeChange() {
    this.setKunde();
    this.onDataChange();
  }

  filterKunden(event: any) {
    let queryparts = event.query.split(' ');
    this.kundenFiltered = this.kunden.reduce((result: IKunde[], current: IKunde) => {
      let i = 0, iMax = queryparts.length;
      for(i; i < iMax; i++) {
        if(queryparts[i].length > 0 && current.Search.indexOf(queryparts[i].toLowerCase()) == -1) {
          return result;
        }
      }
      return result.concat(current);
    }, <IKunde[]>[]); 
  }

  isKundeValid(){
    return isValid(this.form.getFormValue('IDKunde')) && !this.isReadonly('IDKunde');
  }

  resetKunde(){
    this.form.setFormValue('OBJKunde', null);
    this.setKunde();
  }

  setERPNummernkreis(value?: any) {
    this.setFormObjectStandardAuswahl('OBJERPNummernkreis', 'ERPNummernkreis', this.nummernkreise, value);
  }

  onERPNummernkreisChange() {
    this.setERPNummernkreis();
    this.onDataChange();
  }

  setERPFreigabeBei(value?: any) {
    this.setFormObjectStandardAuswahl('OBJERPFreigabeBei', 'ERPFreigabeBei', this.erpFreigabeBeiItems, value);
  }

  onERPFreigabeBeiChange() {
    this.setERPFreigabeBei();
    this.onDataChange();
  }

  setERPArtikelBeschreibung(value?: any) {
    this.setFormObjectStandardAuswahl('OBJERPArtikelBeschreibung', 'ERPArtikelBeschreibung', this.erpArtikelBeschreibungItems, value);
  }

  onERPArtikelBeschreibungChange() {
    this.setERPArtikelBeschreibung();
    this.onDataChange();
  }

  getERPVorgangBildungTest(): string {
    let bildung: string = this.form.getFormValue('ERPVorgangBildung');
    return this.intedigiService.getERPAufnrByBildung(bildung, 355);
  }

  onEmailInaktivLizenzfreigabeChange() {
    this.onDataChange();
  }

  getFKeyData() {
    this.addDataSubscription(TABLENAMES.BILDUNGSZENTRUM, (data: IData[]) => {
      this.bildungszentren = <IBildungszentrum[]>data;
      if (!isEmpty(this.form)) {
        this.setBildungszentrum(this.form.getFormValue('IDBildungszentrum'));
      }
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.KUNDE, (data: IData[]) => {
      this.kunden = <IKunde[]>data;
      if (!isEmpty(this.form)) {
        this.setKunde([ this.form.getFormValue('IDKunde'), this.form.getFormValue('IDBildungszentrum') ]);
      }
      this.detectChanges();
    });

    this.bildungszentren = <IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM);
    this.kunden = <IKunde[]>this.dataService.getData(TABLENAMES.KUNDE);
  }

  onLokaleInstanzChange(): void {
    this.onDataChange();
  }

  onSchnittstelleCronjobChange(): void {
    this.onDataChange();
  }

  onLDAPAktivChange(): void {
    this.onDataChange();
  }

  onLDAPCronjobChange(): void {
    this.onDataChange();
  }

  onLDAPUhrzeitKomplettChange(): void {
    if (!this.LDAPUhrzeitKomplett[0] || !this.LDAPUhrzeitKomplett[1]) {
      this.form.setFormValue('LDAPUhrzeitKomplett', null);
    } else {
      this.form.setFormValue('LDAPUhrzeitKomplett', this.LDAPUhrzeitKomplett[0].ID + ':' + this.LDAPUhrzeitKomplett[1].ID);
    }
  }

  onERPAktivChange(): void {
    this.onDataChange();
  }

  onERPZertifikatspruefungChange(): void {
    this.onDataChange();
  }

  onERPCronjobChange(): void {
    this.onDataChange();
  }

  onERPUhrzeitKomplettChange(): void {
    if (!this.ERPUhrzeitKomplett[0] || !this.ERPUhrzeitKomplett[1]) {
      this.form.setFormValue('ERPUhrzeitKomplett', null);
    } else {
      this.form.setFormValue('ERPUhrzeitKomplett', this.ERPUhrzeitKomplett[0].ID + ':' + this.ERPUhrzeitKomplett[1].ID);
    }
  }

  onERPFachrichtungWiedervorlageChange(): void {
    this.onDataChange();
  }

  onERPFachrichtungAutomatischChange(): void {
    this.onDataChange();
  }

  onERPVerKeineLizenzvergabeChange(): void {
    this.onDataChange();
  }

  onERPVerKeineTeilnehmerChange(): void {
    this.onDataChange();
  }

  isLDAPAktiv(): boolean {
    return !!this.form.getFormValue('LDAPAktiv');
  }

  testInterface(): void {
    this.interfacesService.run(
      'interface.intedigi.lokal.http.php?test=1', null,
      (response: any) => {
        this.notifyService.success('Der Test war erfolgreich');
      },
      (error: string) => {
        this.notifyService.error(error);
      }
    );
  }

  testLDAP(): void {
    this.ldapService.test(
      (response: any) => {
        this.notifyService.success('Der Test war erfolgreich');
      },
      (error: string) => {
        this.notifyService.error(error);
      }
    );
  }

  testERP(): void {
    this.interfacesService.run(
      'interface.erp.lokal.http.php?kontext=test', null,
      (response: any) => {
        this.notifyService.success('Der Test war erfolgreich');
      },
      (error: string) => {
        this.notifyService.error(error);
      }
    );
  }

  isLokal(): boolean {
    return !!this.form.getFormValue('LokaleInstanz');
  }

  onReportWiedervorlageTageChange(): void {
    let reportWiedervorlageText: string = this.ReportWiedervorlage.reduce((result: string, current: boolean) => {
      if (current) {
        return result + '1,'
      } else {
        return result + '0,'
      }
    }, <string>'');

    this.form.setFormValue('ReportWiedervorlageTage', '[' + reportWiedervorlageText.slice(0, -1) + ']');
    this.onDataChange();
  }

  isWiedervorlageAddable(item: number): boolean {
    return !!this.ReportWiedervorlageUhrzeiten[item][0] && !!this.ReportWiedervorlageUhrzeiten[item][1];
  }

  isWiedervorlageRemovable(item: number): boolean {
    return this.ReportWiedervorlageUhrzeiten.length > 1 || !!this.ReportWiedervorlageUhrzeiten[item][0] || !!this.ReportWiedervorlageUhrzeiten[item][1];
  }

  addWiedervorlageUhrzeit(item: number): void {
    if (item === this.ReportWiedervorlageUhrzeiten.length - 1) {
      this.ReportWiedervorlageUhrzeiten.push([null, null]);
    } else {
      this.ReportWiedervorlageUhrzeiten.splice(item + 1, 0, [null, null]);
    }
    this.onDataChange();
  }

  removeWiedervorlageUhrzeit(item: number): void {
    if (this.ReportWiedervorlageUhrzeiten.length > 1) {
      this.ReportWiedervorlageUhrzeiten.splice(item, 1); 
    } else {
      this.ReportWiedervorlageUhrzeiten[item][0] = null;
      this.ReportWiedervorlageUhrzeiten[item][1] = null;
    }
    this.onReportWiedervorlageUhrzeitChange();
  }

  onReportWiedervorlageUhrzeitChange(): void {
    if (this.ReportWiedervorlageUhrzeiten.length === 1 && (!this.ReportWiedervorlageUhrzeiten[0][0] || (!this.ReportWiedervorlageUhrzeiten[0][1]))) {
      this.form.setFormValue('ReportWiedervorlageUhrzeiten', null);
    } else {
      let ReportWiedervorlageUhrzeiten = this.ReportWiedervorlageUhrzeiten.reduce((result: string[], current: any[]) => {
        if (!!current[0] && !!current[1]) {
          return result.concat(current[0].ID + ':' + current[1].ID);
        }
        return result;
      }, <string[]>[])
      .sort((itemA: string, itemB: string) => {
        if (itemA < itemB) {
          return -1;
        } else {
          return 1;
        }
      });

      if (!!ReportWiedervorlageUhrzeiten.length) {
        this.form.setFormValue('ReportWiedervorlageUhrzeiten', JSON.stringify(ReportWiedervorlageUhrzeiten));
      } else {
        this.form.setFormValue('ReportWiedervorlageUhrzeiten', null);
      }
    }
    this.onDataChange();
  }

  onReportFehlerTageChange(): void {
    let reportFehlerText: string = this.ReportFehler.reduce((result: string, current: boolean) => {
      if (current) {
        return result + '1,'
      } else {
        return result + '0,'
      }
    }, <string>'');
    this.form.setFormValue('ReportFehlerTage', '[' + reportFehlerText.slice(0, -1) + ']');
    this.onDataChange();
  }

  isFehlerAddable(item: number): boolean {
    return !!this.ReportFehlerUhrzeiten[item][0] && !!this.ReportFehlerUhrzeiten[item][1];
  }

  isFehlerRemovable(item: number): boolean {
    return this.ReportFehlerUhrzeiten.length > 1 || !!this.ReportFehlerUhrzeiten[item][0] || !!this.ReportFehlerUhrzeiten[item][1];
  }

  addFehlerUhrzeit(item: number): void {
    if (item === this.ReportFehlerUhrzeiten.length - 1) {
      this.ReportFehlerUhrzeiten.push([null, null]);
    } else {
      this.ReportFehlerUhrzeiten.splice(item + 1, 0, [null, null]);
    }
    this.onDataChange();
  }

  removeFehlerUhrzeit(item: number): void {
    if (this.ReportFehlerUhrzeiten.length > 1) {
      this.ReportFehlerUhrzeiten.splice(item, 1); 
    } else {
      this.ReportFehlerUhrzeiten[item][0] = null;
      this.ReportFehlerUhrzeiten[item][1] = null;
    }
    this.onReportFehlerUhrzeitChange();
  }

  onReportFehlerUhrzeitChange(): void {
    if (this.ReportFehlerUhrzeiten.length === 1 && (!this.ReportFehlerUhrzeiten[0][0] || (!this.ReportFehlerUhrzeiten[0][1]))) {
      this.form.setFormValue('ReportFehlerUhrzeiten', null);
    } else {
      let ReportFehlerUhrzeiten = this.ReportFehlerUhrzeiten.reduce((result: string[], current: any[]) => {
        if (!!current[0] && !!current[1]) {
          return result.concat(current[0].ID + ':' + current[1].ID);
        }
        return result;
      }, <string[]>[])
      .sort((itemA: string, itemB: string) => {
        if (itemA < itemB) {
          return -1;
        } else {
          return 1;
        }
      });

      if (!!ReportFehlerUhrzeiten.length) {
        this.form.setFormValue('ReportFehlerUhrzeiten', JSON.stringify(ReportFehlerUhrzeiten));
      } else {
        this.form.setFormValue('ReportFehlerUhrzeiten', null);
      }
    }
    this.onDataChange();
  }

  setCleanupIntervall(value?: any) {
    this.setFormObjectStandardAuswahl('OBJCleanupIntervall', 'CleanupIntervall', this.cleanupItems, value);
  }

  onCleanupIntervallChange() {
    this.setCleanupIntervall();
    this.onDataChange();
  }

  onCleanupIntervallUhrzeitChange(): void {
    if (!this.CleanupIntervallUhrzeit[0] || !this.CleanupIntervallUhrzeit[1]) {
      this.form.setFormValue('CleanupIntervallUhrzeit', null);
    } else {
      this.form.setFormValue('CleanupIntervallUhrzeit', this.CleanupIntervallUhrzeit[0].ID + ':' + this.CleanupIntervallUhrzeit[1].ID);
    }
  }

  sendMails(): void {
    this.http.post<any>(this.restService.getUrl() + 'send.auto.mails.php', {})
    .pipe(first())
    .subscribe(
      (data: any) => {
        this.notifyService.success('Die E-Mails wurden versendet');
      },
      error => {
        this.notifyService.error(error);
        console.log('error', error);
      }
    );
  }
}
