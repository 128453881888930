import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';

import { isEmpty, PopupService, AuthenticationService, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, CommonPopupFormComponent, DataService } from '@bfeoldenburg/bfe-data-forms';

import { IBildungszentrum, IntedigiService, TABLENAMES, IERPBenutzer, IBenutzer, ISettings } from '@bfeoldenburg/intedigi-shared';

@Component({
  selector: 'erp-benutzer-popup',
  templateUrl: './erp-benutzer-popup.component.html',
  styleUrls: ['./erp-benutzer-popup.component.scss']
})
export class ErpBenutzerPopupComponent extends CommonPopupFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public data: IERPBenutzer;

  public benutzer: IBenutzer = <IBenutzer>{};

  constructor(
    dataService: DataService,
    popupService: PopupService,
    elementRef: ElementRef,
    renderer: Renderer2,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService,
    private authService: AuthenticationService
  ) { 
    super(dataService, popupService, elementRef, renderer, cdRef, toolbarService, appSettings);

    this.fkeyTables = [
      TABLENAMES.BILDUNGSZENTRUM,
      TABLENAMES.SETTINGS
    ];

    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => { 
      this.detectChanges();
    }));
  }

  ngOnInit() {
    super.ngOnInit();

    this.getFTables();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.benutzer = <IBenutzer>{};
    if (this.dataService.getDataset(TABLENAMES.BENUTZER, [ this.data.IDBenutzer, this.data.IDBildungszentrum ])) {
      this.benutzer = this.dataService.getDataset(TABLENAMES.BENUTZER, [ this.data.IDBenutzer, this.data.IDBildungszentrum ]) || {};
    } else {
      this.benutzer = this.dataService.getDataset(TABLENAMES.BENUTZERAUSTAUSCH, [ this.data.IDBenutzer, this.data.IDBildungszentrum ]) || {};
    }

    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.form.resetCallbackWritable();
  }

  onResize() {
    this.detectChanges();
  }

  isWritable(): boolean {
    return super.isWritable() && this.intedigiService.isInterfaceWritable(this.tablename, (!isEmpty(this.form) ? this.form.getValues() : {}), this.isNew());
  }

  isDeletable(): boolean {
    return super.isDeletable() && this.intedigiService.isInterfaceWritable(this.tablename, (!isEmpty(this.form) ? this.form.getValues() : {}), this.isNew());
  }

  cbIsReadonly(field: string): boolean {
    switch (field) {
    }
    return false;
  }

  onFormInit() {
    this.onDataSet();

    this.form.setCallbackWritable(
      () => this.intedigiService.isInterfaceWritable(this.tablename, (!isEmpty(this.form) ? this.form.getValues() : {}), this.isNew())
    );
  }

  onDataSet() {
    this.onDataChange();
  }

  onDataGet() {
  }

  getFTables() {
    this.addDataSubscription(TABLENAMES.SETTINGS, (data: ISettings[]) => {
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.BILDUNGSZENTRUM, (data: IBildungszentrum[]) => {
      this.detectChanges();
    });
  }

  getBildungszentrum(): string {
    return this.dataService.getDatasetName(TABLENAMES.BILDUNGSZENTRUM, this.data.IDBildungszentrum);
  }

  isLokal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }

  getBenutzerFachrichtung(dataset: IBenutzer): string {
    if (this.dataService.hasDataset(TABLENAMES.FACHRICHTUNG, dataset.IDFachrichtung)) {
      return this.dataService.getDatasetName(TABLENAMES.FACHRICHTUNG, dataset.IDFachrichtung);
    } else {
      return '---';
    }
  }

  getBenutzerGruppe(dataset: IBenutzer): string {
    if (this.dataService.hasDataset(TABLENAMES.GRUPPE, dataset.IDGruppe)) {
      return this.dataService.getDatasetName(TABLENAMES.GRUPPE, dataset.IDGruppe);
    } else {
      return '---';
    }
  }
}
