import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { AppSettings, AuthenticationService, IHistoryTable, isValid } from '@bfeoldenburg/bfe-shared';
import { CommonFeaturesComponent, DataService, FXSTATE, IFKeyTable, IListColumn, IUserSettings, ToolbarService } from '@bfeoldenburg/bfe-data-forms';

import { IntedigiUserService, TABLENAMES } from '@bfeoldenburg/intedigi-shared';

import { CustomEmailVorlagePopupComponent } from './custom-email-vorlage-popup/custom-email-vorlage-popup.component';


@Component({
  selector: 'email-vorlagen',
  templateUrl: './email-vorlagen.component.html',
  styleUrls: ['./email-vorlagen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailVorlagenComponent extends CommonFeaturesComponent {
  public popupComponent: any = CustomEmailVorlagePopupComponent;
  public columns: IListColumn[] = [];

  public cbFilter: any = (dataset: IUserSettings) => {
    return (
      dataset.Alias === TABLENAMES._EMAIL_VORLAGE && 
      (
        !isValid(dataset.UuidBesitzer) || dataset.UuidBesitzer === this.intedigiUserService.getBildungszentrum() || this.authService.isAdministrator()
      )
    );
  }
  public fKeyTables: IFKeyTable[] = [
    { table: TABLENAMES.BILDUNGSZENTRUM }
  ];
  public historySettings: IHistoryTable[] = [
    { 
      table: TABLENAMES.USER_SETTINGS,
      title: 'E-Mail-Vorlagen',
      filter: {
        Alias: TABLENAMES._EMAIL_VORLAGE
      }
    }
  ];

  constructor(
    dataService: DataService,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private intedigiUserService: IntedigiUserService,
    private authService: AuthenticationService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.columns = [
      { 
        name: 'Name', title: 'Name', minWidth: 150, sort: { Desc: false, Default: true }, search: FXSTATE.Raw, 
      },
      { 
        name: 'Betreff', title: 'Betreff', minWidth: 300, sort: { Desc: false, Default: true }, search: FXSTATE.Callback, 
        cbOnShow: (dataset: IUserSettings) => {
          try {
            let settings: any = JSON.parse(dataset.Settings);
            return ('Betreff' in settings ? settings['Betreff'] : '');
          } catch(e: any) {
            return '';
          }
        }
      },
      { 
        name: 'UuidBesitzer', title: 'Bildungszentrum', minWidth: 150, sort: { Desc: false, Default: false }, search: FXSTATE.Callback, 
        cbOnShow: (dataset: IUserSettings) => {
          return (
            !isValid(dataset.UuidBesitzer) ? 
            '- Alle -' : 
            this.dataService.getDatasetName(TABLENAMES.BILDUNGSZENTRUM, dataset.UuidBesitzer)
          );
        }
      }
    ];
  }
}
