<div class="extended-form" *ngIf="isReady()" (window:resize)="onResize()" [style.minHeight]="getContentHeight()">
  <form [formGroup]="form" autocomplete="off">
    <div class="data-column">
      <div class="data-settings">
        <div class="form-row">
          <label class="form-label">Url Kundenportal</label>
          <input ExtendControl class="url-kundenportal" pInputText type="text" [required]="!isOptional('UrlKundenportal')" [readonly]="isReadonly('UrlKundenportal')" formControlName="UrlKundenportal" [maxlength]="getFieldLength('UrlKundenportal')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('LokaleInstanz')">Lokale Instanz</label>
          <p-checkbox ExtendControl formControlName="LokaleInstanz" [binary]="true" [readonly]="isReadonly('LokaleInstanz')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)"  (onChange)="onLokaleInstanzChange()"></p-checkbox>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">Bildungszentrum</label>
          <p-dropdown ExtendControl class="bildungszentrum" [options]="bildungszentren" 
            [required]="!isOptional('IDBildungszentrum')" [readonly]="isReadonly('IDBildungszentrum')"
            [showClear]="isOptional('IDBildungszentrum') && !isReadonly('IDBildungszentrum')" formControlName="OBJBildungszentrum"
            placeholder="- Bildungszentrum auswählen -" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)"
            (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" 
            (onChange)="onBildungszentrumChange()"
          ></p-dropdown>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">Eigener Kunde</label>
          <div class="kunde-box">
            <p-autoComplete ExtendControl class="kunde" [suggestions]="kundenFiltered" (completeMethod)="filterKunden($event)" [dropdown]="!isReadonly('IDKunde')" [required]="!isOptional('IDKunde')" [readonly]="isReadonly('IDKunde')" formControlName="OBJKunde" fieldName="IDKunde" placeholder="Kunden auswählen" field="Auswahl" appendTo="body" [minLength]="2" [autoHighlight]="true" [delay]="200" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" (onSelect)="onKundeChange()" (onBlur)="onKundeChange()" autocomplete="nope"></p-autoComplete>
            <div class="kunde-clear pi pi-times" (click)="resetKunde()" *ngIf="isKundeValid()"></div>
          </div>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPVorgangBildung')">Bildung Vorgangsnr.</label>
          <div class="erp-auftragsnr-box">
            <input ExtendControl class="erp-auftragsnr" pInputText placeholder="Bildungskriterien festlegen" type="text" [required]="!isOptional('ERPVorgangBildung')" [readonly]="isReadonly('ERPVorgangBildung')" formControlName="ERPVorgangBildung" [maxlength]="getFieldLength('ERPVorgangBildung')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
            <label>{{ getERPVorgangBildungTest() }}</label>
          </div>
        </div>
      </div>
      <div class="data-intedigi">
        <div class="form-row">
          <div class="sub-title">Schnittstelle InteDigi &lt;&gt; InteDigi</div>
        </div>
        <div class="form-row">
          <label class="form-label">Version</label>
          <input ExtendControl class="schnittstelle-version" pInputText type="text" [required]="!isOptional('SchnittstelleVersion')" [readonly]="isReadonly('SchnittstelleVersion')" formControlName="SchnittstelleVersion" [maxlength]="getFieldLength('SchnittstelleVersion')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">Url globale Instanz</label>
          <input ExtendControl class="url-globale-instanz" pInputText type="text" [required]="!isOptional('UrlGlobaleInstanz')" [readonly]="isReadonly('UrlGlobaleInstanz')" formControlName="UrlGlobaleInstanz" [maxlength]="getFieldLength('UrlGlobaleInstanz')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">Benutzername</label>
          <input ExtendControl class="schnittstelle-benutzer" pInputText type="password" [required]="!isOptional('SchnittstelleBenutzer')" [readonly]="isReadonly('SchnittstelleBenutzer')" formControlName="SchnittstelleBenutzer" [maxlength]="getFieldLength('SchnittstelleBenutzer')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">Passwort</label>
          <input ExtendControl class="schnittstelle-passwort" pInputText type="password" [required]="!isOptional('SchnittstellePasswort')" [readonly]="isReadonly('SchnittstellePasswort')" formControlName="SchnittstellePasswort" [maxlength]="getFieldLength('SchnittstellePasswort')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('SchnittstelleCronjob')">Cronjob</label>
          <p-checkbox ExtendControl formControlName="SchnittstelleCronjob" [binary]="true" [readonly]="isReadonly('SchnittstelleCronjob')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)"  (onChange)="onSchnittstelleCronjobChange()"></p-checkbox>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">Cronjob-Intervall</label>
          <input ExtendControl class="schnittstelle-intervall" pInputText type="text" [required]="!isOptional('SchnittstelleIntervall')" [readonly]="isReadonly('SchnittstelleIntervall')" formControlName="SchnittstelleIntervall" [maxlength]="getFieldLength('SchnittstelleIntervall')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
          <label class="form-label info-label" ExtendLabel>Minuten</label>
        </div>
        <div class="form-row form-buttons" style="margin-top: 10px;" *ngIf="isLokal() && isWritable()">
          <button pButton type="button" class="p-button-outlined p-button-rounded" label="Verbindung testen" (click)="testInterface()"></button>
        </div>
      </div>
      <div class="data-ldap">
        <div class="form-row">
          <div class="sub-title">Schnittstelle InteDigi &gt; LDAP</div>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('LDAPAktiv')">Aktiv</label>
          <p-checkbox ExtendControl formControlName="LDAPAktiv" [binary]="true" [readonly]="isReadonly('LDAPAktiv')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)"  (onChange)="onLDAPAktivChange()"></p-checkbox>
        </div>
        <div class="form-row">
          <label class="form-label">API-Version</label>
          <input ExtendControl class="ldap-version" pInputText type="text" [required]="!isOptional('LDAPVersion')" [readonly]="isReadonly('LDAPVersion')" formControlName="LDAPVersion" [maxlength]="getFieldLength('LDAPVersion')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">LDAP-Url</label>
          <input ExtendControl class="ldap-url" pInputText type="text" [required]="!isOptional('LDAPUrl')" [readonly]="isReadonly('LDAPUrl')" formControlName="LDAPUrl" [maxlength]="getFieldLength('LDAPUrl')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">LDAP-Port</label>
          <input ExtendControl class="ldap-port" pInputText type="text" [required]="!isOptional('LDAPPort')" [readonly]="isReadonly('LDAPPort')" formControlName="LDAPPort" [maxlength]="getFieldLength('LDAPPort')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">LDAP-BaseDN</label>
          <input ExtendControl class="ldap-basedn" pInputText type="text" [required]="!isOptional('LDAPBaseDN')" [readonly]="isReadonly('LDAPBaseDN')" formControlName="LDAPBaseDN" [maxlength]="getFieldLength('LDAPBaseDN')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">LDAP-Benutzer</label>
          <input ExtendControl class="ldap-benutzer" pInputText type="password" [required]="!isOptional('LDAPBenutzer')" [readonly]="isReadonly('LDAPBenutzer')" formControlName="LDAPBenutzer" [maxlength]="getFieldLength('LDAPBenutzer')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">LDAP-Passwort</label>
          <input ExtendControl class="ldap-passwort" pInputText type="password" [required]="!isOptional('LDAPPasswort')" [readonly]="isReadonly('LDAPPasswort')" formControlName="LDAPPasswort" [maxlength]="getFieldLength('LDAPPasswort')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('LDAPCronjob')">Cronjob</label>
          <p-checkbox ExtendControl formControlName="LDAPCronjob" [binary]="true" [readonly]="isReadonly('LDAPCronjob')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)"  (onChange)="onLDAPCronjobChange()"></p-checkbox>
        </div>
        <div class="form-row">
          <label class="form-label">Cronjob-Intervall</label>
          <input ExtendControl class="ldap-intervall" pInputText type="text" [required]="!isOptional('LDAPIntervall')" [readonly]="isReadonly('LDAPIntervall')" formControlName="LDAPIntervall" [maxlength]="getFieldLength('LDAPIntervall')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          <label class="form-label info-label" ExtendLabel>Minuten</label>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('LDAPUhrzeitKomplett')">Komplettabgleich</label>
          <p-dropdown ExtendControl class="auswahl-uhrzeit" [options]="stunden" [(ngModel)]="LDAPUhrzeitKomplett[0]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('LDAPUhrzeitKomplett')" [readonly]="isReadonly('LDAPUhrzeitKomplett')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onLDAPUhrzeitKomplettChange()"></p-dropdown>
          <p-dropdown ExtendControl class="auswahl-uhrzeit" [options]="minuten" [(ngModel)]="LDAPUhrzeitKomplett[1]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('LDAPUhrzeitKomplett')" [readonly]="isReadonly('LDAPUhrzeitKomplett')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onLDAPUhrzeitKomplettChange()"></p-dropdown>
          <label class="form-label info-label" ExtendLabel>Uhr</label>
        </div>
        <div class="form-row form-buttons" style="margin-top: 10px;" *ngIf="isLDAPAktiv() && isWritable()">
          <button pButton type="button" class="p-button-outlined p-button-rounded" label="Verbindung testen" (click)="testLDAP()"></button>
        </div>
      </div>
      <div class="data-erp">
        <div class="form-row">
          <div class="sub-title">Schnittstelle InteDigi &lt;&gt; ERP</div>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPAktiv')">Aktiv</label>
          <p-checkbox ExtendControl formControlName="ERPAktiv" [binary]="true" [readonly]="isReadonly('ERPAktiv')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onERPAktivChange()"></p-checkbox>
        </div>
        <div class="form-row">
          <label class="form-label">API-Version</label>
          <input ExtendControl class="erp-version" pInputText type="text" [required]="!isOptional('ERPVersion')" [readonly]="isReadonly('ERPVersion')" formControlName="ERPVersion" [maxlength]="getFieldLength('ERPVersion')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">ERP-Url</label>
          <input ExtendControl class="erp-url" pInputText type="text" [required]="!isOptional('ERPUrl')" [readonly]="isReadonly('ERPUrl')" formControlName="ERPUrl" [maxlength]="getFieldLength('ERPUrl')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPZertifikatspruefung')">ERP-Zertifikatsprüfung</label>
          <p-checkbox ExtendControl formControlName="ERPZertifikatspruefung" [binary]="true" [readonly]="isReadonly('ERPZertifikatspruefung')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onERPZertifikatspruefungChange()"></p-checkbox>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">ERP-Benutzer</label>
          <input ExtendControl class="erp-benutzer" pInputText type="password" [required]="!isOptional('ERPBenutzer')" [readonly]="isReadonly('ERPBenutzer')" formControlName="ERPBenutzer" [maxlength]="getFieldLength('ERPBenutzer')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">ERP-Passwort</label>
          <input ExtendControl class="erp-passwort" pInputText type="password" [required]="!isOptional('ERPPasswort')" [readonly]="isReadonly('ERPPasswort')" formControlName="ERPPasswort" [maxlength]="getFieldLength('ERPPasswort')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPCronjob')">Cronjob</label>
          <p-checkbox ExtendControl formControlName="ERPCronjob" [binary]="true" [readonly]="isReadonly('ERPCronjob')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)"  (onChange)="onERPCronjobChange()"></p-checkbox>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">Cronjob-Intervall</label>
          <input ExtendControl class="erp-intervall" pInputText type="text" [required]="!isOptional('ERPIntervall')" [readonly]="isReadonly('ERPIntervall')" formControlName="ERPIntervall" [maxlength]="getFieldLength('ERPIntervall')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          <label class="form-label info-label" ExtendLabel>Minuten</label>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPUhrzeitKomplett')">Komplettabgleich</label>
          <p-dropdown ExtendControl class="auswahl-uhrzeit" [options]="stunden" [(ngModel)]="ERPUhrzeitKomplett[0]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('ERPUhrzeitKomplett')" [readonly]="isReadonly('ERPUhrzeitKomplett')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onERPUhrzeitKomplettChange()"></p-dropdown>
          <p-dropdown ExtendControl class="auswahl-uhrzeit" [options]="minuten" [(ngModel)]="ERPUhrzeitKomplett[1]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('ERPUhrzeitKomplett')" [readonly]="isReadonly('ERPUhrzeitKomplett')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onERPUhrzeitKomplettChange()"></p-dropdown>
          <label class="form-label info-label" ExtendLabel>Uhr</label>
        </div>
        <div class="form-row form-buttons" style="margin-top: 10px;" *ngIf="isLokal() && isWritable()">
          <button pButton type="button" class="p-button-outlined p-button-rounded" label="Verbindung testen" (click)="testERP()"></button>
        </div>
        <div class="form-row" style="margin-top: 10px;" [class.hidden]="!isLokal()">
          <div class="sub-title">Auftragsspezifische Einstellungen</div>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label">ERP-Nummernkreis</label>
          <p-dropdown ExtendControl class="erp-nummernkreis" [options]="nummernkreise" [required]="!isOptional('ERPNummernkreis')" [readonly]="isReadonly('ERPNummernkreis')" [showClear]="isOptional('ERPNummernkreis') && !(isReadonly('ERPNummernkreis') || !nummernkreise.length)" formControlName="OBJERPNummernkreis" fieldName="ERPNummernkreis" placeholder="Nummernkreis auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onERPNummernkreisChange()"></p-dropdown>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPFachrichtungWiedervorlage')">WV Fachrichtung</label>
          <p-checkbox ExtendControl formControlName="ERPFachrichtungWiedervorlage" [binary]="true" [readonly]="isReadonly('ERPFachrichtungWiedervorlage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onERPFachrichtungWiedervorlageChange()"></p-checkbox>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPFachrichtungAutomatisch')">Auto Fachrichtung</label>
          <p-checkbox ExtendControl formControlName="ERPFachrichtungAutomatisch" [binary]="true" [readonly]="isReadonly('ERPFachrichtungAutomatisch')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onERPFachrichtungAutomatischChange()"></p-checkbox>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPFreigabeBei')">Freigabe bei</label>
          <p-dropdown ExtendControl class="erp-freigabebei" [options]="erpFreigabeBeiItems" [required]="!isOptional('ERPFreigabeBei')" [readonly]="isReadonly('ERPFreigabeBei')" [showClear]="isOptional('ERPFreigabeBei') && !(isReadonly('ERPFreigabeBei') || !erpFreigabeBeiItems.length)" formControlName="OBJERPFreigabeBei" fieldName="ERPFreigabeBei" placeholder="Freigabe festlegen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onERPFreigabeBeiChange()"></p-dropdown>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPArtikelBeschreibung')">Übergabe Artikelbeschr.</label>
          <p-dropdown ExtendControl class="erp-beschreibung" [options]="erpArtikelBeschreibungItems" [required]="!isOptional('ERPArtikelBeschreibung')" [readonly]="isReadonly('ERPArtikelBeschreibung')" [showClear]="isOptional('ERPArtikelBeschreibung') && !(isReadonly('ERPArtikelBeschreibung') || !erpArtikelBeschreibungItems.length)" formControlName="OBJERPArtikelBeschreibung" fieldName="ERPArtikelBeschreibung" placeholder="Übergabe Artikelbeschr. festlegen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onERPArtikelBeschreibungChange()"></p-dropdown>
        </div>
        <div class="form-row" style="margin-top: 10px;" [class.hidden]="!isLokal()">
          <div class="sub-title">Veranstaltungsspezifische Einstellungen</div>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPVerKeineLizenzvergabe')">Keine Lizenzvergabe</label>
          <p-checkbox ExtendControl formControlName="ERPVerKeineLizenzvergabe" [binary]="true" [readonly]="isReadonly('ERPVerKeineLizenzvergabe')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onERPVerKeineLizenzvergabeChange()"></p-checkbox>
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('ERPVerKeineTeilnehmer')">Keine Teilnehmer</label>
          <p-checkbox ExtendControl formControlName="ERPVerKeineTeilnehmer" [binary]="true" [readonly]="isReadonly('ERPVerKeineTeilnehmer')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onERPVerKeineTeilnehmerChange()"></p-checkbox>
        </div>
      </div>
    </div>
    <div class="data-column">
      <div class="data-email" [class.full]="!isLokal()">
        <div class="form-row">
          <div class="sub-title">E-Mail-Einstellungen</div>
        </div>
        <div class="form-row">
          <label class="form-label">E-Mail-Absender</label>
          <input ExtendControl class="email-absender" pInputText type="text" [required]="!isOptional('Email')" [readonly]="isReadonly('Email')" formControlName="Email" [maxlength]="getFieldLength('Email')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row">
          <label class="form-label">Absender-Name</label>
          <input ExtendControl class="email-absender" pInputText type="text" [required]="!isOptional('EmailName')" [readonly]="isReadonly('EmailName')" formControlName="EmailName" [maxlength]="getFieldLength('EmailName')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row">
          <label class="form-label">No-Reply-Abs.</label>
          <input ExtendControl class="email-absender" pInputText type="text" [required]="!isOptional('EmailNoReply')" [readonly]="isReadonly('EmailNoReply')" formControlName="EmailNoReply" [maxlength]="getFieldLength('EmailNoReply')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row">
          <label class="form-label">No-Reply-Name</label>
          <input ExtendControl class="email-absender" pInputText type="text" [required]="!isOptional('EmailNameNoReply')" [readonly]="isReadonly('EmailNameNoReply')" formControlName="EmailNameNoReply" [maxlength]="getFieldLength('EmailNameNoReply')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row" [class.hidden]="!isLokal()">
          <label class="form-label" ExtendLabel [help]="getHelp('EmailVersand')">E-Mail-Versand</label>
          <p-dropdown ExtendControl class="email-versand" [options]="emailVersandItems" [required]="!isOptional('EmailVersand')" [readonly]="isReadonly('EmailVersand')" [showClear]="isOptional('EmailVersand') && !(isReadonly('EmailVersand') || !emailVersandItems.length)" formControlName="OBJEmailVersand" fieldName="EmailVersand" placeholder="Email-Versand festlegen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onEmailVersandChange()"></p-dropdown>
        </div>
        <div [class.hidden]="isEmailVersandSendMail()">
          <div class="form-row">
            <label class="form-label">Host</label>
            <input ExtendControl class="email-host" pInputText type="text" [required]="!isOptional('EmailHost')" [readonly]="isReadonly('EmailHost')" formControlName="EmailHost" [maxlength]="getFieldLength('EmailHost')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          </div>
          <div class="form-row">
            <label class="form-label">Benutzer</label>
            <input ExtendControl class="email-benutzer" pInputText type="password" [required]="!isOptional('EmailBenutzer')" [readonly]="isReadonly('EmailBenutzer')" formControlName="EmailBenutzer" [maxlength]="getFieldLength('EmailBenutzer')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          </div>
          <div class="form-row">
            <label class="form-label">Passwort</label>
            <input ExtendControl class="email-passwort" pInputText type="password" [required]="!isOptional('EmailPasswort')" [readonly]="isReadonly('EmailPasswort')" formControlName="EmailPasswort" [maxlength]="getFieldLength('EmailPasswort')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          </div>
          <div class="form-row">
            <label class="form-label">Port</label>
            <input ExtendControl class="email-port" pInputText type="text" [required]="!isOptional('EmailPort')" [readonly]="isReadonly('EmailPort')" formControlName="EmailPort" [maxlength]="getFieldLength('EmailPort')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          </div>
          <div class="form-row">
            <label class="form-label" ExtendLabel [help]="getHelp('EmailEncryption')">Verschlüsselung</label>
            <p-dropdown ExtendControl class="email-encryption" [options]="emailEncryptionItems" [required]="!isOptional('EmailEncryption')" [readonly]="isReadonly('EmailEncryption')" [showClear]="isOptional('EmailEncryption') && !(isReadonly('EmailEncryption') || !emailEncryptionItems.length)" formControlName="OBJEmailEncryption" fieldName="EmailEncryption" placeholder="Verschlüsselung festlegen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onEmailEncryptionChange()"></p-dropdown>
          </div>
          <div class="form-row">
            <label class="form-label" ExtendLabel [help]="getHelp('EmailZertifikatspruefung')">Zertifikatsprüfung</label>
            <p-checkbox ExtendControl formControlName="EmailZertifikatspruefung" [binary]="true" [readonly]="isReadonly('EmailZertifikatspruefung')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)"  (onChange)="onEmailZertifikatspruefungChange()"></p-checkbox>
          </div>
          <div class="form-row">
            <label class="form-label" ExtendLabel [help]="getHelp('EmailVersand')">Debug-Level</label>
            <p-dropdown ExtendControl class="email-debug-level" [options]="emailDebugLevelItems" [required]="!isOptional('EmailDebugLevel')" [readonly]="isReadonly('EmailDebugLevel')" [showClear]="isOptional('EmailDebugLevel') && !(isReadonly('EmailDebugLevel') || !emailDebugLevelItems.length)" formControlName="OBJEmailDebugLevel" fieldName="EmailDebugLevel" placeholder="Debug-Level festlegen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onEmailDebugLevelChange()"></p-dropdown>
          </div>
        </div>
        <div class="form-row form-buttons" style="margin-top: 10px;" *ngIf="isWritable()">
          <button pButton type="button" class="p-button-outlined p-button-rounded" label="Test-E-Mail senden" (click)="testEmail()"></button>
        </div>
      </div>
      <div class="data-email-kopien" [class.hidden]="!isLokal()">
        <div class="form-row">
          <div class="sub-title">Email-Kopien versenden an</div>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('EmailKopieLizenzfreigabeAn')">Lizenzfreigabe</label>
          <input ExtendControl class="email-kopie-empfaenger" pInputText type="text" [required]="!isOptional('EmailKopieLizenzfreigabeAn')" [readonly]="isReadonly('EmailKopieLizenzfreigabeAn')" formControlName="EmailKopieLizenzfreigabeAn" [maxlength]="getFieldLength('EmailKopieLizenzfreigabeAn')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('EmailKopieBenutzerlisteAn')">Benutzerliste</label>
          <input ExtendControl class="email-kopie-empfaenger" pInputText type="text" [required]="!isOptional('EmailKopieBenutzerlisteAn')" [readonly]="isReadonly('EmailKopieBenutzerlisteAn')" formControlName="EmailKopieBenutzerlisteAn" [maxlength]="getFieldLength('EmailKopieBenutzerlisteAn')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
      </div>
      <div class="data-email-inaktiv" [class.hidden]="!isLokal()">
        <div class="form-row">
          <div class="sub-title">Automatische E-Mails an Kunden deaktivieren</div>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('EmailInaktivLizenzfreigabe')">Lizenzfreigabe inaktiv</label>
          <p-checkbox ExtendControl formControlName="EmailInaktivLizenzfreigabe" [binary]="true" [readonly]="isReadonly('EmailInaktivLizenzfreigabe')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)"  (onChange)="onEmailInaktivLizenzfreigabeChange()"></p-checkbox>
        </div>
      </div>
      <div class="data-berichte-wiedervorlage" [class.hidden]="!isLokal()">
        <div class="form-row">
          <div class="sub-title">Berichte Schnittstellen-Wiedervorlage</div>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('ReportWiedervorlageEmpfaenger')">Empfänger</label>
          <input ExtendControl class="report-empfaenger" pInputText type="text" [required]="!isOptional('ReportWiedervorlageEmpfaenger')" [readonly]="isReadonly('ReportWiedervorlageEmpfaenger')" formControlName="ReportWiedervorlageEmpfaenger" [maxlength]="getFieldLength('ReportWiedervorlageEmpfaenger')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('ReportWiedervorlage')">Sendezeiten</label>
          <div class="auswahl">
            <div class="auswahl-tage">
              <p-checkbox ExtendControl [(ngModel)]="ReportWiedervorlage[0]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Mo" [readonly]="isReadonly('ReportWiedervorlageTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportWiedervorlageTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportWiedervorlage[1]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Di" [readonly]="isReadonly('ReportWiedervorlageTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportWiedervorlageTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportWiedervorlage[2]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Mi" [readonly]="isReadonly('ReportWiedervorlageTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportWiedervorlageTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportWiedervorlage[3]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Do" [readonly]="isReadonly('ReportWiedervorlageTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportWiedervorlageTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportWiedervorlage[4]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Fr" [readonly]="isReadonly('ReportWiedervorlageTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportWiedervorlageTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportWiedervorlage[5]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Sa" [readonly]="isReadonly('ReportWiedervorlageTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportWiedervorlageTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportWiedervorlage[6]" [ngModelOptions]="{standalone: true}" [binary]="true" label="So" [readonly]="isReadonly('ReportWiedervorlageTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportWiedervorlageTageChange()"></p-checkbox>
            </div>
            <div class="auswahl-uhrzeiten">
              <div class="auswahl-uhrzeit-item" *ngFor="let uhrzeit of ReportWiedervorlageUhrzeiten; let i = index">
                <p-dropdown ExtendControl class="auswahl-uhrzeit-stunden" [options]="stunden" [(ngModel)]="ReportWiedervorlageUhrzeiten[i][0]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('ReportWiedervorlageUhrzeiten')" [readonly]="isReadonly('ReportWiedervorlageUhrzeiten')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onReportWiedervorlageUhrzeitChange()"></p-dropdown>
                <p-dropdown ExtendControl class="auswahl-uhrzeit-minuten" [options]="minuten" [(ngModel)]="ReportWiedervorlageUhrzeiten[i][1]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('ReportWiedervorlageUhrzeiten')" [readonly]="isReadonly('ReportWiedervorlageUhrzeiten')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onReportWiedervorlageUhrzeitChange()"></p-dropdown>
                <button pButton type="button" icon="pi pi-minus" class="p-button-outlined p-button-danger" (click)="removeWiedervorlageUhrzeit(i)" *ngIf="isWiedervorlageRemovable(i) && isLokal() && isWritable()"></button>
                <button pButton type="button" icon="pi pi-plus" class="p-button-outlined" (click)="addWiedervorlageUhrzeit(i)" *ngIf="isWiedervorlageAddable(i) && isLokal() && isWritable()"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="data-berichte-fehler" [class.hidden]="!isLokal()">
        <div class="form-row">
          <div class="sub-title">Berichte Schnittstellen-Fehler</div>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('ReportFehlerEmpfaenger')">Empfänger</label>
          <input ExtendControl class="report-empfaenger" pInputText type="text" [required]="!isOptional('ReportFehlerEmpfaenger')" [readonly]="isReadonly('ReportFehlerEmpfaenger')" formControlName="ReportFehlerEmpfaenger" [maxlength]="getFieldLength('ReportFehlerEmpfaenger')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('ReportFehler')">Sendezeiten</label>
          <div class="auswahl">
            <div class="auswahl-tage">
              <p-checkbox ExtendControl [(ngModel)]="ReportFehler[0]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Mo" [readonly]="isReadonly('ReportFehlerTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportFehlerTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportFehler[1]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Di" [readonly]="isReadonly('ReportFehlerTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportFehlerTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportFehler[2]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Mi" [readonly]="isReadonly('ReportFehlerTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportFehlerTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportFehler[3]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Do" [readonly]="isReadonly('ReportFehlerTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportFehlerTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportFehler[4]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Fr" [readonly]="isReadonly('ReportFehlerTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportFehlerTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportFehler[5]" [ngModelOptions]="{standalone: true}" [binary]="true" label="Sa" [readonly]="isReadonly('ReportFehlerTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportFehlerTageChange()"></p-checkbox>
              <p-checkbox ExtendControl [(ngModel)]="ReportFehler[6]" [ngModelOptions]="{standalone: true}" [binary]="true" label="So" [readonly]="isReadonly('ReportFehlerTage')" (keyup)="onKeyUp($event)" (focusin)="onFocus($event)" (keyup.enter)="focusNext($event)" (onChange)="onReportFehlerTageChange()"></p-checkbox>
            </div>
            <div class="auswahl-uhrzeiten">
              <div class="auswahl-uhrzeit-item" *ngFor="let uhrzeit of ReportFehlerUhrzeiten; let i = index">
                <p-dropdown ExtendControl class="auswahl-uhrzeit-stunden" [options]="stunden" [(ngModel)]="ReportFehlerUhrzeiten[i][0]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('ReportFehlerUhrzeiten')" [readonly]="isReadonly('ReportFehlerUhrzeiten')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onReportFehlerUhrzeitChange()"></p-dropdown>
                <p-dropdown ExtendControl class="auswahl-uhrzeit-minuten" [options]="minuten" [(ngModel)]="ReportFehlerUhrzeiten[i][1]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('ReportFehlerUhrzeiten')" [readonly]="isReadonly('ReportFehlerUhrzeiten')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onReportFehlerUhrzeitChange()"></p-dropdown>
                <button pButton type="button" icon="pi pi-minus" class="p-button-outlined p-button-danger" (click)="removeFehlerUhrzeit(i)" *ngIf="isFehlerRemovable(i) && isLokal() && isWritable()"></button>
                <button pButton type="button" icon="pi pi-plus" class="p-button-outlined" (click)="addFehlerUhrzeit(i)" *ngIf="isFehlerAddable(i) && isLokal() && isWritable()"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="data-cleanup full">
        <div class="form-row">
          <div class="sub-title">Daten-Cleanup und Archivierung</div>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('CleanupIntervall')">Intervall</label>
          <p-dropdown ExtendControl class="cleanup-common" [options]="cleanupItems" [required]="!isOptional('CleanupIntervall')" [readonly]="isReadonly('CleanupIntervall')" [showClear]="isOptional('CleanupIntervall') && !(isReadonly('CleanupIntervall') || !cleanupItems.length)" formControlName="OBJCleanupIntervall" fieldName="CleanupIntervall" placeholder="Auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onCleanupIntervallChange()"></p-dropdown>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('CleanupIntervallUhrzeit')">Uhrzeit</label>
          <p-dropdown ExtendControl class="auswahl-uhrzeit" [options]="stunden" [(ngModel)]="CleanupIntervallUhrzeit[0]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('CleanupIntervallUhrzeit')" [readonly]="isReadonly('CleanupIntervallUhrzeit')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onCleanupIntervallUhrzeitChange()"></p-dropdown>
          <p-dropdown ExtendControl class="auswahl-uhrzeit" [options]="minuten" [(ngModel)]="CleanupIntervallUhrzeit[1]" [ngModelOptions]="{standalone: true}" [required]="!isOptional('CleanupIntervallUhrzeit')" [readonly]="isReadonly('CleanupIntervallUhrzeit')" placeholder="--" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onCleanupIntervallUhrzeitChange()"></p-dropdown>
          <label class="form-label info-label" ExtendLabel>Uhr</label>
        </div>
        <div class="form-row">
          <div class="sub-title">Archivierungszeitpunkte</div>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('CleanupRegistrierungenTage')">Registrierungen</label>
          <input ExtendControl class="cleanup-registrierungen" pInputText type="text" [required]="!isOptional('CleanupRegistrierungenTage')" [readonly]="isReadonly('CleanupRegistrierungenTage')" formControlName="CleanupRegistrierungenTage" [maxlength]="getFieldLength('CleanupRegistrierungenTage')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          <label class="form-label info-label" ExtendLabel>Tage</label>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('CleanupAnfragenOffenMonate')">Anfragen Offen</label>
          <input ExtendControl class="cleanup-anfragen" pInputText type="text" [required]="!isOptional('CleanupAnfragenOffenMonate')" [readonly]="isReadonly('CleanupAnfragenOffenMonate')" formControlName="CleanupAnfragenOffenMonate" [maxlength]="getFieldLength('CleanupAnfragenOffenMonate')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          <label class="form-label info-label" ExtendLabel>Monate</label>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('CleanupAnfragenAbgeschlossenMonate')">Anfragen Abgeschl.</label>
          <input ExtendControl class="cleanup-anfragen" pInputText type="text" [required]="!isOptional('CleanupAnfragenAbgeschlossenMonate')" [readonly]="isReadonly('CleanupAnfragenAbgeschlossenMonate')" formControlName="CleanupAnfragenAbgeschlossenMonate" [maxlength]="getFieldLength('CleanupAnfragenAbgeschlossenMonate')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          <label class="form-label info-label" ExtendLabel>Monate</label>
        </div>
        <div class="form-row">
          <label class="form-label" ExtendLabel [help]="getHelp('CleanupLizenzenMonate')">Lizenzen</label>
          <input ExtendControl class="cleanup-lizenzen" pInputText type="text" [required]="!isOptional('CleanupLizenzenMonate')" [readonly]="isReadonly('CleanupLizenzenMonate')" formControlName="CleanupLizenzenMonate" [maxlength]="getFieldLength('CleanupLizenzenMonate')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
          <label class="form-label info-label" ExtendLabel>Monate</label>
        </div>
      </div>
    </div>
  </form>
</div>
