import { 
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges 
} from '@angular/core';

import { isObject, isValid, PopupService, isEmpty, NotifyService, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, CommonPopupFormComponent, DataService, BfeForm } from '@bfeoldenburg/bfe-data-forms';

import { 
  ISettings, IERPArtikelnummer, IFachrichtung, IKundentyp, IKundentyp2, ILizenzVorlage, IPaket, TABLENAMES 
} from '@bfeoldenburg/intedigi-shared';

import { IWiedervorlagePosition } from '../../../../../../_services/wiedervorlage.service';

interface IUeBSItem {
  ID: number;
  Name: string;
}


@Component({
  selector: 'sub-position-popup',
  templateUrl: './sub-position-popup.component.html',
  styleUrls: ['./sub-position-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubPositionPopupComponent extends CommonPopupFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public data: IWiedervorlagePosition;

  @Input() kundenform: BfeForm;

  @Input() fachrichtungen: IFachrichtung[] = [];

  @Output() onignore: EventEmitter<void> = new EventEmitter<void>();

  private fachrichtungIDs: string[] = [];
  private lizenzVorlage: ILizenzVorlage;
  private erpArtikelnummer: IERPArtikelnummer;
  private settings: ISettings;
  public kundentypen: IKundentyp[] = [];
  public kundentypen2: IKundentyp2[] = [];
  private vorlagen: ILizenzVorlage[] = [];
  public vorlagenAutocomplete: ILizenzVorlage[] = []; 
  public uebsitems: IUeBSItem[] = [
    { ID: 1, Name: 'Nicht relevant' },
    { ID: 2, Name: 'mit ÜBS' },
    { ID: 3, Name: 'ohne ÜBS' }
  ];

  constructor(
    dataService: DataService,
    popupService: PopupService,
    elementRef: ElementRef,
    renderer: Renderer2,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private notifyService: NotifyService
  ) { 
    super(dataService, popupService, elementRef, renderer, cdRef, toolbarService, appSettings);
  }

  ngOnInit() {
    super.ngOnInit();

    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => this.getFKeyData()));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    this.lizenzVorlage = this.data.LizenzVorlage;
    this.erpArtikelnummer = this.data.ERPArtikelnummer;

    if (changes['fachrichtungen']) {
      this.fachrichtungIDs = this.fachrichtungen.reduce((result: string[], current: IFachrichtung) => {
        return result.concat(current.ID);
      }, <string[]>[]);
      this.getVorlagen(<ILizenzVorlage[]>this.dataService.getData(TABLENAMES.LIZENZ_VORLAGE));
    }

    if (changes['kundenform']) {
      this.kundentypen = [ <IKundentyp>{ ID: -1, Name: 'Nicht relevant' } ];
      this.kundentypen2 = [ <IKundentyp2>{ ID: -1, Name: 'Nicht relevant' } ];

      if (!isEmpty(this.kundenform.getFormValue('OBJKundentyp'))) {
        this.kundentypen.push(this.kundenform.getFormValue('OBJKundentyp'));
        if (!isEmpty(this.form)) {
          this.setKundentyp(this.form.getFormValue('IDKundentyp'));
        }
      }

      if (!isEmpty(this.kundenform.getFormValue('OBJKundentyp2'))) {
        this.kundentypen2.push(this.kundenform.getFormValue('OBJKundentyp2'));
        if (!isEmpty(this.form)) {
          this.setKundentyp2(this.form.getFormValue('IDKundentyp2'));
        }
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (!isEmpty(this.form)) {
      this.form.resetCallbackReadonly('sub-position-popup');
      this.form.resetCallbackRequired('sub-position-popup');
    }
  }

  cbIsReadonly(field: string): boolean {
    switch (field) {
      case 'IDFachrichtung': {
        return !this.fachrichtungNeeded();
      }
      case 'IDKundentyp': {
        return !this.vorlageNeeded();
      }
      case 'IDKundentyp2': {
        return !this.vorlageNeeded();
      }
      case 'UeBS': {
        return !this.vorlageNeeded() || !this.isBetriebTeilnehmerAusbildungsLizenz();
      }
    }
    return false;
  }

  cbIsRequired(field: string): boolean {
    switch (field) {
      case 'IDFachrichtung': {
        return this.fachrichtungNeeded();
      }
      case 'IDKundentyp': {
        return this.vorlageNeeded();
      }
      case 'IDKundentyp2': {
        return this.vorlageNeeded();
      }
      case 'UeBS': {
        return this.vorlageNeeded() && this.isBetriebTeilnehmerAusbildungsLizenz();
      }
    }
    return false;
  }

  onFormInit() {
    this.onDataSet();

    this.form.setCallbackReadonly('sub-position-popup', (field: string) => this.cbIsReadonly(field));
    this.form.setCallbackRequired('sub-position-popup', (field: string) => this.cbIsRequired(field));
  }

  onDataSet() {
    this.setLizenzVorlage(this.form.getFormValue('IDLizenzVorlage'));
    this.setFachrichtung(this.form.getFormValue('IDFachrichtung'));
    this.setKundentyp(this.form.getFormValue('IDKundentyp'));
    this.setKundentyp2(this.form.getFormValue('IDKundentyp2'));
    this.setUeBSItem(this.form.getFormValue('UeBS'));

    this.onDataChange();
  }

  onDataGet() {
    this.setLizenzVorlage();
    this.setFachrichtung();
    this.setKundentyp();
    this.setKundentyp2();
    this.setUeBSItem();

    if (this.form.isReadonly('UeBS')) {
      this.setUeBSItem(1);
    }
  }

  setLizenzVorlage(value?: any) {
    this.setFormObject(TABLENAMES.LIZENZ_VORLAGE, value);
  }

  onLizenzVorlageChange() {
    this.setLizenzVorlage();

    let vorlage: ILizenzVorlage = this.form.getFormValue('OBJLizenzVorlage');
    if (!isEmpty(vorlage)) {
      this.lizenzVorlage = vorlage;

      this.form.setFormValue('IDLizenzart', vorlage.IDLizenzart);
      this.form.setFormValue('IDBereich', vorlage.IDBereich);
      this.form.setFormValue('IDKurs', vorlage.IDKurs);
      this.form.setFormValue('IDPaketgroesse', vorlage.IDPaketgroesse);
      this.form.setFormValue('IDPaket', vorlage.IDPaket);
      this.form.setFormValue('IDLernangebot', vorlage.IDLernangebot);

      if (isValid(vorlage.IDFachrichtung)) {
        this.setFachrichtung(vorlage.IDFachrichtung);
      } else {
        this.form.setFormValue('IDFachrichtung', null);
        this.form.setFormValue('OBJFachrichtung', null);
      }
      this.onFachrichtungChange();

    } else {
      this.lizenzVorlage = null;
      this.form.setFormValue('IDFachrichtung', null);
      this.form.setFormValue('OBJFachrichtung', null);
      this.onFachrichtungChange();
    }

    this.onDataChange();
  }

  filterVorlagenAutocomplete(event: any) {
    let queryparts = event.query.split(' ');
    this.vorlagenAutocomplete = this.vorlagen.reduce((result: ILizenzVorlage[], current: ILizenzVorlage) => {
      let i = 0, iMax = queryparts.length;
      for(i; i < iMax; i++) {
        if(queryparts[i].length > 0 && current.Search.indexOf(queryparts[i].toLowerCase()) == -1) {
          return result;
        }
      }
      return result.concat(current);
    }, <ILizenzVorlage[]>[]); 
  }
  
  setFachrichtung(value?: any) {
    this.setFormObject(TABLENAMES.FACHRICHTUNG, value);
  }

  onFachrichtungChange(): void {
    this.setFachrichtung();

    if (this.fachrichtungNeeded()) {
      let pakete: IPaket[] = <IPaket[]>this.dataService.getData(TABLENAMES.PAKET, (item: IPaket) => {
        return (
          item.IDBereich === this.form.getFormValue('IDBereich') && item.IDPaketgroesse === this.lizenzVorlage.IDPaketgroesse &&
          item.IDFachrichtung === this.form.getFormValue('IDFachrichtung')
        );
      });
      if (!!pakete.length) {
        this.form.setFormValue('IDPaket', pakete[0].ID);
        this.form.setFormValue('IDKurs', pakete[0].IDKurs);
      }
    }

    this.onDataChange();
  }

  setKundentyp(value?: any) {
    let obj = this.form.getFormValue('OBJKundentyp');
    if (value === -1 || (isObject(value) && value.ID === -1) || (isObject(obj) && obj.ID === -1)) {
      this.form.setFormValue('OBJKundentyp', { ID: -1, Name: 'Nicht relevant' });
      this.form.setFormValue('IDKundentyp', -1);
    } else {
      this.setFormObject(TABLENAMES.KUNDENTYP, value);
    }
  }

  onKundentypChange() {
    this.setKundentyp();
    this.onDataChange();
  }

  setKundentyp2(value?: any) {
    let obj = this.form.getFormValue('OBJKundentyp2');
    if (value === -1 || (isObject(value) && value.ID === -1) || (isObject(obj) && obj.ID === -1)) {
      this.form.setFormValue('OBJKundentyp2', { ID: -1, Name: 'Nicht relevant' });
      this.form.setFormValue('IDKundentyp2', -1);
    } else {
      this.setFormObject(TABLENAMES.KUNDENTYP2, value);
    }
  }

  onKundentyp2Change() {
    this.setKundentyp2();
    this.onDataChange();
  }

  setUeBSItem(value?: any) {
    if (isEmpty(value)) {
      value = this.form.getFormValue('OBJUeBS') || {};
    }
    // Wenn das übergebene value kein Objekt ist --> Es handelt sich um einen Primärschlüssel 
    if (!isObject(value)) {
      value = this.uebsitems.find((item: IUeBSItem) => item.ID === value) || {};
    }
    if (isValid(value.ID)) {
      // Die Felder aktualsieren
      this.form.setFormValue('OBJUeBS', value);
      this.form.setFormValue('UeBS', value.ID);
    } else {
      // Ansonsten die Fwelder zurücksetzen
      this.form.setFormValue('OBJUeBS', null);
      this.form.setFormValue('UeBS', null);
    }
  }

  onUeBSItemChange() {
    this.setUeBSItem();
    this.onDataChange();
  }

  getERPID(): number {
    return this.data.OrbisPosition.artikelId;
  }

  getERPBezeichnung(): string {
    return this.data.OrbisPosition.artikelBez;
  }

  hasBereich(): boolean {
    return isValid(this.form.getFormValue('IDBereich'));
  }

  getBereich(): string {
    return this.dataService.getDatasetName(TABLENAMES.BEREICH, this.form.getFormValue('IDBereich'));
  }

  hasPaketgroesse(): boolean {
    return isValid(this.form.getFormValue('IDPaketgroesse'));
  }

  getPaketgroesse(): string {
    return this.dataService.getDatasetName(TABLENAMES.PAKETGROESSE, this.form.getFormValue('IDPaketgroesse'));
  }

  hasPaket(): boolean {
    return isValid(this.form.getFormValue('IDPaket'));
  }

  getPaket(): string {
    return this.dataService.getDatasetName(TABLENAMES.PAKET, this.form.getFormValue('IDPaket'));
  }

  hasLernangebot(): boolean {
    return isValid(this.form.getFormValue('IDLernangebot'));
  }

  getLernangebot(): string {
    return this.dataService.getDatasetName(TABLENAMES.LERNANGEBOT, this.form.getFormValue('IDLernangebot'));
  }

  hasKurs(): boolean {
    return isValid(this.form.getFormValue('IDKurs'));
  }

  getKurs(): string {
    return this.dataService.getDatasetName(TABLENAMES.KURS, this.form.getFormValue('IDKurs'));
  }

  fachrichtungNeeded(): boolean {
    return !isEmpty(this.lizenzVorlage) && isValid(this.lizenzVorlage.IDPaketgroesse);
  }

  hasFachrichtung(): boolean {
    return isValid(this.form.getFormValue('IDFachrichtung'));
  }

  getFachrichtung(): string {
    return this.dataService.getDatasetName(TABLENAMES.FACHRICHTUNG, this.form.getFormValue('IDFachrichtung'));
  }

  vorlageNeeded(): boolean {
    return !isValid(this.data.LizenzVorlage);
  }

  getVorlage(): string {
    return this.dataService.getDatasetName(TABLENAMES.LIZENZ_VORLAGE, this.form.getFormValue('IDLizenzVorlage'));
  }

  getVorlagen(data: ILizenzVorlage[]): void {
    this.vorlagen = data.reduce((result: ILizenzVorlage[], current: ILizenzVorlage) => {
      if (!isValid(current.IDFachrichtung) || this.fachrichtungIDs.indexOf(current.IDFachrichtung) > -1) {
        return result.concat(current);
      }
      return result;
    }, <ILizenzVorlage[]>[]);
  }

  isBetriebTeilnehmerAusbildungsLizenz(): boolean {
    if (!isEmpty(this.form.getFormValue('OBJLizenzVorlage')) && !isEmpty(this.form.getFormValue('OBJKundentyp'))) {
      return (
        this.form.getFormValue('OBJLizenzVorlage').IDLizenzart === 1 && this.form.getFormValue('OBJLizenzVorlage').IDBereich === 'ausbildung' && 
        this.form.getFormValue('OBJKundentyp').IDLDAPTyp === 1
      );
    } else {
      return false;
    }
  }

  getFKeyData(): void {
    this.addDataSubscription(TABLENAMES.LIZENZ_VORLAGE, (data: ILizenzVorlage[]) => {
      this.getVorlagen(data);
      if (!isEmpty(this.form)) {
        this.setLizenzVorlage(this.form.getFormValue('IDLizenzVorlage'));
      }
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.SETTINGS, (data: ISettings[]) => {
      if (!!data.length) {
        this.settings = data[0];
      }
      this.detectChanges();
    });

    /*this.addDataSubscription(TABLENAMES.FACHRICHTUNG, (data: IFachrichtung[]) => {
      this.fachrichtungen = data;
      if (!isEmpty(this.form)) {
        this.setFachrichtung(this.form.getFormValue('IDFachrichtung'));
      }
      this.detectChanges();
    });*/

    this.getVorlagen(<ILizenzVorlage[]>this.dataService.getData(TABLENAMES.LIZENZ_VORLAGE));
    let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
    if (!!settings.length) {
      this.settings = settings[0];
    }
    this.detectChanges();
  }

  save(): void {
    this.onDataGet();

    let save__ = () => {
      let data: any = {
        IDLizenzVorlage: this.form.getFormValue('IDLizenzVorlage'),
        IDLizenzart: this.form.getFormValue('IDLizenzart'),
        IDBereich: this.form.getFormValue('IDBereich'),
        IDKurs: this.form.getFormValue('IDKurs'),
        IDPaketgroesse: (isValid(this.form.getFormValue('IDPaket')) ? null : this.form.getFormValue('IDPaketgroesse')),
        IDPaket: this.form.getFormValue('IDPaket'),
        IDLernangebot: this.form.getFormValue('IDLernangebot'),
        IDFachrichtung: this.form.getFormValue('IDFachrichtung'),
        IDKundentyp: this.form.getFormValue('IDKundentyp'),
        IDKundentyp2: this.form.getFormValue('IDKundentyp2'),
        UeBS: this.form.getFormValue('UeBS'),
        LizenzVorlage: this.lizenzVorlage,
        ERPArtikelnummer: this.erpArtikelnummer
      };
  
      this.onedit.emit(data);
      this.popup.close();
    }

    if (this.vorlageNeeded()) {
      this.dataService.postDataset(
        TABLENAMES.ERP_ARTIKELNUMMER, null, 
        {
          IDBildungszentrum : this.settings.IDBildungszentrum,
          IDLizenzVorlage: this.form.getFormValue('IDLizenzVorlage'),
          IDKundentyp: this.form.getFormValue('IDKundentyp'),
          IDKundentyp2: this.form.getFormValue('IDKundentyp2'),
          UeBS: this.form.getFormValue('UeBS'),
          Nummernkreis: this.settings.ERPNummernkreis,
          Nummer: this.data.OrbisPosition.artikelId
        },
        (data: IERPArtikelnummer) => {
          this.notifyService.success('Die ERP-Artikelnummer wurde gespeichert');
          this.erpArtikelnummer = data;
          save__();
        },
        (error: string) => {
          this.notifyService.error('Die ERP-Artikelnummer konnte nicht gespeichert werden [' + error + ']');
        },
        true
      )
    } else {
      save__();
    }
  }

  cancel(): void {
    this.oncancel.emit();
    this.popup.close();
  }

  ignore(): void {
    this.popupService.confirm(
      'Möchten Sie ' + (this.settings.ERPNummernkreis === 1 ? 'den Artikel' : 'die Veranstaltung') + ' wirklich ignorieren',
      (this.settings.ERPNummernkreis === 1 ? 'Artikel' : 'Veranstaltung') + ' ignorieren',
      () => {
        this.onignore.emit();
        this.popup.close();
      }
    );
  }
}
