import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';

import { isEmpty, AppSettings } from '@bfeoldenburg/bfe-shared';
import { 
  ToolbarService, CommonFeaturesComponent, DataService, IData, IListColumn, IFKeyTable, FXSTATE, ExtendedListComponent 
} from '@bfeoldenburg/bfe-data-forms';

import { IntedigiService, TABLENAMES, IERPArtikelnummer, ISettings } from '@bfeoldenburg/intedigi-shared';

import { ErpArtikelnummerPopupComponent } from './erp-artikelnummer-popup/erp-artikelnummer-popup.component';

@Component({
  selector: 'erp-artikelnummern',
  templateUrl: './erp-artikelnummern.component.html',
  styleUrls: ['./erp-artikelnummern.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErpArtikelnummernComponent extends CommonFeaturesComponent implements AfterViewInit {
  public popupComponent: any = ErpArtikelnummerPopupComponent;
  public selectedRow: IData;
  public fKeyTables: IFKeyTable[] = [
    { table: TABLENAMES.BILDUNGSZENTRUM },
    { table: TABLENAMES.LIZENZ_VORLAGE },
    { table: TABLENAMES.KUNDENTYP },
    { table: TABLENAMES.KUNDENTYP2 }
  ];

  /*public filterStructures: IFilterStructure[] = [
    {
      table: TABLENAMES.BILDUNGSZENTRUM,
      subtables: [],
      fields: [ 'IDBildungszentrum' ]
    },
    {
      table: TABLENAMES.LIZENZ_VORLAGE,
      subtables: [],
      fields: [ 'IDLizenzVorlage' ],
      selection: FILTERSELECTION.MutliSearch
    },
    {
      table: TABLENAMES.KUNDENTYP,
      subtables: [],
      fields: [ 'IDKundentyp' ]
    },
    {
      table: TABLENAMES.KUNDENTYP2,
      subtables: [],
      fields: [ 'IDKundentyp2' ]
    }
  ];*/

  private settings: ISettings = null;

  public columns: IListColumn[] = [];

  @ViewChild(ExtendedListComponent, { static: false }) private extendedList: ExtendedListComponent;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    private intedigiService: IntedigiService,
    appSettings: AppSettings
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    if (this.dataService.isValid(TABLENAMES.ERP_ARTIKELNUMMER) && !this.isLokal()) {
      this.dataService.setMethods(TABLENAMES.ERP_ARTIKELNUMMER, [ 'GET', 'OPTIONS' ]);
    }
    this.subscriptions.push(this.dataService.onready.subscribe(() => {
      if (this.dataService.isValid(TABLENAMES.ERP_ARTIKELNUMMER) && !this.isLokal()) {
        this.dataService.setMethods(TABLENAMES.ERP_ARTIKELNUMMER, [ 'GET', 'OPTIONS' ]);
        this.detectChanges();
      }
    }));
  }

  ngAfterViewInit() {
    let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
    if (!!settings.length) {
      this.settings = settings[0];
    }
    
    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => {
      let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
      if (!!settings.length) {
        this.settings = settings[0];
      }

      this.getColumns();
    }));

    this.getColumns();
  }

  detectChanges(cbChanging?: any): void {
    super.detectChanges(cbChanging);
    if (!!this.extendedList) {
      this.extendedList.detectChanges();
    }
  }

  isLokal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }

  getColumns(): void {
    this.columns = [];
    
    if (!this.isLokal()) {
      this.columns = [
        { 
          name: 'Bildungszentrum', title: 'Bildungszentrum', minWidth: 150, 
          sort: { Desc: false, Default: true, State: FXSTATE.Callback }, search: FXSTATE.Callback,
          cbOnShow: (dataset: IERPArtikelnummer) => { 
            return this.dataService.getDatasetName(TABLENAMES.BILDUNGSZENTRUM, dataset.IDBildungszentrum);
          } 
        }
      ];
    }

    this.columns = this.columns.concat([
      { 
        name: 'LizenzVorlage', title: 'Lizenz-Vorlage', minWidth: 300, 
        sort: { Desc: false, Default: true, State: FXSTATE.Callback }, search: FXSTATE.Callback,
        cbOnShow: (dataset: IERPArtikelnummer) => { 
          return this.dataService.getDatasetName(TABLENAMES.LIZENZ_VORLAGE, dataset.IDLizenzVorlage);
        } 
      },
      { 
        name: 'Kundentyp', title: 'Kundentyp', minWidth: 130, 
        sort: { Desc: false, Default: false, State: FXSTATE.Callback }, search: FXSTATE.Callback,
        cbOnShow: (dataset: IERPArtikelnummer) => { 
          if (dataset.IDKundentyp === -1) {
            return 'Nicht relevant'; 
          } else {
            return this.dataService.getDatasetName(TABLENAMES.KUNDENTYP, dataset.IDKundentyp);
          }
        },
        cbColor: (dataset: IERPArtikelnummer) => { 
          return (
            !!dataset.Umkehren && dataset.IDKundentyp !== -1 ?
            this.appSettings.colorError :
            null
          ); 
        },
        cbTextDecoration: (dataset: IERPArtikelnummer) => {
          return (
            !!dataset.Umkehren && dataset.IDKundentyp !== -1 ?
            'line-through' :
            null
          );
        }
      },
      { 
        name: 'Kundentyp2', title: 'Kundentyp 2', minWidth: 140, 
        sort: { Desc: false, Default: false, State: FXSTATE.Callback }, search: FXSTATE.Callback,
        cbOnShow: (dataset: IERPArtikelnummer) => { 
          if (dataset.IDKundentyp2 === -1) {
            return 'Nicht relevant'; 
          } else {
            return this.dataService.getDatasetName(TABLENAMES.KUNDENTYP2, dataset.IDKundentyp2);
          }
        },
        cbColor: (dataset: IERPArtikelnummer) => { 
          return (
            !!dataset.Umkehren && dataset.IDKundentyp2 !== -1 ?
            this.appSettings.colorError :
            null
          ); 
        },
        cbTextDecoration: (dataset: IERPArtikelnummer) => {
          return (
            !!dataset.Umkehren && dataset.IDKundentyp2 !== -1 ?
            'line-through' :
            null
          );
        }
      },
      { 
        name: 'UeBS', title: 'ÜBS-Status', minWidth: 130, 
        sort: { Desc: false, Default: false, State: FXSTATE.Callback }, search: FXSTATE.Callback,
        cbOnShow: (dataset: IERPArtikelnummer) => { 
          switch (dataset.UeBS) {
            case 1: return 'Nicht relevant';
            case 2: return 'mit ÜBS';
            case 3: return 'ohne ÜBS';
            default: return '';
          }
        },
        cbColor: (dataset: IERPArtikelnummer) => { 
          return (
            !!dataset.Umkehren && dataset.UeBS !== 1 ?
            this.appSettings.colorError :
            null
          ); 
        },
        cbTextDecoration: (dataset: IERPArtikelnummer) => {
          return (
            !!dataset.Umkehren && dataset.UeBS !== 1 ?
            'line-through' :
            null
          );
        }
      },
      { 
        name: 'Umkehren', title: 'Umkehren', minWidth: 120, align: 'center', isIcon: true, 
        sort: { Desc: false, Default: false }, search: FXSTATE.Raw,
        cbOnShow: (dataset: IERPArtikelnummer) => { 
          return (!!dataset.Umkehren ? 'icon-check' : 'icon-delete') 
        }, 
        cbColor: (dataset: IERPArtikelnummer) => { 
          return (
            !!dataset.Umkehren ?
            this.appSettings.colorSuccess :
            this.appSettings.colorError
          ); 
        } 
      },
      /*{ name: 'Nummernkreis', title: 'Nummernkreis', minWidth: 150, cbOnShow: (dataset: IERPArtikelnummer) => { 
        return (dataset.Nummernkreis === 1 ? 'Artikel' : 'Veranstaltungen');
      } },*/
      { 
        name: 'Nummer', title: 'Artikel-ID', minWidth: 150, 
        sort: { Desc: false, Default: false }, search: FXSTATE.Raw,
        cbOnTitle: () => (
          this.isLokal() ?
          (!isEmpty(this.settings) && this.settings.ERPNummernkreis === 1 ? 'Artikel-ID' : 'Veranstaltungs-ID') :
          'ID'
        )
      },
      { 
        name: 'NummerVerlaengerung', title: 'Verlängerung', minWidth: 150, 
        sort: { Desc: false, Default: false }, search: FXSTATE.Raw,
        cbOnShow: (dataset: IERPArtikelnummer) => { 
          return dataset.NummerVerlaengerung;
        } 
      }
    ]);
  }
}
