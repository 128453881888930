import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AuthenticationService, IUserRolle } from '@bfeoldenburg/bfe-shared';
import { FXSTATE, IListColumn } from '@bfeoldenburg/bfe-data-forms';

import { IIntedigiUser } from '@bfeoldenburg/intedigi-shared';

import { UserPopupComponent } from './user-popup/user-popup.component';

@Component({
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent {
  public popupComponent: any = UserPopupComponent;
  public columns: IListColumn[] = [];
  public userrollen: IUserRolle[] = [];

  constructor(
    private authService: AuthenticationService
  ) { 
    this.userrollen = this.authService.getUserrollen();

    this.columns = [
      { name: 'id', title: 'ID', minWidth: 100, sort: { Desc: false, Default: false } },
      { 
        name: 'Name', title: 'Name', minWidth: 250, sort: { Desc: false, Default: true }, search: FXSTATE.Callback,
        cbOnShow: (dataset: IIntedigiUser) => dataset.lastname + ', ' + dataset.firstname
      },
      { name: 'email', title: 'E-Mail', minWidth: 250, sort: { Desc: false, Default: false }, search: FXSTATE.Raw },
      { 
        name: 'Rolle', title: 'Rolle', minWidth: 150, sort: { Desc: false, Default: false }, search: FXSTATE.Callback, 
        cbOnShow: (dataset: IIntedigiUser) => this.getRoleName(dataset)
      }
    ];
  }

  getRoleName(dataset: IIntedigiUser): string {
    return (this.userrollen.find((item: IUserRolle) => item.role === dataset.role) || <IUserRolle>{ name: '' }).name;
  }
}