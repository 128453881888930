import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { timer } from 'rxjs';

import { isEmpty, isValid, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, CommonFeaturesComponent, CommonListComponent, DataService, IData, IListColumn } from '@bfeoldenburg/bfe-data-forms';

import { IntedigiService, TABLENAMES, ISettings, ISchnittstelleERPArtikelIgnoriert } from '@bfeoldenburg/intedigi-shared';

import { ErpArtikelIgnoriertPopupComponent } from './erp-artikel-ignoriert-popup/erp-artikel-ignoriert-popup.component';

@Component({
  selector: 'erp-artikel-ignoriert',
  templateUrl: './erp-artikel-ignoriert.component.html',
  styleUrls: ['./erp-artikel-ignoriert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErpArtikelIgnoriertComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnDestroy {
  public popupComponent: any = ErpArtikelIgnoriertPopupComponent;
  public selectedRow: IData;

  private settings: ISettings = null;

  public columns: IListColumn[] = [];
  public visibleColumns: IListColumn[] = [];

  private hNummern: HTMLElement;

  public searchText: string;

  @ViewChild('commonList', { static: false })
  private commonList: CommonListComponent;

  public buttonTexts: any = { history: 'Änderungs-Historie' };

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService,
    private elementRef: ElementRef
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.dataService.readData(TABLENAMES.BILDUNGSZENTRUM);
  }

  ngAfterViewInit() {
    let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
    if (!!settings.length) {
      this.settings = settings[0];
    }
    
    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => {
      let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
      if (!!settings.length) {
        this.settings = settings[0];
      }

      this.getColumns();
      this.calcTable();
    }));

    this.getColumns();
    this.calcTable();
  }

  ngOnInit() {
    this.subscriptions.push(this.dataService.onready.subscribe(() => {
      this.getFKeyTables();
      this.detectChanges();
    }));

    this.getFKeyTables();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
    this.calcTable();
    this.detectChanges();
  }

  isLoading(): boolean {
    return this.dataService.neverLoaded(TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT);
  }

  onRowExpand() {
    this.detectChanges();
  }

  getColumns(): void {
    this.columns = [];
    
    if (!this.intedigiService.isInterfaceLocal()) {
      this.columns = [
        { name: 'Bildungszentrum', title: 'Bildungszentrum', minWidth: 150, cbOnShow: (dataset: ISchnittstelleERPArtikelIgnoriert) => { 
          return this.dataService.getDatasetName(TABLENAMES.BILDUNGSZENTRUM, dataset.IDBildungszentrum);
        } }
      ];
    }

    this.columns = this.columns.concat([
      /*{ name: 'Nummernkreis', title: 'Nummernkreis', minWidth: 150, cbOnShow: (dataset: ISchnittstelleERPArtikelIgnoriert) => { 
        return (dataset.Nummernkreis === 1 ? 'Artikel' : 'Veranstaltungen');
      } },*/
      { 
        name: 'Nummer', title: (!isEmpty(this.settings) && this.settings.ERPNummernkreis === 1 ? 'Artikel-ID' : 'Veranstaltungs-ID'), minWidth: 150, 
        cbOnShow: (dataset: ISchnittstelleERPArtikelIgnoriert) => { 
          return dataset.Nummer;
        } 
      },
      { 
        name: 'Bezeichnung', title: 'Bezeichnung', minWidth: 450, 
        cbOnShow: (dataset: ISchnittstelleERPArtikelIgnoriert) => { 
          return dataset.Bezeichnung;
        } 
      }
    ]);
  }

  getNummerBezeichner(): string {
    return (!isEmpty(this.settings) && this.settings.ERPNummernkreis === 1 ? 'Artikel-ID' : 'Veranstaltungs-ID');
  }

  getWidth(): number {
    if (!this.hNummern) {
      this.hNummern = this.elementRef.nativeElement.querySelector('#data-table');
    }
    if (!!this.hNummern) {
      return this.hNummern.offsetWidth;
    }
    return 0;
  }

  isWritable(): boolean {
    return this.dataService.isWritable(TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT);
  }

  getHelp(control: string): string {
    let form = this.dataService.getForm(TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT);
    if (isValid(form)) {
      return form.getHelp(control);
    }
    return '';
  }

  getColumnAlign(column: any): string {
    return (!!column.align && !!column.align.length ? column.align : 'left');
  }

  calcTable(): void {
    if (this.getWidth() > 0) {
      this.visibleColumns = [];
      let width = 40;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].minWidth + width <= this.getWidth()) {
          this.visibleColumns.push(this.columns[i]);
        } else {
          break;
        }
        width += this.columns[i].minWidth;
      }
    } else {
      timer(10).subscribe(() => this.calcTable());
    }

    this.detectChanges();
  }

  getFKeyTables(): void {
    this.addDataSubscription(TABLENAMES.BILDUNGSZENTRUM, () => this.detectChanges());

    this.detectChanges();
  }

  onSearch() {
    this.detectChanges();
  }
}
