<bfe-common-list
  #commonList
  tablename="SchnittstelleERPArtikelIgnoriert" 
  tabletitle="Ignorierte Artikel" 
  [buttonTexts]="{ 'history': 'Änderungs-Historie' }"
  [popupComponent]="popupComponent"
  [(selectedRow)]="selectedRow"
  [searchText]="searchText"
  searchColumn="Search"
  (window:resize)="onResize()"
>
  <ng-template #filter>
    <div class="filter">
      <div class="search">
        <div class="icon icon-magnifying_glass"></div>
        <input ExtendControl class="searchtext" pInputText type="text" [(ngModel)]="searchText" (input)="onSearch()" >
      </div>
    </div>
  </ng-template>
  <ng-template #datalist let-data="data" let-width="width">
    <div class="data-table" id="data-table">
      <p-table styleClass="p-datatable-striped" [value]="data" selectionMode="single" dataKey="_uuid" [(selection)]="selectedRow" (onRowExpand)="onRowExpand()" (onRowCollapse)="onRowExpand()" [loading]="isLoading()">
        <ng-template pTemplate="header">
          <tr *ngIf="getWidth() > 575">
            <th style="width: 40px; max-width: 40px"></th>
            <th *ngFor="let column of visibleColumns" [style.width]="(column.minWidth > 0 && !column.fullWidth ? column.minWidth + 'px' : '100%')" [style.textAlign]="getColumnAlign(column)">
              {{ column.title }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dataset let-expanded="expanded">
          <tr *ngIf="getWidth() <= 575" [pSelectableRow]="dataset">
            <td>
              <table class="stacked">
                <tr *ngFor="let column of columns">
                  <td>{{ column.title }}</td>
                  <td style="overflow: hidden; text-overflow: ellipsis; ">
                    <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
                    <table-icon 
                      *ngIf="column.isIcon" 
                      [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
                      [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
                    ></table-icon>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr *ngIf="getWidth() > 575" [pSelectableRow]="dataset">
            <td style="width: 40px; max-width: 40px; ">
              <div style="margin: -7px;">
                <a href="#" [pRowToggler]="dataset" title="Aufklappen" style="padding: 7px;">
                  <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                </a>
              </div>
            </td>
            <td *ngFor="let column of visibleColumns" [style.width]="(column.minWidth > 0 && !column.fullWidth ? column.minWidth + 'px' : '100%')" [style.textAlign]="getColumnAlign(column)">
              <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
              <table-icon 
                *ngIf="column.isIcon" 
                [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
                [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
              ></table-icon>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-dataset>
          <tr *ngIf="getWidth() > 575">
            <td class="data-expansion" id="data-expansion" [attr.colspan]="visibleColumns.length + 1">
              <div class="expansion-details">
                <div class="expansion-item">
                  <div *ngFor="let column of columns">
                    <div class="form-row">
                      <label class="form-label">{{ column.title }}</label>
                      <label style="overflow: hidden; text-overflow: ellipsis; ">
                        <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
                        <table-icon 
                          *ngIf="column.isIcon" 
                          [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
                          [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
                        ></table-icon>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="visibleColumns.length + 1">
              Es konnten keine Artikel ermittelt werden
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</bfe-common-list>
