<div class="app" (window:resize)="onResize($event)" (window:message)="onMessage($event)" (click)="closeAllOverlays($event)">
  <div class="menubar" id="menubar" [class.overlay]="menuOverlay()" (click)="stopPropagation($event)">
    <div class="menu-title" [class.bfe]="isLocalBfe()" [class.etz]="isLocalEtz()" [class.ebz]="isLocalEbz()" [class.bze]="isLocalBze()" [class.bzl]="isLocalBzl()" [class.zeit]="isLocalZeit()">
      <div class="menu-title-text">{{ getMainTitle() }}</div>
      <div class="close-menu icon-nav_left" (click)="toggleMenu($event, 0)" *ngIf="menuOverlay()"></div>
    </div>
    <div class="menu-inner">
      <div class="navbar" id="navbar">
        <div class="navbar-inner" [CustomScroll]="{ 'bar-position': 'outer', 'animations': true }">
          <div class="navbar-scroll" id="navbar-scroll">
            <base-menu-item [menuitem]="menuHome" *ngIf="isSSoSubpage()"></base-menu-item>
            <div *ngFor="let item of menuitems; let i = index">
              <base-menu-item (onitemclick)="closeAllOverlays($event)" [menuitem]="item"></base-menu-item>
            </div>
          </div>
        </div>
      </div>
      <div><ldap-countdown [settings]="settings" (checksettings)="checkSettings()"></ldap-countdown></div>
      <a [attr.href]="getLogoLink()" target="_blank">
        <div class="logo" *ngIf="isGlobal()"><img src="assets/images/ELKOnet-Logo.png" width="160px"></div>
        <div class="logo bfe" *ngIf="isLocalBfe()"><img src="assets/images/bfe_logo_full.png" height="96px"></div>
        <div class="logo etz" *ngIf="isLocalEtz()"><img src="assets/images/etz_logo.png" height="96px"></div>
        <div class="logo ebz" *ngIf="isLocalEbz()"><img src="assets/images/ebz_logo.png" height="110px"></div>
        <div class="logo bze" *ngIf="isLocalBze()"><img src="assets/images/bze_logo.png" height="96px"></div>
        <div class="logo bzl" *ngIf="isLocalBzl()"><img src="assets/images/bzl_logo.png" height="80px"></div>
        <div class="logo zeit" *ngIf="isLocalZeit()"><img src="assets/images/zeit_logo.jpg" height="96px"></div>
      </a>
    </div>
  </div>
  <div class="main">
    <div class="titlebar" [class.bfe]="isLocalBfe()" [class.etz]="isLocalEtz()" [class.ebz]="isLocalEbz()" [class.bze]="isLocalBze()" [class.bzl]="isLocalBzl()" [class.zeit]="isLocalZeit()">
      <div class="toogle-menu" (click)="toggleMenu($event, 0)"><div class="bun"></div></div>
      <div class="title-text">{{ getTitle() }}</div>
      <div class="icon home-tool icon-home" *ngIf="isSSoSubpage()">
        <div class="home-tool-click" (click)="ssoHome()" title="Zurück zur SSO-Startseite"></div>
      </div>
      <div class="icon help-tool icon-question" *ngIf="isLogged() && hasHelp()">
        <div class="help-tool-click" (click)="openHelppage($event)" title="Wiki Hilfeseite öffnen"></div>
      </div>
      <div class="icon user-tool" [class.bfe]="isLocalBfe()" [class.etz]="isLocalEtz()" [class.ebz]="isLocalEbz()" [class.bze]="isLocalBze()" [class.bzl]="isLocalBzl()" [class.zeit]="isLocalZeit()" [class.icon-user]="!hasGravatar()" *ngIf="isLogged()">
        <div class="user-tool-gravatar" [style.background-image]="getGravatarBinary()" *ngIf="hasGravatar()"></div>
        <div class="user-tool-click" (click)="toggleUserMenu($event)" [title]="getUsername()"></div>
        <div class="user-sub-container" id="user-sub" [style.display]="(isUserMenuVisible() ? 'block' : 'none')">
          <div class="user-sub-menu" *ngIf="isUserMenuVisible()">
            <div class="user-sub-row">{{ getUsername() }}</div>
            <div class="user-sub-row">{{ getUserEmail() }}</div>
            <div class="user-sub-row link" (click)="changePass()" *ngIf="isSSoSubpage()"><div class="icon icon-key"></div><div class="text">Passwort ändern</div></div>
            <div class="user-sub-row link" routerLink="/passwort-aendern" *ngIf="!isSSoSubpage()"><div class="icon icon-key"></div><div class="text">Passwort ändern</div></div>
            <div class="user-sub-row link" routerLink="/email-aendern"><div class="icon icon-mail"></div><div class="text">E-Mailadresse ändern</div></div>
            <div class="user-sub-row link" routerLink="/users" *ngIf="isAdministrator()"><div class="icon icon-users3"></div><div class="text">Zugänge bearbeiten</div></div>
            <div class="user-sub-row link" (click)="logout()"><div class="icon icon-log_out"></div><div class="text">Logout</div></div>
            <div class="user-sub-pointer-outer"><div class="user-sub-pointer"></div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="content" id="content">
      <toolbar-container name="MainTop"></toolbar-container>
      <div class="content-inner" [CustomScroll]="{ 'top': getContentTop(), 'animations': true }" [style.top]="getContentTop() + 'px'" [style.bottom]="getContentBottom() + 'px'">
        <div class="content-scroll" id="content-scroll">
          <router-outlet></router-outlet>
        </div>
      </div>
      <toolbar-container class="toolbarBottom" name="MainBottom" [toolMinWidth]="120" [toolMaxWidth]="200" position="bottom"></toolbar-container>
    </div>
  </div>
  <!--<div class="footer" *ngIf="hasInfo()" [class.bfe]="isLocalBfe()" [class.etz]="isLocalEtz()" [class.ebz]="isLocalEbz()" [class.bze]="isLocalBze()" [class.bzl]="isLocalBzl()" [class.zeit]="isLocalZeit()">{{ getInfo() }}</div>-->
</div>
<div id="logoutModal" class="logoutmodal">
  <!-- Modal content -->
  <div class="logoutmodal-content">
    <div id="logoutloader" class="logoutloader"></div> 
    <img id="logoutcheckSuccess" src="assets/images/Check.png" class="logoutcheckSuccess">
    <img id="logoutcheckFail" src="assets/images/Error.png" class="logoutcheckSuccess">
    <p id="logoutmodalText">Logout wird durchgeführt...</p>
  </div>
</div>

<popups [class.bfe]="isLocalBfe()" [class.etz]="isLocalEtz()" [class.ebz]="isLocalEbz()" [class.bze]="isLocalBze()" [class.bzl]="isLocalBzl()" [class.zeit]="isLocalZeit()"></popups>
<toast [baseZIndex]="5000"></toast>

<div id="loading" *ngIf="!loginReady()">
  <div class="loader">
  </div>
  <div class="logo">
    <img src="assets/images/ELKOnet-Logo.png" width="160px">
  </div>
</div> 
<append-helper></append-helper>