<div class="form-row form-buttons top" (window:resize)="onResize()">
  <div class="sub-title">{{ title }}</div>
</div>
<div class="form-row">
  <p-table [value]="benutzer" [selectionMode]="(isLoggedIn() ? 'single' : null)" dataKey="_uuid" [(selection)]="selBenutzer" (onRowSelect)="onBenutzerSelect()" [paginator]="benutzer.length > maxCount" [rows]="maxCount">
    <ng-template pTemplate="header">
      <tr *ngIf="width > 400">
        <th style="width: 40px; max-width: 40px"></th>
        <th *ngFor="let column of visibleColumns" ExtendLabel [help]="(!!column.cbOnHelp ? column.cbOnHelp() : null)" [title]="column.title" [style.width]="(column.minWidth > 0 && !column.fullWidth ? column.minWidth + 'px' : '100%')" [style.textAlign]="getColumnAlign(column)">
          {{ column.title }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-dataset let-expanded="expanded">
      <tr *ngIf="width <= 400" [pSelectableRow]="dataset">
        <td>
          <table class="stacked">
            <tr *ngFor="let column of columns">
              <td>{{ column.title }}</td>
              <td>
                <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
                <span style="color: #007ad9; " [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon && !!column.cbZusatzinfo && !!column.cbZusatzinfo(dataset) && !!column.cbZusatzinfo(dataset).length"><br>{{ column.cbZusatzinfo(dataset) }}</span>
                <table-icon 
                  *ngIf="column.isIcon" 
                  [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
                  [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
                ></table-icon>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr *ngIf="width > 400" [pSelectableRow]="dataset">
        <td style="width: 40px; max-width: 40px; ">
          <div style="margin: -7px;">
            <a href="#" [pRowToggler]="dataset" title="Aufklappen" style="padding: 7px;">
              <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
            </a>
          </div>
        </td>
        <td *ngFor="let column of visibleColumns" [style.width]="(column.minWidth > 0 && !column.fullWidth ? column.minWidth + 'px' : '100%')" [style.textAlign]="getColumnAlign(column)">
          <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
          <span style="color: #007ad9; " [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon && !!column.cbZusatzinfo && !!column.cbZusatzinfo(dataset) && !!column.cbZusatzinfo(dataset).length"><br>{{ column.cbZusatzinfo(dataset) }}</span>
          <table-icon 
            *ngIf="column.isIcon" 
            [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
            [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
          ></table-icon>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-dataset>
      <tr *ngIf="width > 725">
        <td class="data-expansion" id="data-expansion" [attr.colspan]="visibleColumns.length + 1">
          <div class="expansion-details">
            <div>
              <div *ngFor="let column of columns" class="form-row">
                <label class="form-label">{{ column.title }}</label>
                <label>
                  <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
                  <span style="color: #007ad9; " [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon && !!column.cbZusatzinfo && !!column.cbZusatzinfo(dataset) && !!column.cbZusatzinfo(dataset).length"><br>{{ column.cbZusatzinfo(dataset) }}</span>
                  <table-icon 
                    *ngIf="column.isIcon" 
                    [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
                    [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
                  ></table-icon>
                </label>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="visibleColumns.length + 1">
          Es konnten keine {{ title }} ermittelt werden
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

