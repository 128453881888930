import * as moment from 'moment';

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppSettings, isValid, IUserRolle, AuthenticationService, NotifyService, PopupService } from '@bfeoldenburg/bfe-shared';
import { 
  ToolbarService, CommonFeaturesComponent, DataService, IStandardAuswahl, IData, FXSTATE, IFilterStructure 
} from '@bfeoldenburg/bfe-data-forms';

import { 
  IIntedigiUser, IntedigiService, ISchnittstelle, ISchnittstelleFehler, ISchnittstelleHistorie, TABLENAMES 
} from '@bfeoldenburg/intedigi-shared';
import { BaseSchnittstellenComponent, InterfacesService } from '@bfeoldenburg/bfe-web-apps';


@Component({
  templateUrl: './schnittstellen.component.html',
  styleUrls: ['./schnittstellen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchnittstellenComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit {
  public columnsSchnittstellen: any[] = [];
  public columnsFehler: any[] = [];
  public columnsAenderungen: any[] = [];
  public schnittstellen: IStandardAuswahl[] = [
    { ID: 'interface', Name: 'InteDigi-Schnittstelle' },
    { ID: 'ldap', Name: 'LDAP-Schnittstelle' },
    { ID: 'erp', Name: 'ERP-Schnittstelle' },
    { ID: 'erplizenzverkauf', Name: 'ERP - Lizenzverkauf' },
    { ID: 'erpfirmen', Name: 'ERP - Firmen' },
    { ID: 'erpkurse', Name: 'ERP - Kurse' },
    { ID: 'erpkundentypen', Name: 'ERP - Kundentypen' },
    { ID: 'erpkundentypen2', Name: 'ERP - Kundentypen 2' },
    { ID: 'erpauftraege', Name: 'ERP - Aufträge' },
    { ID: 'erpveranstaltungen', Name: 'ERP - Veranstaltungen' }
  ];
  public cbInterfaceLocal: any;
  public cbInterfaceUser: any;
  public cbGlobalErrors: any;
  public cbResetGlobalErrors: any;
  public userrollen: { [key: string]: IUserRolle } = {};
  public filterStructures: IFilterStructure[] = [];

  @ViewChild(BaseSchnittstellenComponent, { static: false }) private baseSchnittstellen: BaseSchnittstellenComponent;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    dataService: DataService,
    private intedigiService: IntedigiService,
    private interfacesService: InterfacesService,
    private authService: AuthenticationService,
    private notifyService: NotifyService,
    private popupService: PopupService,
    private route: ActivatedRoute
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.userrollen = this.authService.getUserrollen().reduce((result: { [key: string]: IUserRolle }, current: IUserRolle) => {
      result[current.role] = current;
      return result;
    }, <{ [key: string]: IUserRolle }>{});

    this.cbInterfaceLocal = () => {
      return this.intedigiService.isInterfaceLocal();
    }

    this.cbInterfaceUser = (role: string, dataset: ISchnittstelleHistorie) => {
      return this.intedigiService.getInterfaceUser(role, dataset.IDBildungszentrum);
    }

    this.cbGlobalErrors = (fehler: ISchnittstelleFehler[] = []) => {
      return !!fehler.filter((item: ISchnittstelleFehler) => !isValid(item.IDBildungszentrum)).length
    }

    this.cbResetGlobalErrors = (fehler: ISchnittstelleFehler[] = []) => {
      this.resetGlobalErrors(fehler);
    }

    this.readData();
  }

  ngOnInit(): void {
    this.initStructures();
    this.initColumns();
    
    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => {
      this.initStructures();
      this.initColumns();
      this.detectChanges();
    }));
  }

  ngAfterViewInit(): void {
  }

  detectChanges(cbChanging?: any): void {
    super.detectChanges(cbChanging);
    if (!!this.baseSchnittstellen) {
      this.baseSchnittstellen.detectChanges();
    }
  }

  initStructures(): void {
    this.filterStructures = [];

    if (!this.intedigiService.isInterfaceLocal()) {
      this.filterStructures = [{
        table: TABLENAMES.BILDUNGSZENTRUM,
        subtables: [],
        fields: [ 'IDBildungszentrum' ]
      }];
    }
  }

  initColumns(): void {
    this.columnsSchnittstellen = [];
    this.columnsFehler = [];
    this.columnsAenderungen = [];

    if (!this.intedigiService.isInterfaceLocal()) {
      this.columnsSchnittstellen = [
        { 
          name: 'IDBildungszentrum', title: 'Bildungszentrum', minWidth: 160, 
          search: FXSTATE.Callback, sort: { Desc: false, Default: true, State: FXSTATE.Callback },
          cbOnShow: (dataset: ISchnittstelle) => { 
            if (isValid(dataset.IDBildungszentrum)) {
              return this.dataService.getDatasetName(TABLENAMES.BILDUNGSZENTRUM, dataset.IDBildungszentrum);
            } else {
              return 'Global';
            }
          }
        }
      ];
    }

    this.columnsSchnittstellen = this.columnsSchnittstellen.concat([
      { 
        name: 'Schnittstelle', title: 'Schnittstelle', minWidth: 270,
        search: FXSTATE.Callback, sort: { Desc: false, Default: true, State: FXSTATE.Callback },
        cbOnShow: (dataset: ISchnittstelle) => { 
          return this.getSchnittstelle(dataset.Schnittstelle);
        }, 
        cbColor: (dataset: ISchnittstelle) => { return (dataset.Fehler > 0 ? this.appSettings.colorError : null); }
      },
      { 
        name: 'LetzterLauf', title: 'Ausgeführt', minWidth: 150, 
        search: FXSTATE.Callback, sort: { Desc: false, Default: false, State: FXSTATE.Raw },
        cbOnShow: (dataset: ISchnittstelle) => { 
          return (isValid(dataset.LetzterLauf) ? moment.utc(dataset.LetzterLauf).local().format('DD.MM.YY HH:mm:ss') : '---');
        }, 
        cbColor: (dataset: ISchnittstelle) => { return (dataset.Fehler > 0 ? this.appSettings.colorError : null); }
      },
      { 
        name: 'DatensaetzeGet', title: 'Abgerufen', minWidth: 110, align: 'right', 
        sort: { Desc: false, Default: false, State: FXSTATE.Raw },
        cbColor: (dataset: ISchnittstelle) => { return (dataset.Fehler > 0 ? this.appSettings.colorError : null); }
      },
      { 
        name: 'DatensaetzePost', title: 'Übertragen', minWidth: 110, align: 'right', 
        sort: { Desc: false, Default: false, State: FXSTATE.Raw },
        cbColor: (dataset: ISchnittstelle) => { return (dataset.Fehler > 0 ? this.appSettings.colorError : null); }
      },
      { 
        name: 'Fehler', title: 'Fehler', minWidth: 80, align: 'right', bold: true, 
        sort: { Desc: false, Default: false, State: FXSTATE.Raw },
        cbColor: (dataset: ISchnittstelle) => { return (dataset.Fehler > 0 ? this.appSettings.colorError : this.appSettings.colorSuccess); }
      },
      { 
        name: 'Wiedervorlage', title: 'Wiedervorlage', minWidth: 140, align: 'right', 
        sort: { Desc: false, Default: false, State: FXSTATE.Raw }
      },
      { 
        name: 'Geloescht', title: 'Gelöscht', minWidth: 140, align: 'right', 
        sort: { Desc: false, Default: false, State: FXSTATE.Raw }
      },
      { 
        name: 'btnAusfuehren', title: '', minWidth: 120, align: 'center', isButton: true, alwaysVisible: true,
        cbOnShow: (dataset: ISchnittstelle) => { return 'Ausführen'; },
        cbVisible: (dataset: ISchnittstelle) => { 
          return (
            (
              (this.intedigiService.isInterfaceLocal() && isValid(dataset.Lokal)) || 
              (!this.intedigiService.isInterfaceLocal() && isValid(dataset.Global))
            ) && 
            !this.interfacesService.isRunning(this.intedigiService.isInterfaceLocal() ? dataset.Lokal : dataset.Global, dataset) && 
            this.dataService.isWritable(TABLENAMES.SCHNITTSTELLE)
          );
        },
        cbOnClick: (dataset: ISchnittstelle) => { 
          let runOnClick__ = () => {
            if (this.intedigiService.isInterfaceLocal() && isValid(dataset.Lokal)) {
              this.runInterface(dataset.Lokal, dataset, dataset.External, dataset.GetMethode, dataset.Schnittstelle);
            } else if (!this.intedigiService.isInterfaceLocal() && isValid(dataset.Global)) {
              this.runInterface(dataset.Global, dataset, dataset.External, dataset.GetMethode, dataset.Schnittstelle);
            }
          }

          if (isValid(dataset.MethodeConfirm)) {
            this.popupService.confirm(
              dataset.MethodeConfirm, 
              'Schnittstelle ausführen',
              () => {
                runOnClick__();
              }
            );
          } else {
            runOnClick__();
          }
        },
        cbBtnClass: (dataset: ISchnittstelle) => { return 'p-button-outlined button-white'; },
        cbProgress: (dataset: ISchnittstelle) => { 
          return this.interfacesService.isRunning(this.intedigiService.isInterfaceLocal() ? dataset.Lokal : dataset.Global, dataset); 
        }
      }
    ]);

    this.columnsFehler = [
      {
        name: '_edited', title: 'Datum', minWidth: 130, 
        search: FXSTATE.Callback, sort: { Desc: true, Default: true, State: FXSTATE.Raw },
        cbOnShow: (dataset: ISchnittstelleFehler) => { 
          return moment.utc(dataset._edited).local().format('DD.MM.YY HH:mm');
        }
      }
    ];

    if (!this.intedigiService.isInterfaceLocal()) {
      this.columnsFehler = this.columnsFehler.concat([
        { 
          name: 'IDBildungszentrum', title: 'Bildungszentrum', minWidth: 160, 
          search: FXSTATE.Callback, sort: { Desc: false, Default: true, State: FXSTATE.Callback },
          cbOnShow: (dataset: ISchnittstelleFehler) => { 
            if (isValid(dataset.IDBildungszentrum)) {
              return this.dataService.getDatasetName(TABLENAMES.BILDUNGSZENTRUM, dataset.IDBildungszentrum);
            } else {
              return 'Global';
            }
          }
        }
      ]);
    }

    this.columnsFehler = this.columnsFehler.concat([
      { 
        name: 'Schnittstelle', title: 'Schnittstelle', minWidth: 270,
        search: FXSTATE.Callback, sort: { Desc: false, Default: true, State: FXSTATE.Callback },
        cbOnShow: (dataset: ISchnittstelleFehler) => { 
          return this.getSchnittstelle(dataset.Schnittstelle);
        } 
      },
      { 
        name: 'Kontext', title: 'Kontext', minWidth: 160,
        search: FXSTATE.Raw, sort: { Desc: false, Default: true, State: FXSTATE.Raw }
      },
      {
        name: 'Richtung', title: 'Richtung', minWidth: 120, 
        search: FXSTATE.Callback, sort: { Desc: false, Default: false, State: FXSTATE.Callback },
        cbOnShow: (dataset: ISchnittstelleFehler) => { 
          return this.getRichtung(dataset.Richtung);
        }
      },
      { 
        name: 'Fehlercode', title: 'Fehlercode', minWidth: 110, align: 'center',
        search: FXSTATE.Raw, sort: { Desc: false, Default: false, State: FXSTATE.Raw } 
      },
      { 
        name: 'Anzahl', title: 'Fehleranzahl', minWidth: 110, align: 'center',
        sort: { Desc: false, Default: false, State: FXSTATE.Raw }  
      },
      { 
        name: 'Fehlertext', title: 'Fehlertext', minWidth: 440, 
        search: FXSTATE.Callback, sort: { Desc: false, Default: false, State: FXSTATE.Callback }, 
        cbOnShow: (dataset: ISchnittstelleFehler) => { 
          return dataset.Fehlertext.replace(/<[^>]*>?/gm, '');
        }
      }
    ]);

    if (!this.intedigiService.isInterfaceLocal()) {
      this.columnsAenderungen = [
        { 
          name: 'IDBildungszentrum', title: 'Bildungszentrum', minWidth: 160, 
          search: FXSTATE.Callback, sort: { Desc: false, Default: true, State: FXSTATE.Callback },
          cbOnShow: (dataset: ISchnittstelleFehler) => { 
            if (isValid(dataset.IDBildungszentrum)) {
              return this.dataService.getDatasetName(TABLENAMES.BILDUNGSZENTRUM, dataset.IDBildungszentrum);
            } else {
              return 'Global';
            }
          }
        }
      ];
    }

    this.columnsAenderungen = this.columnsAenderungen.concat([
      { 
        name: 'Schnittstelle', title: 'Schnittstelle', minWidth: 300,
        search: FXSTATE.Callback, sort: { Desc: false, Default: true, State: FXSTATE.Callback },
        cbOnShow: (dataset: ISchnittstelleHistorie) => { 
          return this.getSchnittstelle(dataset.Schnittstelle);
        }
      },
      { 
        name: 'Kontext', title: 'Kontext', minWidth: 220,
        search: FXSTATE.Raw, sort: { Desc: false, Default: true, State: FXSTATE.Raw }
      },
      { 
        name: 'LetzterGet', title: 'Abgerufen am', minWidth: 140, 
        search: FXSTATE.Callback, sort: { Desc: false, Default: false, State: FXSTATE.Raw },
        cbOnShow: (dataset: ISchnittstelleHistorie) => { 
          return (isValid(dataset.LetzterGet) ? moment.utc(dataset.LetzterGet).local().format('DD.MM.YY HH:mm') : '---');
        }
      },
      { 
        name: 'DatensaetzeGet', title: 'Abgerufen', minWidth: 110, align: 'right',
        sort: { Desc: false, Default: false, State: FXSTATE.Raw } 
      },
      { 
        name: 'LetzterPost', title: 'Übertragen am', minWidth: 140, 
        search: FXSTATE.Callback, sort: { Desc: false, Default: false, State: FXSTATE.Raw },
        cbOnShow: (dataset: ISchnittstelleHistorie) => { 
          return (isValid(dataset.LetzterPost) ? moment.utc(dataset.LetzterPost).local().format('DD.MM.YY HH:mm') : '---');
        }
      },
      { 
        name: 'DatensaetzePost', title: 'Übertragen', minWidth: 110, align: 'right',
        sort: { Desc: false, Default: false, State: FXSTATE.Raw } 
      }
    ]);
  }

  runInterface(script: string, data: any = {}, external: boolean = false, get: boolean = false, interfaceID: string = null): void {
    if (external) {
      this.interfacesService.runExternal(
        script, data,
        (data: any) => {
          this.notifyService.success('Die Schnittstelle wurde erfolgreich ausgeführt');
          this.readData();
          if (interfaceID === 'ldap') {
            this.dataService.readData(TABLENAMES.LDAP, false, false, true);
          }
          this.detectChanges();
        },
        (error: string) => {
          this.notifyService.error('Die Ausführung der Schnittstelle ist fehlgeschlagen [' + error + ']');
          this.readData();
          this.detectChanges();
        }
      );
    } else {
      this.interfacesService.run(
        script, (!!get ? null : data),
        (data: any) => {
          this.notifyService.success('Die Schnittstelle wurde erfolgreich ausgeführt');
          this.readData();
          if (interfaceID === 'ldap') {
            this.dataService.readData(TABLENAMES.LDAP, false, false, true);
          }
          this.detectChanges();
        },
        (error: string) => {
          this.notifyService.error('Die Ausführung der Schnittstelle ist fehlgeschlagen [' + error + ']');
          this.readData();
          this.detectChanges();
        },
        get
      );
    }
    this.detectChanges();
  }

  readData(): void {
    this.dataService.readData(TABLENAMES.SCHNITTSTELLE, false, false, true);
    this.dataService.readData(TABLENAMES.SCHNITTSTELLE_FEHLER, false, false, true);
    this.dataService.readData(TABLENAMES.SCHNITTSTELLE_HISTORIE, false, false, true);
    this.dataService.readData(TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE, false, false, true);
    this.dataService.readData(TABLENAMES.BILDUNGSZENTRUM, false, false, true);
  }

  getSchnittstelle(name: string): string {
    let index = this.schnittstellen.findIndex((item: any) => item.ID === name);
    if (index > -1) {
      return this.schnittstellen[index].Name;
    } else {
      return (name in this.userrollen ? this.userrollen[name].name : '');
    }
  }

  getRichtung(name: string): string {
    switch (name) {
      case 'GET': {
        return 'Abrufen';
      }
      case 'POST': {
        return 'Übertragen';
      }
      case 'COMMON': {
        return 'Allgemein';
      }
      default: return '';
    }
  }

  getInterfaceUser(role: string, dataset: ISchnittstelleHistorie): IIntedigiUser {
    if (!!this.cbInterfaceUser) {
      return this.cbInterfaceLocal(role, dataset);
    }
    return <IIntedigiUser>this.dataService.getData(TABLENAMES.USERS).find(
      (item: IData) => (<IIntedigiUser>item).role === role
    ) || <IIntedigiUser>{};
  }

  resetGlobalErrors(fehler: ISchnittstelleFehler[] = []): void {
    let count = fehler.filter((item: ISchnittstelleFehler) => !isValid(item.IDBildungszentrum)).length;
    if (!count) {
      return;
    }
    let countSuccess = 0;
    let countErrors = 0;

    let cbMeldung = (countSuccess: number) => {
      if (!!countSuccess) {
        this.notifyService.success('Es ' + (countSuccess > 1 ? 'wurden ' + countSuccess + 'Fehler' : 'wurde ein Fehler') + ' zurückgesetzt');
      } else {
        this.notifyService.error('Es konnten keine Fehler zurückgesetzt werden');
      }
      this.detectChanges();
    }

    this.popupService.confirm(
      'Möchten Sie wirklich alle globalen Fehlermeldungen löschen?', 'Fehler zurücksetzen',
      () => {
        fehler = fehler.reduce((result: ISchnittstelleFehler[], current: ISchnittstelleFehler) => {
          if (isValid(current.IDBildungszentrum)) {
            return result.concat(current);
          } else {
            this.dataService.deleteDatasetManually(
              TABLENAMES.SCHNITTSTELLE_FEHLER, current._uuid, 
              () => { 
                countSuccess++; 
                if (countSuccess + countErrors > count) {
                  cbMeldung(countSuccess);
                }
              },
              () => { 
                countErrors++; 
                if (countSuccess + countErrors > count) {
                  cbMeldung(countSuccess);
                }
              },
              true
            );
            return result;
          }
        }, <ISchnittstelleFehler[]>[]);
      }
    );  
  }
}
