<div class="data-popup" *ngIf="!!form">
  <bfe-popup-title [title]="getTitle()" (onclose)="cancel()"></bfe-popup-title>
  <div class="data-form">
    <form [formGroup]="form" autocomplete="off">
      <input id="username" style="display:none" type="text" name="fakeusernameremembered">
      <input id="password" style="display:none" type="password" name="fakepasswordremembered">
      <div class="form-row">
        <label class="form-label">ID</label>
        <input ExtendControl class="id" pInputText type="text" [required]="!isOptional('id')" [readonly]="isReadonly('id')" formControlName="id"
          [maxlength]="getFieldLength('id')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)"
          (keyup.enter)="focusNext($event)" >
      </div>
      <div class="form-row">
        <label class="form-label">Email</label>
        <input ExtendControl class="email" pInputText type="text" [required]="!isOptional('email')" [readonly]="isReadonly('email')" formControlName="email"
          [maxlength]="getFieldLength('email')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)"
          (keyup.enter)="focusNext($event)"  autocomplete="nope">
      </div>
      <div class="form-row border-top">
        <label class="form-label">Passwort</label>
        <input ExtendControl class="password" id="realpassword" pInputText type="password" [required]="!isOptional('password1')" [readonly]="isReadonly('password1')"
          formControlName="password1" [maxlength]="getFieldLength('password1')" (focusin)="onFocus($event)"
          (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" 
          autocomplete="new-password">
      </div>
      <div class="form-row" *ngIf="passwort2Needed()">
        <label class="form-label"></label>
        <div class="pass-check">
          <span>{{ getPassStrengthText() }}</span>
          <div class="pass-check-container">
            <div class="pass-check-bar" [style.background-color]="getPassStrengthColor()"
              [style.width]="(pass_strength_score >= 0 ? pass_strength_score : '0') + '%'"></div>
          </div>
        </div>
      </div>
      <div class="form-row" [class.border-bottom]="passwordCheck()">
        <label class="form-label">Wiederholung</label>
        <input ExtendControl class="password" pInputText type="password" [required]="!isOptional('password2')" [readonly]="isReadonly('password2')"
          formControlName="password2" [maxlength]="getFieldLength('password2')" (focusin)="onFocus($event)"
          (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" 
          autocomplete="new-password">
      </div>
      <div class="form-row border-bottom" *ngIf="!passwordCheck()">
        <label class="form-label"></label>
        <label class="error-message">{{ getPasswordError() }}</label>
      </div>
      <div class="form-row">
        <label class="form-label">Nachname</label>
        <input ExtendControl class="lastname" pInputText type="text" [required]="!isOptional('lastname')" [readonly]="isReadonly('lastname')" formControlName="lastname"
          [maxlength]="getFieldLength('lastname')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)"
          (keyup.enter)="focusNext($event)" >
      </div>
      <div class="form-row">
        <label class="form-label">Vorname</label>
        <input ExtendControl class="firstname" pInputText type="text" [required]="!isOptional('firstname')" [readonly]="isReadonly('firstname')" formControlName="firstname"
          [maxlength]="getFieldLength('firstname')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)"
          (keyup.enter)="focusNext($event)" >
      </div>
      <div class="form-row" [class.hidden]="isLokal()">
        <label class="form-label">Bildungszentrum</label>
        <p-dropdown ExtendControl class="bildungszentrum" [options]="bildungszentren" [required]="!isOptional('IDBildungszentrum')" [readonly]="isReadonly('IDBildungszentrum')"
          [showClear]="isOptional('IDBildungszentrum') && !isReadonly('IDBildungszentrum')" formControlName="OBJBildungszentrum"
          placeholder="Bildungszentrum auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)"
          (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" 
          (onChange)="onBildungszentrumChange()"></p-dropdown>
      </div>
      <div class="form-row">
        <label class="form-label">Rolle</label>
        <p-dropdown ExtendControl class="userrolle" [options]="userrollen" [required]="!isOptional('role')" [readonly]="isReadonly('role')"
          [showClear]="isOptional('role') && !isReadonly('role')" formControlName="OBJRole"
          placeholder="Rolle auswählen" optionLabel="name" appendTo="body" (focusin)="onFocus($event)"
          (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" 
          (onChange)="onUserrolleChange()"></p-dropdown>
      </div>
      <div class="form-row form-buttons bottom">
        <button pButton type="button" label="Speichern" [disabled]="form.isInvalid() || !hasChanged() || !passwordCheck()"
          (click)="save()"></button>
        <button pButton type="button" label="Abbrechen" (click)="cancel()"></button>
        <div style="flex: 1; min-height: 100%; "></div>
        <button pButton type="button" label="Löschen" class="p-button-danger" [disabled]="isNew()"
          (click)="delete()"></button>
      </div>
    </form>
  </div>
</div>