<div class="extended-form" (window:resize)="onResize()" [style.minHeight]="getContentHeight()">
  <div class="form-columns">
    <div class="data-column" [class.full]="!kursgruppe?.ERPID">
      <div class="data-veranstaltung">
        <div class="form-row form-buttons">
          <div class="sub-title">Veranstaltung / Lerngruppe</div>
          <div style="min-height: 100%; flex: 1;"></div>
          <!--<button pButton type="button" icon="icon-magnifying_glass" label="Suchen" class="p-button-rounded p-button-outlined" (click)="search()" *ngIf="isLoggedIn()"></button>
          <button pButton type="button" icon="icon-printer" label="Code" class="p-button-rounded p-button-outlined" (click)="print()" *ngIf="!!kursgruppe?.ERPID"></button>-->
        </div>
        <div class="form-row" *ngIf="!kursgruppe?.ERPID && isLoggedIn()" style="margin-top: 20px;">
          <label>Es ist keine gültige Veranstaltung ausgewählt, nutzen Sie die Suche zur Anzeige.</label>
        </div>
        <div class="form-row" *ngIf="!kursgruppe?.ERPID && !isLoggedIn()" style="margin-top: 20px;">
          <label>Es ist keine gültige Veranstaltung ausgewählt.</label>
        </div>
        <div class="form-row" *ngIf="!!kursgruppe?.ERPID">
          <label class="form-label">ERP-Nummer</label>
          <label>{{ kursgruppe?.ERPNummer }}</label>
        </div>
        <div class="form-row" *ngIf="!!kursgruppe?.ERPID">
          <label class="form-label">Name</label>
          <label>{{ kursgruppe?.Name }}</label>
        </div>
        <div class="form-row" *ngIf="!!kursgruppe?.ERPID">
          <label class="form-label">Code</label>
          <label>{{ kursgruppe?.Code }}</label>
        </div>
        <div class="form-row" *ngIf="!!kursgruppe?.ERPID">
          <label class="form-label">Kurs</label>
          <label>{{ getKurs() }}</label>
        </div>
        <div class="form-row" *ngIf="!!kursgruppe?.ERPID">
          <label class="form-label">Laufzeit</label>
          <label>{{ getLaufzeit() }}</label>
        </div>
      </div>
      <div class="data-wiedervorlage" *ngIf="isValidWiedervorlage()">
        <div class="form-row form-buttons">
          <div class="sub-title">Wiedervorlage</div>
          <div style="min-height: 100%; flex: 1;"></div>
          <!--<button pButton type="button" icon="icon-navigate_right" label="Öffnen" class="p-button-rounded p-button-outlined" (click)="openWV()" *ngIf="isLoggedIn()"></button>-->
        </div>
        <div class="form-row">
          <label [style.color]="getColorError()">Diese Veranstaltung befindet sich derzeit in der Wiedervorlage</label>
        </div>
      </div>
      <div class="data-kunde" *ngIf="!!kursgruppe?.ERPID">
        <div class="form-row form-buttons">
          <div class="sub-title">Kundendaten</div>
          <div style="min-height: 100%; flex: 1;"></div>
          <!--<button pButton type="button" icon="icon-navigate_right" label="Aufrufen" class="p-button-rounded p-button-outlined" (click)="openKunde()" *ngIf="isLoggedIn()"></button>
          <button pButton type="button" icon="icon-printer" label="Code" class="p-button-rounded p-button-outlined" (click)="codeKunde()"></button>-->
        </div>
        <div class="form-row">
          <label class="form-label">Name</label>
          <label>{{ kunde?.Name }}</label>
        </div>
        <div class="form-row">
          <label class="form-label">Code</label>
          <label>{{ kunde?.Code }}</label>
        </div>
        <div class="form-row">
          <label class="form-label">Kennung</label>
          <label>{{ kunde?.Kennung }}</label>
        </div>
      </div>
      <div class="data-codes" *ngIf="!!kursgruppe?.ERPID">
        <div id="data-registrierung">
          <erp-sub-registrierung
            [erpID]="kursgruppe.ERPID"
            [kunde]="kunde"
            [kundentypGruppen]="kundentypGruppen"
            [gruppen]="gruppen"
            [width]="getRegistrierungWidth()"
          ></erp-sub-registrierung>
        </div>
      </div>
    </div>
    <div class="data-column" *ngIf="!!kursgruppe?.ERPID">
      <div class="data-vorlage">
        <div class="form-row">
          <div class="sub-title">Lizenz-Vorlage</div>
        </div>
        <div class="form-row">
          <label class="form-label">Name</label>
          <label>{{ lizenzVorlage?.Name }}</label>
        </div>
        <div class="form-row">
          <label class="form-label">Lizenzart</label>
          <label>{{ getLVLizenzart() }}</label>
        </div>
        <div class="form-row">
          <label class="form-label">Lernbereich</label>
          <label>{{ getLVBereich() }}</label>
        </div>
        <div class="form-row" *ngIf="!!lizenzVorlage?.IDPaketgroesse">
          <label class="form-label">Paketgröße</label>
          <label>{{ getLVPaketgroesse() }}</label>
        </div>
        <div class="form-row" *ngIf="!!lizenzVorlage?.IDPaket">
          <label class="form-label">Paket</label>
          <label>{{ getLVPaket() }}</label>
        </div>
        <div class="form-row" *ngIf="!!lizenzVorlage?.IDLernangebot">
          <label class="form-label">Lernangebot</label>
          <label>{{ getLVLernangebot() }}</label>
        </div>
        <div class="form-row">
          <label class="form-label" *ngIf="!!lizenzVorlage?.IDFachrichtung">Fachrichtung</label>
          <label>{{ getLVFachrichtung() }}</label>
        </div>
        <div class="form-row">
          <label class="form-label">Laufzeit</label>
          <label>{{ lizenzVorlage?.Laufzeit }} Monate</label>
        </div>
      </div>
      <div class="data-teilnehmer" id="data-teilnehmer">
        <erp-sub-benutzer
          title="Referenten"
          [referenten]="true"
          [benutzer]="referenten"
          [buchungenBenutzer]="buchungenBenutzer"
          [buchungen]="buchungen"
          [gruppen]="gruppen"
          [maxCount]="5"
          [width]="getTeilnehmerWidth()"
        ></erp-sub-benutzer>
      </div>
      <div class="data-teilnehmer">
        <erp-sub-benutzer
          title="Teilnehmer"
          [benutzer]="teilnehmer"
          [buchungenBenutzer]="buchungenBenutzer"
          [buchungen]="buchungen"
          [gruppen]="gruppen"
          [width]="getTeilnehmerWidth()"
        ></erp-sub-benutzer>
      </div>
    </div>
    <div class="data-lizenzen" id="data-buchungen" *ngIf="!!kursgruppe?.ERPID">
      <erp-sub-buchungen
        [kunde]="kunde"
        [buchungen]="buchungen"
        [buchungenBenutzer]="buchungenBenutzer"
        [benutzerBuchungen]="benutzerBuchungen"
        [benutzer]="benutzer"
        [bereiche]="bereiche"
        [fachrichtungen]="fachrichtungen"
        [kurse]="kurse"
        [lernangebote]="lernangebote"
        [lizenzarten]="lizenzarten"
        [pakete]="pakete"
        [paketgroessen]="paketgroessen"
        [gruppen]="gruppen"
        [bildungszentren]="bildungszentren"
        [showBenutzer]="true"
        [width]="getBuchungenWidth()"
      ></erp-sub-buchungen>
    </div>
  </div>
</div>  
