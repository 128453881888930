declare const moment: any;
import { cloneDeep } from 'lodash-es';

import { 
  Component, OnInit, AfterViewInit, OnChanges, OnDestroy, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef 
} from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { AppSettings, isValid, isEmpty } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, IData, DataService, CommonFeaturesComponent } from '@bfeoldenburg/bfe-data-forms';


import { 
  ISettings, IGruppe, ILizenzart, Lizenztyp, TABLENAMES, IKunde, IBenutzer, IBuchung, IPaket, IPaketgroesse
} from '@bfeoldenburg/intedigi-shared';

interface ILizenzartExt extends ILizenzart {
  offen: number;
}


@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public benutzerlist: any[] = [];
  //public hinweise: any[] = [];
  public benutzerStats: any;
  public paketStats: any;

  public countTimer: Subscription = null;

  private nativeElem: HTMLElement;

  public colors: any[] = [
    { r: 0, g: 188, b: 212 },
    { r: 233, g: 30, b: 99 },
    { r: 255, g: 179, b: 0 },
    { r: 96, g: 125, b: 139 },
    { r: 0, g: 140, b: 80 },
    { r: 23, g: 63, b: 179 },
    { r: 201, g: 202, b: 140 },
    { r: 139, g: 102, b: 93 },
    { r: 171, g: 76, b: 141 },
    { r: 244, g: 74, b: 24 },
    { r: 191, g: 108, b: 37 },
    { r: 149, g: 231, b: 100 },
    { r: 165, g: 125, b: 128 },
    { r: 83, g: 138, b: 61 },
    { r: 42, g: 52, b: 211 },
    { r: 202, g: 129, b: 69 },
    { r: 214, g: 202, b: 52 },
    { r: 212, g: 219, b: 221 },
    { r: 136, g: 5, b: 163 },
    { r: 243, g: 156, b: 82 },
    { r: 50, g: 149, b: 169 },
    { r: 103, g: 136, b: 142 },
    { r: 231, g: 69, b: 227 },
    { r: 25, g: 202, b: 141 },
    { r: 126, g: 3, b: 46 },
    { r: 205, g: 207, b: 21 },
    { r: 53, g: 25, b: 119 }
  ];

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private elementRef: ElementRef
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.nativeElem = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.initDataSubscriptions();

    this.subscriptions.push(this.dataService.onready.subscribe(() => this.initDataSubscriptions()));
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.detectChanges();
  }

  ngOnDestroy() {
    this.deleteCountTimer();
    super.ngOnDestroy();
  }

  onResize(): void {
    this.detectChanges();
  }

  deleteCountTimer(): void{
    if (!!this.countTimer) {
      this.countTimer.unsubscribe();
      this.countTimer = null;
    }
  }

  getWidth(): number {
    return this.nativeElem.querySelector('#dashboard').clientWidth;
  }

  initDataSubscriptions() {
    this.addDataSubscription(TABLENAMES.SETTINGS, () => this.count());
    this.addDataSubscription(TABLENAMES.KUNDE, () => this.count());
    this.addDataSubscription(TABLENAMES.BENUTZER, () => this.count());
    this.addDataSubscription(TABLENAMES.BUCHUNG, () => this.count());
    this.addDataSubscription(TABLENAMES.BUCHUNG_BENUTZER, () => this.count());
    this.addDataSubscription(TABLENAMES.PAKET, () => this.count());
    this.addDataSubscription(TABLENAMES.PAKETGROESSE, () => this.count());

    this.count();
  }

  getColor(colors: any[], opacity: number = 1.0): string {
    if (!!colors.length) {
      let color = colors.shift();
      return ('rgba(' + color.r + ', ' + color.g + ', ' + color.b + ', ' + opacity + ')');
    } else {
      var color: any = {};
      for (var i = 0; i < 3; i++) {
        switch(i) {
          case 0: color.r = Math.round(Math.random() * 255);
          case 1: color.g = Math.round(Math.random() * 255);
          case 2: color.b = Math.round(Math.random() * 255);
        }
      }
      return ('rgba(' + color.r + ', ' + color.g + ', ' + color.b + ', ' + opacity + ')');
    }
  }

  count() {
    this.deleteCountTimer();

    this.countTimer = timer(250).subscribe(() => {
      this.count__();
    });
  }
  
  private count__(): void {
    this.benutzerlist = [];

    let colors: any[] = cloneDeep(this.colors);
    this.benutzerlist.push({
      icon: 'icon-toolbox',
      color: this.getColor(colors),
      title: 'Handwerk',
      count: this.dataService.getData(TABLENAMES.KUNDE, (item: IKunde) => item.IDKundentyp === 1).length
    });
    this.benutzerlist.push({
      icon: 'icon-factory',
      color: this.getColor(colors),
      title: 'Industrie',
      count: this.dataService.getData(TABLENAMES.KUNDE, (item: IKunde) => item.IDKundentyp === 5).length
    });
    this.benutzerlist.push({
      icon: 'icon-graduate',
      color: this.getColor(colors),
      title: 'Berufsschulen',
      count: this.dataService.getData(TABLENAMES.KUNDE, (item: IKunde) => item.IDKundentyp === 2).length
    });
    this.benutzerlist.push({
      icon: 'icon-school',
      iconMarginTop: '-5px',
      color: this.getColor(colors),
      title: 'ÜLU / ÜBA',
      count: this.dataService.getData(TABLENAMES.KUNDE, (item: IKunde) => item.IDKundentyp === 3).length
    });
    this.benutzerlist.push({
      icon: 'icon-office_building',
      color: this.getColor(colors),
      title: 'Fachverbände',
      count: this.dataService.getData(TABLENAMES.KUNDE, (item: IKunde) => item.IDKundentyp === 7).length
    });
    this.benutzerlist.push({
      icon: 'icon-users3',
      color: this.getColor(colors),
      title: 'Benutzer',
      count: this.dataService.getData(TABLENAMES.BENUTZER).length
    });

    //this.hinweise = [];

    let settingsArray: ISettings[] = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
    if (!settingsArray.length) {
      return;
    }
    let settings: ISettings = settingsArray[0];

    /*let lizenzenOffen: { [key: string]: ILizenzartExt } = this.dataService.getData(
      TABLENAMES.LIZENZART, (item: ILizenzart) => item.IDLizenztyp === 1 || item.IDLizenztyp === 2
    )
    .reduce((result: { [key: string]: ILizenzartExt }, current: IData) => {
      result[(<ILizenzartExt>current).ID] = <ILizenzartExt>current;
      result[(<ILizenzartExt>current).ID].offen = 0;
      return result;
    }, <{ [key: string]: ILizenzartExt }>{});

    lizenzenOffen = (<IBuchung[]>this.dataService.getData(TABLENAMES.BUCHUNG)).reduce((result: { [key: string]: ILizenzartExt }, current: IBuchung) => {
      if (
        (!settings.LokaleInstanz || settings.IDBildungszentrum === current.IDBildungszKd) &&
        (current.IDLizenzart in result) && 
        this.dataService.getData(
          TABLENAMES.BUCHUNG_BENUTZER, 
          (item: IBuchungBenutzer) => (
            item.IDBildungszKd === current.IDBildungszKd && item.IDKunde === current.IDKunde && item.IDBuchung === current.ID
          )
        ).length < current.Anzahl
      ) {
        result[current.IDLizenzart].offen++;
      }
      return result;
    }, lizenzenOffen);

    let buchungenNichtFreigegeben = (<IBuchung[]>this.dataService.getData(TABLENAMES.BUCHUNG)).reduce((result: IBuchung[], current: IBuchung) => {
      if (
        (!settings.LokaleInstanz || settings.IDBildungszentrum === current.IDBildungszKd) &&
        !current.Freigabe && (<IKunde>this.dataService.getDataset(TABLENAMES.KUNDE, [ current.IDKunde, current.IDBildungszKd ]) || {}).IDKundentyp === 1
      ) {
        return result.concat(current);
      }
      return result;
    }, <IBuchung[]>[]);

    let buchungenUnberechnet = (<IBuchung[]>this.dataService.getData(TABLENAMES.BUCHUNG)).reduce((result: IBuchung[], current: IBuchung) => {
      if (
        (!settings.LokaleInstanz || settings.IDBildungszentrum === current.IDBildungszKd) &&
        !current.Berechnet && (<IKunde>this.dataService.getDataset(TABLENAMES.KUNDE, [ current.IDKunde, current.IDBildungszKd ]) || {}).IDKundentyp === 1
      ) {
        return result.concat(current);
      }
      return result;
    }, <IBuchung[]>[]);
    
    this.hinweise.push({
      title: 'Nicht freigegebene Lizenzen',
      count: buchungenNichtFreigegeben.length
    });
    this.hinweise.push({
      title: 'Unberechnete Lizenzen',
      count: buchungenUnberechnet.length
    });

    let keys = Object.keys(lizenzenOffen);
    for (let i = 0; i < keys.length; i++) {
      this.hinweise.push({
        title: 'Offene ' + lizenzenOffen[keys[i]].Name + '-Lizenzen',
        count: lizenzenOffen[keys[i]].offen
      });
    }*/


    let now = moment(new Date());
    let thisMonth: number = now.month() + 1;
    let thisYear: number = now.year();
    let monate: any[] = [];

    const ALLE = 'alle';
    colors = cloneDeep(this.colors);
    let gruppen: { [key: string]: IGruppe } = {};

    let data: { [key: string]: number[] } = this.dataService.getData(TABLENAMES.GRUPPE).reduce(
      (result: { [key: string]: number[] }, current: IData) => {
        result[(<IGruppe>current).ID] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        gruppen[(<IGruppe>current).ID] = <IGruppe>current;
        return result;
      }, <{ [key: string]: number[] }>{}
    );
    data[ALLE] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    gruppen[ALLE] = <any>{
      ID: ALLE,
      Name: 'Benutzer gesamt',
    };

    let monatsnamen = [
      'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
    ]

    let labels: string[] = [];
    for (let i = thisMonth + 1; i <= 12; i++) {
      monate.push({
        month: i,
        year: thisYear - 1
      });
      labels.push(monatsnamen[i - 1]);
    }
    for (let i = 1; i <= thisMonth; i++) {
      monate.push({
        month: i,
        year: thisYear
      });
      labels.push(monatsnamen[i - 1]);
    }

    (<IBenutzer[]>this.dataService.getData(TABLENAMES.BENUTZER)).reduce((result: IBenutzer[], current: IBenutzer) => {
      if (!settings.LokaleInstanz || settings.IDBildungszentrum === current.IDBildungszentrum) {
        if (current.IDGruppe in data) {
          let created = moment.utc(current._created).local(true);
          let index = monate.findIndex((item: any) => item.month === created.month() + 1 && item.year === created.year());
          if (index > -1) {
            data[ALLE][index]++;
            data[current.IDGruppe][index]++;
          }

          for (let i = index + 1; i < monate.length; i++) {
            data[ALLE][i]++;
            data[current.IDGruppe][i]++;
          }
        }
      }
      return result;
    }, []);

    let keys = Object.keys(gruppen);
    let datasets = [];
    for (let i = 0; i < keys.length; i++) {
      datasets.push({
        label: gruppen[keys[i]].Name,
        data: data[keys[i]],
        fill: false,
        borderColor: this.getColor(colors)
      });
    }

    this.benutzerStats = {
      labels: labels,
      datasets: datasets
    }

    colors = cloneDeep(this.colors);

    let paketcount: number[] = [];
    let colors__: string[] = [];
    labels = [];
    let paketgroessen: IPaketgroesse[] = <IPaketgroesse[]>this.dataService.getData(TABLENAMES.PAKETGROESSE);
    let counter = 0;
    let lizenzarten: { [ key: number ]: any } = this.dataService.getData(TABLENAMES.LIZENZART).reduce((result: { [ key: number ]: any }, current: IData) => {
      result[(<ILizenzart>current).ID] = <any>current;
      if ((<ILizenzart>current).IDLizenztyp === Lizenztyp.Lernangebot) {
        for (let i = 0; i < paketgroessen.length; i++) {
          labels[(counter * paketgroessen.length) + i] = (<ILizenzart>current).Name + ' ' + paketgroessen[i].Name;
          paketcount[(counter * paketgroessen.length) + i] = 0;
          colors__[(counter * paketgroessen.length) + i] = this.getColor(colors);
        }
        result[(<ILizenzart>current).ID].count = counter;
        counter++;
      }
      return result;
    }, <{ [ key: number ]: any }>{});

    (<IBuchung[]>this.dataService.getData(TABLENAMES.BUCHUNG)).reduce((result: IBuchung[], current: IBuchung) => {
      if (
        !current.Demo && 
        (!settings.LokaleInstanz || settings.IDBildungszentrum === current.IDBildungszKd) && isValid(current.IDLizenzart) && 
        (current.IDLizenzart in lizenzarten) && lizenzarten[current.IDLizenzart].IDLizenztyp === Lizenztyp.Lernangebot
      ) {
        if (isValid(current.IDPaket)) {
          let paket: IPaket = this.dataService.getDataset(TABLENAMES.PAKET, current.IDPaket) || null;
          if (!isEmpty(paket)) {
            let paketgroesseIndex = paketgroessen.findIndex((item: IPaketgroesse) => item.ID === paket.IDPaketgroesse);
            if (paketgroesseIndex > -1) {
              paketcount[(lizenzarten[current.IDLizenzart].count * paketgroessen.length) + paketgroesseIndex] += (current.Anzahl - current.Gutgeschrieben);
            }
          }
        } else if (isValid(current.IDPaketgroesse)) {
          let paketgroesseIndex = paketgroessen.findIndex((item: IPaketgroesse) => item.ID === current.IDPaketgroesse);
          if (paketgroesseIndex > -1) {
            paketcount[(lizenzarten[current.IDLizenzart].count * paketgroessen.length) + paketgroesseIndex] += (current.Anzahl - current.Gutgeschrieben);
          }
        }
      }
      return result;
    }, []);

    this.paketStats = {
      labels: labels,
      datasets: [
        {
          data: paketcount,
          backgroundColor: colors__,
          hoverBackgroundColor: colors__
        }
      ]    
    };

    this.detectChanges();
  }
}