<div class="sub-title">
  <div class="lizenzart">{{ title }}</div>
  <div class="anzahl-info">
    <div class="anzahl-text">Summe:</div>
    <div class="anzahl">{{ getSumme() }}</div>
  </div>
</div>
<div class="lizenzen">
  <div class="lizenz-block" *ngFor="let lizenz of lizenzen; let i = index">
    <div class="lizenz-row" [class.loading]="isLoading()" [class.onlysublizenzen]="!lizenz.anzahl" (click)="onLizenzSelect(lizenz)">
      <div class="lizenz-row-inner">
        <div class="lizenz-name">
          <span [innerHtml]="getName(lizenz)"></span>
          <span class="fachrichtung-attached"><br>{{ getFachrichtung(lizenz) }}</span>
          <span class="fachrichtung-lizenzart-attached"><br>{{ getFachrichtungUndLizenzart(lizenz) }}</span>
        </div>
        <div class="fachrichtung">{{ getFachrichtung(lizenz) }}</div>
        <div class="lizenzart">{{ getLizenzart(lizenz) }}</div>
        <div class="anzahl-info">
          <div class="anzahl-text">Anzahl:</div>
          <div class="anzahl">{{ lizenz.anzahl }}</div>
        </div>
      </div>
      <div class="lizenz-row-inner sub" *ngFor="let sublizenz of lizenz.subLizenzen; let j = index">
        <div class="lizenz-name sub">
          <span [innerHtml]="getName(sublizenz)"></span>
          <span class="fachrichtung-attached" *ngIf="hasFachrichtung(sublizenz)"> ({{ getFachrichtung(sublizenz) }})</span>
        </div>
        <div class="fachrichtung sub">{{ getFachrichtung(sublizenz) }}</div>
        <div class="lizenzart sub">&nbsp;</div>
        <div class="anzahl-info sub">
          <div class="anzahl-text sub">&nbsp;</div>
          <div class="anzahl sub">{{ sublizenz.anzahl }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
