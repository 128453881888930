<div class="extended-form" (window:resize)="onResize()" [style.minHeight]="getContentHeight()">
  <div class="form-columns">
    <!--<div class="data-main">-->
      <sub-kundendaten
        [wiedervorlage]="false"
        class="data-main"
        [loading]="isLoading()"
        (onforminit)="onKundeForm($event)"
        (onselect)="onKundeSelect($event)"
        (onkundentypchanged)="onKundentypChange()"
      ></sub-kundendaten>
    <!--</div>-->
    <div class="data-sub">
      <div class="data-fachrichtungen" [class.noadmin]="!needsAdminLizenz()">
        <div class="form-row">
          <div class="sub-title">Fachrichtungen</div>
        </div>
        <kunde-fachrichtungen
          *ngIf="!!kundenForm"
          [form]="kundenForm"
          [buchungen]="buchungen"
          [buchungenBenutzer]="buchungenBenutzer"
          [kundenFachrichtungenReady]="subDataReady(tablenames.KUNDEFACHRICHTUNG) || isNew()"
          [kundeFachrichtungen]="kundeFachrichtungen"
          [width]="getFachrichtungenWidth()"
          [writable]="isWritable() && !isLoading()"
          [loading]="isLoading()"
          (formChange)="onFachrichtungenChange()"
        ></kunde-fachrichtungen>
      </div>
      <div class="data-vertrag" [class.noadmin]="!needsAdminLizenz()">
        <div class="form-row">
          <div class="sub-title">Vertragsdaten</div>
        </div>
        <sub-vertragsdaten
          [kundenForm]="kundenForm"
          [wiedervorlage]="false"
          [loading]="isLoading()"
          (onforminit)="onVertragsform($event)"
        ></sub-vertragsdaten>
      </div>
      <div class="data-administrator" *ngIf="needsAdminLizenz()">
        <div class="form-row">
          <div class="sub-title">Kunden-Administrator</div>
          <div style="flex: 1; min-height: 100%;"></div>
          <button pButton type="button" icon="icon-magnifying_glass" label="Suche" class="p-button-rounded p-button-outlined" [disabled]="isLoading()" (click)="ausbilderSuchen()" *ngIf="hasAusbilder()"></button>
        </div>
        <sub-kundenadmin
          [kunden]="kunden"
          [wiedervorlage]="false"
          [loading]="isLoading()"
          (onforminit)="onAdminform($event)"
        ></sub-kundenadmin>
      </div>
    </div>
  </div>
</div>
