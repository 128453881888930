<div class="toolbar" *ngIf="isActive()" [class.bottom]="!isPositionTop()" [style.height]="getHeight()" [style.background-color]="bgColor" [style.border-color]="bgColor" [style.color]="fontColor" (window:resize)="onResize()">
  <common-tool
    *ngIf="!!tools.length" 
    [barname]="name"
    [tool]="tools[0]"   
    [bgColor]="bgColor"
    [fontColor]="fontColor"
    [bgColorHover]="bgColorHover"
    [fontColorHover]="fontColorHover"
    [borderTop]="borderTop"
    [borderBottom]="borderBottom"
    [toolMinWidth]="toolMinWidth"
    [toolMaxWidth]="toolMaxWidth"
    [widthState]="widthState"
    [width]="width"
    [availableWidth]="availableWidth"
    [countTools]="countTools"
    [visibleTools]="2"
    [realMaxWidth]="realMaxWidth"
  ></common-tool>
  <div class="info"
    [style.flex]="getInfoFlex()" 
    [style.min-width]="getInfoMinWidth()" 
    [style.max-width]="getInfoMaxWidth()" 
  >
    <div class="info-text" [style.min-width]="getInfoMinWidth(true)" [class.error]="hasStatusError()">{{ getStatusText() }}</div>
  </div> 
  <common-tool
    *ngIf="!!toolsRight.length" 
    [barname]="name"
    [tool]="toolsRight[0]"   
    [bgColor]="bgColor"
    [fontColor]="fontColor"
    [bgColorHover]="bgColorHover"
    [fontColorHover]="fontColorHover"
    [borderTop]="borderTop"
    [borderBottom]="borderBottom"
    [toolMinWidth]="toolMinWidth"
    [toolMaxWidth]="toolMaxWidth"
    [widthState]="widthState"
    [width]="width"
    [availableWidth]="availableWidth"
    [countTools]="countTools"
    [visibleTools]="2"
    [realMaxWidth]="realMaxWidth"
  ></common-tool>
</div>  
