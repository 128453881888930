<div class="form-row">
  <p-table [value]="benutzer" [selectionMode]="(isWritable() ? 'single' : null)" dataKey="ERPBenutzer.ERPID" [(selection)]="selPosition" (onRowSelect)="onBenutzerSelect()" [loading]="loading">
    <ng-template pTemplate="header">
      <tr *ngIf="width > 575">
        <th style="width: 40px; max-width: 40px"></th>
        <th *ngFor="let column of visibleColumns" ExtendLabel [help]="(!!column.cbOnHelp ? column.cbOnHelp() : null)" [title]="column.title" [style.width]="(column.minWidth > 0 && !column.fullWidth ? column.minWidth + 'px' : '100%')" [style.textAlign]="getColumnAlign(column)">
          {{ column.title }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-dataset let-expanded="expanded">
      <tr *ngIf="width <= 575" [pSelectableRow]="dataset" [style.background-color]="getBackgroundColor(dataset)">
        <td>
          <table class="stacked">
            <tr *ngFor="let column of columns">
              <td *ngIf="(!!column.cbVisible ? column.cbVisible(dataset) : true)">{{ column.title }}</td>
              <td style="overflow: hidden; text-overflow: ellipsis; " *ngIf="(!!column.cbVisible ? column.cbVisible(dataset) : true)">
                <span *ngIf="!column.isButton">
                  <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
                  <span style="color: #007ad9; " [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon && !!column.cbZusatzinfo && !!column.cbZusatzinfo(dataset) && !!column.cbZusatzinfo(dataset).length"><br>{{ column.cbZusatzinfo(dataset) }}</span>
                  <table-icon 
                    *ngIf="column.isIcon" 
                    [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
                    [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
                  ></table-icon>
                </span>
                <button ExtendButton style="font-size: 14px; min-width: 100%; " [btnClass]="(!!column.cbBtnClass ? column.cbBtnClass(dataset) : '')" *ngIf="!!column.isButton" pButton type="button" [label]="(!!column.cbOnShow ? column.cbOnShow(dataset) : '')" (click)="column.cbOnClick(dataset)"></button>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr *ngIf="width > 575" [pSelectableRow]="dataset" [style.background-color]="getBackgroundColor(dataset)">
        <td style="width: 40px; max-width: 40px; ">
          <div style="margin: -7px;">
            <a href="#" [pRowToggler]="dataset" title="Aufklappen" style="padding: 7px;">
              <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
            </a>
          </div>
        </td>
        <td *ngFor="let column of visibleColumns" [style.width]="(column.minWidth > 0 && !column.fullWidth ? column.minWidth + 'px' : '100%')" [style.textAlign]="getColumnAlign(column)">
          <span *ngIf="!column.isButton">
            <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
            <span style="color: #007ad9; " [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon && !!column.cbZusatzinfo && !!column.cbZusatzinfo(dataset) && !!column.cbZusatzinfo(dataset).length"><br>{{ column.cbZusatzinfo(dataset) }}</span>
            <table-icon 
              *ngIf="column.isIcon" 
              [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
              [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
            ></table-icon>
          </span>
          <button ExtendButton style="font-size: 14px; min-width: 100%; " [btnClass]="(!!column.cbBtnClass ? column.cbBtnClass(dataset) : '')" *ngIf="!!column.isButton && (!!column.cbVisible ? column.cbVisible(dataset) : true) && (!column.cbProgress || !column.cbProgress(dataset))" pButton type="button" [label]="(!!column.cbOnShow ? column.cbOnShow(dataset) : '')" (click)="column.cbOnClick(dataset)"></button>
          <p-progressSpinner *ngIf="!!column.isButton && !!column.cbProgress && column.cbProgress(dataset)" [style]="{width: '28px', height: '28px'}" strokeWidth="3" animationDuration="1.0s"></p-progressSpinner>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-dataset>
      <tr *ngIf="width > 575">
        <td class="data-expansion" id="data-expansion" [attr.colspan]="visibleColumns.length + 1">
          <div class="expansion-details">
            <div>
              <div *ngFor="let column of columns">
                <div class="form-row" *ngIf="(!!column.cbVisible ? column.cbVisible(dataset) : true)">
                  <label class="form-label">{{ column.title }}</label>
                  <label style="overflow: hidden; text-overflow: ellipsis; ">
                    <span *ngIf="!column.isButton">
                      <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
                      <span style="color: #007ad9; " [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon && !!column.cbZusatzinfo && !!column.cbZusatzinfo(dataset) && !!column.cbZusatzinfo(dataset).length"><br>{{ column.cbZusatzinfo(dataset) }}</span>
                      <table-icon 
                        *ngIf="column.isIcon" 
                        [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
                        [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
                      ></table-icon>
                    </span>
                    <button ExtendButton style="font-size: 14px; min-width: 100%; " [btnClass]="(!!column.cbBtnClass ? column.cbBtnClass(dataset) : '')" *ngIf="!!column.isButton && (!column.cbProgress || !column.cbProgress(dataset))" pButton type="button" [label]="(!!column.cbOnShow ? column.cbOnShow(dataset) : '')" (click)="column.cbOnClick(dataset)"></button>
                    <p-progressSpinner *ngIf="!!column.isButton && !!column.cbProgress && column.cbProgress(dataset)" [style]="{width: '28px', height: '28px'}" strokeWidth="3" animationDuration="1.0s"></p-progressSpinner>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="visibleColumns.length + 1">
          Es konnten keine Datensätze ermittelt werden
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
