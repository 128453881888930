import { isEqual } from 'lodash-es';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

import { isEmpty, isValid, NotifyService, PopupService, AppSettings, IComponent } from '@bfeoldenburg/bfe-shared';
import { 
  BfeForm, CommonSearchComponent, DataService, ToolbarService, TOOLBAR, BaseTool, ITool, CommonFeaturesToolbarComponent 
} from '@bfeoldenburg/bfe-data-forms';

import { IFachrichtung, ILizenzart, IntedigiService, Lizenztyp, TABLENAMES } from '@bfeoldenburg/intedigi-shared';

import { TABLEALIASSE } from '../../../../_models/table-aliasse';
import { ILizenzVorlageExt, LizenzverkaufService, VKLIZENZBEREICH, ILizenzverkauf, LIZENZSTATUS } from '../../../../_services/lizenzverkauf.service';
import { LizenzPopupComponent } from './lizenz-popup/lizenz-popup.component';
import { LizenzverkaufToolbarComponent } from '../lizenzverkauf-toolbar/lizenzverkauf-toolbar.component';

enum LIZENZVERKAUFTOOLS {
  Zurueck = 0,
  Abschicken 
}


@Component({
  selector: 'lizenzen',
  templateUrl: './lizenzen.component.html',
  styleUrls: ['./lizenzen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LizenzenComponent extends CommonFeaturesToolbarComponent implements OnInit, OnDestroy {
  public teilnehmer: string;
  public ausbilder: string;
  private kundenform: BfeForm;
  private vertragsform: BfeForm;
  private adminform: BfeForm;
  public loading: boolean = false;

  public fachrichtungen: IFachrichtung[] = [];

  public azubiPakete: ILizenzVorlageExt[] = [];
  public ausbilderPakete: ILizenzVorlageExt[] = [];
  public sonstigePakete: ILizenzVorlageExt[] = [];
  public lernangebote: ILizenzVorlageExt[] = [];
  public weitereLizenzen: ILizenzVorlageExt[] = [];

  private lastStatus: { error?: boolean; text?: string; } = {};

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    private router: Router,
    appSettings: AppSettings,
    private popupService: PopupService,
    private lizenzService: LizenzverkaufService,
    private notifyService: NotifyService,
    private intedigiService: IntedigiService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.dataService.onready.subscribe(() => {
      this.getFormulare();
    });
    this.getFormulare();

    this.lastStatus = { error: !this.isValid(), text: this.getInfo() };

    this.addToolbar(TOOLBAR.MainTop);
    this.toolbarService.setComponent(TOOLBAR.MainTop, <IComponent>{ 
      component: LizenzverkaufToolbarComponent,
      inputs: { status: this.lastStatus },
      outputs: {}
    });
  }

  ngOnInit(): void {
    let tools: BaseTool[] = [
      this.toolbarService.createTool(<ITool>{
        id: LIZENZVERKAUFTOOLS.Zurueck, name: 'Zurück', icon: 'icon-navigate_left',
        cbExecute: () => this.previous(),
        cbDisabled: () => this.isLoading()
      }),
      this.toolbarService.createTool(<ITool>{
        id: LIZENZVERKAUFTOOLS.Abschicken, name: 'Abschicken', icon: 'icon-floppy_disk', align: 'right', wiggle: true, 
        cbExecute: () => this.next(),
        cbDisabled: () => !this.isValid() || this.isLoading()
      }),
    ];

    this.setTools(
      tools,
      TOOLBAR.MainTop
    );

    this.setStatus();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
  }

  detectChanges(cbChanging?: any): void {
    this.setStatus();

    super.detectChanges(cbChanging);
  }

  setStatus(): void {
    let status = { error: !this.isValid(), text: this.getInfo() };

    if (!isEqual(this.lastStatus, status)) {
      this.lastStatus = status;
      this.toolbarService.updateComponent(
        TOOLBAR.MainTop, { status: status }
      );
    }
  }

  isLoading(): boolean {
    return this.loading || this.lizenzService.isSaving();
  }

  getFormulare(): void {
    this.kundenform = this.dataService.getForm(TABLEALIASSE.Kundendaten);
    this.vertragsform = this.dataService.getForm(TABLENAMES._LIZENZVERKAUF);
    this.adminform = this.dataService.getForm(TABLEALIASSE.Kundenadmin);

    if (!isEmpty(this.kundenform) && !isEmpty(this.adminform)) {
      if (
        !this.kundenform.valid || 
        !(!this.needsAdminLizenz() || this.adminform.valid)
      ) {
        this.router.navigate([ '/lizenzverkauf/grunddaten' ]);
      }
    }

    this.prepareSelections();
    this.detectChanges();
  }

  getInfo(): string {
    if (!this.isValid()) {
      if (!this.lizenzService.hasLizenzen()) {
        return 'Wählen Sie mindestens einen Artikel aus';
      } else {
        return 'Es wird mindestens eine Kundenadministratoren-Lizenz benötigt';
      }
    } else {
      return 'Fahren Sie fort';
    }
  }

  previous(): void {
    this.router.navigate([ '/lizenzverkauf/grunddaten' ]);
  }

  next(): void {
    this.loading = true;

    this.lizenzService.save(
      this.kundenform, this.vertragsform, this.adminform, 
      () => {
        // Alles zurücksetzen
        this.kundenform.reset();
        this.vertragsform.reset();
        if (!!this.adminform) {
          this.adminform.reset();
        }
        this.lizenzService.resetLizenzen();
        this.loading = false;
        this.detectChanges();
        this.router.navigate([ '/lizenzverkauf/grunddaten' ]);
      },
      (error: string) => {
        this.loading = false;
        this.detectChanges();
      }
    );

    this.detectChanges();
    timer().subscribe(() => this.detectChanges());
  }

  isNew(): boolean {
    return (!isEmpty(this.kundenform) ? this.kundenform.isNew() : false);
  }

  needsAdminLizenz(): boolean {
    return this.isNew() || this.lizenzService.needsAdminLizenz();
  }

  isValid(): boolean {
    return (
      this.lizenzService.hasLizenzen() && 
      (!this.needsAdminLizenz() || this.lizenzService.hasLizenzart(5))
    );
  }

  isSelf(): boolean {
    return (
      this.kundenform.getFormValue('IDBildungszKd') === this.intedigiService.getBildungszentrum() && 
      this.kundenform.getFormValue('ID') === this.intedigiService.getKunde()
    );
  }

  isDemo(): boolean {
    return !!this.vertragsform.getFormValue('Demozugang');
  }

  getAzubiPaketSearchColumns(): any[] {
    let columns = [
      { 
        name: 'dataset', title: '', minWidth: 300, hidden: true, searchable: true, 
        cbOnread: (dataService: DataService, dataset: ILizenzVorlageExt) => { return dataset; } 
      },
      { 
        name: 'Lizenz', title: 'Lizenz', minWidth: 300, searchable: true, 
        cbOnread: (dataService: DataService, dataset: ILizenzVorlageExt) => { return dataset.Name; } 
      },
      { 
        name: 'Fachrichtung', title: 'Fachrichtung', minWidth: 150, searchable: true, 
        cbOnread: (dataService: DataService, dataset: ILizenzVorlageExt) => { 
          return (!isEmpty(dataset.fachrichtung) ? dataset.fachrichtung.Name + ' (' + dataset.fachrichtung.ID + ')' : '---'); 
        } 
      },
      { 
        name: 'Lizenzart', title: 'Lizenzart', minWidth: 90, searchable: true, 
        cbOnread: (dataService: DataService, dataset: ILizenzVorlageExt) => { 
          return dataService.getDatasetName(TABLENAMES.LIZENZART, dataset.IDLizenzart); 
        } 
      }
    ];

    if (this.lizenzService.isLocalErp() && !this.isSelf() && !this.isDemo()) {
      return columns.concat([
        { 
          name: 'Status', title: 'Status', minWidth: 90, searchable: true, 
          cbOnread: (dataService: DataService, dataset: ILizenzVorlageExt) => { 
            return (dataset.status === LIZENZSTATUS.Grundlizenzen ? 'Grundlizenz' : 'Verlängerung'); 
          } 
        },
        { 
          name: 'Artikelnr.', title: 'Artikelnr.', minWidth: 90, searchable: true, 
          cbOnread: (dataService: DataService, dataset: ILizenzVorlageExt) => { 
            return (dataset.status === LIZENZSTATUS.Grundlizenzen ? dataset.Artikelnummer : dataset.Verlaengerung); 
          } 
        }
      ]);
    } else {
      return columns;
    }
  }

  prepareSelections(): void {
    this.azubiPakete = this.lizenzService.getLizenzVorlagen((dataset: ILizenzVorlageExt) => {
      return (
        !this.lizenzService.hasLizenz(dataset) &&
        dataset.IDBereich === 'ausbildung' && 
        dataset.IDLizenzart === 1 &&
        (isValid(dataset.IDPaket) || isValid(dataset.IDPaketgroesse)) && 
        (
          !isEmpty(this.kundenform) &&
          (
            !isValid(dataset.IDKundentyp) || 
            (this.kundenform.getFormValue('IDKundentyp') === dataset.IDKundentyp && !dataset.Umkehren) ||
            (this.kundenform.getFormValue('IDKundentyp') !== dataset.IDKundentyp && dataset.Umkehren)
          ) && 
          (
            !isValid(dataset.IDKundentyp2) || 
            (this.kundenform.getFormValue('IDKundentyp2') === dataset.IDKundentyp2 && !dataset.Umkehren) ||
            (this.kundenform.getFormValue('IDKundentyp2') !== dataset.IDKundentyp2 && dataset.Umkehren)
          )
        )
      );
    });

    this.ausbilderPakete = this.lizenzService.getLizenzVorlagen((dataset: ILizenzVorlageExt) => {
      return (
        !this.lizenzService.hasLizenz(dataset) &&
        dataset.IDBereich === 'ausbildung' && 
        dataset.IDLizenzart === 2 &&
        (isValid(dataset.IDPaket) || isValid(dataset.IDPaketgroesse)) && 
        (
          !isEmpty(this.kundenform) &&
          (
            !isValid(dataset.IDKundentyp) || 
            (this.kundenform.getFormValue('IDKundentyp') === dataset.IDKundentyp && !dataset.Umkehren) ||
            (this.kundenform.getFormValue('IDKundentyp') !== dataset.IDKundentyp && dataset.Umkehren)
          ) && 
          (
            !isValid(dataset.IDKundentyp2) || 
            (this.kundenform.getFormValue('IDKundentyp2') === dataset.IDKundentyp2 && !dataset.Umkehren) ||
            (this.kundenform.getFormValue('IDKundentyp2') !== dataset.IDKundentyp2 && dataset.Umkehren)
          )
        )
      );
    });

    this.sonstigePakete = this.lizenzService.getLizenzVorlagen((dataset: ILizenzVorlageExt) => {
      return (
        !this.lizenzService.hasLizenz(dataset) &&
        dataset.IDBereich !== 'ausbildung' && 
        (isValid(dataset.IDPaket) || isValid(dataset.IDPaketgroesse)) && 
        (
          !isEmpty(this.kundenform) &&
          (
            !isValid(dataset.IDKundentyp) || 
            (this.kundenform.getFormValue('IDKundentyp') === dataset.IDKundentyp && !dataset.Umkehren) ||
            (this.kundenform.getFormValue('IDKundentyp') !== dataset.IDKundentyp && dataset.Umkehren)
          ) && 
          (
            !isValid(dataset.IDKundentyp2) || 
            (this.kundenform.getFormValue('IDKundentyp2') === dataset.IDKundentyp2 && !dataset.Umkehren) || 
            (this.kundenform.getFormValue('IDKundentyp2') !== dataset.IDKundentyp2 && dataset.Umkehren)
          )
        )
      );
    });

    this.lernangebote = this.lizenzService.getLizenzVorlagen((dataset: ILizenzVorlageExt) => {
      return (
        !this.lizenzService.hasLizenz(dataset) &&
        isValid(dataset.IDLernangebot) && 
        (
          !isEmpty(this.kundenform) &&
          (
            !isValid(dataset.IDKundentyp) || 
            (this.kundenform.getFormValue('IDKundentyp') === dataset.IDKundentyp && !dataset.Umkehren) || 
            (this.kundenform.getFormValue('IDKundentyp') !== dataset.IDKundentyp && dataset.Umkehren)
          ) && 
          (
            !isValid(dataset.IDKundentyp2) || 
            (this.kundenform.getFormValue('IDKundentyp2') === dataset.IDKundentyp2 && !dataset.Umkehren) ||
            (this.kundenform.getFormValue('IDKundentyp2') !== dataset.IDKundentyp2 && dataset.Umkehren)
          )
        )
      );
    });

    this.weitereLizenzen = this.lizenzService.getLizenzVorlagen((dataset: ILizenzVorlageExt) => {
      if (
        !this.lizenzService.hasLizenz(dataset) &&
        (
          !isEmpty(this.kundenform) &&
          (
            !isValid(dataset.IDKundentyp) || 
            (this.kundenform.getFormValue('IDKundentyp') === dataset.IDKundentyp && !dataset.Umkehren) || 
            (this.kundenform.getFormValue('IDKundentyp') !== dataset.IDKundentyp && dataset.Umkehren)
          ) && 
          (
            !isValid(dataset.IDKundentyp2) || 
            (this.kundenform.getFormValue('IDKundentyp2') === dataset.IDKundentyp2 && !dataset.Umkehren) || 
            (this.kundenform.getFormValue('IDKundentyp2') !== dataset.IDKundentyp2 && dataset.Umkehren)
          )
        )
      ) {
        let lizenzart: ILizenzart = <ILizenzart>this.dataService.getDataset(TABLENAMES.LIZENZART, dataset.IDLizenzart);
        if (!isEmpty(lizenzart) && (lizenzart.IDLizenztyp === Lizenztyp.Rolle || lizenzart.IDLizenztyp === Lizenztyp.Sonstiges)) {
          return true;
        }
      }
      return false;
    });
  }

  private select__(title: string, lizenzbereich: VKLIZENZBEREICH, rawdata: ILizenzVorlageExt[]) {
    this.popupService.open(
      CommonSearchComponent, 
      {
        columns: this.getAzubiPaketSearchColumns(),
        tablename: null,
        tabletitle: title,
        rawdata: rawdata
      },
      {
        onselect: (selected: any) => { 
          this.saveLizenz(selected.dataset, lizenzbereich);
        },
        oncancel: () => { 
          this.detectChanges();
        }
      }
    );
  }

  selectAzubiPaket(): void {
    this.select__('Azubi-Pakete', VKLIZENZBEREICH.azubi, this.azubiPakete);
  }

  selectAusbilderPaket(): void {
    this.select__('Ausbilder-Pakete', VKLIZENZBEREICH.ausbilder, this.ausbilderPakete);
  }

  selectPaket(): void {
    this.select__('Sonstige Pakete', VKLIZENZBEREICH.sonstige, this.sonstigePakete);
  }

  selectLernangebot(): void {
    this.select__('Lernangebote', VKLIZENZBEREICH.lernangebote, this.lernangebote);
  }

  selectWeitereLizenzen(): void {
    this.select__('Weitere Lizenzen', VKLIZENZBEREICH.weitere, this.weitereLizenzen);
  }

  hasLizenzverkaeufe(): boolean {
    return this.lizenzService.hasLizenzen();
  }

  hasAzubiPakete(): boolean {
    return this.lizenzService.hasLizenzen(VKLIZENZBEREICH.azubi);
  }

  hasAusbilderPakete(): boolean {
    return this.lizenzService.hasLizenzen(VKLIZENZBEREICH.ausbilder);
  }

  hasSonstigePakete(): boolean {
    return this.lizenzService.hasLizenzen(VKLIZENZBEREICH.sonstige);
  }

  hasLernangebote(): boolean {
    return this.lizenzService.hasLizenzen(VKLIZENZBEREICH.lernangebote);
  }

  hasWeitereLizenzen(): boolean {
    return this.lizenzService.hasLizenzen(VKLIZENZBEREICH.weitere);
  }

  getAzubiPakete(): ILizenzverkauf[] {
    return this.lizenzService.getLizenzen(VKLIZENZBEREICH.azubi);
  }

  getAusbilderPakete(): ILizenzverkauf[] {
    return this.lizenzService.getLizenzen(VKLIZENZBEREICH.ausbilder);
  }

  getSonstigePakete(): ILizenzverkauf[] {
    return this.lizenzService.getLizenzen(VKLIZENZBEREICH.sonstige);
  }

  getLernangebote(): ILizenzverkauf[] {
    return this.lizenzService.getLizenzen(VKLIZENZBEREICH.lernangebote);
  }

  getWeitereLizenzen(): ILizenzverkauf[] {
    return this.lizenzService.getLizenzen(VKLIZENZBEREICH.weitere);
  }

  getFlexStyle(): string {
    return '1 calc(' + (100 / this.lizenzService.countLizenzbereiche()) + '% - 10px)'; 
  }

  saveLizenz(extLizenzVorlage: ILizenzVorlageExt, lizenzbereich: VKLIZENZBEREICH) {
    extLizenzVorlage.IDLizenzart === 1
    if (extLizenzVorlage.IDLizenzart === 1 && !!parseInt(this.teilnehmer)) {
      this.lizenzService.addLizenz(extLizenzVorlage, lizenzbereich, parseInt(this.teilnehmer));
      this.prepareSelections();
      this.detectChanges();
    } else if (extLizenzVorlage.IDLizenzart === 2 && !!parseInt(this.ausbilder)) {
      this.lizenzService.addLizenz(extLizenzVorlage, lizenzbereich, parseInt(this.ausbilder));
      this.prepareSelections();
      this.detectChanges();
    } else {
      timer().subscribe(() => {
        this.popupService.open(
          LizenzPopupComponent, 
          {
            extLizenzVorlage: extLizenzVorlage,
            lizenzbereich: lizenzbereich,
            count: null,
            new: true
          },
          {
            onedit: (count: number) => { 
              if (!!count) {
                this.lizenzService.addLizenz(extLizenzVorlage, lizenzbereich, count);
              }
              this.prepareSelections();
              this.detectChanges();
            },
            oncancel: () => { 
              this.detectChanges();
            }
          }
        );
      });
    }
  }

  onLizenzSelect(event: ILizenzverkauf) {
    this.popupService.open(
      LizenzPopupComponent, 
      {
        extLizenzVorlage: event.extLizenzVorlage,
        lizenzbereich: event.lizenzbereich,
        count: event.anzahl
      },
      {
        onedit: (count: number) => { 
          if (!count) {
            this.lizenzService.removeLizenz(event.extLizenzVorlage);
          } else {
            event.anzahl = count;
          }
          this.prepareSelections();
          this.detectChanges();
        },
        oncancel: () => { 
          this.detectChanges();
        }
      }
    );
  }
}
