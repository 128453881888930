import { USERROLE_NONE } from '@bfeoldenburg/bfe-data-forms';
import { SCHNITTSTELLEN_FILTERSIDEBAR_ALIASSE } from '@bfeoldenburg/bfe-web-apps';
import { TABLENAMES, IDUSERROLE, IMenuItem, MENU_CHECK_METHOD, MENU_BATCH_METHOD, INTEDIGI_FILTERSIDEBAR_ALIASSE } from '@bfeoldenburg/intedigi-shared';

let allCommonRoles = [ IDUSERROLE.ADMIN, IDUSERROLE.DATA, IDUSERROLE.READONLY ];

export const menuItems = [
  <IMenuItem>{
    title: 'Login',
    link: '/login',
    role: USERROLE_NONE
  },
  <IMenuItem>{
    title: 'Dashboard',
    link: '/dashboard',
    role: allCommonRoles
  },
  <IMenuItem>{
    title: 'Archiv',
    link: '/archiv',
    role: allCommonRoles,
    checktable: TABLENAMES.TABLE_ARCHIVE,
    checkmethod: MENU_CHECK_METHOD.ARCHIVE_ACTIVE,
    batchmethod: MENU_BATCH_METHOD.ARCHIVE_COUNT,
    batchbgcolor: '#007ad9'
  },
  <IMenuItem>{
    title: 'System',
    writabletable: [ 
      TABLENAMES.SETTINGS, TABLENAMES.CRONJOB, TABLENAMES.USER_SETTINGS
    ],
    role: allCommonRoles,
    children: [
      <IMenuItem>{
        title: 'Einstellungen',
        link: '/einstellungen',
        form: TABLENAMES.SETTINGS,
        writabletable: TABLENAMES.SETTINGS  
      },
      <IMenuItem>{
        title: 'Cronjobs',
        link: '/cronjobs',
        writabletable: TABLENAMES.CRONJOB
      },
      <IMenuItem>{
        title: 'Tabellen-Infos',
        link: '/table-info',
        role: IDUSERROLE.ADMIN
      },
      <IMenuItem>{
        title: 'E-Mail-Vorlagen',
        link: '/email-vorlagen',
        writabletable: TABLENAMES.USER_SETTINGS
      }
    ]
  },
  <IMenuItem>{
    title: 'Stammdaten',
    checktable: [ 
      TABLENAMES.POSTLEITZAHL, TABLENAMES.BILDUNGSZENTRUM, TABLENAMES.IDENTITYPROVIDER, TABLENAMES.FACHRICHTUNG,
      TABLENAMES.KUNDENTYP, TABLENAMES.KUNDENTYP2, TABLENAMES.ROLLE, TABLENAMES.GRUPPE, TABLENAMES.LIZENZART, 
      TABLENAMES.INNUNG
    ],
    role: allCommonRoles,
    children: [
      <IMenuItem>{
        title: 'Postleitzahlen',
        link: '/stammdaten/postleitzahlen',
        form: TABLENAMES.POSTLEITZAHL,
        checktable: TABLENAMES.POSTLEITZAHL
      },
      <IMenuItem>{
        title: 'Identity-Provider',
        link: '/stammdaten/idps',
        checktable: TABLENAMES.IDENTITYPROVIDER
      },
      <IMenuItem>{
        title: 'Bildungszentren',
        link: '/stammdaten/bildungszentren',
        checktable: TABLENAMES.BILDUNGSZENTRUM
      },
      <IMenuItem>{
        title: 'Innungen',
        link: '/stammdaten/innungen',
        checktable: TABLENAMES.INNUNG,
        checkmethod: [ MENU_CHECK_METHOD.CHECK_DEVELOP_2024 ]
      },
      <IMenuItem>{
        title: 'Fachrichtungen',
        link: '/stammdaten/fachrichtungen',
        checktable: TABLENAMES.FACHRICHTUNG
      },
      <IMenuItem>{
        title: 'Kundentypen',
        link: '/stammdaten/kundentypen',
        checktable: TABLENAMES.KUNDENTYP
      },
      <IMenuItem>{
        title: 'Kundentypen 2',
        link: '/stammdaten/kundentypen2',
        checktable: TABLENAMES.KUNDENTYP2
      },
      <IMenuItem>{
        title: 'Rollen',
        link: '/stammdaten/rollen',
        checktable: TABLENAMES.ROLLE
      },
      <IMenuItem>{
        title: 'Benutzergruppen',
        link: '/stammdaten/gruppen',
        checktable: TABLENAMES.GRUPPE
      },
      <IMenuItem>{
        title: 'Lizenzarten',
        link: '/stammdaten/lizenzarten',
        checktable: TABLENAMES.LIZENZART
      }
    ]
  },
  <IMenuItem>{
    title: 'Lernangebote',
    checktable: [ 
      TABLENAMES.BEREICH, TABLENAMES.LERNANGEBOT, TABLENAMES.PAKETGROESSE, TABLENAMES.PAKET, TABLENAMES.KURS, 
      TABLENAMES.LIZENZ_VORLAGE 
    ],
    role: allCommonRoles,
    children: [
      <IMenuItem>{
        title: 'Lernbereiche',
        link: '/lernangebote/bereiche',
        checktable: TABLENAMES.BEREICH
      },
      <IMenuItem>{
        title: 'Lernangebote',
        link: '/lernangebote/lernangebote',
        checktable: TABLENAMES.LERNANGEBOT
      },
      <IMenuItem>{
        title: 'Lernpaketgrößen',
        link: '/lernangebote/paketgroessen',
        checktable: TABLENAMES.PAKETGROESSE
      },
      <IMenuItem>{
        title: 'Lernpakete',
        link: '/lernangebote/pakete',
        checktable: TABLENAMES.PAKET
      },
      <IMenuItem>{
        title: 'Kurse',
        link: '/lernangebote/kurse',
        form: TABLENAMES.KURS,
        checktable: TABLENAMES.KURS
      },
      <IMenuItem>{
        title: 'Lizenz-Vorlagen',
        link: '/lernangebote/lizenz-vorlagen',
        form: TABLENAMES.LIZENZ_VORLAGE,
        checktable: TABLENAMES.LIZENZ_VORLAGE
      }
    ]
  },
  <IMenuItem>{
    title: 'Kundendaten',
    checktable: [ TABLENAMES.KUNDE, TABLENAMES.BENUTZER, TABLENAMES.BUCHUNG ],
    role: allCommonRoles,
    open: true,
    children: [
      <IMenuItem>{
        title: 'Kunden',
        link: '/kunden/kunden',
        form: TABLENAMES.KUNDE,
        checktable: TABLENAMES.KUNDE
      },
      <IMenuItem>{
        title: 'Lizenzverkäufe',
        link: '/kunden/lizenzverkaeufe/basisdaten',
        form: TABLENAMES._LIZENZVERKAEUFE,
        checktable: [ TABLENAMES.BUCHUNG, TABLENAMES.KUNDE ]
      },
      <IMenuItem>{
        title: 'Lizenzen',
        link: '/kunden/lizenzen',
        form: TABLENAMES.BUCHUNG,
        checktable: TABLENAMES.BUCHUNG
      },
      <IMenuItem>{
        title: 'Benutzer',
        link: '/kunden/benutzer',
        form: TABLENAMES.BENUTZER,
        checktable: TABLENAMES.BENUTZER
      },
      <IMenuItem>{
        title: 'Lerngruppen',
        link: '/kunden/lerngruppen',
        form: TABLENAMES.KURSGRUPPE,
        checktable: TABLENAMES.KURSGRUPPE
      },
      <IMenuItem>{
        title: 'Unbestätigte E-Mailadressen',
        link: '/kunden/email-unbestaetigt',
        checktable: TABLENAMES.BENUTZER,
        batchmethod: MENU_BATCH_METHOD.EMAILS_UNCONFIRMED,
        //batchbgcolor: '#007ad9'
      },
      <IMenuItem>{
        title: 'Unvollst. Registrierungen',
        link: '/kunden/registrierung-unvollstaendig',
        checktable: TABLENAMES.BENUTZER,
        batchmethod: MENU_BATCH_METHOD.REGS_INCOMPLETE,
      }
    ]
  },
  <IMenuItem>{
    title: 'Workflows',
    checktable: [ TABLENAMES.BENUTZER, TABLENAMES.KUNDE, TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE ],
    role: allCommonRoles,
    checkmethod: [ MENU_CHECK_METHOD.INSTANCE, MENU_CHECK_METHOD.ERP ],
    open: true,
    children: [
      <IMenuItem>{
        title: 'Lizenzverkauf',
        link: '/lizenzverkauf/grunddaten',
        checktable: TABLENAMES.KUNDE,
        role: [ IDUSERROLE.ADMIN, IDUSERROLE.DATA ],
        checkmethod: MENU_CHECK_METHOD.INSTANCE
      },
      <IMenuItem>{
        title: 'Benutzererfassung',
        link: '/benutzererfassung',
        checktable: TABLENAMES.BENUTZER,
        role: [ IDUSERROLE.ADMIN, IDUSERROLE.DATA ]
      },
      <IMenuItem>{
        title: 'Wiedervorlage',
        link: '/wiedervorlage',
        checktable: TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE,
        role: allCommonRoles,
        checkmethod: MENU_CHECK_METHOD.ERP,
        batchmethod: MENU_BATCH_METHOD.ERP_WV
      }
    ]
  },
  <IMenuItem>{
    title: 'Schnittstellen',
    checktable: [ TABLENAMES.ERP_ARTIKELNUMMER, TABLENAMES.SCHNITTSTELLE_FEHLER, TABLENAMES.LDAP ],
    role: allCommonRoles,
    open: false,
    children: [
      <IMenuItem>{
        title: 'ERP-Artikel',
        link: '/erp-artikel',
        checktable: TABLENAMES.ERP_ARTIKELNUMMER,
        checkmethod: MENU_CHECK_METHOD.ERP_GLOBAL 
      },
      <IMenuItem>{
        title: 'Schnittstellen',
        link: '/schnittstellen/schnittstellen',
        highlight: '/schnittstellen',
        checktable: TABLENAMES.SCHNITTSTELLE_FEHLER,
        batchmethod: MENU_BATCH_METHOD.INTERFACE_ERRORS,
        form: SCHNITTSTELLEN_FILTERSIDEBAR_ALIASSE.__FILTER_SCHNITTSTELLEN_SCHNITTSTELLEN
      },
      <IMenuItem>{
        title: 'LDAP-Daten',
        link: '/ldap',
        checktable: TABLENAMES.LDAP,
        //batchmethod: MENU_BATCH_METHODS.LDAP_COUNT
      }
    ]
  },
  <IMenuItem>{
    title: 'ERP-Daten',
    checktable: [ 
      TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT, TABLENAMES.ERP_BENUTZER, TABLENAMES.BUCHUNG, TABLENAMES.KUNDE 
    ],
    open: false,
    role: allCommonRoles,
    checkmethod: [ MENU_CHECK_METHOD.ERP, MENU_CHECK_METHOD.ERP_GLOBAL ],
    children: [
      <IMenuItem>{
        title: 'Ignorierte ERP-Artikel',
        link: '/erp-artikel-ignoriert',
        checktable: TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT,
        checkmethod: MENU_CHECK_METHOD.ERP_GLOBAL,
        batchmethod: MENU_BATCH_METHOD.ERP_IGNORE
      },
      <IMenuItem>{
        title: 'ERP-Benutzer',
        link: '/erp-benutzer',
        checktable: TABLENAMES.ERP_BENUTZER,
        checkmethod: MENU_CHECK_METHOD.ERP
      },
      <IMenuItem>{
        title: 'ERP-Veranstaltungen',
        link: '/erp-veranstaltungen',
        form: TABLENAMES._ERPVERANSTALTUNG,
        checktable: [ TABLENAMES.BUCHUNG, TABLENAMES.KUNDE ],
        checkmethod: MENU_CHECK_METHOD.ERP
      }
    ]
  },
  <IMenuItem>{
    title: 'Statistiken / Listen',
    checktable: [ TABLENAMES.KUNDE ],
    open: false,
    role: allCommonRoles,
    children: [
      <IMenuItem>{
        title: 'Kundenliste',
        link: '/kunden/kundenliste',
        checktable: TABLENAMES.KUNDE,
        form: INTEDIGI_FILTERSIDEBAR_ALIASSE.__FILTER_KUNDEN_LIST
      },
      <IMenuItem>{
        title: 'Benutzerliste',
        link: '/kunden/benutzerliste',
        checktable: TABLENAMES.BENUTZER,
        form: INTEDIGI_FILTERSIDEBAR_ALIASSE.__FILTER_BENUTZER_LIST
      },
      <IMenuItem>{
        title: 'Lizenzliste',
        link: '/kunden/lizenzliste',
        checktable: TABLENAMES.BUCHUNG,
        form: INTEDIGI_FILTERSIDEBAR_ALIASSE.__FILTER_BUCHUNGEN_LIST,
        checkmethod: [ MENU_CHECK_METHOD.CHECK_DEVELOP_2024 ]
      }
    ]
  }
]