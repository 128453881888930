<bfe-common-list
  #commonList
  tablename="SchnittstelleWiedervorlage" 
  tabletitle="Wiedervorlage" 
  [buttonTexts]="{ 'history': 'Änderungs-Historie' }"
  [(selectedRow)]="selectedRow"
  [searchText]="searchText"
  searchColumn="Search"
  (window:resize)="onResize()"
>
  <ng-template #filter>
    <div class="filter">
      <div class="search">
        <div class="icon icon-magnifying_glass"></div>
        <input ExtendControl class="searchtext" pInputText type="text" [(ngModel)]="searchText" (input)="onSearch()" >
      </div>
    </div>
  </ng-template>
  <ng-template #datalist let-data="data" let-width="width">
    <div class="data-table" id="data-table">
      <p-table styleClass="p-datatable-striped" [value]="data" selectionMode="single" dataKey="_uuid" [(selection)]="selectedRow" [loading]="isLoading()" (onRowSelect)="onSelect($event)">
        <ng-template pTemplate="header">
          <tr *ngIf="getWidth() > 575">
            <th *ngFor="let column of visibleColumns" [style.width]="(column.minWidth > 0 && !column.fullWidth ? column.minWidth + 'px' : '100%')" [style.textAlign]="getColumnAlign(column)">
              {{ column.title }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dataset>
          <tr *ngIf="getWidth() <= 575" [pSelectableRow]="dataset">
            <td>
              <table class="stacked">
                <tr *ngFor="let column of columns">
                  <td>{{ column.title }}</td>
                  <td style="overflow: hidden; text-overflow: ellipsis; ">
                    <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
                    <table-icon 
                      *ngIf="column.isIcon" 
                      [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
                      [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
                    ></table-icon>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr *ngIf="getWidth() > 575" [pSelectableRow]="dataset">
            <td *ngFor="let column of visibleColumns" [style.width]="(column.minWidth > 0 && !column.fullWidth ? column.minWidth + 'px' : '100%')" [style.textAlign]="getColumnAlign(column)">
              <span [style.color]="(!!column.cbColor ? column.cbColor(dataset) : null)" [style.fontWeight]="(!!column.bold ? 'bold' : 'normal')" *ngIf="!column.isIcon">{{ (!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name]) }}</span>
              <table-icon 
                *ngIf="column.isIcon" 
                [icon]="(!!column.cbOnShow ? column.cbOnShow(dataset) : dataset[column.name])" 
                [color]="(!!column.cbColor ? column.cbColor(dataset) : null)"
              ></table-icon>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="visibleColumns.length">
              Es konnten keine Datensätze ermittelt werden
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</bfe-common-list>
