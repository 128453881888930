import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { timer } from 'rxjs';

import { isValid, NotifyService, PopupService, AppSettings } from '@bfeoldenburg/bfe-shared';
import { 
  BfeForm, CommonFeaturesComponent, CommonSearchComponent, DataService, IListColumn, ToolbarService 
} from '@bfeoldenburg/bfe-data-forms';

import { 
  IBenutzer, IBenutzerAustausch, IBuchungBenutzer, ISettings, IGruppe, IKundeAustausch, IKundeBenutzer, IKursgruppeBenutzer, IntedigiService, TABLENAMES 
} from '@bfeoldenburg/intedigi-shared';


@Component({
  selector: 'wiedervorlage-sub-benutzer',
  templateUrl: './wiedervorlage-sub-benutzer.component.html',
  styleUrls: ['./wiedervorlage-sub-benutzer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WiedervorlageSubPositionenComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() form: BfeForm;

  @Input() benutzer: any[] = [];

  @Input() settings: ISettings;

  @Input() width: number;

  @Input() gruppenIDs: number[] = [];

  @Input() rollenIDs: string[] = [];
  
  @Input() bereichIDs: string[] = [];

  @Input() loading: boolean = false;

  @Input() writable: boolean = false;

  @Output() onchange: EventEmitter<any[]> = new EventEmitter<any[]>();

  private gruppenRolle: { [key: number]: string } = {};
  private bzBenutzerIDs: { [key: string]: number[] } = {};

  public selPosition: any = null;

  public columns: IListColumn[] = [];
  public visibleColumns: IListColumn[] = [];

  constructor(
    dataService: DataService,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService,
    private popupService: PopupService,
    private notifyService: NotifyService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings); 

    this.columns = [
      { name: 'ERPID', title: 'ERP-ID', minWidth: 100, cbOnShow: (dataset: any) => { return dataset.ERPBenutzer.ERPID; }},
      { name: 'ERPName', title: 'ERP-Name', minWidth: 250, cbOnShow: (dataset: any) => { return dataset.ERPBenutzer.Name + ', ' + dataset.ERPBenutzer.Vorname; }},
      { name: 'ERPEmail', title: 'ERP-E-Mail', minWidth: 200, cbOnShow: (dataset: any) => { return dataset.ERPBenutzer.Email; }},
      { name: 'InteDigiName', title: 'InteDigi-Name', minWidth: 250, cbOnShow: (dataset: any) => { 
        if ('Selected' in dataset && isValid(dataset.Selected.ID)) {
          return dataset.Selected.Nachname + ', ' + dataset.Selected.Vorname;
        } else if ('Benutzer' in dataset && isValid(dataset.Benutzer.ID)) {
          return dataset.Benutzer.Nachname + ', ' + dataset.Benutzer.Vorname;
        } else {
          return '---';
        }
      }},
      { name: 'InteDigiName', title: 'InteDigi-E-Mail', minWidth: 200, cbOnShow: (dataset: any) => { 
        if ('Selected' in dataset && isValid(dataset.Selected.Email)) {
          return dataset.Selected.Email; 
        } else if ('Benutzer' in dataset && isValid(dataset.Benutzer.Email)) {
          return dataset.Benutzer.Email; 
        } else {
          return '---';
        }
      }},
      { name: 'InteDigiFachrichtung', title: 'InteDigi-Fachrichtung', minWidth: 200, cbOnShow: (dataset: any) => { 
        if ('Selected' in dataset && isValid(dataset.Selected.IDFachrichtung)) {
          return dataset.Selected.IDFachrichtung; 
        } else if ('Benutzer' in dataset && isValid(dataset.Benutzer.IDFachrichtung)) {
          return dataset.Benutzer.IDFachrichtung; 
        } else {
          return '---';
        }
      }},
      { name: 'InteDigiGruppe', title: 'InteDigi-Gruppe', minWidth: 180, cbOnShow: (dataset: any) => { 
        if ('Selected' in dataset && isValid(dataset.Selected.ID)) {
          return this.dataService.getDatasetName(TABLENAMES.GRUPPE, dataset.Selected.IDGruppe); 
        } else if ('Benutzer' in dataset && isValid(dataset.Benutzer.ID)) {
          return this.dataService.getDatasetName(TABLENAMES.GRUPPE, dataset.Benutzer.IDGruppe); 
        } else {
          return '---';
        }
      }}
    ];
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => this.getFKeyData()));

    this.calcTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.calcTable();
    this.detectChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onResize(): void {
    this.calcTable();
    super.onResize();
  }

  isWritable(): boolean {
    return this.writable;
  }

  getColumnAlign(column: any): string {
    return (!!column.align && !!column.align.length ? column.align : 'left');
  }

  calcTable(): void {
    if (this.width > 0) {
      this.visibleColumns = [];
      let width = 40;
      let alwaysVisible = [];
      let i = 0;
      for (i; i < this.columns.length; i++) {
        if (!!this.columns[i].alwaysVisible) {
          alwaysVisible.push(this.columns[i]);
          width += this.columns[i].minWidth;
        }
      }
      i = 0;
      for (i; i < this.columns.length; i++) {
        if (this.columns[i].minWidth + width <= this.width) {
          this.visibleColumns.push(this.columns[i]);
          if (!!this.columns[i].alwaysVisible) {
            alwaysVisible.shift();
          }
        } else {
          break;
        }
        width += this.columns[i].minWidth;
      }
      if (!!alwaysVisible.length) {
        this.visibleColumns = this.visibleColumns.concat(alwaysVisible);
      }
    } else {
      setTimeout(() => this.calcTable(), 10);
    }

    this.detectChanges();
  }

  prepareGruppen(data: IGruppe[]) {
    this.gruppenRolle = data.reduce((result: { [key: number]: string }, current: IGruppe) => {
      result[current.ID] = current.IDRolle;
      return result;
    }, <{ [key: number]: string }>{});
  }
  
  prepareBenutzer() {
    this.bzBenutzerIDs = (<IKundeBenutzer[]>this.dataService.getData(
      TABLENAMES.KUNDE_BENUTZER, 
      (item: IKundeBenutzer) => item.IDBildungszKd === this.settings.IDBildungszentrum && item.IDKunde === this.settings.IDKunde
    ))
    .reduce((result: { [key: string]: number[] }, current: IKundeBenutzer) => {
      if (!(current.IDBildungszentrum in result)) {
        result[current.IDBildungszentrum] = [];
      }
      result[current.IDBildungszentrum].push(current.IDBenutzer);
      return result;
    }, <{ [key: string]: number[] }>{});

    this.bzBenutzerIDs = (<IBuchungBenutzer[]>this.dataService.getData(
      TABLENAMES.BUCHUNG_BENUTZER, 
      (item: IBuchungBenutzer) => item.IDBildungszKd === this.settings.IDBildungszentrum && item.IDKunde === this.settings.IDKunde
    ))
    .reduce((result: { [key: string]: number[] }, current: IBuchungBenutzer) => {
      if (!(current.IDBildungszentrum in result)) {
        result[current.IDBildungszentrum] = [];
      }
      result[current.IDBildungszentrum].push(current.IDBenutzer);
      return result;
    }, this.bzBenutzerIDs);

    this.bzBenutzerIDs = (<IKursgruppeBenutzer[]>this.dataService.getData(
      TABLENAMES.KURSGRUPPEBENUTZER, 
      (item: IKursgruppeBenutzer) => item.IDBildungszKd === this.settings.IDBildungszentrum && item.IDKunde === this.settings.IDKunde
    ))
    .reduce((result: { [key: string]: number[] }, current: IKursgruppeBenutzer) => {
      if (!(current.IDBildungszentrum in result)) {
        result[current.IDBildungszentrum] = [];
      }
      result[current.IDBildungszentrum].push(current.IDBenutzer);
      return result;
    }, this.bzBenutzerIDs);
  }
  
  getFKeyData() {
    this.addDataSubscription(TABLENAMES.BENUTZER, () => {
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.GRUPPE, (data: IGruppe[]) => {
      this.prepareGruppen(data);
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.KUNDE_BENUTZER, () => {
      this.prepareBenutzer();
      this.detectChanges();
    });
    
    this.addDataSubscription(TABLENAMES.BUCHUNG_BENUTZER, () => {
      this.prepareBenutzer();
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.KURSGRUPPEBENUTZER, () => {
      this.prepareBenutzer();
      this.detectChanges();
    });

    this.prepareGruppen(<IGruppe[]>this.dataService.getData(TABLENAMES.GRUPPE));
    this.prepareBenutzer();
  }

  getBackgroundColor(dataset: any): string {
    return (dataset.Fehler && (!this.selPosition || this.selPosition.ERPBenutzer.ERPID !== dataset.ERPBenutzer.ERPID) ? '#ffcabc' : null)
  }

  /** Das Popup zum Bearbeiten öffnen */
  onBenutzerSelect() {
    if (!isValid(this.form.getFormValue('IDLizenzVorlage'))) {
      this.notifyService.error('Wählen Sie bitte zuerst eine gültige Lizenz-Vorlage');
      timer().subscribe(() => { this.selPosition = null; this.detectChanges() });
      return;
    }

    if (!('Benutzer' in this.selPosition.Backup)) {
      this.popupService.open(
        CommonSearchComponent, 
        {
          columns: this.dataService.getSearchColumns(TABLENAMES.BENUTZERAUSTAUSCH).concat({ 
            name: 'Kunden', title: 'Kunden', minWidth: 200, searchable: true, 
            cbOnread: (dataService: DataService, dataset: any) => { return dataset.Kunden; } 
          }),
          tablename: TABLENAMES.BENUTZERAUSTAUSCH,
          tabletitle: 'Benutzer',
          minWidth: 1600,
          cbFilter: (dataset: IBenutzerAustausch) => {
            return (
              dataset.IDBildungszentrum in this.bzBenutzerIDs && this.bzBenutzerIDs[dataset.IDBildungszentrum].indexOf(dataset.ID) > -1 && 
              this.gruppenIDs.indexOf(dataset.IDGruppe) > -1 && this.rollenIDs.indexOf(this.gruppenRolle[dataset.IDGruppe]) > -1 && 
              (
                !isValid(dataset.IDFachrichtung) || !isValid(this.form.getFormValue('OBJLizenzVorlage').IDFachrichtung) || 
                this.form.getFormValue('OBJLizenzVorlage').IDFachrichtung === dataset.IDFachrichtung
              )
            );
          },
          cbManipulate: (data: any[]) => {
            data = data.reduce((result: any[], current: IBenutzerAustausch) => {
              let dataset__: any = <any>current;
              dataset__.Kunden = this.dataService.getData(
                TABLENAMES.KUNDE_BENUTZER, (item: IKundeBenutzer) => { 
                  return item.IDBildungszentrum === current.IDBildungszentrum && item.IDBenutzer === current.ID; 
                }
              ).
              reduce((result: string, current: any) => {
                let kunde: IKundeAustausch = this.dataService.getDataset(TABLENAMES.KUNDEAUSTAUSCH, [ current.IDKunde, current.IDBildungszKd ]);
                result += (!!result.length ? ', ' : '') + kunde.Name;
                return result;
              }, <string>'');
              return result.concat(dataset__);
            }, <any[]>[]);
          }
        },
        {
          onselect: (dataset: any) => { 
            let benutzer: IBenutzer = null;
            if (this.dataService.hasDataset(TABLENAMES.BENUTZER, [ dataset.ID, dataset.IDBildungszentrum ])) {
              benutzer = this.dataService.getDataset(TABLENAMES.BENUTZER, [ dataset.ID, dataset.IDBildungszentrum ]);
            } else {
              benutzer = <IBenutzer>this.dataService.getDataset(TABLENAMES.BENUTZERAUSTAUSCH, [ dataset.ID, dataset.IDBildungszentrum ]);
            }

            this.selPosition.Benutzer = benutzer; 
            this.selPosition.ERPBenutzer.IDBildungszentrum = benutzer.IDBildungszentrum; 
            this.selPosition.ERPBenutzer.IDBenutzer = benutzer.ID; 

            this.selPosition = null; 

            this.detectChanges(); 
            this.onchange.emit(this.benutzer);
          },
          oncancel: () => { 
            this.selPosition = null; 
            this.detectChanges(); 
          }
        }
      );
    }
  }
}
