<div class="dashboard" id="dashboard" (window:resize)="onResize()">
  <div class="benutzer">
    <div class="item" [class.small]="getWidth() < 450" [class.middle]="getWidth() >= 450 && getWidth() < 900" *ngFor="let benutzer of benutzerlist; let i = index" [ngStyle]="{ 'background-image': 'linear-gradient(#fff -200%, ' + benutzer.color + ' 100%)' }">
      <div [class]="'icon ' + benutzer.icon" [style.marginTop]="(benutzer.iconMarginTop ? benutzer.iconMarginTop : '0px')"></div>
      <div class="text">{{ benutzer.title }}</div>
      <div class="count">{{ benutzer.count }}</div>
    </div>
  </div>
  <!--<div class="block hinweise" [class.middle]="getWidth() < 900">
    <div class="title">Hinweise</div>
    <div class="items">
      <div class="item" *ngFor="let hinweis of hinweise; let i = index" [style.backgroundColor]="(i % 2 ? '#f9f9f9' : '#fff')">
        <div class="text">{{ hinweis.title }}</div><div class="count">{{ hinweis.count }}</div>
      </div>
    </div>
  </div>-->
  <div class="block statistik" [class.middle]="getWidth() < 900">
    <div class="title">Benutzer-Statistik</div>
    <div class="statistik-inner">
      <p-chart type="line" [data]="benutzerStats" height="400px" [options]="{ animation: { duration: 0 } }"></p-chart>
    </div>
  </div>
  <div class="block statistik" [class.middle]="getWidth() < 900">
    <div class="title">Paket-Statistik</div>
    <div class="statistik-inner">
      <p-chart type="pie" [data]="paketStats" height="400px" [options]="{ animation: { duration: 0 } }"></p-chart>
    </div>
  </div>
  <div class="block temp" [class.middle]="getWidth() < 900"></div>
</div>