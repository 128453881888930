import { 
  Component, OnInit, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, ElementRef, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, AfterViewChecked, Input
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { timer } from 'rxjs';

import { AuthenticationService, isEmpty, PopupService, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, BfeForm, DataService, ExtendedFormComponent, IStandardAuswahl } from '@bfeoldenburg/bfe-data-forms';

import { IntedigiService } from '@bfeoldenburg/intedigi-shared';
import { TABLENAMES } from '@bfeoldenburg/intedigi-shared';

enum SUBFORMS{
  kundenAnsprechpartner = 0
}


@Component({
  selector: 'sub-vertragsdaten',
  templateUrl: './sub-vertragsdaten.component.html',
  styleUrls: ['./sub-vertragsdaten.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
        state('void', style({
            transform: 'translateX(-10%)',
            opacity: 0
        })),
        state('active', style({
            transform: 'translateX(0)',
            opacity: 1
        })),
        transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubVertragsdatenComponent extends ExtendedFormComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {
  @Input() kundenForm: BfeForm;

  @Input() wiedervorlage: boolean = false;
  /** Es findet gerade eine Datenbank-Operation statt */
  @Input() loading: boolean = false;

  @Output() onforminit = new EventEmitter<BfeForm>(); 

  private ready: boolean;

  public uebsitems: IStandardAuswahl[] = [
    { ID: 1, Name: 'Nicht relevant' },
    { ID: 2, Name: 'mit ÜBS' },
    { ID: 3, Name: 'ohne ÜBS' }
  ];
  public lizenzStati: IStandardAuswahl[] = [
    { ID: 1, Name: 'Alle' },
    { ID: 2, Name: 'Grundlizenzen' },
    { ID: 3, Name: 'Verlängerungen' },
  ];

  constructor(
    elementRef: ElementRef,
    renderer2: Renderer2,
    dataService: DataService,
    popupService: PopupService,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    route: ActivatedRoute,
    router: Router,
    private intedigiService: IntedigiService,
    private authService: AuthenticationService
  ) { 
    super(dataService, popupService, appSettings, elementRef, renderer2, cdRef, toolbarService, route, router);

    this.manuallyDataset = true;
    this.nofocus = true;

    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => { this.onDataChange(); }));
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngAfterViewChecked() {
    timer(500).subscribe(() => {
      this.ready = true;
      this.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['wiedervorlage']) {
      this.tablename = (this.wiedervorlage ? TABLENAMES._WVLIZENZVERKAUF : TABLENAMES._LIZENZVERKAUF);
      this.tabletitle = 'Vertragsdaten';
      this.prepareForm();
    }

    super.ngOnChanges(changes);

    this.onDataChange();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (!isEmpty(this.form)) {
      this.form.resetCallbackReadonly('vertragsdaten');
      this.form.resetCallbackRequired('vertragsdaten');
    }
  }

  detectChanges(): void {
    super.detectChanges();
  }

  onResize() {
    super.onResize();
  }

  cbIsReadonly(field: string): boolean {
    switch (field) {
      case 'Demozugang': {
        return this.isSelf();
      }
      case 'UeBS': {
        return !this.isKundeBetrieb() || this.wiedervorlage || !this.isLokalErp() || this.isDemoZugang() || this.isSelf();
      }
      case 'LizenzStatus': {
        return this.wiedervorlage || this.isDemoZugang() || !this.isLokalErp() || this.isSelf();
      }
      case 'RechnungErzeugen': {
        return !this.isLokalErp();
      }
      case 'ERPNummer': {
        return true;
      }
      case 'Auftragsdatum': {
        return true;
      }
    }
    return false;
  }

  cbIsRequired(field: string): boolean {
    switch (field) {
      case 'UeBS': {
        return this.isKundeBetrieb() && !this.wiedervorlage && this.isLokalErp() && !this.isDemoZugang() && !this.isSelf();
      }
      case 'LizenzStatus': {
        return !this.wiedervorlage && !this.isDemoZugang() && this.isLokalErp() && !this.isSelf();
      }
    }
    return false;
  }

  /*isReady(): boolean {
    return super.isReady() && this.ready;
  }*/

  onFormInit() {
    this.onDataSet();

    this.onforminit.emit(this.form);

    this.form.setCallbackReadonly('vertragsdaten', (field: string) => this.cbIsReadonly(field));
    this.form.setCallbackRequired('vertragsdaten', (field: string) => this.cbIsRequired(field));
  }

  onDataSet() {
    this.setUeBSItem(this.form.getFormValue('UeBS'));

    /*if (!this.form.getFormValue('LizenzStatus')) {
      this.form.setFormValue('LizenzStatus', 1);
    }*/
    this.setLizenzStatus(this.form.getFormValue('LizenzStatus'));

    this.onDataChange();
  }

  onDataGet() {
    if (this.wiedervorlage) {
      this.form.setFormValue('LizenzStatus', null);
      this.form.setFormValue('UeBS', null);
      this.form.setFormValue('RechnungErzeugen', false);
    }

    this.setUeBSItem();
    this.setLizenzStatus();
  }

  onDataReset(): void {
    this.onDataSet();
  }

  getYearRange(): string {
    return this.intedigiService.getYearRange();
  }

  isLokal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }

  isLokalErp(): boolean {
    return this.intedigiService.isInterfaceLocal() && this.intedigiService.isErpInterfaceActive();
  }

  reset(): void {
    // Formular zurücksetzen
    this.form.reset();
    // onDataReset-Hook (CommonFormComponent) ausführen
    this.onDataReset();
  }

  onVertragsbeginnChange(): void {
    this.onDataChange();
  }

  onRechnungErzeugenChange(): void {
    this.onDataChange();
  }

  setRechnungErzeugen(value?: boolean) {
    this.form.setFormValue('RechnungErzeugen', value);
    this.onRechnungErzeugenChange();
  }

  setUeBSItem(value?: any) {
    this.setFormObjectStandardAuswahl('OBJUeBS', 'UeBS', this.uebsitems, value);
  }

  resetUeBSItem(): void {
    this.form.setFormValue('OBJUeBS', null);
    this.form.setFormValue('UeBS', null);
  }

  onUeBSItemChange() {
    this.setUeBSItem();
    this.onDataChange();
  }

  setLizenzStatus(value?: any) {
    this.setFormObjectStandardAuswahl('OBJLizenzStatus', 'LizenzStatus', this.lizenzStati, value);
  }

  resetLizenzStatus(): void {
    this.form.setFormValue('OBJLizenzStatus', null);
    this.form.setFormValue('LizenzStatus', null);
  }

  onLizenzStatusChange() {
    this.setLizenzStatus();
    this.onDataChange();
  }

  isKundeBetrieb(): boolean {
    return !isEmpty(this.kundenForm) && this.intedigiService.isKundeBetrieb(this.kundenForm.getValues());
  }

  onDemozugangChange(): void {
    this.onDataChange();
  }

  isDemoZugang(): boolean {
    return !isEmpty(this.form) && !!this.form.getFormValue('Demozugang');
  }

  isSelf(): boolean {
    return (
      !isEmpty(this.kundenForm) && this.kundenForm.getFormValue('IDBildungszKd') === this.intedigiService.getBildungszentrum() && 
      this.kundenForm.getFormValue('ID') === this.intedigiService.getKunde()
    );
  }
}
