<div class="data-popup popup-outer" id="data-popup" (window:resize)="onResize()"> 
  <bfe-popup-title title="Lizenz-Anzahl" (onclose)="cancel()"></bfe-popup-title>
  <div class="data-form popup-inner">
    <div class="form-row">
      <label class="form-label">{{ extLizenzVorlage?.Name }}</label>
    </div>
    <div class="form-row" *ngIf="hasFachrichtung()">
      <label class="form-label">{{ getFachrichtung() }}</label>
    </div>
    <div class="form-row" style="margin-top: 10px;">
      <label class="form-label">Anzahl</label>
      <input ExtendControl class="count" id="count" pInputText type="text" [(ngModel)]="count" (keydown.enter)="save()">
    </div>
    <div class="form-row form-buttons bottom">
      <button pButton type="button" label="Speichern" (click)="save()"></button>
      <button pButton type="button" label="Abbrechen" (click)="cancel()"></button>
      <div style="flex: 1;"></div>
      <button pButton type="button" label="Entfernen" class="p-button-danger" [disabled]="isNew()" (click)="delete()"></button>
    </div>
  </div>  
</div> 
