<div *ngIf="isReady()">
  <benutzer-form-main
    [tablename]="tablename"
    [form]="form"
    [data]="data"
    [kunden]="kunden"
    [rollenIDs]="rollenIDs"
    [writable]="isWritable()"
    [loading]="isLoading()"
    [workflow]="true"
  ></benutzer-form-main>
</div>