<div class="schnellerfassung" (window:resize)="onResize()" [style.minHeight]="getContentHeight()">
  <div class="form-columns">
    <div class="form-column">
      <form [formGroup]="form" autocomplete="off">
        <div class="form-row">
          <label class="form-label">Kunde</label>
          <div class="form-row kunde-box">
            <input ExtendControl formControlName="Kundenname" class="kundenname" pInputText type="text" [required]="!isOptional('IDGruppe')" [readonly]="true">
            <button class="kunde-button important" pButton type="button" icon="icon-magnifying_glass" title="Kunden auswählen" [class.isneeded]="!isKundeSelected()" [disabled]="isReadonly('IDKunde')" (click)="selectKunde()"></button>
          </div>
        </div>
        <div class="form-row">
          <label class="form-label">Gruppe</label>
          <p-dropdown ExtendControl class="gruppe" [options]="gruppen" [required]="!isOptional('IDGruppe')" [readonly]="isReadonly('IDGruppe')" [showClear]="isOptional('IDGruppe') && !isReadonly('IDGruppe')" formControlName="OBJGruppe" fieldName="IDGruppe" placeholder="Gruppe auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onGruppeSelect()"></p-dropdown>
        </div>
      </form>
    </div>
    <div class="form-column">
      <div class="form-row">
        <div class="sub-title">Lizenzen</div>
      </div>
      <multi-select 
        [items]="buchungen"
        [(selectedItems)]="buchungenSelected"
        keycol="_uuid"
        namecol="Lernangebot"
        titlecol="Info"
        [disabled]="benutzerChanged()"
        [countFirstColumn]="1"
        [minColumnWidth]="300"
        [disableButtons]="true"
        (selectedItemsChange)="selectedBuchungenChange($event)"
      >
        <ng-template #title let-item="item">
          <div class="multi-name">
            <div>
              <div>{{ getLernangebot(item) }}</div>
              <div *ngIf="hasLizenzart(item)">{{ getLizenzart(item) }}</div>
              <div *ngIf="hasLaufzeit(item)">{{ getLaufzeit(item) }}</div>
            </div>
            <div class="zusatz" [style.color]="getLizenzColor(item)">{{ getLizenzInfo(item) }}</div>
          </div>
        </ng-template>
      </multi-select>
    </div>
  </div>
  <div class="form-benutzer">
    <form [formGroup]="benutzerForm" autocomplete="off">
      <div class="benutzer" formArrayName="Benutzer">
        <div class="benutzeritem" *ngFor="let control of getControls(); let i = index" [formGroupName]="i">
          <div class="itemtitle">{{ (i + 1) }}. Benutzer</div>
          <div class="form-cell">
            <label class="cell-label">Anrede</label>
            <p-dropdown ExtendControl class="control" [options]="anreden" special="1" [required]="isIndexRequired(i)" [readonly]="isIndexReadonly(i)" [showClear]="true" [index]="i" formControlName="OBJAnrede" fieldName="Anrede" placeholder="Anrede auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocusBenutzer($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNextBenutzer($event)" (onChange)="onAnredeChange(i)"></p-dropdown>
          </div>
          <div class="form-cell">
            <label class="cell-label">Vorname</label>
            <input ExtendControl class="control" pInputText type="text" special="1" [required]="isIndexRequired(i)" [readonly]="isIndexReadonly(i)" [index]="i" formControlName="Vorname" [maxlength]="getArrayFieldLength('Vorname')" (focusin)="onFocusBenutzer($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNextBenutzer($event)" autocomplete="nope" (input)="fieldChanged()">
          </div>
          <div class="form-cell">
            <label class="cell-label">Nachname</label>
            <input ExtendControl class="control" pInputText type="text" special="1" [required]="isIndexRequired(i)" [readonly]="isIndexReadonly(i)" [index]="i" formControlName="Nachname" [maxlength]="getArrayFieldLength('Nachname')" (focusin)="onFocusBenutzer($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNextBenutzer($event)" autocomplete="nope" (input)="fieldChanged()">
          </div>
          <div class="form-cell">
            <label class="cell-label">Email</label>
            <input ExtendControl class="control" pInputText type="text" special="1" [required]="isIndexRequired(i)" [readonly]="isIndexReadonly(i)" [index]="i" formControlName="Email" [maxlength]="getArrayFieldLength('Email')" (focusin)="onFocusBenutzer($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNextBenutzer($event)" autocomplete="nope" (input)="fieldChanged()">
          </div>
          <div class="form-cell" *ngIf="isGruppeAzubis()">
            <label class="cell-label">Ausbildungsbeginn</label>
            <p-calendar 
              ExtendControl 
              special="1"
              [index]="i"
              formControlName="Ausbildungsbeginn" 
              appendTo="body" 
              dateFormat="dd.mm.yy"
              [firstDayOfWeek]="1"
              [showIcon]="true" 
              [required]="isIndexRequired(i)"
              [readonly]="isIndexReadonly(i)"
              (focusin)="onFocusBenutzer($event)" 
              (keyup)="onKeyUp($event)" 
              (keyup.enter)="focusNextBenutzer($event);" 
              (onSelect)="onAusbildungsbeginnChange(i)"
              (onInput)="onAusbildungsbeginnChange(i)"
            ></p-calendar>
          </div>
          <div class="form-cell" *ngIf="hasMultipleFachrichtungen() || (!isGruppeAzubis() && !!fachrichtungen.length)">
            <label class="cell-label">Fachrichtung</label>
            <p-dropdown ExtendControl class="control" [options]="fachrichtungen" special="1" [required]="isFachrichtungRequired(i)" [readonly]="isIndexReadonly(i)" [showClear]="true" [index]="i" formControlName="OBJFachrichtung" fieldName="IDFachrichtung" [placeholder]="getFachrichtungPlaceholder()" optionLabel="Name" appendTo="body" (focusin)="onFocusBenutzer($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNextBenutzer($event)" (onChange)="onFachrichtungChange(i)"></p-dropdown>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
