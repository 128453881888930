<div class="data-popup popup-outer" *ngIf="!!form"> 
  <bfe-popup-title [title]="getTitle()" (onclose)="cancel()"></bfe-popup-title>
  <div class="data-form popup-inner">
    <div class="form-row">
      <div class="sub-title">ERP</div>
    </div>
    <div class="form-row">
      <label class="form-label">ID</label>
      <label>{{ data?.ERPID }}</label>
    </div>
    <div class="form-row">
      <label class="form-label">Name</label>
      <label>{{ data?.Name }}, {{ data?.Vorname }}</label>
    </div>
    <div class="form-row">
      <label class="form-label">E-Mail</label>
      <label>{{ data?.Email }}</label>
    </div>
    <div class="form-row" style="margin-top: 20px;">
      <div class="sub-title">InteDigi</div>
    </div>
    <div class="form-row">
      <label class="form-label">Name</label>
      <label>{{ benutzer?.Nachname }}, {{ benutzer?.Vorname }}</label>
    </div>
    <div class="form-row">
      <label class="form-label">E-Mail</label>
      <label>{{ benutzer?.Email }}</label>
    </div>
    <div class="form-row">
      <label class="form-label">Fachrichtung</label>
      <label>{{ getBenutzerFachrichtung(benutzer) }}</label>
    </div>
    <div class="form-row">
      <label class="form-label">Gruppe</label>
      <label>{{ getBenutzerGruppe(benutzer) }}</label>
    </div>
    <div class="form-row form-buttons bottom">
      <!--<button pButton type="button" label="Speichern" [disabled]="form.isInvalid() || !hasChanged()" (click)="save()" *ngIf="isWritable()"></button>-->
      <button pButton type="button" label="Abbrechen" (click)="cancel()"></button>
      <div style="flex: 1; min-height: 100%; "></div>
      <button pButton type="button" label="Löschen" class="p-button-danger" [disabled]="isNew()" (click)="delete()" *ngIf="isDeletable()"></button>
    </div>
  </div>
</div>
