import { cloneDeep } from 'lodash-es';

import { 
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild 
} from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

import { isEmpty, AppSettings, PopupService, isValid, NotifyService } from '@bfeoldenburg/bfe-shared';
import { 
  ToolbarService, BfeForm, CommonFeaturesComponent, DataService, IWriteSubDataset, WRITESUBSTATE 
} from '@bfeoldenburg/bfe-data-forms';

import { 
  IBuchung, IBuchungBenutzer, IFachrichtung, IKunde, IKundeFachrichtung, IKundentypGruppe, TABLENAMES, ISchnittstelleWiedervorlage, ISettings,
  IERPArtikelnummer, ILizenzVorlage, ISchnittstelleERPArtikelIgnoriert, KundeFachrichtungenComponent 
} from '@bfeoldenburg/intedigi-shared';

import { IWiedervorlagePosition, WiedervorlageService } from '../../../../_services/wiedervorlage.service';
import { SubKundenadminComponent } from '../../sub-kundenadmin/sub-kundenadmin.component';
import { SubKundendatenComponent } from '../../sub-kundendaten/sub-kundendaten.component';
import { SubVertragsdatenComponent } from '../../sub-vertragsdaten/sub-vertragsdaten.component';


@Component({
  selector: 'wiedervorlage-auftrag-form',
  templateUrl: './wiedervorlage-auftrag-form.component.html',
  styleUrls: ['./wiedervorlage-auftrag-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WiedervorlageAuftragFormComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnDestroy {
  public settings: ISettings = <ISettings>{};
  public kunde: IKunde;
  public kundenForm: BfeForm;
  public kunden: IKunde[] = [];
  public adminForm: BfeForm;
  public vertragsForm: BfeForm;
  public loading: boolean = false;

  public buchungen: IBuchung[] = [];
  public buchungenBenutzer: IBuchungBenutzer[] = [];
  public kundeFachrichtungen: IKundeFachrichtung[] = []
  public kundentypGruppen: IKundentypGruppe[] = [];
  public fachrichtungen: IFachrichtung[] = [];

  private hFachrichtungen: HTMLElement;
  private hPositionen: HTMLElement;

  public tablenames = TABLENAMES;

  @ViewChild(SubKundendatenComponent, { static: false }) private kundendatenComp: SubKundendatenComponent;
  @ViewChild(SubKundenadminComponent, { static: false }) private kundenadminComp: SubKundenadminComponent;
  @ViewChild(SubVertragsdatenComponent, { static: false }) private vertragsdatenComp: SubVertragsdatenComponent;
  @ViewChild(KundeFachrichtungenComponent, { static: false }) private fachrichtungenComp: KundeFachrichtungenComponent;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private elementRef: ElementRef,
    private router: Router,
    private popupService: PopupService,
    private wiedervorlageService: WiedervorlageService,
    private notifyService: NotifyService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.dataService.readData(TABLENAMES.KUNDE, false, false, true);
    this.dataService.readData(TABLENAMES.SETTINGS, false, false, true);
    this.dataService.readData(TABLENAMES.KUNDEFACHRICHTUNG, false, false, true);
    this.dataService.readData(TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT, false, false, true);
    this.dataService.readData(TABLENAMES.ERP_ARTIKELNUMMER, false, false, true);
    this.dataService.readData(TABLENAMES.LIZENZ_VORLAGE, false, false, true);
    this.dataService.readData(TABLENAMES.FACHRICHTUNG, false, false, true);
    this.dataService.readData(TABLENAMES.BUCHUNG, false, false, true);
    this.dataService.readData(TABLENAMES.BUCHUNG_BENUTZER, false, false, true);
    this.dataService.readData(TABLENAMES.KUNDEFACHRICHTUNG, false, false, true);
    this.dataService.readData(TABLENAMES.KUNDENTYPGRUPPE, false, false, true);

    this.subscriptions.push(this.wiedervorlageService.onselect.subscribe(() => this.fillForms()));
  }

  ngOnInit(): void {
    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => this.getFKeyData()));
  }

  ngAfterViewInit(): void {
    timer().subscribe(() => this.fillForms());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
  }

  isLoading(): boolean {
    return this.loading;
  }

  isWritable(): boolean {
    return (!isEmpty(this.kundenForm) ? this.kundenForm.isWritable() : false);
  }

  isNew(): boolean {
    return (!isEmpty(this.kundenForm) ? this.kundenForm.isNew() : false);
  }

  onKundeform(event: BfeForm): void {
    this.kundenForm = event;
    this.fillForms();
    this.detectChanges();
  }

  onVertragsform(event: BfeForm): void {
    this.vertragsForm = event;
    this.fillForms();
    this.detectChanges();
  }

  onAdminform(event: BfeForm): void {
    this.adminForm = event;
    this.fillForms();
    this.detectChanges();
  }

  resetForms(): void {
    this.kundenForm.reset();
    this.vertragsForm.reset();
    this.kundendatenComp.onDataSet();
    this.vertragsdatenComp.onDataSet();
    if (!isEmpty(this.adminForm)) {
      this.adminForm.reset();
      if (!!this.kundenadminComp) {
        this.kundenadminComp.onDataSet();
      }
    }
  }

  fillForms(): void {
    if (this.wiedervorlageService.isSelected()) {
      if (
        !isEmpty(this.kundenForm) && !isEmpty(this.vertragsForm) && !isEmpty(this.kundendatenComp) && !isEmpty(this.vertragsdatenComp) && 
        !this.dataService.neverLoaded(TABLENAMES.SETTINGS)
      ) {
        let selected: ISchnittstelleWiedervorlage = this.wiedervorlageService.getSelected();
        let data = JSON.parse(selected.Data);
        
        console.log('data', data);

        if (data.Vertragsdaten.ERPID !== this.vertragsForm.getFormValue('ERPID')) {
          this.resetForms();
        } else if (selected._edited !== this.vertragsForm.getFormValue('_edited')) {
          this.resetForms();
          this.notifyService.info('Alle Änderungen wurden zurückgesetzt, da der Auftrag zwischenzeitlich im ERP-System geändert wurde')
        }

        data.Kunde.IDPostleitzahl2 = data.Kunde.IDPostleitzahl;
        data.Kunde.Ort2 = data.Kunde.Ort;
        data.Kunde.IDPostleitzahl2_RE = data.Kunde.IDPostleitzahl_RE;
        data.Kunde.Ort2_RE = data.Kunde.Ort_RE;

        if (this.kundenForm.hasData() && !(Object.keys(this.kundenForm.getValues(true)).length === 1 && this.kundenForm.hasColumnChanged('IDBildungszKd'))) {
          this.kundendatenComp.updateDatasetManually(data.Kunde);
        } else {
          this.kundendatenComp.setDatasetManually(data.Kunde);
        }

        let kunden: IKunde[] = <IKunde[]>this.dataService.getData(TABLENAMES.KUNDE, (item: IKunde) => item.ERPID === data.Kunde.ERPID);
        if (!!kunden.length) {
          this.kunde = kunden[0];

          let values: any = data.Kunde;
          values['ID'] = this.kunde.ID;
          values['IDBildungszKd'] = this.kunde.IDBildungszKd;
          values['_uuid'] = this.kunde._uuid;

          let controls = this.kundenForm.getInvalidControls();
          for (let i = 0; i < controls.length; i++) {
            if (isValid((<any>this.kunde)[controls[i]])) {
              values[controls[i]] = (<any>this.kunde)[controls[i]];

              switch (controls[i]) {
                case 'IDPostleitzahl': values['IDPostleitzahl2'] = values['IDPostleitzahl']; break;
                case 'Ort': values['Ort2'] = values['Ort']; break;
                case 'IDPostleitzahl_RE': values['IDPostleitzahl2_RE'] = values['IDPostleitzahl_RE']; break;
                case 'Ort_RE': values['Ort2_RE'] = values['Ort_RE']; break;
              }
            }
          }
          this.kundendatenComp.updateDatasetManually(values);

        } else {
          this.kunde = null;
          let values: any = data.Kunde;
          values['IDBildungszKd'] = this.settings.IDBildungszentrum;
          this.kundendatenComp.updateDatasetManually(values);
        }

        this.getSubdata();
      
        let positionen = this.checkPositionen(data.Lizenzen);
  
        let vertragsdaten = {
          Vertragsbeginn: data.Vertragsdaten.Vertragsbeginn,
          ERPNummer: data.Vertragsdaten.ERPNummer,
          ERPID: data.Vertragsdaten.ERPID,
          Auftragsdatum: data.Vertragsdaten.Auftragsdatum,
          OBJPositionen: positionen,
          _edited: selected._edited,
          _uuid: selected._uuid
        }

        if (this.vertragsForm.hasData()) {
          this.vertragsdatenComp.updateDatasetManually(vertragsdaten, true);
          this.vertragsForm.setFormValue('OBJPositionen', this.checkPositionen(this.vertragsForm.getFormValue('OBJPositionen')));
        } else {
          this.vertragsdatenComp.setDatasetManually(vertragsdaten);
        }

        /*this.kundendatenComp.onDataSet();
        this.vertragsdatenComp.onDataSet();*/
      }
    }
  }

  reset(): void {
    if (this.hasChanged()) {
      this.popupService.confirm(
        'Wenn Sie die Übersicht aufrufen gehen alle Änderungen verloren. Möchten Sie fortfahren?', 
        'Übersicht aufrufen',
        () => {
          this.resetForms();
          this.wiedervorlageService.setSelected(null);
        }
      );
    } else {
      this.resetForms();
      this.wiedervorlageService.setSelected(null);
    }
  }

  hasPrevious(): boolean {
    return this.wiedervorlageService.hasPrevious();
  }

  hasNext(): boolean {
    return this.wiedervorlageService.hasNext();
  }

  previous(): void {
    if (this.hasChanged()) {
      this.popupService.confirm(
        'Wenn Sie den Datensatz wechseln gehen alle Änderungen verloren. Möchten Sie fortfahren?', 
        'Vorherigen Datensatz aufrufen',
        () => {
          this.resetForms();
          this.wiedervorlageService.previous();
        }
      );
    } else {
      this.resetForms();
      this.wiedervorlageService.previous();
    }
  }

  next(): void {
    if (this.hasChanged()) {
      this.popupService.confirm(
        'Wenn Sie den Datensatz wechseln gehen alle Änderungen verloren. Möchten Sie fortfahren?', 
        'Nächsten Datensatz aufrufen',
        () => {
          this.resetForms();
          this.wiedervorlageService.next();
        }
      );
    } else {
      this.resetForms();
      this.wiedervorlageService.next();
    }
  }

  save(): void {
    this.loading = true;
    this.detectChanges();

    let data = JSON.parse(this.wiedervorlageService.getSelected().Data);
    data.Kunde = this.kundenForm.getValues();
    if (this.kundenForm.hasSubDatasets()) {
      let kundeFachrichtungen = [];
      let subdatasets: IWriteSubDataset[] = this.kundenForm.getSubDatasets();
      for (let i = 0; i < subdatasets.length; i++) {
        if (subdatasets[i].table === TABLENAMES.KUNDEFACHRICHTUNG) {
          let data = subdatasets[i].data;
          if (subdatasets[i].state === WRITESUBSTATE.DELETE) {
            data._deleted = 1;
          }

          kundeFachrichtungen.push(data);
        }
      }
      data.Fachrichtungen = kundeFachrichtungen;
    }
    data.Vertragsdaten.Vertragsbeginn = this.vertragsForm.getFormValue('Vertragsbeginn');
    data.Vertragsdaten.Auftragsdatum = this.vertragsForm.getFormValue('Auftragsdatum');
    data.Lizenzen = this.vertragsForm.getFormValue('OBJPositionen');

    if (!isEmpty(this.adminForm) && this.isNew()) {
      data.Kundenadmin = this.adminForm.getValues();
    }

    data.Wiedervorlage = {
      Kontext: this.wiedervorlageService.getSelected().Kontext,
      _uuid: this.wiedervorlageService.getSelected()._uuid
    };

    
    this.wiedervorlageService.save(
      data, 
      () => { 
        this.loading = false; 
        this.detectChanges() 
      }, 
      (error: string) => { 
        this.notifyService.error(error);
        this.loading = false; 
        this.detectChanges();
      }
    );
  }

  hasFachrichtungen(): boolean {
    if (isEmpty(this.kundenForm)) {
      return false;
    }
    let count = this.kundenForm.getSubDatasets().reduce((result: number, current: IWriteSubDataset) => {
      if (current.table === TABLENAMES.KUNDEFACHRICHTUNG && current.state === WRITESUBSTATE.NEW) {
        return result + 1;
      } else if (current.table === TABLENAMES.KUNDEFACHRICHTUNG && current.state === WRITESUBSTATE.DELETE) {
        return result - 1;
      }
      return result;
    }, 0);
    return !!(this.kundeFachrichtungen.length + count);
  }

  isReady(): boolean {
    return !isEmpty(this.kundenForm) && !isEmpty(this.vertragsForm) && (!this.isNew() || !isEmpty(this.adminForm));
  }

  isValid(test?: string): boolean {
    return (
      (!isEmpty(this.kundenForm) ? this.kundenForm.valid : true) && 
      (!isEmpty(this.vertragsForm) ? this.vertragsForm.valid : true) && 
      (this.isNew() ? (!isEmpty(this.adminForm) ? this.adminForm.valid : true) : true) &&
      this.positionenValid() && this.positionenValid(true) && (!this.isNew() || this.positionenHasAdminLizenz())
    );
  }

  checkPositionen(data: IWiedervorlagePosition[]): IWiedervorlagePosition[] {
    return data.reduce((result: IWiedervorlagePosition[], current: IWiedervorlagePosition) => {
      if (this.dataService.hasDataset(
        TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT, [ 
          this.settings.IDBildungszentrum, this.settings.ERPNummernkreis, current.OrbisPosition.artikelId 
        ])
      ) {
        return result;
      }
      current.Fehler = false;
      current.FehlerLizenzen = false;

      let erpartikel: IERPArtikelnummer[] = <IERPArtikelnummer[]>this.dataService.getData(
        TABLENAMES.ERP_ARTIKELNUMMER, (item: IERPArtikelnummer) => item.Nummer === current.OrbisPosition.artikelId.toString()
      );

      if (!!erpartikel.length) {
        current.ERPArtikelnummer = erpartikel[0];
      } else {
        current.ERPArtikelnummer = null;
      }

      if (isEmpty(current.ERPArtikelnummer) && isValid(current.IDLizenzVorlage)) {
        current.LizenzVorlage = null;
        current.IDLizenzVorlage = null;
        current.IDLizenzart = null;
        current.IDBereich = null;
        current.IDKurs = null;
        current.IDPaketgroesse = null;
        current.IDPaket = null;
        current.IDLernangebot = null;
        current.IDFachrichtung = null;
        current.Laufzeit = null;
      }

      if (!isValid(current.IDLizenzVorlage)) {
        current.Fehler = true;
        if (!isEmpty(current.ERPArtikelnummer)) {
          let vorlage: ILizenzVorlage = this.dataService.getDataset(TABLENAMES.LIZENZ_VORLAGE, erpartikel[0].IDLizenzVorlage);
          if (!isEmpty(vorlage)) {
            current.LizenzVorlage = vorlage;
            current.IDLizenzVorlage = vorlage.ID;
            current.IDLizenzart = vorlage.IDLizenzart;
            current.IDBereich = vorlage.IDBereich;
            current.IDKurs = vorlage.IDKurs;
            current.IDPaketgroesse = vorlage.IDPaketgroesse;
            current.IDPaket = vorlage.IDPaket;
            current.IDLernangebot = vorlage.IDLernangebot;
            current.IDFachrichtung = vorlage.IDFachrichtung;
            current.Laufzeit = vorlage.Laufzeit;
            current.Fehler = false;
          }
        }
      } else {
        current.LizenzVorlage = this.dataService.getDataset(TABLENAMES.LIZENZ_VORLAGE, current.IDLizenzVorlage);
      }
      
      if (this.settings.ERPFachrichtungWiedervorlage && isValid(current.IDPaketgroesse) && !isValid(current.IDFachrichtung)) {
        current.Fehler = true;
      }

      current.OrbisPosition.verbraucht = 0;
      if (!isEmpty(current.Buchung) && isValid(current.Buchung.ID)) {
        current.OrbisPosition.verbraucht = this.dataService.getData(TABLENAMES.BUCHUNG_BENUTZER, (item: IBuchungBenutzer) => {
          return (
            item.IDBildungszKd === current.Buchung.IDBildungszKd && item.IDKunde === current.Buchung.IDKunde && 
            item.IDBuchung === current.Buchung.ID
          );
        }).length;
      }
      if (current.OrbisPosition.anzahl < current.OrbisPosition.verbraucht) {
        current.FehlerLizenzen = true;
      }

      if (isEmpty(current.Backup)) {
        current.Backup = cloneDeep(current);
      }
      if (isValid(current.IDFachrichtung)) {
        this.fachrichtungenComp.selectFachrichtung(this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, current.IDFachrichtung));
      }
      return result.concat(current);
    }, <IWiedervorlagePosition[]>[]);
  }

  positionenValid(checkLizenzen: boolean = false): boolean {
    if (!isEmpty(this.vertragsForm)) {
      let data = this.vertragsForm.getFormValue('OBJPositionen');
      if (!isEmpty(data)) {
        return data.reduce((result: boolean, current: IWiedervorlagePosition) => {
          if (!checkLizenzen && current.Fehler) {
            result = false; 
          } else if (checkLizenzen && current.FehlerLizenzen) {
            result = false; 
          }
          return result;
        }, <boolean>true);
      }
    }
    return true;
  }

  positionenHasAdminLizenz(): boolean {
    if (!isEmpty(this.vertragsForm)) {
      let data = this.vertragsForm.getFormValue('OBJPositionen');
      if (!isEmpty(data)) {
        return data.reduce((result: boolean, current: IWiedervorlagePosition) => {
          let lizenzVorlage = this.dataService.getDataset(TABLENAMES.LIZENZ_VORLAGE, current.IDLizenzVorlage);
          if (lizenzVorlage.IDLizenzart === 5) {
            result = true; 
          }
          return result;
        }, <boolean>false);
      }
    }
    return false;
  }

  positionenChanged(): boolean {
    if (!isEmpty(this.vertragsForm)) {
      let data = this.vertragsForm.getFormValue('OBJPositionen');
      if (!isEmpty(data)) {
        return data.reduce((result: boolean, current: IWiedervorlagePosition) => {
          if (current.IDLizenzVorlage !== current.Backup.IDLizenzVorlage || current.IDFachrichtung !== current.Backup.IDFachrichtung) {
            result = true; 
          }
          return result;
        }, <boolean>false);
      }
    }
    return false;
  }

  hasChanged(): boolean {
    return (
      (!!this.kundendatenComp && !isEmpty(this.kundenForm) && this.kundendatenComp.hasChanged()) || 
      (!!this.kundenadminComp && !isEmpty(this.adminForm) && this.kundenForm.isNew() && this.kundenadminComp.hasChanged()) || 
      (!!this.vertragsdatenComp && !isEmpty(this.vertragsForm) && this.vertragsdatenComp.hasChanged()) || 
      this.positionenChanged()
    );
  }

  getInfo(): string {
    if (!this.isValid()) {
      if (!this.kundendatenComp || !this.kundendatenComp.isKundendatenValid()) {
        return 'Füllen Sie die Kundendaten vollständig und korrekt aus';
      } else if (!this.kundendatenComp || !this.kundendatenComp.isRechnungsadresseValid()) {
        return 'Füllen Sie die Rechnungsadresse vollständig und korrekt aus';
      /*} else if (!this.hasFachrichtungen()) {
        return 'Legen Sie mindestens eine Fachrichtung fest';*/
      } else if (isEmpty(this.vertragsForm) || !this.vertragsForm.valid) {
        return 'Füllen Sie die Vertragsdaten vollständig und korrekt aus';
      } else if (this.isNew() && (isEmpty(this.adminForm) || !this.adminForm.valid)) {
        return 'Füllen Sie die Benutzerdaten vollständig und korrekt aus';
      } else if (!this.positionenValid(true)) {
        return 'Überprüfen Sie die verbrauchten Lizenzen';
      } else if (!this.positionenValid()) {
        return 'Korrigieren Sie bitte die Auftragspositionen';
      } else if (this.isNew() && !this.positionenHasAdminLizenz()) {
        return 'Es wurde keine Administratoren-Lizenz verkauft';
      } else {
        return 'Unbekannter Fehler';
      }
    } else {
      return 'Sie können die Daten nun übernehmen';
    }
  }

  subDataReady(table: string): boolean {
    return !this.dataService.neverLoaded(table);
  }

  getFachrichtungenWidth(): number {
    if (!this.hFachrichtungen) {
      this.hFachrichtungen = this.elementRef.nativeElement.querySelector('#data-fachrichtungen');
    }
    if (!!this.hFachrichtungen) {
      return this.hFachrichtungen.offsetWidth;
    }
    return 0;
  }

  getPositionenWidth(): number {
    if (!this.hPositionen) {
      this.hPositionen = this.elementRef.nativeElement.querySelector('#data-positionen');
    }
    if (!!this.hPositionen) {
      return this.hPositionen.offsetWidth - 20;
    }
    return 0;
  }

  getPositionen(): IWiedervorlagePosition[] {
    let data = this.vertragsForm.getFormValue('OBJPositionen');
    if (!isEmpty(data)) {
      return data;
    } else {
      return [];
    }
  }

  onPositionenChanged(data: IWiedervorlagePosition[]): void {
    this.vertragsForm.setFormValue('OBJPositionen', this.checkPositionen(data));
    this.detectChanges();
  }

  getBuchungen(): IBuchung[] {
    return this.buchungen.concat(
      this.getPositionen().reduce((result: IBuchung[], current: IWiedervorlagePosition) => {
        return result.concat(<IBuchung>{
          IDFachrichtung: current.IDFachrichtung,
          IDKurs: current.IDKurs
        });
      }, <IBuchung[]>[])
    );
  }

  getSubdata() {
    this.buchungen = [];
    this.buchungenBenutzer = [];
    this.kundeFachrichtungen = [];
    this.kundentypGruppen = [];

    if (!isEmpty(this.kunde)) {
      let getBuchungen = () => {
        this.buchungen = (<IBuchung[]>this.dataService.getData(
          TABLENAMES.BUCHUNG, (item: IBuchung) => item.IDBildungszKd === this.kunde.IDBildungszKd && item.IDKunde === this.kunde.ID
        )).reduce((result: IBuchung[], current: IBuchung) => {
          current.Zusatzinfo = '';
          return result.concat(current);
        }, <IBuchung[]>[]);

        let buchungenIds: { [key: string]: { [key: number]: number[] } } = this.buchungen.reduce(
          (result: { [key: string]: { [key: number]: number[] } }, current: IBuchung) => {
            if (!(current.IDBildungszKd in result)) {
              result[current.IDBildungszKd] = {};
            }
            if (!(current.IDKunde in result[current.IDBildungszKd])) {
              result[current.IDBildungszKd][current.IDKunde] = [];
            }
            result[current.IDBildungszKd][current.IDKunde].push(current.ID);
            return result;
          }, <{ [key: string]: { [key: number]: number[] } }>{}
        );

        this.buchungenBenutzer = <IBuchungBenutzer[]>this.dataService.getData(
          TABLENAMES.BUCHUNG_BENUTZER, 
          (item: IBuchungBenutzer) => (
            item.IDBildungszKd in buchungenIds && item.IDKunde in buchungenIds[item.IDBildungszKd] && 
            buchungenIds[item.IDBildungszKd][item.IDKunde].indexOf(item.IDBuchung) > -1
          )
        );
      }

      this.addDataSubscription(TABLENAMES.BUCHUNG, (data: IBuchung[]) => {
        getBuchungen();
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.BUCHUNG_BENUTZER, (data: IBuchungBenutzer[]) => {
        getBuchungen();
        this.detectChanges();
      });

      this.addDataSubscription(TABLENAMES.KUNDEFACHRICHTUNG, (data: IKundeFachrichtung[]) => {
        this.kundeFachrichtungen = data.filter((item: IKundeFachrichtung) => item.IDKunde === this.kunde.ID && item.IDBildungszKd === this.kunde.IDBildungszKd);
        this.detectChanges();
        this.setFachrichtungen();
      });

      this.addDataSubscription(TABLENAMES.KUNDENTYPGRUPPE, (data: IKundentypGruppe[]) => {
        this.kundentypGruppen = data.filter((item: IKundentypGruppe) => item.IDKundentyp === this.kunde.IDKundentyp);
        this.detectChanges();
      });

      getBuchungen();
      this.kundeFachrichtungen = <IKundeFachrichtung[]>this.dataService.getData(
        TABLENAMES.KUNDEFACHRICHTUNG, (item: IKundeFachrichtung) => item.IDKunde === this.kunde.ID && item.IDBildungszKd === this.kunde.IDBildungszKd
      );
      this.kundentypGruppen = <IKundentypGruppe[]>this.dataService.getData(
        TABLENAMES.KUNDENTYPGRUPPE, (item: IKundentypGruppe) => item.IDKundentyp === this.kunde.IDKundentyp
      );
      this.setFachrichtungen();
    }

    this.detectChanges();
  }

  getFKeyData(): void {
    this.addDataSubscription(TABLENAMES.KUNDE, (data: IKunde[]) => {
      this.fillForms();
    });

    this.addDataSubscription(TABLENAMES.KUNDEFACHRICHTUNG, (data: IKundeFachrichtung[]) => {
      this.fillForms();
    });

    this.addDataSubscription(TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT, (data: ISchnittstelleERPArtikelIgnoriert[]) => {
      this.onPositionenChanged(this.getPositionen());
    });

    this.settings = <ISettings>{};

    this.addDataSubscription(TABLENAMES.SETTINGS, (data: ISettings[]) => {
      if (!!data.length) {
        this.settings = data[0];
      }
      this.fillForms();
    });
    let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
    if (!!settings.length) {
      this.settings = settings[0];
    }
  }

  setFachrichtungen(): void {
    
  }

  onFachrichtungenChange(): void {
    this.fachrichtungen = [];

    if (
      !isEmpty(this.kundenForm) && !this.dataService.neverLoaded(TABLENAMES.FACHRICHTUNG) && 
      !this.dataService.neverLoaded(TABLENAMES.KUNDEFACHRICHTUNG)
    ) {
      let removed: string[] = [];

      this.fachrichtungen = this.kundenForm.getSubDatasets().reduce((result: IFachrichtung[], current: IWriteSubDataset) => {
        if (current.table === TABLENAMES.KUNDEFACHRICHTUNG && current.state === WRITESUBSTATE.DELETE) {
          removed.push(current.data.IDFachrichtung);
        } else if (current.table === TABLENAMES.KUNDEFACHRICHTUNG && current.state === WRITESUBSTATE.NEW) {
          return result.concat(this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, current.data.IDFachrichtung));
        }
        return result;
      }, <IFachrichtung[]>[]);

      this.fachrichtungen = this.kundeFachrichtungen.reduce((result: IFachrichtung[], current: IKundeFachrichtung) => {
        if (removed.indexOf(current.IDFachrichtung) === -1) {
          return result.concat(this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, current.IDFachrichtung));
        }
        return result;
      }, this.fachrichtungen);
    }

    this.detectChanges();
  }

  onKundentypChange(): void {
    if (!isEmpty(this.kundenForm)) {
      this.kunden = [ this.kundenForm.getValues() ];
    } else {
      this.kunden = [];
    }
    this.detectChanges();
    if (!isEmpty(this.vertragsdatenComp)) {
      this.vertragsdatenComp.onDataChange();
    }
  }
}

