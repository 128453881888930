import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { timer } from 'rxjs';

import { AppSettings, isEmpty, isValid } from '@bfeoldenburg/bfe-shared';
import { 
  CommonFeaturesComponent, CommonListComponent, DataService, IData, IListColumn, ToolbarService 
} from '@bfeoldenburg/bfe-data-forms';

import { IntedigiService, TABLENAMES, IERPBenutzer, IBenutzer, ISettings } from '@bfeoldenburg/intedigi-shared';

import { ErpBenutzerPopupComponent } from './erp-benutzer-popup/erp-benutzer-popup.component';

@Component({
  selector: 'erp-benutzer',
  templateUrl: './erp-benutzer.component.html',
  styleUrls: ['./erp-benutzer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErpBenutzerComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnDestroy {
  public popupComponent: any = ErpBenutzerPopupComponent;
  public selectedRow: IData;

  private settings: ISettings = null;
  private benutzer__: { [key: string]: IBenutzer } = {};

  public columns: IListColumn[] = [];
  public visibleColumns: IListColumn[] = [];

  private hNummern: HTMLElement;

  public searchText: string;

  @ViewChild('commonList', { static: false })
  private commonList: CommonListComponent;

  public buttonTexts: any = { history: 'Änderungs-Historie' };

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService,
    private elementRef: ElementRef
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.dataService.readData(TABLENAMES.BILDUNGSZENTRUM);
  }

  ngAfterViewInit() {
    let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
    if (!!settings.length) {
      this.settings = settings[0];
    }
    
    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => {
      let settings = <ISettings[]>this.dataService.getData(TABLENAMES.SETTINGS);
      if (!!settings.length) {
        this.settings = settings[0];
      }

      this.getColumns();
      this.calcTable();
    }));

    this.getColumns();
    this.calcTable();
  }

  ngOnInit() {
    this.subscriptions.push(this.dataService.onready.subscribe(() => {
      this.getFKeyTables();
      this.detectChanges();
    }));

    this.getFKeyTables();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
    this.calcTable();
    this.detectChanges();
  }

  isLoading(): boolean {
    return this.dataService.neverLoaded(TABLENAMES.ERP_BENUTZER);
  }

  onRowExpand() {
    this.detectChanges();
  }

  getBenutzer(dataset: IERPBenutzer) {
    let key: string = dataset.IDBildungszentrum + '_' + dataset.IDBenutzer;
    if (!(key in this.benutzer__)) {
      if (this.dataService.hasDataset(TABLENAMES.BENUTZER, [ dataset.IDBenutzer, dataset.IDBildungszentrum ])) {
        this.benutzer__[key] = this.dataService.getDataset(TABLENAMES.BENUTZER, [ dataset.IDBenutzer, dataset.IDBildungszentrum ]);
      } else {
        this.benutzer__[key] = <IBenutzer>this.dataService.getDataset(TABLENAMES.BENUTZERAUSTAUSCH, [ dataset.IDBenutzer, dataset.IDBildungszentrum ]);
      }
    }
    return this.benutzer__[key];
  }

  getColumns(): void {
    this.columns = [];
    
    if (!this.intedigiService.isInterfaceLocal()) {
      this.columns = [
        { name: 'Bildungszentrum', title: 'Bildungszentrum', minWidth: 150, cbOnShow: (dataset: IERPBenutzer) => { 
          return this.dataService.getDatasetName(TABLENAMES.BILDUNGSZENTRUM, dataset.IDBildungszentrum);
        } }
      ];
    }

    this.columns = this.columns.concat([
      { name: 'ERPID', title: 'ERP-ID', minWidth: 100, cbOnShow: (dataset: IERPBenutzer) => { return dataset.ERPID; }},
      { name: 'ERPName', title: 'ERP-Name', minWidth: 250, cbOnShow: (dataset: IERPBenutzer) => { return dataset.Name + ', ' + dataset.Vorname; }},
      { name: 'ERPEmail', title: 'ERP-E-Mail', minWidth: 200, cbOnShow: (dataset: IERPBenutzer) => { return dataset.Email; }},
      { name: 'InteDigiName', title: 'InteDigi-Name', minWidth: 250, cbOnShow: (dataset: IERPBenutzer) => { 
        let benutzer = this.getBenutzer(dataset);
        if (!isEmpty(benutzer)) {
          return benutzer.Nachname + ', ' + benutzer.Vorname;
        } else {
          return '---';
        }
      }},
      { name: 'InteDigiName', title: 'InteDigi-E-Mail', minWidth: 200, cbOnShow: (dataset: IERPBenutzer) => { 
        let benutzer = this.getBenutzer(dataset);
        if (!isEmpty(benutzer)) {
          return benutzer.Email; 
        } else {
          return '---';
        }
      }},
      { name: 'InteDigiFachrichtung', title: 'InteDigi-Fachrichtung', minWidth: 200, cbOnShow: (dataset: IERPBenutzer) => { 
        let benutzer = this.getBenutzer(dataset);
        if (!isEmpty(benutzer) && isValid(benutzer.IDFachrichtung)) {
          return benutzer.IDFachrichtung;  
        } else {
          return '---';
        }
      }},
      { name: 'InteDigiGruppe', title: 'InteDigi-Gruppe', minWidth: 180, cbOnShow: (dataset: IERPBenutzer) => { 
        let benutzer = this.getBenutzer(dataset);
        if (!isEmpty(benutzer) && isValid(benutzer.IDGruppe)) {
          return this.dataService.getDatasetName(TABLENAMES.GRUPPE, benutzer.IDGruppe); 
        } else {
          return '---';
        }
      }}
    ]);
  }

  getNummerBezeichner(): string {
    return (!isEmpty(this.settings) && this.settings.ERPNummernkreis === 1 ? 'Artikel-ID' : 'Veranstaltungs-ID');
  }

  getWidth(): number {
    if (!this.hNummern) {
      this.hNummern = this.elementRef.nativeElement.querySelector('#data-table');
    }
    if (!!this.hNummern) {
      return this.hNummern.offsetWidth;
    }
    return 0;
  }

  isWritable(): boolean {
    return this.dataService.isWritable(TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT);
  }

  getHelp(control: string): string {
    let form = this.dataService.getForm(TABLENAMES.SCHNITTSTELLE_ERPARTIKEL_IGNORIERT);
    if (isValid(form)) {
      return form.getHelp(control);
    }
    return '';
  }

  getColumnAlign(column: any): string {
    return (!!column.align && !!column.align.length ? column.align : 'left');
  }

  calcTable(): void {
    if (this.getWidth() > 0) {
      this.visibleColumns = [];
      let width = 40;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].minWidth + width <= this.getWidth()) {
          this.visibleColumns.push(this.columns[i]);
        } else {
          break;
        }
        width += this.columns[i].minWidth;
      }
    } else {
      timer(10).subscribe(() => this.calcTable());
    }

    this.detectChanges();
  }

  getFKeyTables(): void {
    this.addDataSubscription(TABLENAMES.BILDUNGSZENTRUM, () => this.detectChanges());

    this.addDataSubscription(TABLENAMES.BENUTZERAUSTAUSCH, () => {
      let keys = Object.keys(this.benutzer__);
      for (let i = 0; i < keys.length; i++) {
        let keysparts = keys[i].split('_');
        if (this.dataService.hasDataset(TABLENAMES.BENUTZER, [ keysparts[1], keysparts[0] ])) {
          this.benutzer__[keys[i]] = this.dataService.getDataset(TABLENAMES.BENUTZER, [ keysparts[1], keysparts[0] ]);
        } else {
          this.benutzer__[keys[i]] = <IBenutzer>this.dataService.getDataset(TABLENAMES.BENUTZERAUSTAUSCH, [ keysparts[1], keysparts[0] ]);
        }
      }
    });

    this.addDataSubscription(TABLENAMES.BENUTZER, () => {
      let keys = Object.keys(this.benutzer__);
      for (let i = 0; i < keys.length; i++) {
        let keysparts = keys[i].split('_');
        if (this.dataService.hasDataset(TABLENAMES.BENUTZER, [ keysparts[1], keysparts[0] ])) {
          this.benutzer__[keys[i]] = this.dataService.getDataset(TABLENAMES.BENUTZER, [ keysparts[1], keysparts[0] ]);
        } else {
          this.benutzer__[keys[i]] = <IBenutzer>this.dataService.getDataset(TABLENAMES.BENUTZERAUSTAUSCH, [ keysparts[1], keysparts[0] ]);
        }
      }
    });

    this.detectChanges();
  }

  onSearch() {
    this.detectChanges();
  }
}
