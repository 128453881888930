import { 
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild 
} from '@angular/core';

import { 
  AppSettings, isValid, PopupService, isEmpty, NotifyService, AuthenticationService
} from '@bfeoldenburg/bfe-shared';
import { 
  DataService, IUserSettings, ToolbarService
} from '@bfeoldenburg/bfe-data-forms';

import { IBildungszentrum, IntedigiUserService, TABLENAMES } from '@bfeoldenburg/intedigi-shared';
import { EmailVorlagePopupComponent } from '@bfeoldenburg/bfe-web-apps';


@Component({
  selector: 'custom-email-vorlage-popup',
  templateUrl: './custom-email-vorlage-popup.component.html',
  styleUrls: ['./custom-email-vorlage-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomEmailVorlagePopupComponent extends EmailVorlagePopupComponent implements OnInit, OnChanges, OnDestroy {
  public data: IUserSettings;

  public bildungszentren: IBildungszentrum[] = [];

  constructor(
    dataService: DataService,
    popupService: PopupService,
    elementRef: ElementRef,
    renderer: Renderer2,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private notifyService: NotifyService,
    private authService: AuthenticationService,
    private intedigiUserService: IntedigiUserService
  ) { 
    super(dataService, popupService, elementRef, renderer, cdRef, toolbarService, appSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => this.getFKeyData()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
  }

  onDataSet() {
    if (isValid(this.form.getFormValue('UuidBesitzer'))) {
      if (this.dataService.hasDataset(TABLENAMES.BILDUNGSZENTRUM, this.form.getFormValue('UuidBesitzer'))) {
        this.form.setFormValue('IDBildungszentrum', this.form.getFormValue('UuidBesitzer'));
      }
    }

    this.setBildungszentrum(this.form.getFormValue('IDBildungszentrum'));

    super.onDataSet();
  }

  setBildungszentrum(value?: any) {
    if (!this.dataService.neverLoaded(TABLENAMES.BILDUNGSZENTRUM)) {
      this.setFormObjectStandardAuswahl('OBJBildungszentrum', 'IDBildungszentrum', this.bildungszentren, value);

      if (!isValid(this.form.getFormValue('IDBildungszentrum'))) {
        this.setBildungszentrum('__ALLE__');
      }
    }
  }

  onBildungszentrumChange(): void {
    this.setBildungszentrum();

    if (isValid(this.form.getFormValue('IDBildungszentrum')) && this.form.getFormValue('IDBildungszentrum') !== '__ALLE__') {
      this.form.setFormValue('UuidBesitzer', this.form.getFormValue('IDBildungszentrum'));
    } else {
      this.form.setFormValue('UuidBesitzer', null);
    }

    this.onDataChange();
  }

  getFKeyData(): void {
    this.addDataSubscription(TABLENAMES.BILDUNGSZENTRUM, (data: IBildungszentrum[]) => {
      this.bildungszentren = <any[]>[{ ID: '__ALLE__', Name: '- Alle -' }].concat(
        data.filter((item: IBildungszentrum) => this.authService.isAdministrator() || item.ID === this.intedigiUserService.getBildungszentrum())
      );

      if (!isEmpty(this.form)) {
        if (isValid(this.form.getFormValue('UuidBesitzer'))) {
          if (this.dataService.hasDataset(TABLENAMES.BILDUNGSZENTRUM, this.form.getFormValue('UuidBesitzer'))) {
            this.form.setFormValue('IDBildungszentrum', this.form.getFormValue('UuidBesitzer'));
          }
        }
      }
    });

    this.bildungszentren = <any[]>[{ ID: '__ALLE__', Name: '- Alle -' }].concat(<any[]>this.dataService.getData(
      TABLENAMES.BILDUNGSZENTRUM, (item: IBildungszentrum) => this.authService.isAdministrator() || item.ID === this.intedigiUserService.getBildungszentrum()
    ));
  }

  copyVorlage(): void {
    this.dataService.copyDataset(
      TABLENAMES.USER_SETTINGS, this.data._uuid, null, [], [], 
      [
        'Alias', 'Settings', 'UuidBesitzer', 'Standard'
      ], 
      {
        Name: 'Kopie von ' + this.data.Name
      },
      (dataset: IUserSettings) => {
        this.setData(dataset);
        this.onDataChange();
      },
      (error: string) => {
        this.notifyService.error(error);
      }
    );
  }
}
