import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminGuard, AuthGuard } from '@bfeoldenburg/bfe-shared';
import { 
  ArchiveComponent, EmailAendernComponent, LoginComponent, NeuesPasswortComponent, PasswortAendernComponent, PasswortVergessenComponent, SSOLoginComponent, TableInfoComponent 
} from '@bfeoldenburg/bfe-web-apps';
import { DATATYPE, IDataFilter } from '@bfeoldenburg/bfe-data-forms';

import { UsersComponent } from './_components/users/users.component';
import { HomeComponent } from './_components/home/home.component';
import { LdapComponent } from './_components/schnittstellen/ldap/ldap.component';
import { EinstellungenComponent } from './_components/system/einstellungen/einstellungen.component';
import { SchnittstellenComponent } from './_components/schnittstellen/schnittstellen/schnittstellen.component';
import { ErpArtikelnummernComponent } from './_components/schnittstellen/erp-artikelnummern/erp-artikelnummern.component';
import { GrunddatenComponent } from './_components/workflows/lizenzverkauf/grunddaten/grunddaten.component';
import { LizenzenComponent } from './_components/workflows/lizenzverkauf/lizenzen/lizenzen.component';
import { WiedervorlageComponent } from './_components/workflows/wiedervorlage/wiedervorlage.component';
import { ErpArtikelIgnoriertComponent } from './_components/schnittstellen/erp-artikel-ignoriert/erp-artikel-ignoriert.component';
import { ErpBenutzerComponent } from './_components/schnittstellen/erp-benutzer/erp-benutzer.component';
import { FehlerComponent } from './_components/fehler/fehler.component';
import { ErpVeranstaltungComponent } from './_components/schnittstellen/erp-veranstaltung/erp-veranstaltung.component';
import { CronjobsComponent } from './_components/system/cronjobs/cronjobs.component';
import { BenutzerSchnellerfassungComponent } from './_components/workflows/benutzer-schnellerfassung/benutzer-schnellerfassung.component';
import { EmailVorlagenComponent } from './_components/email-vorlagen/email-vorlagen.component';


const appRoutes: Routes = [
  { path: 'dashboard', component: HomeComponent, canActivate: [ AuthGuard ], data: { title: 'Dashboard', help: 'Benutzerdokumentation_Grundlagen#Dashboard' } },
  { path: 'email-vorlagen', component: EmailVorlagenComponent, canActivate: [ AuthGuard ], data: { title: 'E-Mail-Vorlagen', help: '' } },
  { 
    path: 'cronjobs', component: CronjobsComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Cronjobs', help: 'Benutzerdokumentation_Grundlagen#Cronjobs' } 
  },
  { 
    path: 'sso-login', component: SSOLoginComponent, 
    data: { 
      title: 'SSO-Login', noauth: true, vergessenLink: '/passwort-vergessen', loginLink: '/login', 
      filter: <IDataFilter[]>[ { column: 'Administration', type: DATATYPE.DATATYPE_BOOLEAN, value: true } ]
    } 
  },
  { 
    path: 'login', component: LoginComponent, 
    data: { title: 'Login', vergessenLink: '/passwort-vergessen', ssoLink: '/sso-login' } 
  },
  { 
    path: 'passwort-vergessen', component: PasswortVergessenComponent, 
    data: { title: 'Passwort vergessen', help: '', noauth: true, loginLink: '/login' } 
  },
  { 
    path: 'neues-passwort', component: NeuesPasswortComponent, 
    data: { title: 'Neues Passwort vergeben', help: '', noauth: true, loginLink: '/login', tokenData: ['uuid'] } 
  },
  { 
    path: 'passwort-aendern', component: PasswortAendernComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Passwort ändern', help: 'Benutzerdokumentation_Grundlagen#Passwort_.C3.A4ndern' } 
  },
  { 
    path: 'email-aendern', component: EmailAendernComponent, canActivate: [ AuthGuard ], 
    data: { title: 'E-Mailadresse ändern', help: 'Benutzerdokumentation_Grundlagen#Emailadresse_.C3.A4ndern' } 
  },
  { 
    path: 'benutzererfassung', component: BenutzerSchnellerfassungComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Benutzererfassung', help: 'Benutzerdokumentation_Kundendaten#Benutzererfassung' } 
  },
  { 
    path: 'ldap', component: LdapComponent, canActivate: [ AuthGuard ], 
    data: { title: 'LDAP-Daten', help: 'Benutzerdokumentation_Schnittstellen#LDAP-Daten' } 
  },
  { 
    path: 'einstellungen', component: EinstellungenComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Einstellungen', help: 'Benutzerdokumentation_Stammdaten#Einstellungen' } 
  },
  { 
    path: 'erp-artikel', component: ErpArtikelnummernComponent, canActivate: [ AuthGuard ], 
    data: { title: 'ERP-Artikel', help: 'Benutzerdokumentation_Schnittstellen#ERP-Artikel' } 
  },
  { 
    path: 'erp-artikel-ignoriert', component: ErpArtikelIgnoriertComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Ignorierte ERP-Artikel', help: 'Benutzerdokumentation_ERP-Daten#Ignorierte_ERP-Artikel' } 
  },
  { 
    path: 'erp-benutzer', component: ErpBenutzerComponent, canActivate: [ AuthGuard ], 
    data: { title: 'ERP-Benutzer', help: 'Benutzerdokumentation_ERP-Daten#ERP-Benutzer' } 
  },
  { 
    path: 'schnittstellen/schnittstellen', component: SchnittstellenComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Schnittstellen', help: 'Benutzerdokumentation_Schnittstellen#Schnittstellen', subpage: 0 } 
  },
  { 
    path: 'schnittstellen/fehler', component: SchnittstellenComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Schnittstellen - Fehler', help: 'Benutzerdokumentation_Schnittstellen#Schnittstellen', subpage: 1 } 
  },
  { 
    path: 'schnittstellen/aenderungen', component: SchnittstellenComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Schnittstellen - Zuletzt geändert', help: 'Benutzerdokumentation_Schnittstellen#Schnittstellen', subpage: 2 } 
  },
  { 
    path: 'schnittstellen/schnittstellen/:IDFILTER', component: SchnittstellenComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Schnittstellen', help: 'Benutzerdokumentation_Schnittstellen#Schnittstellen', subpage: 0 } 
  },
  { 
    path: 'schnittstellen/fehler/:IDFILTER', component: SchnittstellenComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Schnittstellen - Fehler', help: 'Benutzerdokumentation_Schnittstellen#Schnittstellen', subpage: 1 } 
  },
  { 
    path: 'schnittstellen/aenderungen/:IDFILTER', component: SchnittstellenComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Schnittstellen - Zuletzt geändert', help: 'Benutzerdokumentation_Schnittstellen#Schnittstellen', subpage: 2 } 
  },
  {
    path: 'users', component: UsersComponent, canActivate: [ AdminGuard ], 
    data: { title: 'Zugänge', help: 'Benutzerdokumentation_Grundlagen#Zug.C3.A4nge_bearbeiten' } 
  },
  {
    path: 'lizenzverkauf', children: [
      {
        path: 'grunddaten', component: GrunddatenComponent, canActivate: [ AuthGuard ], 
        data: { title: 'Lizenzverkauf - Grunddaten', help: 'Benutzerdokumentation_Workflows#Lizenzverkauf' } 
      },
      {
        path: 'lizenzen', component: LizenzenComponent, canActivate: [ AuthGuard ], 
        data: { title: 'Lizenzverkauf - Lizenzen', help: 'Benutzerdokumentation_Workflows#Erfassungsschritt_2' } 
      },
      { path: '', redirectTo: 'grunddaten', pathMatch: 'full' }
    ]
  },
  {
    path: 'wiedervorlage', component: WiedervorlageComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Wiedervorlage', help: 'Benutzerdokumentation_Workflows#Wiedervorlage' } 
  },
  {
    path: 'wiedervorlage/:UUID', component: WiedervorlageComponent, canActivate: [ AuthGuard ], 
    data: { title: 'Wiedervorlage', help: 'Benutzerdokumentation_Workflows#Wiedervorlage' } 
  },
  {
    path: 'fehler', component: FehlerComponent, data: { title: 'Fehler', help: null, noauth: true } 
  },
  { 
    path: 'erp-veranstaltungen', component: ErpVeranstaltungComponent, 
    data: { title: 'ERP-Veranstaltungen', help: 'Benutzerdokumentation_ERP-Daten#ERP-Veranstaltungen', noauth: true } 
  },
  { 
    path: 'erp-veranstaltungen/:ID', component: ErpVeranstaltungComponent, 
    data: { title: 'ERP-Veranstaltungen', help: 'Benutzerdokumentation_ERP-Daten#ERP-Veranstaltungen', noauth: true } 
  },
  { path: 'table-info', component: TableInfoComponent, canActivate: [ AuthGuard ], data: { title: 'Tabellen-Informationen' } },
  { path: 'archiv', component: ArchiveComponent, canActivate: [ AuthGuard ], data: { title: 'Archiv' } },
  { path: 'kunden', loadChildren: () => import('./kunden/kunden.module').then(m => m.KundenModule) },
  { path: 'stammdaten', loadChildren: () => import('./stammdaten/stammdaten.module').then(m => m.StammdatenModule) },
  { path: 'lernangebote', loadChildren: () => import('./lernangebote/lernangebote.module').then(m => m.LernangeboteModule) },
  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard' }
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],//, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }