import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { AppSettings, isEmpty } from '@bfeoldenburg/bfe-shared';
import { BaseToolbarComponent, DataService, ToolbarService, WIDTHSTATE } from '@bfeoldenburg/bfe-data-forms';

@Component({
  selector: 'lizenzverkauf-toolbar',
  templateUrl: './lizenzverkauf-toolbar.component.html',
  styleUrls: ['./lizenzverkauf-toolbar.component.scss']
})
export class LizenzverkaufToolbarComponent extends BaseToolbarComponent implements OnChanges {
  @Input() status: { error?: boolean; text?: string; } = {};

  constructor(
    cdRef: ChangeDetectorRef,
    dataService: DataService,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    elementRef: ElementRef
  ) { 
    super(cdRef, toolbarService, dataService, appSettings, elementRef);

    this.countCustom = 3;
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  hasStatusError(): boolean {
    return !isEmpty(this.status) && !!this.status.error;
  }

  getStatusText(): string {
    return (!isEmpty(this.status) ? this.status.text : '');
  }

  getInfoFlex(): string {
    if (this.widthState !== WIDTHSTATE.toolarge) {
      return '1 ' + (this.realMaxWidth > 0 ? (this.realMaxWidth * this.countCustom) + 'px' : (this.availableWidth / this.countTools * this.countCustom) + 'px');
    } else {
      return '1';
    }
  }

  getInfoMinWidth(inner?: boolean): string {
    return (this.toolMinWidth * this.countCustom) - (inner ? 60 : 0) + 'px';
  }

  getInfoMaxWidth(): string {
    if (this.widthState !== WIDTHSTATE.toolarge) {
      return (this.realMaxWidth > 0 ? (this.realMaxWidth * this.countCustom) + 'px' : (this.availableWidth / this.countTools * this.countCustom) + 'px');
    } else {
      return 'none';
    }
  }
}
