import * as moment from 'moment';

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, AppSettings, isValid, isEmpty } from '@bfeoldenburg/bfe-shared';
import { CommonFeaturesComponent, ToolbarService, DataService, IListColumn } from '@bfeoldenburg/bfe-data-forms';

import { 
  IBenutzerAustausch, IBenutzerKursgruppe, IBuchung, IBuchungBenutzer, IGruppe, IKursgruppeBenutzer, TABLENAMES, IntedigiService
} from '@bfeoldenburg/intedigi-shared';

interface IExtBenutzerAustausch extends IBenutzerAustausch {
  kursgruppeBenutzer: IKursgruppeBenutzer;
  benutzerKursgruppe: IBenutzerKursgruppe;
}


@Component({
  selector: 'erp-sub-benutzer',
  templateUrl: './erp-sub-benutzer.component.html',
  styleUrls: ['./erp-sub-benutzer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErpSubBenutzerComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() benutzer: IExtBenutzerAustausch[] = [];
  @Input() buchungenBenutzer: IBuchungBenutzer[] = [];
  @Input() buchungen: IBuchung[] = [];
  @Input() gruppen: IGruppe[] = [];
  @Input() width: number = 0;
  @Input() maxCount: number = 10;
  @Input() referenten: boolean;

  public selBenutzer: IExtBenutzerAustausch;
  public benutzerBuchungen__: { [key: string]: IBuchung[] } = {};

  public columns: IListColumn[] = [];
  public visibleColumns: IListColumn[] = [];

  public tablenames = TABLENAMES;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    private intedigiService: IntedigiService,
    appSettings: AppSettings,
    private authService: AuthenticationService,
    private router: Router
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.createColumns();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.calcTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['referenten']) {
      setTimeout(() => {
        this.createColumns();
        this.calcTable();
      });
    }

    if (changes['buchungenBenutzer'] || changes['buchungen']) {
      this.benutzerBuchungen__ = this.buchungenBenutzer.reduce((result: { [key:string]: IBuchung[] }, current: IBuchungBenutzer) => {
        if (!(current.IDBildungszentrum + '_' + current.IDBenutzer in result)) {
          result[current.IDBildungszentrum + '_' + current.IDBenutzer] = [];
        }
        let buchung = this.buchungen.find(
          (item: IBuchung) => item.IDBildungszKd === current.IDBildungszKd && item.IDKunde === current.IDKunde && item.ID === current.IDBuchung
        ) || null;
        if (!isEmpty(buchung)) {
          result[current.IDBildungszentrum + '_' + current.IDBenutzer].push(buchung);
        }
        return result;
      }, <{ [key:string]: IBuchung[] }>{});
    }

    this.detectChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy
  }

  onResize(): void {
    this.calcTable();
    super.onResize();
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  getColumnAlign(column: any): string {
    return (!!column.align && !!column.align.length ? column.align : 'left');
  }

  createColumns(): void {
    this.columns = [
      { 
        name: 'Benutzer', title: 'Benutzer', minWidth: 160, 
        cbOnShow: (dataset: IExtBenutzerAustausch) => { 
          return dataset.Nachname + ', ' + dataset.Vorname;
        },
        cbZusatzinfo: (dataset: IExtBenutzerAustausch) => {
          return dataset.Zusatzinfo;
        } 
      },
      { name: 'Gruppe', title: 'Gruppe', minWidth: 150, cbOnShow: (dataset: IExtBenutzerAustausch) => { 
        let gruppe = this.gruppen.find((item: IGruppe) => item.ID === dataset.IDGruppe) || null;
        return (!isEmpty(gruppe) ? gruppe.Name : '');
      } },
      { 
        name: 'Bestaetigt', title: 'Bestätigt', minWidth: 90, align: 'center', isIcon: true, 
        cbOnShow: (dataset: IExtBenutzerAustausch) => { 
          return (isValid(dataset.kursgruppeBenutzer) ? 'icon-check' : 'icon-delete') 
        }, 
        cbColor: (dataset: IExtBenutzerAustausch) => { 
          return (
            isValid(dataset.kursgruppeBenutzer) ?
            this.appSettings.colorSuccess :
            this.appSettings.colorError
          ); 
        } 
      }
    ]

    if (!this.referenten) {
      this.columns = this.columns.concat(
        { name: 'Lizenzen', title: 'Lizenzen', minWidth: 90, align: 'center', cbOnShow: (dataset: IExtBenutzerAustausch) => { 
          return this.getBenutzerBuchung(dataset).length;
        } }
      );
    }

    this.columns = this.columns.concat([
      { 
        name: 'Aktiv', title: 'Aktiv', minWidth: 80, isIcon: true, align: 'center', 
        cbOnShow: (dataset: IExtBenutzerAustausch) => { 
          return (
            this.intedigiService.isBenutzerAktiv(dataset) ?
            'icon-check' :
            'icon-delete'
          ); 
        },
        cbOnHelp: () => {
          return 'Der Aktiv Zeitraum des Benutzers ist gültig und es liegt keine Sperrung vor';
        }, 
        cbColor: (dataset: IExtBenutzerAustausch) => { 
          return (
            this.intedigiService.isBenutzerAktiv(dataset) ?
            this.appSettings.colorSuccess :
            this.appSettings.colorError
          ); 
        } 
      },
      { name: 'Fachrichtung', title: 'Fachrichtung', minWidth: 140, cbOnShow: (dataset: IExtBenutzerAustausch) => { 
        return dataset.IDFachrichtung;
      } },
      { 
        name: 'Ausbildungsbeginn', title: 'Ausbildungsbeginn', minWidth: 170, 
        cbOnShow: (dataset: IExtBenutzerAustausch) => { 
          return (!!dataset.Ausbildungsbeginn ? moment.utc(dataset.Ausbildungsbeginn).local(true).format('DD.MM.YYYY') : '---') 
        }, 
        cbVisible: (dataset: IExtBenutzerAustausch) => { 
          return (!dataset.Austausch ? true : false)
        }
      },
      { 
        name: 'Ausbildungsende', title: 'Ausbildungsende', minWidth: 170, 
        cbOnShow: (dataset: IExtBenutzerAustausch) => { 
          return (!!dataset.Ausbildungsende ? moment.utc(dataset.Ausbildungsende).local(true).format('DD.MM.YYYY') : '---') 
        }, 
        cbVisible: (dataset: IExtBenutzerAustausch) => { 
          return (!dataset.Austausch ? true : false)
        }
      }
    ]);
  }

  calcTable(): void {
    if (this.width > 0) {
      this.visibleColumns = [];
      let width = 40;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].minWidth + width <= this.width) {
          this.visibleColumns.push(this.columns[i]);
        } else {
          break;
        }
        width += this.columns[i].minWidth;
      }
    } else {
      setTimeout(() => this.calcTable(), 10);
    }

    this.detectChanges();
  }

  getBenutzerBuchung(benutzer: IExtBenutzerAustausch): IBuchung[] {
    if (benutzer.IDBildungszentrum + '_' + benutzer.ID in this.benutzerBuchungen__) {
      return this.benutzerBuchungen__[benutzer.IDBildungszentrum + '_' + benutzer.ID];
    } else {
      return [];
    }
  }

  onBenutzerSelect(): void {
    if (this.isLoggedIn()) {
      this.router.navigate([
        '/kunden/benutzer', JSON.stringify([ this.selBenutzer.ID, this.selBenutzer.IDBildungszentrum ]) ]
      )
    }
  }
}
