import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';

import { isEmpty, PopupService, AuthenticationService, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, CommonPopupFormComponent, DataService } from '@bfeoldenburg/bfe-data-forms';

import { 
  IBildungszentrum, IntedigiService, TABLENAMES, ISchnittstelleERPArtikelIgnoriert, ISettings
} from '@bfeoldenburg/intedigi-shared';


interface INummernkreis {
  ID: number;
  Name: string;
}

@Component({
  selector: 'erp-artikel-ignoriert-popup',
  templateUrl: './erp-artikel-ignoriert-popup.component.html',
  styleUrls: ['./erp-artikel-ignoriert-popup.component.scss']
})
export class ErpArtikelIgnoriertPopupComponent extends CommonPopupFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public data: ISchnittstelleERPArtikelIgnoriert;

  constructor(
    dataService: DataService,
    popupService: PopupService,
    elementRef: ElementRef,
    renderer: Renderer2,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService,
    private authService: AuthenticationService
  ) { 
    super(dataService, popupService, elementRef, renderer, cdRef, toolbarService, appSettings);

    this.fkeyTables = [
      TABLENAMES.BILDUNGSZENTRUM,
      TABLENAMES.LIZENZ_VORLAGE,
      TABLENAMES.KUNDENTYP,
      TABLENAMES.KUNDENTYP2,
      TABLENAMES.SETTINGS
    ];

    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => { 
      this.detectChanges();
    }));
  }

  ngOnInit() {
    super.ngOnInit();

    this.getFTables();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.form.resetCallbackWritable();
  }

  onResize() {
    this.detectChanges();
  }

  isWritable(): boolean {
    return super.isWritable() && this.intedigiService.isInterfaceWritable(this.tablename, (!isEmpty(this.form) ? this.form.getValues() : {}), this.isNew());
  }

  isDeletable(): boolean {
    return super.isDeletable() && this.intedigiService.isInterfaceWritable(this.tablename, (!isEmpty(this.form) ? this.form.getValues() : {}), this.isNew());
  }

  cbIsReadonly(field: string): boolean {
    switch (field) {
    }
    return false;
  }

  onFormInit() {
    this.onDataSet();

    this.form.setCallbackWritable(
      () => this.intedigiService.isInterfaceWritable(this.tablename, (!isEmpty(this.form) ? this.form.getValues() : {}), this.isNew())
    );
  }

  onDataSet() {
    this.onDataChange();
  }

  onDataGet() {
  }

  getFTables() {
    this.addDataSubscription(TABLENAMES.SETTINGS, (data: ISettings[]) => {
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.BILDUNGSZENTRUM, (data: IBildungszentrum[]) => {
      this.detectChanges();
    });
  }

  getBildungszentrum(): string {
    return this.dataService.getDatasetName(TABLENAMES.BILDUNGSZENTRUM, this.data.IDBildungszentrum);
  }

  getNummerBezeichner(): string {
    return this.data.Nummernkreis === 1 ? 'Artikel-ID' : 'Veranstaltungs-ID';
  }

  getNummernkreis(): string {
    return this.data.Nummernkreis  === 1 ? 'Artikel' : 'Veranstaltung';
  }

  isLokal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }
}
