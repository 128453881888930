declare const moment: any;

import { 
  Component, ChangeDetectionStrategy, OnInit, AfterViewInit, OnChanges, OnDestroy, ChangeDetectorRef, ViewChild 
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NotifyService, AppSettings, isEmpty } from '@bfeoldenburg/bfe-shared';
import { 
  ToolbarService, CommonFeaturesComponent, DataService, IFilterGroup, FILTERSELECTION, IDefaultFilter, FILTERTYPE, IListColumn, FXSTATE, IFilterSearchSettings
} from '@bfeoldenburg/bfe-data-forms';

import { IBenutzer, IKunde, ILDAP, IntedigiService, TABLENAMES } from '@bfeoldenburg/intedigi-shared';
import { BaseLdapComponent } from '@bfeoldenburg/bfe-web-apps';


@Component({
  templateUrl: './ldap.component.html',
  styleUrls: ['./ldap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LdapComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public columns: IListColumn[] = [];

  public filterGroups: IFilterGroup[] = [
    <IFilterGroup>{ 
      ID: '__Kunde', Name: 'Kunden', type: FILTERTYPE.Custom, selection: FILTERSELECTION.MutliSearch, filter: [],
      cbFilter: (filterGroup: IFilterGroup, item: ILDAP) => {
        return (
          !filterGroup.IDs.length || 
          (!!filterGroup.IDs.length && (
            filterGroup.IDs.indexOf(item.customerid) > -1 || filterGroup.IDs.indexOf(item.sid) > -1 || filterGroup.IDs.indexOf(item.bzid) > -1
          ))
        );
      },
      cbSearch: (filterGroup: IFilterGroup) => <IFilterSearchSettings>{
        columns: this.dataService.getSearchColumns(TABLENAMES.KUNDE),
        tablename: TABLENAMES.KUNDE,
        tabletitle: 'Kunde',
        minWidth: 1600,
        cbFilter: (dataset: IKunde) => filterGroup.IDs.indexOf(dataset.Kennung) === -1, 
        keyColumn: 'Kennung' 
      }
    },
    <IFilterGroup>{ 
      ID: '__Benutzer', Name: 'Benutzer', type: FILTERTYPE.Custom, selection: FILTERSELECTION.MutliSearch, filter: [],
      cbFilter: (filterGroup: IFilterGroup, item: ILDAP) => {
        return (
          !filterGroup.IDs.length || 
          (!!filterGroup.IDs.length && filterGroup.IDs.indexOf(item.uiddb) > -1)
        );
      },
      cbSearch: (filterGroup: IFilterGroup) => <IFilterSearchSettings>{
        columns: this.dataService.getSearchColumns(TABLENAMES.BENUTZER),
        tablename: TABLENAMES.BENUTZER,
        tabletitle: 'Benutzer',
        minWidth: 1600,
        cbFilter: (dataset: IBenutzer) => filterGroup.IDs.indexOf(dataset.IDBildungszentrum + dataset._uuid) === -1, 
        keyColumn: 'uuidb'
      }
    }
  ];

  public queryFilter: IDefaultFilter[] = [];
  public idFilter: number;
  public queryParams: any;

  @ViewChild(BaseLdapComponent, { static: false }) private baseLdap: BaseLdapComponent;

  constructor(
    dataService: DataService,
    private intedigiService: IntedigiService,
    private notifyService: NotifyService,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private route: ActivatedRoute
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);
  }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  ngAfterViewInit() {
    this.initColumns();
    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => {
      this.initColumns();
      this.detectChanges();
    }));

    this.checkQueryParams();
    this.subscriptions.push(this.dataService.onready.subscribe(() => {
      this.checkQueryParams();
      this.detectChanges();
    }));

    // Überwachung der Routing-Parameter, Übergabe einer ID
    this.subscriptions.push(this.route.params.subscribe(params => {
      if ('IDFILTER' in params && !this.hasQueryParams()) {
        this.idFilter = parseInt(params.IDFILTER);
      }
      // Datensatz bei Bedarf einlesen und anzeigen
      this.detectChanges();
    }));
  }

  ngOnChanges() {
    this.detectChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  detectChanges(cbChanging?: any): void {
    super.detectChanges(cbChanging);
    if (!!this.baseLdap) {
      this.baseLdap.detectChanges();
    }
  }

  isLokal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }

  isDeleted(dataset: ILDAP) {
    return dataset._deleted > 0;
  }

  isFilterReady(): boolean {
    return !this.dataService.neverLoaded(TABLENAMES.KUNDE) && !this.dataService.neverLoaded(TABLENAMES.BENUTZER);
  }

  hasQueryParams(): boolean {
    return !isEmpty(this.queryParams);
  }

  private checkQueryParams__(): void {
    if (this.hasQueryParams()) {
      this.idFilter = null;
      if ('Status' in this.queryParams) {
        this.queryFilter = [
          { ID: '__Status', values: [ parseInt(this.queryParams['Status']) ] }
        ];
      } else {
        this.queryFilter = [];
      }
      if (
        (
          !('IDKunde' in this.queryParams && 'IDBildungszKd' in this.queryParams) || 
          ('IDKunde' in this.queryParams && 'IDBildungszKd' in this.queryParams && !this.dataService.neverLoaded(TABLENAMES.KUNDE))
        ) &&
        (
          !('IDBenutzer' in this.queryParams && 'IDBildungszentrum' in this.queryParams) || 
          ('IDBenutzer' in this.queryParams && 'IDBildungszentrum' in this.queryParams && !this.dataService.neverLoaded(TABLENAMES.BENUTZER))
        )
      ) {
        if ('IDKunde' in this.queryParams && 'IDBildungszKd' in this.queryParams) {
          let kunde = this.dataService.getDataset(
            TABLENAMES.KUNDE, [ this.queryParams['IDKunde'], this.queryParams['IDBildungszKd'] ]
          );
          if (!isEmpty(kunde)) {
            this.queryFilter.push({ ID: '__Kunde', values: [ { ID: kunde.Kennung, Name: kunde.Name } ] });
          } else {
            this.notifyService.error('Unbekannter Kunde');
          }
        }
        if ('IDBenutzer' in this.queryParams && 'IDBildungszentrum' in this.queryParams) {
          let benutzer = this.dataService.getDataset(
            TABLENAMES.BENUTZER, [ this.queryParams['IDBenutzer'], this.queryParams['IDBildungszentrum'] ]
          );
          if (!isEmpty(benutzer)) {
            this.queryFilter.push({ ID: '__Benutzer', values: [ { ID: benutzer.IDBildungszentrum + benutzer._uuid, Name: benutzer.Name } ] });
          } else {
            this.notifyService.error('Unbekannter Benutzer');
          }
        }
      }
      this.detectChanges();
    }
  }

  checkQueryParams(): void {
    this.addDataSubscription(TABLENAMES.KUNDE, () => this.checkQueryParams__());
    this.addDataSubscription(TABLENAMES.BENUTZER, () => this.checkQueryParams__());
    this.checkQueryParams__();
  }

  initColumns(): void {
    let cbColor: any = (dataset: ILDAP) => (!!dataset._deleted ? this.appSettings.colorError : null)
    let cbTextDecoration: any = (dataset: ILDAP) => (!!dataset._deleted ? 'line-through' : null)

    this.columns = [
      { 
        name: 'uiddb', title: 'uiddb', minWidth: 150, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      }
    ];

    if (!this.isLokal()) {
      this.columns.push(
        { 
          name: '_idp', title: 'Idp', minWidth: 150, search: FXSTATE.Callback, sort: { Desc: false, Default: false, State: FXSTATE.Callback }, 
          cbOnShow: (dataset: ILDAP) => this.dataService.getDatasetName(TABLENAMES.IDENTITYPROVIDER, dataset._idp), 
          cbColor: cbColor, cbTextDecoration: cbTextDecoration 
        }
      );
    }

    this.columns = this.columns.concat([
      { 
        name: 'customerid', title: 'customerid', minWidth: 100, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'sid', title: 'sid', minWidth: 100, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'bzid', title: 'bzid', minWidth: 100, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'ou', title: 'ou', minWidth: 60, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'roleid', title: 'roleid', minWidth: 120, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'gid', title: 'gid', minWidth: 120, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'frid', title: 'frid', minWidth: 120, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'salutation', title: 'salutation', minWidth: 100, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'sn', title: 'sn', minWidth: 120, search: FXSTATE.Raw, sort: { Desc: false, Default: true }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'givenname', title: 'givenname', minWidth: 120, search: FXSTATE.Raw, sort: { Desc: false, Default: true }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'mail', title: 'mail', minWidth: 200, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'cn', title: 'cn', minWidth: 150, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'userpassword', title: 'userpassword', minWidth: 130, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      }
    ]);

    for (let i = 1; i <= 20; i++) {
      this.columns.push({ 
        name: 'course' + i, title: 'course' + i, minWidth: 130, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      });
    }
    
    this.columns = this.columns.concat([
      { 
        name: 'lgids', title: 'lgids', minWidth: 150, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'pid', title: 'pid', minWidth: 100, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'laids', title: 'laids', minWidth: 200, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration, cbOnDescription: (dataset: ILDAP) => dataset.laids
      },
      { 
        name: 'klaids', title: 'klaids', minWidth: 200, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration, cbOnDescription: (dataset: ILDAP) => dataset.klaids
      },
      { 
        name: 'dozentplus', title: 'dozentplus', minWidth: 150, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'customername', title: 'customername', minWidth: 200, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'bzname', title: 'bzname', minWidth: 200, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'sname', title: 'sname', minWidth: 200, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'cmseducationfrom', title: 'cmseducationfrom', minWidth: 100, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'cmseducationto', title: 'cmseducationto', minWidth: 100, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'objectclass', title: 'objectclass', minWidth: 150, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: 'settings', title: 'settings', minWidth: 150, search: FXSTATE.Raw, sort: { Desc: false, Default: false }, 
        cbColor: cbColor, cbTextDecoration: cbTextDecoration 
      },
      { 
        name: '_created', title: 'erstellt', minWidth: 100, search: FXSTATE.Callback, sort: { Desc: false, Default: false }, 
        cbOnShow: (dataset: ILDAP) => moment.utc(dataset._created).local().format('DD.MM.YY HH:mm')
      },
      { 
        name: '_edited', title: 'bearbeitet', minWidth: 100, search: FXSTATE.Callback, sort: { Desc: false, Default: false }, 
        cbOnShow: (dataset: ILDAP) => moment.utc(dataset._edited).local().format('DD.MM.YY HH:mm')
      },
      { 
        name: '_lastsync', title: 'synchronisiert', minWidth: 100, search: FXSTATE.Callback, sort: { Desc: false, Default: false }, 
        cbOnShow: (dataset: ILDAP) => { 
          if (!!dataset._lastsync && !!dataset._lastsync.length) {
            return moment.utc(dataset._lastsync).local().format('DD.MM.YY HH:mm'); 
          } else {
            return 'Noch nie';
          }
        }
      }
    ]);
  }
}