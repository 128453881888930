<div class="data-vertrag" *ngIf="isReady()">
  <form [formGroup]="form" autocomplete="off">
    <div class="form-row" *ngIf="!wiedervorlage" [class.hidden]="isSelf()">
      <label class="form-label">Demozugang</label>
      <p-checkbox 
        ExtendControl 
        formControlName="Demozugang" 
        [binary]="true" 
        [readonly]="isReadonly('Demozugang')" 
        (keyup)="onKeyUp($event)" 
        (focusin)="onFocus($event)" 
        (keyup.enter)="focusNext($event)" 
        (click)="setFieldFocus('Demozugang')" 
        (onChange)="onDemozugangChange()"
      ></p-checkbox>
    </div>
    <div class="form-row" *ngIf="!!wiedervorlage">
      <label class="form-label">Auftragsnummer</label>
      <input ExtendControl class="auftragsnummer" pInputText type="text" [required]="!isOptional('ERPNummer')" [readonly]="isReadonly('ERPNummer')" formControlName="ERPNummer" [maxlength]="getFieldLength('ERPNummer')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
    </div>
    <div class="form-row" *ngIf="!!wiedervorlage">
      <label class="form-label">Auftragsdatum</label>
      <p-calendar 
        ExtendControl 
        formControlName="Auftragsdatum" 
        appendTo="body" 
        class="laufzeit-von" 
        dateFormat="dd.mm.yy"
        [firstDayOfWeek]="1"
        [showIcon]="!isReadonly('Auftragsdatum')" 
        [readonlyInput]="isReadonly('Auftragsdatum') || !isReady()" 
        [required]="!isOptional('Auftragsdatum')" 
        [showOnFocus]="!isReadonly('Auftragsdatum')" 
        [monthNavigator]="true" 
        [yearNavigator]="true" 
        [yearRange]="getYearRange()"
        (focusin)="onFocus($event)" 
        (keyup)="onKeyUp($event)" 
        (keyup.enter)="focusNext($event)"  
      ></p-calendar>
    </div>
    <div class="form-row">
      <label class="form-label">Vertragsbeginn</label>
      <p-calendar 
        ExtendControl 
        formControlName="Vertragsbeginn" 
        appendTo="body" 
        class="laufzeit-von" 
        dateFormat="dd.mm.yy"
        [firstDayOfWeek]="1"
        [showIcon]="!isReadonly('Vertragsbeginn')" 
        [readonlyInput]="isReadonly('Vertragsbeginn') || !isReady()" 
        [required]="!isOptional('Vertragsbeginn')" 
        [showOnFocus]="!isReadonly('Vertragsbeginn')" 
        [monthNavigator]="true" 
        [yearNavigator]="true" 
        [yearRange]="getYearRange()"
        (focusin)="onFocus($event)" 
        (keyup)="onKeyUp($event)" 
        (keyup.enter)="focusNext($event)"  
        (onInput)="onVertragsbeginnChange()" 
        (onSelect)="onVertragsbeginnChange()"
      ></p-calendar>
    </div>
    <div class="form-row" *ngIf="!wiedervorlage && isLokalErp()" [class.hidden]="isDemoZugang() || isSelf() || !isKundeBetrieb()">
      <label class="form-label">ÜBS-Status</label>
      <p-dropdown ExtendControl class="uebs" [options]="uebsitems" [required]="!isOptional('UeBS')" [readonly]="isReadonly('UeBS')" [showClear]="isOptional('UeBS') && !(isReadonly('UeBS') || !uebsitems.length)" formControlName="OBJUeBS" fieldName="UeBS" placeholder="ÜBS-Status auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onUeBSItemChange()"></p-dropdown>
    </div>
    <div class="form-row" *ngIf="!wiedervorlage && isLokalErp()" [class.hidden]="isDemoZugang() || isSelf()">
      <label class="form-label">Rechnung erzeugen</label>
      <p-checkbox 
        ExtendControl 
        formControlName="RechnungErzeugen" 
        [binary]="true" 
        [readonly]="isReadonly('RechnungErzeugen')" 
        (keyup)="onKeyUp($event)" 
        (focusin)="onFocus($event)" 
        (keyup.enter)="focusNext($event)" 
        (click)="setFieldFocus('RechnungErzeugen')" 
        (onChange)="onRechnungErzeugenChange()"
      ></p-checkbox>
    </div>
    <div class="form-row" *ngIf="!wiedervorlage && isLokalErp()" [class.hidden]="isDemoZugang() || isSelf()">
      <label class="form-label">Lizenz-Status</label>
      <p-dropdown ExtendControl class="lizenz-status" [options]="lizenzStati" [required]="!isOptional('LizenzStatus')" [readonly]="isReadonly('LizenzStatus')" [showClear]="isOptional('LizenzStatus') && !(isReadonly('LizenzStatus') || !lizenzStati.length)" formControlName="OBJLizenzStatus" fieldName="LizenzStatus" placeholder="Lizenz-Status auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" (onChange)="onLizenzStatusChange()"></p-dropdown>
    </div>
  </form>
</div>