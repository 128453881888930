import * as moment from 'moment';

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';

import { AuthenticationService, isEmpty, isValid, PopupService, AppSettings } from '@bfeoldenburg/bfe-shared';
import { 
  BfeForm, CommonSearchComponent, DataService, ITool, TOOLBAR, ToolbarService, CommonFeaturesToolbarComponent 
} from '@bfeoldenburg/bfe-data-forms';
import { InterfacesService } from '@bfeoldenburg/bfe-web-apps';

import { 
  Gruppe, IBenutzerAustausch, IBenutzerBuchung, IBenutzerKursgruppe, IBereich, IBildungszentrum, IBuchung, IBuchungBenutzer, IFachrichtung, IGruppe, IKunde, IKundeFachrichtung, IKundentypGruppe, IKurs, IKursgruppe, IKursgruppeBenutzer, ILernangebot, ILizenzart, ILizenzVorlage, IntedigiService, IPaket, IPaketgroesse, ISchnittstelleWiedervorlage, TABLENAMES 
} from '@bfeoldenburg/intedigi-shared';

import { WiedervorlageService } from '../../../_services/wiedervorlage.service';

interface IExtBenutzerAustausch extends IBenutzerAustausch {
  kursgruppeBenutzer: IKursgruppeBenutzer;
  benutzerKursgruppe: IBenutzerKursgruppe;
}

interface IKursgruppeSearch extends IKursgruppe {
  Wiedervorlage: boolean;
}

enum ERPVERANTOOL {
  Suche = 0,
  Code,
  Wiedervorlage,
  Kunde,
  Kundencode
}

@Component({
  selector: 'erp-veranstaltung',
  templateUrl: './erp-veranstaltung.component.html',
  styleUrls: ['./erp-veranstaltung.component.scss']
})
export class ErpVeranstaltungComponent extends CommonFeaturesToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
  private form: BfeForm;
  private erpid: string;

  public kursgruppe: IKursgruppe;
  public wiedervorlage: ISchnittstelleWiedervorlage;
  public lizenzVorlage: ILizenzVorlage;
  public kursgruppeBenutzer: IKursgruppeBenutzer[] = [];
  public benutzerKursgruppe: IBenutzerKursgruppe[] = [];
  public kunde: IKunde;
  public kundentypGruppen: IKundentypGruppe[] = [];
  public gruppen: IGruppe[] = [];
  public bereiche: IBereich[] = [];
  public buchungen: IBuchung[] = [];
  public buchungenBenutzer: IBuchungBenutzer[] = [];
  public benutzerBuchungen: IBenutzerBuchung[] = [];
  public benutzer: IBenutzerAustausch[] = [];
  public kundeFachrichtungen: IKundeFachrichtung[] = [];
  public fachrichtungen: IFachrichtung[] = [];
  public kurse: IKurs[] = [];
  public lernangebote: ILernangebot[] = [];
  public lizenzarten: ILizenzart[] = [];
  public pakete: IPaket[] = [];
  public paketgroessen: IPaketgroesse[] = [];
  public bildungszentren: IBildungszentrum[] = [];

  public referenten: IExtBenutzerAustausch[] = [];
  public teilnehmer: IExtBenutzerAustausch[] = [];

  private hTeilnehmer: HTMLElement;
  private hLizenzen: HTMLElement;
  private hBuchungen: HTMLElement;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private authService: AuthenticationService,
    private elementRef: ElementRef,
    private popupService: PopupService,
    private router: Router,
    private route: ActivatedRoute,
    private intedigiService: IntedigiService,
    private wvService: WiedervorlageService,
    private interfacesService: InterfacesService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.form = this.dataService.getForm(TABLENAMES._ERPVERANSTALTUNG);

    this.activateToolbar();

    this.subscriptions.push(this.authService.currentUser.subscribe(() => {
      this.toolbarService.changed(TOOLBAR.MainTop, true);
    }));

    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => {
      this.toolbarService.changed(TOOLBAR.MainTop, true);
    }));
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    // Überwachung der Routing-Parameter, Übergabe einer ID
    this.subscriptions.push(this.route.params.subscribe(params => {
      if ('ID' in params) {
        this.erpid = params['ID'];
        this.loadData();
      }
    }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
    timer().subscribe(() => this.detectChanges());
  }

  loadData(): void {
    let data: any = { subkontext: 'veranstaltungen' };

    if (isValid(this.erpid)) {
      this.form.setFormValue('ERPID', this.erpid);
      this.form.onchange.emit();

      data.ID = this.erpid;

      this.interfacesService.run(
        'interface.erp.lokal.http.php?kontext=anzeige-veranstaltung', data,
        (response: any) => {
          this.kursgruppe = response.Kursgruppe;
          this.wiedervorlage = response.SchnittstelleWiedervorlage;
          this.lizenzVorlage = response.LizenzVorlage;
          this.kursgruppeBenutzer = response.KursgruppeBenutzer;
          this.benutzerKursgruppe = response.BenutzerKursgruppe;
          this.kunde = response.Kunde;
          this.kundentypGruppen = response.KundentypGruppen;
          this.gruppen = response.Gruppen;
          this.bereiche = response.Bereiche;
          this.buchungen = response.Buchungen;
          this.buchungenBenutzer = response.BuchungenBenutzer;
          this.benutzerBuchungen = response.BenutzerBuchungen;
          this.benutzer = response.Benutzer;
          this.fachrichtungen = response.Fachrichtungen;
          this.kurse = response.Kurse;
          this.lernangebote = response.Lernangebote;
          this.lizenzarten = response.Lizenzarten;
          this.pakete = response.Pakete;
          this.paketgroessen = response.Paketgroessen;
          this.bildungszentren = response.Bildungszentren;

          this.referenten = [];
          this.teilnehmer = [];
          let benutzerIDs: { [key: string]: number[] } = {};
          let refGruppen: number[] = [ Gruppe.AUSBILDER, Gruppe.UELUDOZENT ];

          this.teilnehmer = this.kursgruppeBenutzer.reduce((result: IExtBenutzerAustausch[], current: IKursgruppeBenutzer) => {
            let benutzer = this.benutzer.find(
              (item: IBenutzerAustausch) => item.ID === current.IDBenutzer && item.IDBildungszentrum === current.IDBildungszentrum
            ) || null;
            if (!(current.IDBildungszentrum in benutzerIDs)) {
              benutzerIDs[current.IDBildungszentrum] = [];
            }
            benutzerIDs[current.IDBildungszentrum].push(current.IDBenutzer);
            if (!isEmpty(benutzer)) {
              let extBenutzer = <IExtBenutzerAustausch>benutzer;
              if (extBenutzer.IDBildungszentrum !== this.kunde.IDBildungszKd) {
                extBenutzer.Zusatzinfo = this.getName(this.bildungszentren, extBenutzer, 'IDBildungszentrum');
              }
              extBenutzer.kursgruppeBenutzer = current;
              if (refGruppen.indexOf(extBenutzer.IDGruppe) > -1) {
                this.referenten.push(extBenutzer);
              } else {
                return result.concat(extBenutzer);
              }
            }
            return result;
          }, this.teilnehmer);

          this.teilnehmer = this.benutzerKursgruppe.reduce((result: IExtBenutzerAustausch[], current: IBenutzerKursgruppe) => {
            if (!(current.IDBildungszentrum in benutzerIDs) || benutzerIDs[current.IDBildungszentrum].indexOf(current.IDBenutzer) === -1) {
              let benutzer = this.benutzer.find(
                (item: IBenutzerAustausch) => item.ID === current.IDBenutzer && item.IDBildungszentrum === current.IDBildungszentrum
              ) || null;
              if (!isEmpty(benutzer)) {
                let extBenutzer = <IExtBenutzerAustausch>benutzer;
                extBenutzer.benutzerKursgruppe = current;
                if (refGruppen.indexOf(extBenutzer.IDGruppe) > -1) {
                  this.referenten.push(extBenutzer);
                } else {
                  return result.concat(extBenutzer);
                }
              }
            }
            return result;
          }, this.teilnehmer);

          this.detectChanges();
        },
        null,
        true
      );
    }
  }

  activateToolbar() {
    this.addToolbar(TOOLBAR.MainTop);

    this.setTools(
      [
        this.toolbarService.createTool(<ITool>{
          id: ERPVERANTOOL.Suche, name: 'Suche', icon: 'icon-magnifying_glass',
          cbExecute: () => this.search(),
          cbHidden: () => !this.isLoggedIn()
        }),
        this.toolbarService.createTool(<ITool>{
          id: ERPVERANTOOL.Code, name: 'Code', icon: 'icon-printer',
          cbExecute: () => this.print(),
          cbDisabled: () => (!isEmpty(this.kursgruppe) ? !this.kursgruppe?.ERPID : true)
        }),
        this.toolbarService.createTool(<ITool>{
          id: ERPVERANTOOL.Wiedervorlage, name: 'Wiedervorlage', icon: 'icon-navigate_right',
          cbExecute: () => this.openWV(),
          cbHidden: () => !this.isValidWiedervorlage() || !this.isLoggedIn() || !this.intedigiService.isInterfaceLocal()
        }),
        this.toolbarService.createTool(<ITool>{
          id: ERPVERANTOOL.Kunde, name: 'Kunde', icon: 'icon-navigate_right',
          cbExecute: () => this.openKunde(),
          cbDisabled: () => !this.isKundeValid(),
          cbHidden: () => !this.isLoggedIn()
        }),
        this.toolbarService.createTool(<ITool>{
          id: ERPVERANTOOL.Kundencode, name: 'Kunden-Code', icon: 'icon-printer',
          cbExecute: () => this.codeKunde(),
          cbDisabled: () => !this.isKundeValid()
        })
      ],
      TOOLBAR.MainTop
    );
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  getTeilnehmerWidth(): number {
    if (!this.hTeilnehmer) {
      this.hTeilnehmer = this.elementRef.nativeElement.querySelector('#data-teilnehmer');
    }
    if (!!this.hTeilnehmer) {
      return this.hTeilnehmer.offsetWidth;
    }
    return 0;
  }

  getRegistrierungWidth(): number {
    if (!this.hLizenzen) {
      this.hLizenzen = this.elementRef.nativeElement.querySelector('#data-registrierung');
    }
    if (!!this.hLizenzen) {
      return this.hLizenzen.offsetWidth;
    }
    return 0;
  }

  getBuchungenWidth(): number {
    if (!this.hBuchungen) {
      this.hBuchungen = this.elementRef.nativeElement.querySelector('#data-buchungen');
    }
    if (!!this.hBuchungen) {
      return this.hBuchungen.offsetWidth;
    }
    return 0;
  }

  isValidWiedervorlage(): boolean {
    return (!isEmpty(this.wiedervorlage) ? isValid(this.wiedervorlage.ID) : false);
  }

  getColorError(): string {
    return this.appSettings.colorError;
  }

  getColorSuccess(): string {
    return this.appSettings.colorSuccess;
  }

  getName(array: any[] = [], main: any, key: string): string {
    if (!isEmpty(main)) {
      let sub = array.find((item: IKurs) => item.ID === main[key]) || null;
      return (!isEmpty(sub) ? sub.Name : '');
    } else {
      return '';
    }
  }

  getKurs(): string {
    return this.getName(this.kurse, this.kursgruppe, 'IDKurs');
  }

  getLVLizenzart(): string {
    return this.getName(this.lizenzarten, this.lizenzVorlage, 'IDLizenzart');
  }

  getLVBereich(): string {
    return this.getName(this.bereiche, this.lizenzVorlage, 'IDBereich');
  }

  getLVPaketgroesse(): string {
    return this.getName(this.paketgroessen, this.lizenzVorlage, 'IDPaketgroesse');
  }

  getLVPaket(): string {
    return this.getName(this.pakete, this.lizenzVorlage, 'IDPaket');
  }

  getLVLernangebot(): string {
    return this.getName(this.lernangebote, this.lizenzVorlage, 'IDLernangebot');
  }

  getLVFachrichtung(): string {
    return this.getName(this.fachrichtungen, this.lizenzVorlage, 'IDFachrichtung');
  }

  getLaufzeit(): string {
    if (!isEmpty(this.kursgruppe)) {
      if (!!this.kursgruppe.Laufzeit_von && !!this.kursgruppe.Laufzeit_bis) {
        return moment(this.kursgruppe.Laufzeit_von).format('DD.MM.YY') + ' bis ' + moment(this.kursgruppe.Laufzeit_bis).format('DD.MM.YY') ;
      } else if (!!this.kursgruppe.Laufzeit_von) {
        return 'ab ' + moment(this.kursgruppe.Laufzeit_von).format('DD.MM.YY')
      } else if (!!this.kursgruppe.Laufzeit_bis) {
        return 'bis ' + moment(this.kursgruppe.Laufzeit_bis).format('DD.MM.YY')
      } else {
        return '---';
      }
    } else {
      return '';
    }
  }

  search(): void {
    if (this.isLoggedIn()) {
      let erpIDs: number[] = [];

      this.popupService.open(
        CommonSearchComponent, 
        {
          columns: [
            { 
              name: 'ERPID', title: 'ERPID', minWidth: 0, hidden: true, 
              cbOnread: (dataService: DataService, dataset: IKursgruppeSearch) => { return dataset.ERPID; } 
            },
            { 
              name: 'ERPNummer', title: 'Auftragsnummer', minWidth: 160, searchable: true, 
              cbOnread: (dataService: DataService, dataset: IKursgruppeSearch) => { return (isValid(dataset.ERPNummer) ? dataset.ERPNummer : '---'); } 
            },
            { 
              name: 'Name', title: 'Name', minWidth: 90, searchable: true, 
              cbOnread: (dataService: DataService, dataset: IKursgruppeSearch) => { return (isValid(dataset.Name) ? dataset.Name : '---'); } 
            },
            { 
              name: 'Laufzeit', title: 'Laufzeit', minWidth: 300, searchable: true, 
              cbOnread: (dataService: DataService, dataset: IKursgruppeSearch) => { 
                if (!!dataset.Laufzeit_von && !!dataset.Laufzeit_bis) {
                  return moment(dataset.Laufzeit_von).format('DD.MM.YY') + ' bis ' + moment(dataset.Laufzeit_bis).format('DD.MM.YY') ;
                } else if (!!dataset.Laufzeit_von) {
                  return 'ab ' + moment(dataset.Laufzeit_von).format('DD.MM.YY')
                } else if (!!dataset.Laufzeit_bis) {
                  return 'bis ' + moment(dataset.Laufzeit_bis).format('DD.MM.YY')
                } else {
                  return '---';
                }
              } 
            },
            { 
              name: 'Wiedervorlage', title: 'Wiedervorlage', minWidth: 140, align: 'center', 
              cbOnread: (dataService: DataService, dataset: IKursgruppeSearch) => { return (!!dataset.Wiedervorlage ? 'Ja' : 'Nein'); } 
            }
          ],
          tablename: TABLENAMES.KURSGRUPPE,
          tabletitle: 'ERP-Veranstaltung',
          minWidth: 1600,
          cbFilter: (dataset: IKursgruppe) => { 
            let double = false;
            if (erpIDs.indexOf(dataset.ERPID) > -1) {
              double = true;
            }
            erpIDs.push(dataset.ERPID);
            return !double && !!dataset.ERPID  && this.intedigiService.getBildungszentrum() === dataset.IDBildungszKd;
          },
          cbManipulate: (datasets: IKursgruppeSearch[] = []) => {
            datasets = datasets.reduce((result: IKursgruppeSearch[], current: IKursgruppeSearch) => {
              current.Wiedervorlage = this.dataService.hasDataset(
                TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE, [ this.intedigiService.getBildungszentrum(), 'erp', 'veranstaltungen', current.ERPID ]
              );
              return result.concat(current);
            }, <IKursgruppeSearch[]>[]);

            let wiedervorlagen = <ISchnittstelleWiedervorlage[]>this.dataService.getData(TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE, (item: ISchnittstelleWiedervorlage) => {
              return (
                item.Kontext === 'veranstaltungen' && this.intedigiService.getBildungszentrum() === item.IDBildungszentrum && 
                erpIDs.indexOf(item.ID) === -1
              );
            });

            datasets = wiedervorlagen.reduce((result: IKursgruppeSearch[], current: ISchnittstelleWiedervorlage) => {
              try {
                let wvdata = JSON.parse(current.Data);
                return result.concat(<IKursgruppeSearch>{
                  ERPID: wvdata.Vertragsdaten.ERPID,
                  ERPNummer: wvdata.Vertragsdaten.ERPNummer,
                  Name: wvdata.Kursgruppe.Name,
                  Laufzeit_von: wvdata.Kursgruppe.Laufzeit_von,
                  Laufzeit_bis: wvdata.Kursgruppe.Laufzeit_bis,
                  Wiedervorlage: true
                });
              } catch (e: any) {
                return result;
              }
            }, datasets);
            return datasets;
          },
          cbSort: (itemA: IKursgruppe, itemB: IKursgruppe) => {
            if (itemA.ERPNummer > itemB.ERPNummer) {
              return -1;
            } else {
              return 1;
            }
          }
        },
        {
          onselect: (dataset: any) => { 
            this.router.navigate([ '/erp-veranstaltungen', dataset.ERPID ]);
          },
          oncancel: () => {  }
        }
      );
    }
  }

  isKundeValid(): boolean {
    return (!isEmpty(this.kunde) ? isValid(this.kunde.ID) : false);
  }

  openKunde(): void {
    if (this.isLoggedIn()) {
      this.router.navigate(['/kunden/kunden', JSON.stringify([ this.kunde.ID, this.kunde.IDBildungszKd ]) ]);
    }
  }

  print(): void {
    window.open(
      'interface.erp.lokal.http.php?kontext=druck-veranstaltung&ERPID=' + this.kursgruppe.ERPID + '&ID=' + this.kursgruppe.ID + '&IDKunde=' + this.kursgruppe.IDKunde + '&IDBildungszKd=' + this.kursgruppe.IDBildungszKd, 
      '_blank'
    );
  }

  codeKunde(): void {
    window.open('interface.erp.lokal.http.php?kontext=druck-kunde&ERPID=' + this.kursgruppe.ERPID + '&IDKunde=' + this.kunde.ID + '&IDBildungszKd=' + this.kunde.IDBildungszKd, '_blank');
  }

  openWV(): void {
    this.wvService.setSelected(this.wiedervorlage);
    this.router.navigate([ '/wiedervorlage' ]);
  }
}
