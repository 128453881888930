import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, CommonFeaturesComponent, DataService } from '@bfeoldenburg/bfe-data-forms';

import { ISchnittstelleWiedervorlage, TABLENAMES } from '@bfeoldenburg/intedigi-shared';

import { WiedervorlageService } from '../../../_services/wiedervorlage.service';

@Component({
  selector: 'wiedervorlage',
  templateUrl: './wiedervorlage.component.html',
  styleUrls: ['./wiedervorlage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WiedervorlageComponent extends CommonFeaturesComponent implements OnInit {

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private route: ActivatedRoute,
    private wiedervorlageService: WiedervorlageService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.dataService.readData(TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE);

    this.subscriptions.push(this.wiedervorlageService.onselect.subscribe(() => this.detectChanges()));
  }

  ngOnInit(): void {
    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => this.getFKeyData()));
  }

  isSelected(): boolean {
    return this.wiedervorlageService.isSelected();
  }

  isAuftragSelected(): boolean {
    return this.wiedervorlageService.isAuftragSelected();
  }

  isVeranstaltungSelected(): boolean {
    return this.wiedervorlageService.isVeranstaltungSelected();
  }

  getFKeyData(): void {
    this.addDataSubscription(TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE, (data: ISchnittstelleWiedervorlage[]) => {
      this.wiedervorlageService.setWiedervorlagen(data);
    });

    this.wiedervorlageService.setWiedervorlagen(<ISchnittstelleWiedervorlage[]>this.dataService.getData(TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE));
  }
}
