import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { CalendarModule, Calendar } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChartModule } from 'primeng/chart';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EditorModule } from 'primeng/editor';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

import { BfeSharedModule, MockLocalStorage } from '@bfeoldenburg/bfe-shared';
import { 
  BfeDataFormsModule, Callbacks, InternalFields, IProviderSettings, MenuItems, SearchColumns 
} from '@bfeoldenburg/bfe-data-forms';
import { BfeWebAppsModule, webAppCallbacks, webAppInternalFields, webAppSearchColumns } from '@bfeoldenburg/bfe-web-apps';
import { 
  intedigiCallbacks, intedigiInternalFields, intedigiSearchColumns, IntedigiSharedModule 
} from '@bfeoldenburg/intedigi-shared';

import { ErrorInterceptor } from '@bfeoldenburg/bfe-shared';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { appSettings } from '../environments/app-settings';
import { AppSettings } from '@bfeoldenburg/bfe-shared';

import { AdminLizenzenPopupComponent } from './_components/workflows/lizenzverkauf/lizenzen/admin-lizenzen-popup/admin-lizenzen-popup.component';
import { BenutzerSchnellerfassungComponent } from './_components/workflows/benutzer-schnellerfassung/benutzer-schnellerfassung.component';
import { CronjobsComponent } from './_components/system/cronjobs/cronjobs.component';
import { CustomEmailVorlagePopupComponent } from './_components/email-vorlagen/custom-email-vorlage-popup/custom-email-vorlage-popup.component';
import { EinstellungenComponent } from './_components/system/einstellungen/einstellungen.component';
import { EmailVorlagenComponent } from './_components/email-vorlagen/email-vorlagen.component';
import { ErpBenutzerComponent } from './_components/schnittstellen/erp-benutzer/erp-benutzer.component';
import { ErpBenutzerPopupComponent } from './_components/schnittstellen/erp-benutzer/erp-benutzer-popup/erp-benutzer-popup.component';
import { ErpVeranstaltungComponent } from './_components/schnittstellen/erp-veranstaltung/erp-veranstaltung.component';
import { ErpSubBenutzerComponent } from './_components/schnittstellen/erp-veranstaltung/erp-sub-benutzer/erp-sub-benutzer.component';
import { ErpArtikelnummernComponent } from './_components/schnittstellen/erp-artikelnummern/erp-artikelnummern.component';
import { ErpArtikelnummerPopupComponent } from './_components/schnittstellen/erp-artikelnummern/erp-artikelnummer-popup/erp-artikelnummer-popup.component';
import { ErpArtikelIgnoriertComponent } from './_components/schnittstellen/erp-artikel-ignoriert/erp-artikel-ignoriert.component';
import { ErpArtikelIgnoriertPopupComponent } from './_components/schnittstellen/erp-artikel-ignoriert/erp-artikel-ignoriert-popup/erp-artikel-ignoriert-popup.component';
import { FehlerComponent } from './_components/fehler/fehler.component';
import { GrunddatenComponent } from './_components/workflows/lizenzverkauf/grunddaten/grunddaten.component';
import { HomeComponent } from './_components/home/home.component';
import { LdapComponent } from './_components/schnittstellen/ldap/ldap.component';
import { LizenzenComponent } from './_components/workflows/lizenzverkauf/lizenzen/lizenzen.component';
import { LizenzPopupComponent } from './_components/workflows/lizenzverkauf/lizenzen/lizenz-popup/lizenz-popup.component';
import { LizenzverkaufToolbarComponent } from './_components/workflows/lizenzverkauf/lizenzverkauf-toolbar/lizenzverkauf-toolbar.component';
import { SchnittstellenComponent } from './_components/schnittstellen/schnittstellen/schnittstellen.component';
import { SubKundenadminComponent } from './_components/workflows/sub-kundenadmin/sub-kundenadmin.component';
import { SubKundendatenComponent } from './_components/workflows/sub-kundendaten/sub-kundendaten.component';
import { SubLizenzenComponent } from './_components/workflows/lizenzverkauf/lizenzen/sub-lizenzen/sub-lizenzen.component';
import { SubPositionenComponent } from './_components/workflows/wiedervorlage/wiedervorlage-auftrag-form/sub-positionen/sub-positionen.component';
import { SubPositionPopupComponent } from './_components/workflows/wiedervorlage/wiedervorlage-auftrag-form/sub-positionen/sub-position-popup/sub-position-popup.component';
import { SubVertragsdatenComponent } from './_components/workflows/sub-vertragsdaten/sub-vertragsdaten.component';
import { UsersComponent } from './_components/users/users.component';
import { UserPopupComponent } from './_components/users/user-popup/user-popup.component';
import { WiedervorlageAuftragFormComponent } from './_components/workflows/wiedervorlage/wiedervorlage-auftrag-form/wiedervorlage-auftrag-form.component';
import { WiedervorlageComponent } from './_components/workflows/wiedervorlage/wiedervorlage.component';
import { WiedervorlageListComponent } from './_components/workflows/wiedervorlage/wiedervorlage-list/wiedervorlage-list.component';
import { WiedervorlageVeranstaltungFormComponent } from './_components/workflows/wiedervorlage/wiedervorlage-veranstaltung-form/wiedervorlage-veranstaltung-form.component';
import { WiedervorlageSubPositionenComponent } from './_components/workflows/wiedervorlage/wiedervorlage-veranstaltung-form/wiedervorlage-sub-benutzer/wiedervorlage-sub-benutzer.component';

import { LizenzverkaufService } from './_services/lizenzverkauf.service';
import { WiedervorlageService } from './_services/wiedervorlage.service';

import { menuItems } from './_models/menu-items';


// Alle ProviderSettings aus bfe-data-forms erstellen
let searchColumns__ = new SearchColumns();
let internalFields__ = new InternalFields();
let callbacks__ = new Callbacks();
let menuItems__ = new MenuItems(menuItems);

// Die Provider-Settings aus bfe-web-apps ergänzen
searchColumns__.addColumns(webAppSearchColumns);
internalFields__.addFields(webAppInternalFields);
callbacks__.addCallbacks(webAppCallbacks);

// Die lokalen Provider-Settings ergänzen
searchColumns__.addColumns(intedigiSearchColumns);
internalFields__.addFields(intedigiInternalFields);
callbacks__.addCallbacks(intedigiCallbacks);

let providerSettings: IProviderSettings = <IProviderSettings>Object.assign({
  appSettings: appSettings,
  searchColumns: searchColumns__,
  internalFields: internalFields__,
  callbacks: callbacks__,
  menuItems: menuItems__
}, {});

Calendar.prototype.onInputKeydown = function (event) {
  this.isKeydown = true;
  if (event.keyCode === 9 || event.keyCode === 13) {
      this.hideOverlay();
  }
};


@NgModule({
  imports: [
    /** Angular */
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    /** PrimeNG */
    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    ChartModule,
    CheckboxModule,
    DropdownModule,
    EditorModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    TableModule,
    TabViewModule,
    ProgressSpinnerModule,
    /** Others */
    BfeSharedModule.forRoot(),
    BfeDataFormsModule.forRoot(providerSettings),
    BfeWebAppsModule.forRoot(providerSettings),
    IntedigiSharedModule.forRoot(providerSettings),
    /*StammdatenModule.forRoot(providerSettings),
    KundenModule.forRoot(providerSettings),
    LernangeboteModule.forRoot(providerSettings),*/
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    CronjobsComponent,
    UsersComponent,
    UserPopupComponent,
    LdapComponent,
    EinstellungenComponent,
    SchnittstellenComponent,
    ErpArtikelnummernComponent,
    ErpArtikelnummerPopupComponent,
    SubKundendatenComponent,
    GrunddatenComponent,
    SubKundenadminComponent,
    LizenzenComponent,
    SubLizenzenComponent,
    LizenzPopupComponent,
    SubVertragsdatenComponent,
    WiedervorlageComponent,
    WiedervorlageListComponent,
    WiedervorlageAuftragFormComponent,
    SubPositionenComponent,
    SubPositionPopupComponent,
    SubPositionPopupComponent,
    ErpArtikelIgnoriertComponent,
    ErpArtikelIgnoriertPopupComponent,
    WiedervorlageVeranstaltungFormComponent,
    WiedervorlageSubPositionenComponent,
    ErpBenutzerComponent,
    ErpBenutzerPopupComponent,
    FehlerComponent,
    ErpVeranstaltungComponent,
    ErpSubBenutzerComponent,
    BenutzerSchnellerfassungComponent,
    AdminLizenzenPopupComponent,
    LizenzverkaufToolbarComponent,
    EmailVorlagenComponent,
    CustomEmailVorlagePopupComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: SearchColumns, useValue: searchColumns__ },
    { provide: InternalFields, useValue: internalFields__ },
    { provide: Callbacks, useValue: callbacks__ },
    { provide: MenuItems, useValue: menuItems__ },
    { provide: AppSettings, useValue: appSettings },
    LizenzverkaufService,
    WiedervorlageService,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: {} } 
  ],
  entryComponents: [
    UserPopupComponent,
    ErpArtikelnummerPopupComponent,
    LizenzPopupComponent,
    SubPositionPopupComponent,
    ErpArtikelIgnoriertPopupComponent,
    ErpBenutzerPopupComponent,
    AdminLizenzenPopupComponent,
    LizenzverkaufToolbarComponent,
    CustomEmailVorlagePopupComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
