<extended-list
  tablename="user_settings" 
  alias="__email_vorlage" 
  tabletitle="E-Mail-Vorlage"
  [columns]="columns"
  [fKeyTables]="fKeyTables"
  [cbFilter]="cbFilter"
  [popupComponent]="popupComponent"
  [historySettings]="historySettings"
  [disableHistory]="true"
  [stackedWidth]="570"
  [maxWidth]="900"
  [expandable]="false"
  [disableExport]="true"
>
</extended-list>
