import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { AppSettings, isValid } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, CommonFeaturesComponent, DataService } from '@bfeoldenburg/bfe-data-forms';


@Component({
  selector: 'fehler',
  templateUrl: './fehler.component.html',
  styleUrls: ['./fehler.component.scss']
})
export class FehlerComponent extends CommonFeaturesComponent implements OnInit {
  public errormsg: string;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService, 
    dataService: DataService,
    appSettings: AppSettings
  ) { 
    super(cdRef, toolbarService, dataService, appSettings); 

    this.errormsg = this.getCookie('ERPError');

    if (isValid(this.errormsg)) {
      document.cookie = "ERPError=; expires=Thu, 01 Jan 1970 00:00:01 GMT; secure; samesite=none; path=/";
    }
  }

  ngOnInit(): void {
  }

  getCookie(name: string): string {
    let cookie: any = {};
    //console.log('getCookie', name, document.cookie);
    document.cookie.split(';').forEach(function(el) {
      let [k,v] = el.split('=');
      cookie[k.trim()] = v;
    })
    return cookie[name];
  }
}
