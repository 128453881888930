<extended-list
  tablename="users" 
  tabletitle="Zugang"
  mainPKey="id"
  [columns]="columns"
  [popupComponent]="popupComponent"
  [stackedWidth]="550"
  [maxWidth]="1000"
  [expandable]="false"
  [disableExport]="true"
  emptyMessage="Es konnten keine Zugänge ermittelt werden"
>
</extended-list>
