<div class="data-column">
  <div class="data-main-kunde">
    <div *ngIf="isReady()">
      <div class="data-form">
        <p-tabView (onChange)="tabviewChanged($event)">
          <p-tabPanel header="Kundendaten">
            <ng-template pTemplate="header">
              <span class="p-tabview-title" [style.color]="(!isKundendatenValid() ? getColorError() : null)">Kundendaten</span>
            </ng-template>
            <div class="form-row form-buttons top responsive">
              <button pButton type="button" icon="icon-document_empty" label="Neukunde" class="p-button-rounded" [class.p-button-outlined]="!isNew()" [disabled]="isLoading()" (click)="reset()" *ngIf="!wiedervorlage"></button>
              <button pButton type="button" icon="icon-magnifying_glass" label="Bestandskunde" class="p-button-rounded" [class.p-button-outlined]="isNew()" [disabled]="isLoading()" (click)="search()" *ngIf="!wiedervorlage"></button>
              <div style="flex: 1; min-height: 100%;"></div>
              <button pButton type="button" icon="icon-magnifying_glass" label="ERP-Suche" class="p-button-rounded p-button-outlined" [disabled]="isLoading()" (click)="orbisSearch()" *ngIf="isErpAktiv() && !wiedervorlage && !isERPDataset()" style="margin-left: 20px !important;"></button>
              <button pButton type="button" icon="icon-magnifying_glass" label="ERP-Aktualisierung" class="p-button-rounded p-button-outlined" [disabled]="isLoading()" (click)="orbisUpdate()" *ngIf="isErpAktiv() && (!!wiedervorlage || isERPDataset())" style="margin-left: 20px !important;"></button>
            </div>
            <form [formGroup]="form" autocomplete="off">
              <div class="form-row" [class.hidden]="isLokal()">
                <label class="form-label">Bildungszentrum</label>
                <p-dropdown ExtendControl class="bildungszentrum" [options]="bildungszentren" [required]="!isOptional('IDBildungszKd')" [readonly]="isReadonly('IDBildungszKd')" formControlName="OBJBildungszKd" fieldName="IDBildungszKd" placeholder="Bildungszentrum auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onBildungszentrumChange()"></p-dropdown>
              </div>
              <div class="form-row" [class.hidden]="!hasERPNummer() && isErpAktiv()">
                <label class="form-label">ERP-Nummer</label>
                <input ExtendControl class="nummer" pInputText type="text" [required]="!isOptional('ERPNummer')" [readonly]="isReadonly('ERPNummer')" formControlName="ERPNummer" [maxlength]="getFieldLength('ERPNummer')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)">
              </div>
              <div class="form-row">
                <label class="form-label">Kundentyp</label>
                <p-dropdown ExtendControl class="kundentyp" [options]="kundentypen" [required]="!isOptional('IDKundentyp')" [readonly]="isReadonly('IDKundentyp')" [showClear]="isOptional('IDKundentyp') && !isReadonly('IDKundentyp')" formControlName="OBJKundentyp" fieldName="IDKundentyp" placeholder="Kundentyp auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onKundentypChange()"></p-dropdown>
              </div>
              <div class="form-row">
                <label class="form-label">Kundentyp 2</label>
                <p-dropdown ExtendControl class="kundentyp" [options]="kundentypen2" [required]="!isOptional('IDKundentyp2')" [readonly]="isReadonly('IDKundentyp2')" [showClear]="isOptional('IDKundentyp2') && !isReadonly('IDKundentyp2')" formControlName="OBJKundentyp2" fieldName="IDKundentyp2" placeholder="Kundentyp 2 auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onKundentyp2Change()"></p-dropdown>
              </div>
              <div class="form-row">
                <label class="form-label">Name 1</label>
                <input ExtendControl class="name" pInputText type="text" [required]="!isOptional('Name')" [readonly]="isReadonly('Name')" formControlName="Name" [maxlength]="getFieldLength('Name')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
              </div>
              <div class="form-row">
                <label class="form-label">Name 2</label>
                <input ExtendControl class="name" pInputText type="text" [required]="!isOptional('Name2')" [readonly]="isReadonly('Name2')" formControlName="Name2" [maxlength]="getFieldLength('Name2')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
              </div>
              <div class="form-row">
                <label class="form-label">Name 3</label>
                <input ExtendControl class="name" pInputText type="text" [required]="!isOptional('Name3')" [readonly]="isReadonly('Name3')" formControlName="Name3" [maxlength]="getFieldLength('Name3')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
              </div>
              <div class="form-row">
                <label class="form-label">Adresse</label>
                <input ExtendControl class="adresse" pInputText type="text" [required]="!isOptional('Adresse')" [readonly]="isReadonly('Adresse')" formControlName="Adresse" [maxlength]="getFieldLength('Adresse')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
              </div>
              <div class="form-row" [class.hidden]="isERPDataset()">
                <label class="form-label">PLZ / Ort</label>
                <div class="ort-box">
                  <p-autoComplete ExtendControl class="ort" [suggestions]="plzFiltered" (completeMethod)="filterOrt($event)" [required]="!isOptional('IDPostleitzahl') || !isOptional('Ort')" [readonly]="isReadonly('IDPostleitzahl') && isReadonly('Ort')" formControlName="OBJPostleitzahl" fieldName="IDPostleitzahl" placeholder="PLZ und Ort auswählen (Autovervollständigung)" field="Fullname" appendTo="body" [minLength]="2" [autoHighlight]="true" [delay]="200" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onSelect)="onOrtChange()" (onBlur)="onOrtChange()" autocomplete="nope"></p-autoComplete>
                  <div class="ort-clear pi pi-times" (click)="resetOrt()" *ngIf="isOrtValid()"></div>
                </div>
              </div>
              <div class="form-row" [class.hidden]="!isERPDataset()">
                <label class="form-label">PLZ / Ort</label>
                <div class="ort-box erp">
                  <input ExtendControl class="erp-plz" pInputText type="text" [required]="!isOptional('IDPostleitzahl2')" [readonly]="isReadonly('IDPostleitzahl2')" formControlName="IDPostleitzahl2" [maxlength]="getFieldLength('IDPostleitzahl2')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" (change)="onPostleitzahl2Change()">
                  <input ExtendControl class="erp-ort" pInputText type="text" [required]="!isOptional('Ort2')" [readonly]="isReadonly('Ort2')" formControlName="Ort2" [maxlength]="getFieldLength('Ort2')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" (change)="onOrt2Change()">
                </div>
              </div>
              <div class="form-row">
                <label class="form-label">Telefon</label>
                <input ExtendControl class="telefon" pInputText type="text" [required]="!isOptional('Telefon')" [readonly]="isReadonly('Telefon')" formControlName="Telefon" [maxlength]="getFieldLength('Telefon')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
              </div>
              <div class="form-row">
                <label class="form-label">Telefax</label>
                <input ExtendControl class="telefon" pInputText type="text" [required]="!isOptional('Telefax')" [readonly]="isReadonly('Telefax')" formControlName="Telefax" [maxlength]="getFieldLength('Telefax')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
              </div>
              <div class="form-row">
                <label class="form-label">Mobil</label>
                <input ExtendControl class="telefon" pInputText type="text" [required]="!isOptional('Mobil')" [readonly]="isReadonly('Mobil')" formControlName="Mobil" [maxlength]="getFieldLength('Mobil')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
              </div>
              <div class="form-row">
                <label class="form-label">E-Mail</label>
                <input ExtendControl class="email" pInputText type="text" [required]="!isOptional('Email')" [readonly]="isReadonly('Email')" formControlName="Email" [maxlength]="getFieldLength('Email')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)" >
              </div>
              <div class="form-row">
                <label class="form-label">Rechnung p. E-Mail</label>
                <p-checkbox 
                  ExtendControl 
                  formControlName="RechnungPerMail" 
                  [binary]="true" 
                  [readonly]="isReadonly('RechnungPerMail')" 
                  (keyup)="onKeyUp($event)" 
                  (focusin)="onFocus($event)" 
                  (keyup.enter)="focusNext($event)" 
                  (click)="setFieldFocus('RechnungPerMail')" 
                  (onChange)="onRechnungPerMailChange()"
                ></p-checkbox>
              </div>
              <div class="form-row">
                <label class="form-label">Einstellungen</label>
                <textarea ExtendControl class="einstellungen" [rows]="3" pInputTextarea [required]="!isOptional('Einstellungen')" [readonly]="isReadonly('Einstellungen')" formControlName="Einstellungen" [maxlength]="getFieldLength('Einstellungen')" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.control.enter)="textareaNextLine($event)" (keydown.enter)="textareaKeyDownEnter($event)" (keyup.enter)="focusNext($event)" ></textarea>
              </div>
              <div>
                <div class="textarea-info">STRG + Enter für einen Zeilenumbruch</div>
              </div>
            </form> 
            <div class="form-row" style="margin-top: 20px;">
              <div class="sub-title">Ansprechpartner</div>
            </div>
            <div *ngIf="isReady()">
              <kunde-ansprechpartner
                [data]="data"
                [form]="form"
                [tablename]="tablename"
                [writable]="isWritable()"
                [loading]="isLoading()"
              ></kunde-ansprechpartner>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Rechnungsadresse">
            <ng-template pTemplate="header">
              <span class="p-tabview-title" [style.color]="(!isRechnungsadresseValid() ? getColorError() : null)">Rechnungsadresse</span>
            </ng-template>
            <form [formGroup]="form" autocomplete="off">
              <div class="form-row">
                <label class="form-label">Abweichend</label>
                <p-checkbox 
                  ExtendControl 
                  formControlName="Abweichend_RE" 
                  [binary]="true" 
                  [readonly]="isReadonly('Abweichend_RE')" 
                  (keyup)="onKeyUp($event)" 
                  (focusin)="onFocus($event)" 
                  (keyup.enter)="focusNext($event)" 
                  (click)="setFieldFocus('Abweichend_RE')" 
                  (onChange)="onAbweichendREChange()"
                ></p-checkbox>
              </div>
            </form>
            <kunde-rechnungsadresse
              [data]="data"
              [tablename]="tablename"
              [form]="form"
              [postleitzahlen]="postleitzahlen"
              [writable]="isWritable()"
              [loading]="isLoading()"
            ></kunde-rechnungsadresse>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
  <!--<div class="data-main-ansprechpartner">
    <div class="form-row">
      <div class="sub-title">Ansprechpartner</div>
    </div>
    <div *ngIf="isReady()">
      <kunde-ansprechpartner
        [data]="data"
        [form]="form"
        [tablename]="tablename"
        [writable]="isWritable()"
        [loading]="isLoading()"
      ></kunde-ansprechpartner>
    </div>
  </div>-->
</div>