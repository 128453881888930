import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { isValid, AppSettings } from '@bfeoldenburg/bfe-shared';
import { ToolbarService, CommonFeaturesComponent, DataService, IListColumn } from '@bfeoldenburg/bfe-data-forms';

import { TABLENAMES } from '@bfeoldenburg/intedigi-shared';

import { ILizenzverkauf, LIZENZSTATUS } from '../../../../../_services/lizenzverkauf.service';

@Component({
  selector: 'sub-lizenzen',
  templateUrl: './sub-lizenzen.component.html',
  styleUrls: ['./sub-lizenzen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubLizenzenComponent extends CommonFeaturesComponent implements OnInit, OnDestroy {
  @Input() title: string;

  @Input() lizenzen: ILizenzverkauf[] = [];

  @Input() loading: boolean = false;

  @Output() onselect: EventEmitter<ILizenzverkauf> = new EventEmitter<ILizenzverkauf>();

  public columns: IListColumn[] = [];
  public visibleColumns: IListColumn[] = [];

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private sanitizer: DomSanitizer
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onResize(): void {
    super.onResize();
  }

  isLoading(): boolean {
    return this.loading;
  }

  onLizenzSelect(lizenz: ILizenzverkauf): void {
    if (!this.isLoading()) {
      this.onselect.emit(lizenz);
    }
  }

  getName(lizenz: ILizenzverkauf): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      lizenz.extLizenzVorlage.Name + 
      (lizenz.extLizenzVorlage.status === LIZENZSTATUS.Verlaengerungen ? ' <span style="color: ' + this.appSettings.colorError + ';">(Verlängerung)</span>' : '')
    );
  }

  hasFachrichtung(lizenz: ILizenzverkauf): boolean {
    return isValid(lizenz.extLizenzVorlage.fachrichtung)
  }

  getFachrichtung(lizenz: ILizenzverkauf): string {
    return (isValid(lizenz.extLizenzVorlage.fachrichtung) ? lizenz.extLizenzVorlage.fachrichtung.ID : '');
  }

  getFachrichtungUndLizenzart(lizenz: ILizenzverkauf): string {
    return (isValid(lizenz.extLizenzVorlage.fachrichtung) ? lizenz.extLizenzVorlage.fachrichtung.ID + ', ' + this.getLizenzart(lizenz) : this.getLizenzart(lizenz));
  }

  getLizenzart(lizenz: ILizenzverkauf): string {
    return this.dataService.getDatasetName(TABLENAMES.LIZENZART, lizenz.extLizenzVorlage.IDLizenzart);
  }

  getSumme(): number {
    return this.lizenzen.reduce((result: number, current: ILizenzverkauf) => {
      return result + current.anzahl;
    }, 0)
  }
}
