<div class="data-popup popup-outer" *ngIf="!!form"> 
  <bfe-popup-title [title]="getTitle()" (onclose)="cancel()"></bfe-popup-title>
  <div class="data-form popup-inner">
    <form [formGroup]="form" autocomplete="off">
      <div class="form-row">
        <label class="form-label">ERP-ID</label>
        <label>{{ getERPID() }}</label>
      </div>
      <div class="form-row">
        <label class="form-label">ERP-Bezeichnung</label>
        <label>{{ getERPBezeichnung() }}</label>
      </div>
      <div class="form-row">
        <label class="form-label">Lizenz-Vorlage</label>
        <p-autoComplete ExtendControl class="vorlage" [suggestions]="vorlagenAutocomplete" (completeMethod)="filterVorlagenAutocomplete($event)" [dropdown]="!isReadonly('IDLizenzVorlage')" [required]="!isOptional('IDLizenzVorlage')" [readonly]="isReadonly('IDLizenzVorlage')" formControlName="OBJLizenzVorlage" fieldName="IDLizenzVorlage" placeholder="Vorlage auswählen" field="Name" appendTo="body" [minLength]="2" [autoHighlight]="true" [delay]="200" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onSelect)="onLizenzVorlageChange()" (onBlur)="onLizenzVorlageChange()" autocomplete="nope" *ngIf="vorlageNeeded()"></p-autoComplete>
        <label *ngIf="!vorlageNeeded()">{{ getVorlage() }}</label>
      </div>
      <div class="form-row" [class.hidden]="!vorlageNeeded()">
        <label class="form-label">Kundentyp</label>
        <p-dropdown ExtendControl class="kundentyp" [options]="kundentypen" [required]="!isOptional('IDKundentyp')" [readonly]="isReadonly('IDKundentyp')" [showClear]="isOptional('IDKundentyp') && !(isReadonly('IDKundentyp') || !kundentypen.length)" formControlName="OBJKundentyp" fieldName="IDKundentyp" placeholder="Kundentyp auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onKundentypChange()"></p-dropdown>
      </div>
      <div class="form-row" [class.hidden]="!vorlageNeeded()">
        <label class="form-label">Kundentyp 2</label>
        <p-dropdown ExtendControl class="kundentyp" [options]="kundentypen2" [required]="!isOptional('IDKundentyp2')" [readonly]="isReadonly('IDKundentyp2')" [showClear]="isOptional('IDKundentyp2') && !(isReadonly('IDKundentyp2') || !kundentypen2.length)" formControlName="OBJKundentyp2" fieldName="IDKundentyp2" placeholder="Kundentyp 2 auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onKundentyp2Change()"></p-dropdown>
      </div>
      <div class="form-row" [class.hidden]="!vorlageNeeded() || !isBetriebTeilnehmerAusbildungsLizenz()">
        <label class="form-label">ÜBS-Status</label>
        <p-dropdown ExtendControl class="uebs" [options]="uebsitems" [required]="!isOptional('UeBS')" [readonly]="isReadonly('UeBS')" [showClear]="isOptional('UeBS') && !(isReadonly('UeBS') || !uebsitems.length)" formControlName="OBJUeBS" fieldName="UeBS" placeholder="ÜBS-Status auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onUeBSItemChange()"></p-dropdown>
      </div>
      <div class="form-row" *ngIf="hasBereich()">
        <label class="form-label">Lernbereich</label>
        <label>{{ getBereich() }}</label>
      </div>
      <div class="form-row" *ngIf="hasPaketgroesse() && !hasPaket()">
        <label class="form-label">Paketgröße</label>
        <label>{{ getPaketgroesse() }}</label>
      </div>
      <div class="form-row" *ngIf="hasPaket()">
        <label class="form-label">Paket</label>
        <label>{{ getPaket() }}</label>
      </div>
      <div class="form-row" *ngIf="hasLernangebot()">
        <label class="form-label">Lernangebot</label>
        <label>{{ getLernangebot() }}</label>
      </div>
      <div class="form-row" *ngIf="hasKurs()">
        <label class="form-label">Kurs</label>
        <label>{{ getKurs() }}</label>
      </div>
      <div class="form-row" [class.hidden]="!fachrichtungNeeded() && !hasFachrichtung()">
        <label class="form-label" ExtendLabel [help]="getHelp('Registrierung')">Fachrichtung</label>
        <p-dropdown ExtendControl class="fachrichtung" [options]="fachrichtungen" [required]="!isOptional('IDFachrichtung')" [readonly]="isReadonly('IDFachrichtung')" [showClear]="isOptional('IDFachrichtung') && !(isReadonly('IDFachrichtung') || !fachrichtungen.length)" formControlName="OBJFachrichtung" fieldName="IDFachrichtung" placeholder="Fachrichtung auswählen" optionLabel="Name" appendTo="body" (focusin)="onFocus($event)" (keyup)="onKeyUp($event)" (keyup.enter)="focusNext($event)"  (onChange)="onFachrichtungChange()" *ngIf="fachrichtungNeeded()"></p-dropdown>
        <label *ngIf="!fachrichtungNeeded()">{{ getFachrichtung() }}</label>
      </div>
      <div class="form-row form-buttons bottom">
        <button pButton type="button" label="Übernehmen" [disabled]="form.isInvalid()" (click)="save()"></button>
        <button pButton type="button" label="Abbrechen" (click)="cancel()"></button>
        <div style="flex: 1; min-height: 100%; "></div>
        <button pButton type="button" label="Ignorieren" class="p-button-danger" (click)="ignore()" *ngIf="vorlageNeeded()"></button>
      </div>
    </form> 
  </div>  
</div> 
