<div class="extended-form" (window:resize)="onResize()" [style.minHeight]="getContentHeight()">
  <div class="data-auswahl">
    <div>
      <div class="form-row">
        <div class="sub-title">Auswahl</div>
      </div>
      <div class="form-row form-buttons">
        <button pButton type="button" label="Azubi-Pakete" [disabled]="!azubiPakete.length || isLoading()" class="p-button-outlined" (click)="selectAzubiPaket()"></button>
        <button pButton type="button" label="Ausbilder-Pakete" [disabled]="!ausbilderPakete.length || isLoading()" class="p-button-outlined" (click)="selectAusbilderPaket()"></button>
        <button pButton type="button" label="Sonstige Pakete" [disabled]="!sonstigePakete.length || isLoading()" class="p-button-outlined" (click)="selectPaket()"></button>
        <button pButton type="button" label="Lernangebote" [disabled]="!lernangebote.length || isLoading()" class="p-button-outlined" (click)="selectLernangebot()"></button>
        <button pButton type="button" label="Weitere Lizenzen" [disabled]="!weitereLizenzen.length || isLoading()" class="p-button-outlined" (click)="selectWeitereLizenzen()"></button>
      </div>
    </div>
    <div>
      <div class="form-row">
        <div class="sub-title">Vorbelegung</div>
      </div>
      <div class="vorbelegung-row">
        <div class="form-row" style="flex: 1 50%; min-width: 50%; ">
          <label class="form-label">Teilnehmer</label>
          <input ExtendControl class="vorbelegung" pInputText type="text" [(ngModel)]="teilnehmer">
        </div>
        <div class="form-row" style="flex: 1 50%; min-width: 50%; ">
          <label class="form-label">Ausbilder</label>
          <input ExtendControl class="vorbelegung" pInputText type="text" [(ngModel)]="ausbilder">
        </div>
      </div>
    </div>
  </div>
  <div class="data-lizenzen">
    <div class="ohne-verkaeufe" *ngIf="!hasLizenzverkaeufe()"></div>
    <sub-lizenzen 
      title="Azubi-Pakete" [lizenzen]="getAzubiPakete()" [loading]="isLoading()" (onselect)="onLizenzSelect($event)"
      class="data-azubi-pakete" *ngIf="hasLizenzverkaeufe() && hasAzubiPakete()" [style.flex]="getFlexStyle()"
    ></sub-lizenzen>
    <sub-lizenzen 
      title="Ausbilder-Pakete" [lizenzen]="getAusbilderPakete()" [loading]="isLoading()" (onselect)="onLizenzSelect($event)"
      class="data-ausbilder-pakete" *ngIf="hasLizenzverkaeufe() && hasAusbilderPakete()" [style.flex]="getFlexStyle()"
    ></sub-lizenzen>
    <sub-lizenzen 
      title="Sonstige Pakete" [lizenzen]="getSonstigePakete()" [loading]="isLoading()" (onselect)="onLizenzSelect($event)"
      class="data-sonstige-pakete" *ngIf="hasLizenzverkaeufe() && hasSonstigePakete()" [style.flex]="getFlexStyle()"
    ></sub-lizenzen>
    <sub-lizenzen 
      title="Lernangebote" [lizenzen]="getLernangebote()" [loading]="isLoading()" (onselect)="onLizenzSelect($event)"
      class="data-lernangebote" *ngIf="hasLizenzverkaeufe() && hasLernangebote()" [style.flex]="getFlexStyle()"
    ></sub-lizenzen>
    <sub-lizenzen 
      title="Weitere Lizenzen" [lizenzen]="getWeitereLizenzen()" [loading]="isLoading()" (onselect)="onLizenzSelect($event)"
      class="data-weitere-lizenzen" *ngIf="hasLizenzverkaeufe() && hasWeitereLizenzen()" [style.flex]="getFlexStyle()"
    ></sub-lizenzen>
  </div>
</div>