import * as moment from 'moment';
import { isEqual } from 'lodash';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';

import { isEmpty, isValid, PopupService, AppSettings, generatePassword, NotifyService, RestService } from '@bfeoldenburg/bfe-shared';
import { 
  DataService, ToolbarService, CommonSearchComponent, BfeForm, CommonFormComponent, IColumn, TOOLBAR, ITool 
} from '@bfeoldenburg/bfe-data-forms';

import { 
  Gruppe, IBenutzer, IBuchung, IBuchungBenutzer, IFachrichtung, IGruppe, IGruppeBereich, IKunde, IKundeBenutzer, IKundeFachrichtung, IKundentypGruppe, ILizenzart, ILizenzartBereichRolle, IntedigiService, Lizenztyp, TABLENAMES 
} from '@bfeoldenburg/intedigi-shared';

enum BENUTZERTOOLS {
  Reset = 0,
  Save = 1
}

interface IExtBenutzer extends IBenutzer {
  kundeBenutzer?: IKundeBenutzer;
  buchungenBenutzer: IBuchungBenutzer[];
}

@Component({
  selector: 'benutzer-schnellerfassung',
  templateUrl: './benutzer-schnellerfassung.component.html',
  styleUrls: ['./benutzer-schnellerfassung.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenutzerSchnellerfassungComponent extends CommonFormComponent implements OnInit, OnDestroy {
  public form: BfeForm;
  public benutzerForm: FormGroup;

  public kunde: IKunde;
  public selGruppe: IGruppe;
  public gruppen: IGruppe[] = [];
  public lizenzartBereichIDs: string[] = [];
  public bereichIDs: string[] = [];
  public buchungen: IBuchung[] = [];
  public buchungenSelected: IBuchung[] = [];
  public buchungenBenutzer__: { [key: string]: IBuchungBenutzer[] } = {};
  public countBenutzer: number = 0;
  public fachrichtungen: IFachrichtung[] = [];
  public allowedFachrichtungsIDs: string[] = []; 
  public anreden: any[] = [
    { ID: 'Herr', Name: 'Herr' },
    { ID: 'Frau', Name: 'Frau' }
  ];
  private benutzerFields = ['Anrede', 'Vorname', 'Nachname', 'Email', 'IDFachrichtung'];
  private benutzerFieldsAzubi = ['Anrede', 'Vorname', 'Nachname', 'Email', 'Ausbildungsbeginn', 'IDFachrichtung'];

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    private intedigiService: IntedigiService,
    private popupService: PopupService,
    appSettings: AppSettings,
    private notifyService: NotifyService,
    private http: HttpClient,
    private restService: RestService
  ) { 
    super(elementRef, renderer, cdRef, toolbarService, dataService, appSettings);

    this.fkeyTables = [
      TABLENAMES.KUNDE,
      TABLENAMES.KUNDEFACHRICHTUNG,
      TABLENAMES.BENUTZER,
      TABLENAMES.BUCHUNG,
      TABLENAMES.BUCHUNG_BENUTZER,
      TABLENAMES.KUNDE_BENUTZER,
      TABLENAMES.GRUPPE,
      TABLENAMES.KUNDENTYPGRUPPE,
      TABLENAMES.LIZENZART,
      TABLENAMES.FACHRICHTUNG
    ]

    this.tablename = TABLENAMES._BENUTZERSCHNELLERFASSUNG;
    this.form = this.dataService.getForm(TABLENAMES._BENUTZERSCHNELLERFASSUNG);
    this.initForm();

    this.addToolbar(TOOLBAR.MainTop);

    if (!isEmpty(this.form.getFormValue('BenutzerForm'))) {
      this.benutzerForm = this.form.getFormValue('BenutzerForm');
    } else {
      this.benutzerForm = new FormGroup({
        'Benutzer': new FormArray([])
      });
      this.form.setFormValue('BenutzerForm', this.benutzerForm);
    }

    this.kunde = this.form.getFormValue('OBJKunde');
    this.prepareFachrichtungen();
    this.setGruppe(this.form.getFormValue('IDGruppe'));
    if (!isEmpty(this.form.getFormValue('Buchungen'))) {
      this.buchungenSelected = this.form.getFormValue('Buchungen');
    }
  }

  ngOnInit(): void {
    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => this.getFKeyData()));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.form.resetCallbackReadonly('benutzer-schnellerfassung');
  }

  onResize(): void {
    super.onResize();
    timer().subscribe(() => this.detectChanges());
  }

  cbIsReadonly(field: string): boolean {
    switch (field) {
      case 'Kundenname': {
        return true;
      }
      case 'IDKunde': {
        return this.benutzerChanged() || !!this.buchungenSelected.length;
      }
      case 'IDGruppe': {
        return !this.isKundeSelected() || this.benutzerChanged() || !!this.buchungenSelected.length;
      }
    }
    return false;
  }

  onFormInit(): void {
    this.form.setCallbackReadonly('benutzer-schnellerfassung', (field: string) => this.cbIsReadonly(field));

    this.setTools(
      [
        this.toolbarService.createTool(<ITool>{
          id: BENUTZERTOOLS.Reset, name: 'Zurücksetzen', icon: 'icon-undo',
          cbExecute: () => this.reset(),
          cbDisabled: () => !this.isKundeSelected() || this.isLoading()
        }),
        this.toolbarService.createTool(<ITool>{
          id: BENUTZERTOOLS.Save, name: 'Speichern', icon: 'icon-floppy_disk', wiggle: true,
          cbExecute: () => this.save(),
          cbDisabled: () => !this.benutzerValid() || this.isLoading()
        })
      ],
      TOOLBAR.MainTop
    );
  }

  reset(): void {
    this.form.reset();
    this.benutzerForm.reset();
    this.kunde = null;
    this.selGruppe = null;
    this.buchungenSelected = [];
    this.allowedFachrichtungsIDs = [];
    this.countBenutzer = 0;
    this.prepareFachrichtungen();
    this.prepareGruppen();
    this.prepareBuchungen();
    this.detectChanges();
  }

  save(): void {
    this.loading = true;
    this.detectChanges();
    let benutzer: IBenutzer[] = []

    for (let i = 0; i < this.countBenutzer; i++) {
      if (!this.isIndexInvalid(i)) {
        let IDFachrichtung = this.getBenutzerValue(i, 'IDFachrichtung');
        if (this.isGruppeAzubis() && !this.hasMultipleFachrichtungen() && !!this.fachrichtungen.length) {
          IDFachrichtung = this.fachrichtungen[0].ID;
        }

        let benutzerItem: IBenutzer = <any>{
          IDGruppe: this.selGruppe.ID,
          IDBildungszentrum: this.kunde.IDBildungszKd,
          Anrede: this.getBenutzerValue(i, 'Anrede'),
          Nachname: this.getBenutzerValue(i, 'Nachname'),
          Vorname: this.getBenutzerValue(i, 'Vorname'),
          Email: this.getBenutzerValue(i, 'Email'),
          IDFachrichtung: IDFachrichtung,
          Passwort: generatePassword(8),
          EmailBestaetigt: 1,
          Freigabe: 1
        }

        if (this.isGruppeAzubis()) {
          benutzerItem.Ausbildungsbeginn = moment(this.getBenutzerValue(i, 'Ausbildungsbeginn')).format('YYYY-MM-DD');
          benutzerItem.Ausbildungsende = moment(benutzerItem.Ausbildungsbeginn).add(4, 'years').add(-1, 'days').format('YYYY-MM-DD')
        }

        benutzer.push(benutzerItem);
      }
    }

    let data = {
      Kunde: this.kunde,
      Lizenzen: this.buchungenSelected,
      Benutzer: benutzer
    }

    this.http.post<any>(this.restService.getUrl() + 'benutzererfassung.php', data)
    .pipe(first())
    .subscribe(
      (benutzer__: IBenutzer[]) => {
        this.notifyService.success('Die Benutzer wurden gespeichert');
        this.loading = false;
        this.detectChanges();
        this.readAllData();
        this.popupService.confirm(
          'Möchten Sie dem Kunden direkt eine E-Mail mit den erfassten Benutzerkonten senden?', 'E-Mailversand',
          () => {
            let benutzerIDs: number[] = benutzer__.reduce((result: number[], current: IBenutzer) => {
              return result.concat(current.ID);
            }, <number[]>[]);
            this.intedigiService.benutzerListe(this.kunde.IDBildungszKd, this.kunde.ID, benutzerIDs);
          },
          () => {
          },
          false,
          ['Ja', 'Nein']
        );
        this.buchungenSelected = [];
        this.selectedBuchungenChange();
        this.detectChanges();
      },
      (error: string) => {
        console.log(error);
        this.notifyService.error(error);
        this.loading = false;
        this.detectChanges();
      }
    );
  }

  prepareBuchungen(): void {
    this.buchungen = [];
    this.buchungenBenutzer__ = {};

    let lastBuchungenSelected = this.buchungenSelected;

    if (!isEmpty(this.kunde) && !isEmpty(this.selGruppe)) {
      let now = moment().format('YYYY-MM-DD');

      let buchungsIDs: string[] = [];
      this.buchungen = (<IBuchung[]>this.dataService.getData(TABLENAMES.BUCHUNG)).reduce((result: IBuchung[], current: IBuchung) => {
        let IDBereich: string = (isValid(current.IDBereich) ? current.IDBereich : '');
        let lizenzart: ILizenzart = this.dataService.getDataset(TABLENAMES.LIZENZART, current.IDLizenzart);
        if (
          !isEmpty(lizenzart) && (lizenzart.IDLizenztyp === Lizenztyp.Rolle || lizenzart.IDLizenztyp === Lizenztyp.Lernangebot) && 
          current.IDBildungszKd === this.kunde.IDBildungszKd && current.IDKunde === this.kunde.ID && 
          (!current.Gesperrt && (!isValid(current.Laufzeit_bis) || now < current.Laufzeit_bis)) && 
          this.lizenzartBereichIDs.indexOf(current.IDLizenzart + '_' + IDBereich) > -1 && 
          (this.bereichIDs.indexOf(current.IDBereich) > -1 || !isValid(current.IDBereich)) && 
          (
            !this.allowedFachrichtungsIDs.length || !isValid(current.IDFachrichtung) || 
            this.allowedFachrichtungsIDs.indexOf(current.IDFachrichtung) > -1
          )
        ) {
          buchungsIDs.push(current.IDBildungszKd + '_' + current.IDKunde + '_'  + current.ID);
          return result.concat(current);
        }
        return result;
      }, <IBuchung[]>[]);

      this.buchungenBenutzer__ = (<IBuchungBenutzer[]>this.dataService.getData(TABLENAMES.BUCHUNG_BENUTZER)).reduce(
        (result: { [key: string]: IBuchungBenutzer[] }, current: IBuchungBenutzer) => {
          let index = current.IDBildungszKd + '_' + current.IDKunde + '_'  + current.IDBuchung;
          if (buchungsIDs.indexOf(index) > -1) {
            if (!(index in result)) {
              result[index] = [];
            }
            result[index].push(current);
          }
          return result;
        }, <{ [key: string]: IBuchungBenutzer[] }>{}
      );

      this.buchungen = this.buchungen.filter((item: IBuchung) => {
        let count: number = item.Anzahl - item.Gutgeschrieben - this.getBuchungenBenutzer(item).length;
        return count > 0;
      });
    }

    this.buchungenSelected = this.buchungenSelected.reduce((result: IBuchung[], current: IBuchung) => {
      if (this.buchungen.findIndex(
        (item: IBuchung) => item.ID === current.ID && item.IDBildungszKd === current.IDBildungszKd && item.IDKunde === current.IDKunde
      ) > -1) {
        return result.concat(current);
      }
      return result;
    }, <IBuchung[]>[]);

    if (!isEqual(lastBuchungenSelected, this.buchungenSelected)) {
      this.selectedBuchungenChange();
    }
  }

  getBuchungenBenutzer(buchung: IBuchung): IBuchungBenutzer[] {
    let index = buchung.IDBildungszKd + '_' + buchung.IDKunde + '_'  + buchung.ID;
    if (index in this.buchungenBenutzer__) {
      return this.buchungenBenutzer__[index];
    } else {
      return [];
    }
  }

  prepareGruppen(): void {
    this.gruppen = [];

    if (!isEmpty(this.kunde)) {
      let gruppenIDs = (<IKundentypGruppe[]>this.dataService.getData(TABLENAMES.KUNDENTYPGRUPPE)).reduce(
        (result: number[], current: IKundentypGruppe) => {
          if (current.IDKundentyp === this.kunde.IDKundentyp) {
            return result.concat(current.IDGruppe);
          }
          return result;
        }, <number[]>[]
      );

      this.gruppen = <IGruppe[]>this.dataService.getData(TABLENAMES.GRUPPE, (item: IGruppe) => gruppenIDs.indexOf(item.ID) > -1);
    }
  }

  prepareLizenzarten(): void {
    this.lizenzartBereichIDs = [];

    if (!isEmpty(this.selGruppe)) {
      this.lizenzartBereichIDs = (<ILizenzartBereichRolle[]>this.dataService.getData(TABLENAMES.LIZENZARTBEREICHROLLE)).reduce(
        (result: string[], current: ILizenzartBereichRolle) => {
          let IDBereich: string = (isValid(current.IDBereich) ? current.IDBereich : '');
          if (current.IDRolle === this.selGruppe.IDRolle && result.indexOf(current.IDLizenzart + '_' + IDBereich) === -1) {
            return result.concat(current.IDLizenzart + '_' + IDBereich);
          }
          return result;
        }, <string[]>[]
      );

      this.bereichIDs = (<IGruppeBereich[]>this.dataService.getData(TABLENAMES.GRUPPEBEREICH)).reduce(
        (result: string[], current: IGruppeBereich) => {
          if (current.IDGruppe === this.selGruppe.ID) {
            return result.concat(current.IDBereich);
          }
          return result;
        }, <string[]>[]
      );
    }
  }

  prepareFachrichtungen(): void {
    if (!isEmpty(this.kunde)) {
      this.fachrichtungen = (<IKundeFachrichtung[]>this.dataService.getData(TABLENAMES.KUNDEFACHRICHTUNG)).reduce(
        (result: IFachrichtung[], current: IKundeFachrichtung) => {
          if (
            current.IDKunde === this.kunde.ID && 
            this.dataService.hasDataset(TABLENAMES.FACHRICHTUNG, current.IDFachrichtung) && 
            (!this.allowedFachrichtungsIDs.length || this.allowedFachrichtungsIDs.indexOf(current.IDFachrichtung) > -1)
          ) {
            return result.concat(this.dataService.getDataset(TABLENAMES.FACHRICHTUNG, current.IDFachrichtung));
          }
          return result;
        }, <IFachrichtung[]>[]
      );

      if (!this.fachrichtungen.length) {
        this.fachrichtungen = <IFachrichtung[]>this.dataService.getData(TABLENAMES.FACHRICHTUNG);
      }

    } else {
      this.fachrichtungen = [];
    }
  }

  getFKeyData(): void {
    this.addDataSubscription(TABLENAMES.BUCHUNG, (data: IBuchung[]) => {
      this.prepareBuchungen();
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.BUCHUNG_BENUTZER, (data: IBuchungBenutzer[]) => {
      this.prepareBuchungen();
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.GRUPPE, (data: IGruppe[]) => {
      this.prepareGruppen();
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.KUNDENTYPGRUPPE, (data: IKundentypGruppe[]) => {
      this.prepareGruppen();
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.LIZENZART, (data: IGruppe[]) => {
      this.prepareLizenzarten();
      this.prepareBuchungen();
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.KUNDEFACHRICHTUNG, (data: IKundeFachrichtung[]) => {
      this.prepareFachrichtungen();
      this.detectChanges();
    });

    this.addDataSubscription(TABLENAMES.FACHRICHTUNG, (data: IFachrichtung[]) => {
      this.prepareFachrichtungen();
      this.detectChanges();
    });

    this.prepareFachrichtungen();
    this.prepareGruppen();
    this.prepareLizenzarten();
    this.prepareBuchungen();
    this.detectChanges();
  }

  hasLizenzen(item: IBuchung): boolean {
    return item.Anzahl - item.Gutgeschrieben - this.getBuchungenBenutzer(item).length > 0;
  }

  getLizenzColor(item: IBuchung): string {
    if (item.Anzahl - item.Gutgeschrieben - this.getBuchungenBenutzer(item).length > 0) {
      return this.appSettings.colorSuccess;
    } else {
      return this.appSettings.colorError;
    }
  }
  
  getLizenzInfo(item: IBuchung): string {
    let Anzahl = item.Anzahl - item.Gutgeschrieben;
    return (Anzahl - this.getBuchungenBenutzer(item).length) + ' / ' + Anzahl;
  }

  getLaufzeit(dataset: IBuchung): string {
    if (isValid(dataset.Laufzeit_von) && isValid(dataset.Laufzeit_bis)) {
      return moment.utc(dataset.Laufzeit_von).format('DD.MM.YY') + ' - ' + moment.utc(dataset.Laufzeit_bis).format('DD.MM.YY');
    } else if (isValid(dataset.Laufzeit_von)) {
      return 'ab ' + moment.utc(dataset.Laufzeit_von).format('DD.MM.YY');
    } else if (isValid(dataset.Laufzeit_bis)) {
      return 'bis ' + moment.utc(dataset.Laufzeit_bis).format('DD.MM.YY')
    }
    return '';
  }

  hasLaufzeit(dataset: IBuchung): boolean {
    return isValid(dataset.Laufzeit_von) || isValid(dataset.Laufzeit_bis);
  }

  getLernangebot(dataset: IBuchung): string {
    return (
      this.intedigiService.getLernangebot(dataset) + 
      (isValid(dataset.IDBereich) ? ', ' + this.dataService.getDatasetName(TABLENAMES.BEREICH, dataset.IDBereich) : '') + 
      (
        isValid(dataset.IDFachrichtung) ? ', ' + dataset.IDFachrichtung : 
        (dataset.IDBereich === 'ausbildung' ? ', alle Fachrichtungen' : '')
      )
    );
  }

  hasLizenzart(dataset: IBuchung): boolean {
    return isValid(dataset.IDLizenzart);
  }

  getLizenzart(dataset: IBuchung): string {
    let lizenzart: ILizenzart = this.dataService.getDataset(TABLENAMES.LIZENZART, dataset.IDLizenzart);
    return (!isEmpty(lizenzart) ? lizenzart.Name : '');
  }

  selectKunde() {
    this.popupService.open(
      CommonSearchComponent, 
      {
        columns: this.dataService.getSearchColumns(TABLENAMES.KUNDE),
        tablename: TABLENAMES.KUNDE,
        tabletitle: 'Kunde',
        minWidth: 1600,
        cbFilter: (dataset: IKunde) => {
          return this.intedigiService.isBildungszentrumWritable(dataset.IDBildungszKd);
        }
      },
      {
        onselect: (dataset: IKunde) => { 
          this.kunde = this.dataService.getDataset(TABLENAMES.KUNDE, [ dataset.ID, dataset.IDBildungszKd ]);
          this.form.setFormValue('IDKunde', this.kunde.ID);
          this.form.setFormValue('OBJKunde', this.kunde);
          this.form.setFormValue('Kundenname', this.kunde.Name + ', ' + this.kunde.Ort);

          this.prepareFachrichtungen();
          this.prepareGruppen();
          this.prepareBuchungen();
          this.detectChanges();
        },
        oncancel: () => {  }
      }
    );
  }

  isKundeSelected(): boolean {
    return !isEmpty(this.kunde);
  }

  setGruppe(value?: any) {
    this.setFormObject(TABLENAMES.GRUPPE, value);
    this.selGruppe = this.form.getFormValue('OBJGruppe');
    this.prepareLizenzarten();
    this.prepareBuchungen();
    this.detectChanges();
  }

  onGruppeSelect(): void {
    this.setGruppe();
  }

  selectedBuchungenChange(event?: any) {
    this.form.setFormValue('Buchungen', this.buchungenSelected);

    let lastAllowedFachrichtungsIDs: string[] = this.allowedFachrichtungsIDs;
    this.allowedFachrichtungsIDs = [];

    this.countBenutzer = this.buchungenSelected.reduce((result: number, current: IBuchung) => {
      if (isValid(current.IDFachrichtung)) {
        this.allowedFachrichtungsIDs.push(current.IDFachrichtung);
      }
      let count: number = current.Anzahl - current.Gutgeschrieben - this.getBuchungenBenutzer(current).length;
      if (count > 0 && (!result || count < result)) {
        return count;
      }
      return result;
    }, <number>0);

    if (!isEqual(lastAllowedFachrichtungsIDs, this.allowedFachrichtungsIDs)) {
      this.prepareBuchungen();
      this.prepareFachrichtungen();
    }
    this.createBenutzerForms();
    this.detectChanges();
  }

  private createBenutzerArray__(array?: AbstractControl): AbstractControl[] {
    let response: AbstractControl[] = [];
    if (!isEmpty(array) && !!this.countBenutzer) {
      if ((<FormArray>array).controls.length <= this.countBenutzer) {
        response = (<FormArray>array).controls;
      } else if ((<FormArray>array).controls.length > this.countBenutzer) {
        response = (<FormArray>array).controls.slice(0, this.countBenutzer - 1);
      }
    }

    if (response.length < this.countBenutzer) {
      for (let i = response.length; i < this.countBenutzer; i++) {
        response.push(new FormGroup({
          'OBJAnrede': new FormControl({ value: null, disabled: false }),
          'Anrede': new FormControl({ value: null, disabled: false }),
          'Nachname': new FormControl({ value: null, disabled: false }),
          'Vorname': new FormControl({ value: null, disabled: false }),
          'Email': new FormControl({ value: null, disabled: false }),
          'Ausbildungsbeginn': new FormControl({ value: null, disabled: false }),
          'IDFachrichtung': new FormControl({ value: null, disabled: false }),
          'OBJFachrichtung': new FormControl({ value: null, disabled: false })
        }));
      }
    }

    return response;
  }

  createBenutzerForms(): void {
    this.benutzerForm.controls['Benutzer'] = new FormArray(this.createBenutzerArray__(this.benutzerForm.controls['Benutzer']));
  }

  isGruppeAzubis(): boolean {
    return !isEmpty(this.selGruppe) && this.selGruppe.ID === Gruppe.AZUBI;
  }

  getControls(): AbstractControl[] {
    if (!isEmpty(this.benutzerForm) && 'Benutzer' in this.benutzerForm.controls) {
      return (<FormArray>this.benutzerForm.controls['Benutzer']).controls;
    } else {
      return [];
    }
  }

  getBenutzerValue(index: number, control: string): any {
    return (<FormGroup>this.getControls()[index]).controls[control].value;
  }

  setBenutzerValue(index: number, control: string, value: any): any {
    return (<FormGroup>this.getControls()[index]).controls[control].setValue(value);
  }

  benutzerChanged(): boolean {
    let controls = this.getControls();
    for (let i = 0; i < controls.length; i++) {
      if (this.isGruppeAzubis()) {
        if (
          !this.isIndexControlEmpty(i, 'Anrede') || !this.isIndexControlEmpty(i, 'Vorname') || !this.isIndexControlEmpty(i, 'Nachname') || 
          !this.isIndexControlEmpty(i, 'Email') || !this.isIndexControlEmpty(i, 'Ausbildungsbeginn')
        ) {
          return true;
        }
      } else {
        if (
          !this.isIndexControlEmpty(i, 'Anrede') || !this.isIndexControlEmpty(i, 'Vorname') || !this.isIndexControlEmpty(i, 'Nachname') || 
          !this.isIndexControlEmpty(i, 'Email')
        ) {
          return true;
        }
      }
    }
    return false;
  }

  benutzerValid(): boolean {
    let controls = this.getControls();
    for (let i = 0; i < controls.length; i++) {
      if (this.isIndexRequired(i) && this.isIndexInvalid(i)) {
        return false;
      }
    }
    return !!controls.length;
  }

  isIndexReadonly(index: number): boolean {
    return this.isLoading();
  }

  isIndexRequired(index: number): boolean {
    if (index === 0) {
      return true;
    } else {
      if (this.isGruppeAzubis()) {
        return (
          !this.isIndexControlEmpty(index, 'Anrede') || !this.isIndexControlEmpty(index, 'Vorname') || !this.isIndexControlEmpty(index, 'Nachname') || 
          !this.isIndexControlEmpty(index, 'Email') || !this.isIndexControlEmpty(index, 'Ausbildungsbeginn') || 
          !this.isIndexControlEmpty(index, 'IDFachrichtung')
        );
      } else {
        return (
          !this.isIndexControlEmpty(index, 'Anrede') || !this.isIndexControlEmpty(index, 'Vorname') || !this.isIndexControlEmpty(index, 'Nachname') || 
          !this.isIndexControlEmpty(index, 'Email') || !this.isIndexControlEmpty(index, 'IDFachrichtung')
        );
      }
    }
  }

  isIndexInvalid(index: number): boolean {
    if (this.isGruppeAzubis()) {
      return (
        this.isIndexControlEmpty(index, 'Vorname') || this.isIndexControlEmpty(index, 'Nachname') || this.isIndexControlEmpty(index, 'Email') || 
        this.isIndexControlEmpty(index, 'Ausbildungsbeginn') || (this.hasMultipleFachrichtungen() && this.isIndexControlEmpty(index, 'IDFachrichtung'))
      );
    } else {
      return this.isIndexControlEmpty(index, 'Vorname') || this.isIndexControlEmpty(index, 'Nachname') || this.isIndexControlEmpty(index, 'Email');
    }
  }

  isIndexControlEmpty(index: any, control: string) {
    return !isValid(this.getBenutzerValue(index, control));
  }

  isIndexControlValid(index: any, control: string): boolean {
    return (<FormGroup>this.getControls()[index]).controls[control].valid && !(<FormGroup>this.getControls()[index]).controls[control].errors;
  }

  getFachrichtungPlaceholder(): string {
    if (this.isGruppeAzubis()) {
      return 'Fachrichtung auswählen';
    } else {
      return 'Optionale Fachrichtung';
    }
  }

  hasMultipleFachrichtungen(): boolean {
    return this.fachrichtungen.length > 1;
  }

  isFachrichtungRequired(index: number) {
    return this.isGruppeAzubis() && this.hasMultipleFachrichtungen() && this.isIndexRequired(index);
  }

  fieldChanged(): void {
    this.toolbarService.changed(TOOLBAR.MainTop);
    this.onDataChange();
  }

  onAnredeChange(index: number) {
    let OBJAnrede = (<FormGroup>this.getControls()[index]).controls['OBJAnrede'].value || {};
    (<FormGroup>this.getControls()[index]).controls['Anrede'].setValue(OBJAnrede.ID);
    this.toolbarService.changed(TOOLBAR.MainTop);
    this.onDataChange();
  }

  getArrayFieldLength(column: string): number {
    let column_: IColumn = this.dataService.getColumn('Benutzer', column);
    if (!!column_) {
      let value = column_.length;
      if (!!column_.decimals) {
        value += column_.decimals + 1;
      }
      return value;
    } else {
      return 0;
    }
  }

  onAusbildungsbeginnChange(index: number) {
    this.toolbarService.changed(TOOLBAR.MainTop);
    this.onDataChange();
  } 

  onFachrichtungChange(index: number) {
    let OBJFachrichtung = (<FormGroup>this.getControls()[index]).controls['OBJFachrichtung'].value || {};
    (<FormGroup>this.getControls()[index]).controls['IDFachrichtung'].setValue(OBJFachrichtung.ID);
    this.toolbarService.changed(TOOLBAR.MainTop);
    this.onDataChange();
  }

  onFocusBenutzer(event: any): void {
  }

  /** Ermitteln des Formular-Feldnamens zu einem Eingabefeld */
  private getMainElement__(field: HTMLElement): HTMLElement {
    // Das Fieldname-Attribut wurde gesetzt (z.B. Comboboxen, da zwei Felder für ein Control benötigt werden)
    if (field.hasAttribute('fieldName')) {
      return field;
    // formControlName-Attribut wurde gesetzt (reactive Forms)
    } else if (field.hasAttribute('formControlName')) {
      return field; // + (field.hasAttribute('index') ? '|' + field.getAttribute('index') : '');
    // Ansonsten die Parents durchlaufen bis ein Fieldname oder ein formControlName gefunden wurde
    } else if (!!field.parentElement) {
      return this.getMainElement__(field.parentElement);
    } else {
      return null;
    }
  }

  focusNextBenutzer(KeyboardEvent: any): void {
    let element: HTMLElement = this.getMainElement__(KeyboardEvent.target);
    if (!!element) {
      let name = element.getAttribute('formControlName');
      if (element.hasAttribute('fieldName')) {
        name = element.getAttribute('fieldName');
      }
      let index: number = parseInt(element.getAttribute('index'));
      let fieldIndex = -1;
      let maxField = -1;
      let fields = [];

      if (this.isGruppeAzubis()) {
        fields = this.benutzerFieldsAzubi;
        fieldIndex = this.benutzerFieldsAzubi.indexOf(name);
        maxField = this.benutzerFieldsAzubi.length - 1 - (
          this.fachrichtungen.length > 1 || (!this.isGruppeAzubis() && !!this.fachrichtungen.length) ? 0 : 1
        );

      } else {
        fields = this.benutzerFields;
        fieldIndex = this.benutzerFields.indexOf(name);
        maxField = this.benutzerFields.length - 1 - (
          this.fachrichtungen.length > 1 || (!this.isGruppeAzubis() && !!this.fachrichtungen.length) ? 0 : 1
        );
      }
      
      let nextField: HTMLElement = null;
      if (fieldIndex < maxField) {
        nextField = document.body.querySelector('[formControlName=\'' + fields[fieldIndex + 1] + '\'][index=\'' + index + '\']');
        if (!nextField) {
          nextField = document.body.querySelector('[fieldName=\'' + fields[fieldIndex + 1] + '\'][index=\'' + index + '\']');
        }

      } else {
        let nextIndex = index + 1;
        if (nextIndex === this.countBenutzer) {
          nextIndex = 0;
        }
        nextField = document.body.querySelector('[formControlName=\'' + fields[0] + '\'][index=\'' + nextIndex + '\']');
        if (!nextField) {
          nextField = document.body.querySelector('[fieldName=\'' + fields[0] + '\'][index=\'' + nextIndex + '\']');
        }
      }

      if (!!nextField) {
        if (nextField.nodeName.toLowerCase() !== 'input' && nextField.nodeName.toLowerCase() !== 'textarea') {
          nextField = nextField.querySelector('input');
        }
        if (!!nextField) {
          nextField.focus();
          (<HTMLInputElement>nextField).select();
        }
      }
    }
  }

}
