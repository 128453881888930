import { 
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild 
} from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

import { isValid, AppSettings } from '@bfeoldenburg/bfe-shared';
import { 
  ToolbarService, CommonFeaturesComponent, CommonListComponent, DataService, IData, IListColumn 
} from '@bfeoldenburg/bfe-data-forms';

import { IntedigiService, TABLENAMES, ISchnittstelleWiedervorlage } from '@bfeoldenburg/intedigi-shared';

import { WiedervorlageService } from '../../../../_services/wiedervorlage.service';


@Component({
  selector: 'wiedervorlage-list',
  templateUrl: './wiedervorlage-list.component.html',
  styleUrls: ['./wiedervorlage-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WiedervorlageListComponent extends CommonFeaturesComponent implements OnInit, AfterViewInit, OnDestroy {
  public selectedRow: IData;

  public columns: IListColumn[] = [];
  public visibleColumns: IListColumn[] = [];

  private hTable: HTMLElement;

  public searchText: string;

  @ViewChild('commonList', { static: false })
  private commonList: CommonListComponent;

  constructor(
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    dataService: DataService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService,
    private elementRef: ElementRef,
    private router: Router,
    private wiedervorlageService: WiedervorlageService
  ) { 
    super(cdRef, toolbarService, dataService, appSettings);

    this.columns = this.columns.concat([
      { name: 'Schnittstelle', title: 'Schnittstelle', minWidth: 150, cbOnShow: (dataset: ISchnittstelleWiedervorlage) => { 
        switch (dataset.Schnittstelle) {
          case 'erp': return 'ERP-Schnittstelle';
        }
        return dataset.Schnittstelle;
      } },
      { name: 'Kontext', title: 'Kontext', minWidth: 130, cbOnShow: (dataset: ISchnittstelleWiedervorlage) => { 
        switch (dataset.Kontext) {
          case 'auftraege': return 'Aufträge';
          case 'veranstaltungen': return 'Veranstaltungen';
          default: return 'Unbekannt';
        }
      } },
      { name: 'Details', title: 'Details', minWidth: 200, cbOnShow: (dataset: ISchnittstelleWiedervorlage) => { 
        let data = JSON.parse(dataset.Data);
        switch (dataset.Kontext) {
          case 'auftraege': return data.Kunde.Name + ', ' + data.Vertragsdaten.ERPNummer;
          case 'veranstaltungen': return data.Kursgruppe.Name + ', ' + data.Kursgruppe.ERPNummer;
          default: return 'Unbekannter Kontext';
        }
      } },
      { name: 'Fehler', title: 'Fehler', minWidth: 400, cbOnShow: (dataset: ISchnittstelleWiedervorlage) => { 
        return dataset.Fehlertext;
      } },
    ]);
  }

  ngAfterViewInit() {
    this.calcTable();
  }

  ngOnInit() {
    this.subscriptions.push(this.dataService.onready.subscribe(() => {
      this.getFKeyTables();
      this.detectChanges();
    }));

    this.getFKeyTables();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onResize(): void {
    this.calcTable();
    this.detectChanges();
  }

  isLoading(): boolean {
    return this.dataService.neverLoaded(TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE);
  }

  getWidth(): number {
    if (!this.hTable) {
      this.hTable = this.elementRef.nativeElement.querySelector('#data-table');
    }
    if (!!this.hTable) {
      return this.hTable.offsetWidth;
    }
    return 0;
  }

  isWritable(): boolean {
    return this.dataService.isWritable(TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE);
  }

  getHelp(control: string): string {
    let form = this.dataService.getForm(TABLENAMES.SCHNITTSTELLE_WIEDERVORLAGE);
    if (isValid(form)) {
      return form.getHelp(control);
    }
    return '';
  }

  getColumnAlign(column: any): string {
    return (!!column.align && !!column.align.length ? column.align : 'left');
  }

  calcTable(): void {
    if (this.getWidth() > 0) {
      this.visibleColumns = [];
      let width = 40;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].minWidth + width <= this.getWidth()) {
          this.visibleColumns.push(this.columns[i]);
        } else {
          break;
        }
        width += this.columns[i].minWidth;
      }
    } else {
      timer(10).subscribe(() => this.calcTable());
    }

    this.detectChanges();
  }

  getFKeyTables(): void {
    this.detectChanges();
  }

  onSelect(event: any) {
    this.wiedervorlageService.setSelected(event.data);
  }

  onSearch() {
    this.detectChanges();
  }
}
