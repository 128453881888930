declare const $: any;

import { 
  Component, OnInit, AfterViewInit, OnChanges, OnDestroy, ElementRef, Renderer2, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy 
} from '@angular/core';
import { Router } from '@angular/router';

import { 
  PopupService, NotifyService, AuthenticationService, IUserRolle, isEmpty, isValid, isObject, AppSettings 
} from '@bfeoldenburg/bfe-shared';
import { ToolbarService, DataService, CommonPopupFormComponent } from '@bfeoldenburg/bfe-data-forms';

import { IBildungszentrum, IntedigiService, IIntedigiUser, IntedigiUserService, TABLENAMES } from '@bfeoldenburg/intedigi-shared';


enum PASS_STRENGTH_COLOR {
  WEAK = 'red', 
  MEDIUM = 'yellow',
  STRONG = 'green'
}

@Component({
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPopupComponent extends CommonPopupFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  /** Überladung des allgemeinen Datensatz-Objektes */
  public data: IIntedigiUser = <IIntedigiUser>{};
  public bildungszentren: IBildungszentrum[] = [];
  public userrollen: IUserRolle[] = [];
  
  public pass_strength_text: string;
  public pass_strength_score: number = -1;

  constructor(
    private authService: AuthenticationService,
    private notifyService: NotifyService,
    private router: Router,
    dataService: DataService,
    popupService: PopupService,
    elementRef: ElementRef,
    renderer: Renderer2,
    cdRef: ChangeDetectorRef,
    toolbarService: ToolbarService,
    appSettings: AppSettings,
    private intedigiService: IntedigiService,
    private intedigiUserService: IntedigiUserService
  ) { 
    super(dataService, popupService, elementRef, renderer, cdRef, toolbarService, appSettings);

    this.dataService.readData(TABLENAMES.BILDUNGSZENTRUM);

    this.userrollen = this.authService.getUserrollen();

    this.subscriptions.push(this.intedigiService.onsettings.subscribe(() => { 
      this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));
      this.onDataChange(); 
    }));
  }

  ngOnInit() {
    this.getFKeyData();
    this.subscriptions.push(this.dataService.onready.subscribe(() => {
      this.getFKeyData();
    }));
    
    super.ngOnInit();
  }

  initPassword() {
    let jQPassword = $('#realpassword');

    if (!!jQPassword && !!jQPassword.length) {
      jQPassword.password({
        shortPass: 'Das Passwort ist zu kurz',
        badPass: 'Schwach; Kombinieren Sie Buchstaben und Zahlen',
        goodPass: 'Mittel; Versuchen Sie Sonderzeichen',
        strongPass: 'Ein starkes Passwort',
        containsUsername: 'Das Passwort enthält den Benutzernamen',
        enterPass: 'Geben Sie Ihr neues Passwort ein',
        showPercent: false,
        showText: true, // shows the text tips
        animate: true, // whether or not to animate the progress bar on input blur/focus
        animateSpeed: 'fast', // the above animation speed
        username: false, // select the username field (selector or jQuery instance) for better password checks
        usernamePartialMatch: true, // whether to check for username partials
        minimumLength: 4 // minimum password length (below this threshold, the score is 0)
      });

      jQPassword.on('password.score', (e: any, score: number) => {
        this.pass_strength_score = score;
        this.detectChanges();
      })

      jQPassword.on('password.text', (e: any, text: string, score: number) => {
        this.pass_strength_text = text;
        this.pass_strength_score = score;
        this.detectChanges();
      })
    } else {
      setTimeout(() => this.initPassword(), 100);
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.initPassword();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onAdminChange() {
    this.onDataChange();
  }

  onFormInit() {
    this.onDataSet();
  }

  onDataSet() {
    this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));
    this.setUserrolle(this.form.getFormValue('role'));
  }

  onDataGet() {
    this.setBildungszentrum();
    this.setUserrolle();
  }

  onDataSave() {
    if (this.data.id === this.authService.getUserId() && !!this.data.sso === !!this.authService.isSSO()) {
      // Erfolgsmeldung anzeigen
      this.notifyService.success('Sie haben Ihren Zugang geändert. Bitte melden Sie sich neu an.');
      // Benutzer zwingen sich neu anzumelden
      this.authService.logout(
        () => this.router.navigate([ this.appSettings.noAuthRoute ]), () => this.router.navigate([ this.appSettings.noAuthRoute ])
      );
    }
  }

  setBildungszentrum(value?: any) {
    this.setFormObject(TABLENAMES.BILDUNGSZENTRUM, value);
  }

  setUserrolle(value?: any) {
    // Wenn kein value übergeben wurde --> Aus dem Formular anhand des Namens ermitteln
    if (isEmpty(value)) {
      value = this.form.getFormValue('OBJRole') || {};
    }
    // Wenn das übergebene value kein Objekt ist --> Es handelt sich um einen Primärschlüssel 
    if (!isObject(value)) {
      value = this.userrollen.find((item: IUserRolle) => item.role === value) || {};
    }

    if (isValid(value.role)) {
      this.form.setFormValue('OBJRole', value);
      this.form.setFormValue('role', value.role);
    } else {
      this.form.setFormValue('OBJRole', null);
      this.form.setFormValue('role', null);
    }
  }

  onBildungszentrumChange() {
    this.setBildungszentrum();
    this.onDataChange();
  }

  onUserrolleChange() {
    this.setUserrolle();
    this.onDataChange();
  }

  getPassStrengthText(): string {
    return (!!this.pass_strength_text && !!this.pass_strength_text.length ? this.pass_strength_text : 'Bitte geben Sie ein Passwort ein');
  }

  getPassStrengthColor(): string {
    if (this.pass_strength_score < 34) return PASS_STRENGTH_COLOR.WEAK;
    if (this.pass_strength_score < 68) return PASS_STRENGTH_COLOR.MEDIUM;
    return PASS_STRENGTH_COLOR.STRONG;
  }

  getPasswordError(): string {
    if (!this.form.getFormValue('password1') || this.form.getFormValue('password1').length < 4) {
      return 'Das Passwort ist zu kurz!';
    }else if (this.pass_strength_score < 34) {
      return 'Das Passwort ist zu schwach!';
    } else {
      return 'Die Passwörter stimmen nicht überein!';
    }
  }

  passwort2Needed(): boolean {
    return this.isNew() || (!!this.form.getFormValue('password1') && !!this.form.getFormValue('password1').length);
  }

  passwordCheck(): boolean {
    return !this.passwort2Needed() || this.form.getFormValue('password1') === this.form.getFormValue('password2') && this.pass_strength_score >= 34;
  }

  getBildungszentren(data: IBildungszentrum[]) {
    this.bildungszentren = [];
    if (this.intedigiService.isInterfaceLocal()) {
      this.bildungszentren = [ <IBildungszentrum>this.dataService.getDataset(TABLENAMES.BILDUNGSZENTRUM, this.intedigiService.getBildungszentrum()) ];

      if (!isEmpty(this.form) && !this.dataService.neverLoaded(TABLENAMES.BILDUNGSZENTRUM) && !this.dataService.neverLoaded(TABLENAMES.SETTINGS)) {
        this.setBildungszentrum(this.intedigiService.getBildungszentrum());
      }
    } else {
      this.bildungszentren = data.reduce((result: IBildungszentrum[], current: IBildungszentrum) => {
        if (
          (!current.LokaleInstanz && (this.authService.isAdministrator() || current.ID === this.intedigiUserService.getBildungszentrum())) || 
          (!isEmpty(this.data) && current.ID === this.data.IDBildungszentrum)
        ) {
          return result.concat(current);
        }
        return result;
      }, <IBildungszentrum[]>[]);

      if (!isEmpty(this.form) && !this.dataService.neverLoaded(TABLENAMES.BILDUNGSZENTRUM)) {
        this.setBildungszentrum(this.form.getFormValue('IDBildungszentrum'));

        if (!isValid(this.form.getFormValue('IDBildungszentrum'))) {
          this.setBildungszentrum(this.intedigiUserService.getBildungszentrum());
        }
      }
    }

    this.detectChanges();
  }

  getFKeyData() {
    this.addDataSubscription(TABLENAMES.BILDUNGSZENTRUM, (data: IBildungszentrum[]) => {
      this.getBildungszentren(data);
    });
    this.getBildungszentren(<IBildungszentrum[]>this.dataService.getData(TABLENAMES.BILDUNGSZENTRUM));
  }

  isLokal(): boolean {
    return this.intedigiService.isInterfaceLocal();
  }

  /** Abfrage ob sich der Formular-Inhalt geändert hat */
  hasChanged(): boolean {
    return (
      this.form.hasChanged() && 
      (
        !this.isNew() || 
        !(Object.keys(this.form.getValues(true)).length === 1 && this.form.hasColumnChanged('IDBildungszentrum'))
      )
    );
  }
}
