<div class="extended-form" (window:resize)="onResize()" [style.minHeight]="getContentHeight()">
  <div class="data-buttons">
    <div class="left">
      <button pButton type="button" icon="icon-navigate_left" class="p-button-outlined" (click)="previous()" [disabled]="!hasPrevious() || isLoading()"></button>
      <button pButton type="button" label="Übersicht" class="p-button-outlined" (click)="reset()" [disabled]="isLoading()"></button>
      <button pButton type="button" icon="icon-navigate_right" class="p-button-outlined" (click)="next()" [disabled]="!hasNext() || isLoading()"></button>
    </div>
    <div class="info"><div class="info-text" [class.error]="!isValid()" *ngIf="isReady()">{{ getInfo() }}</div></div>
    <div class="right">
      <button pButton type="button" label="Übernehmen" (click)="save()" [disabled]="!isValid('Button') || isLoading()" *ngIf="isWritable()"></button>
    </div>
  </div>
  <div class="form-columns">
    <!--<div class="data-main">-->
      <sub-kundendaten
        class="data-main"
        [wiedervorlage]="true"
        [loading]="isLoading()"
        (onforminit)="onKundeform($event)"
        (onkundentypchanged)="onKundentypChange()"
      ></sub-kundendaten>
    <!--</div>-->
    <div class="data-sub">
      <div class="data-fachrichtungen" [class.notnew]="!isNew()">
        <div class="form-row">
          <div class="sub-title">Fachrichtungen</div>
        </div>
        <kunde-fachrichtungen
          *ngIf="!!kundenForm"
          [form]="kundenForm"
          [buchungen]="getBuchungen()"
          [buchungenBenutzer]="buchungenBenutzer"
          [kundenFachrichtungenReady]="subDataReady(tablenames.KUNDEFACHRICHTUNG) || isNew()"
          [kundeFachrichtungen]="kundeFachrichtungen"
          [width]="getFachrichtungenWidth()"
          [writable]="isWritable() && !isLoading()"
          (formChange)="onFachrichtungenChange()"
        ></kunde-fachrichtungen>
      </div>
      <div class="data-vertrag" [class.notnew]="!isNew()">
        <div class="form-row">
          <div class="sub-title">Vertragsdaten</div>
        </div>
        <sub-vertragsdaten
          (onforminit)="onVertragsform($event)"
          [kundenForm]="kundenForm"
          [wiedervorlage]="true"
          [loading]="isLoading()"
        ></sub-vertragsdaten>
      </div>
      <div class="data-administrator" *ngIf="isNew()">
        <div class="form-row">
          <div class="sub-title">Kunden-Administrator</div>
        </div>
        <sub-kundenadmin
          [kunden]="kunden"
          [wiedervorlage]="true"
          [loading]="isLoading()"
          (onforminit)="onAdminform($event)"
        ></sub-kundenadmin>
      </div>
    </div>
  </div>
  <div class="data-positionen" id="data-positionen">
    <div class="form-row">
      <div class="sub-title">Auftragspositionen</div>
    </div>
    <sub-positionen
      [width]="getPositionenWidth()"
      [positionen]="getPositionen()"
      [fachrichtungen]="fachrichtungen"
      [kundenForm]="kundenForm"
      [settings]="settings"
      [loading]="isLoading()"
      [writable]="isWritable()"
      (onchange)="onPositionenChanged($event)"
    ></sub-positionen>
  </div>
</div>
